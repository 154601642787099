<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Số lượng năm lấy dữ liệu</mat-label>
                        <input matInput placeholder="Placeholder" type="number" formControlName="khoan" (input)="onChange()">
                    </mat-form-field>
                </div>
              
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>


        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tổng quan dịch HIV tại tỉnh</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tongQuanHivTaiTinh.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChart1 == 7 ? 'h-100'  : (isWithChart1 == 15 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #tongQuanHivTaiTinh [isSummary]="false" ></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê mẫu dương tính qua các năm</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.thongKeMauDuongTinhCacNam.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChart2 == 7 ? 'h-100' : (isWithChart2 == 15 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #thongKeMauDuongTinhCacNam></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê nhiễm HIV theo đối tượng qua từng năm</h4>
                        <!-- <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.thongKeHIVTheoDoiTuong.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div> -->
                        <div class="h-100  overflow-x">
                            <div class="h-100">
                                <!-- <app-bar-chart #thongKeHIVTheoDoiTuong [isSummary]="true"></app-bar-chart> -->
                                <app-bar-chart #thongKeHIVTheoDoiTuong ></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê nhiễm HIV theo độ tuổi</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.theoDoTuoi.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChart3 == 7 ? 'h-100' : (isWithChart3 == 15 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #theoDoTuoi [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê nhiễm HIV theo giới tính</h4>
                        <!-- <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.nghiemTheoGioiTinh.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div> -->
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChart4 == 7 ? 'h-100' : (isWithChart4 == 15 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #nghiemTheoGioiTinh [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                        
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê nhiễm HIV theo nguy cơ</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.theoNguyCo.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChart5 == 7 ? 'h-100' : (isWithChart5 == 15 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #theoNguyCo [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>