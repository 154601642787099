<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Loại thời gian</mat-label>
            <mat-select
              panelClass="dark-mode"
              (selectionChange)="onFilterChangeCombobox()"
              formControlName="loai"
            >
              <mat-option [value]="0">Theo năm </mat-option>
              <mat-option [value]="2">Theo quý </mat-option>
              <mat-option [value]="1">Theo tháng </mat-option>
              <mat-option [value]="3">Từ ngày đến ngày </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showNam" class="mr-3">
          <ngx-dropdown
            panelClass="dark-mode"
            class="mb-0 dark-mode"
            placeholder="Chọn năm"
            [data]="YEARS"
            [formControl]="formSearch.controls.year"
            label="Chọn năm"
            (selected)="getData()"
            keyId="id"
            keyName="text"
          >
          </ngx-dropdown>
        </div>
        <div *ngIf="showQuy" class="col-xl-1 col-lg-2 col-md-6 col-sm-12">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Chọn quý</mat-label>
            <mat-select
              panelClass="dark-mode"
              (selectionChange)="onFilterChange()"
              formControlName="quy"
            >
              <mat-option
                [value]="quy.GIA_TRI_QUY"
                *ngFor="let quy of listQuy"
                >{{
                  quy.GIA_TRI_QUY == "Tất cả"
                    ? "Chọn quý"
                    : "Qúy " + quy.GIA_TRI_QUY
                }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showThang" class="mr-3">
          <ngx-dropdown
            panelClass="dark-mode"
            class="mb-0"
            placeholder="Chọn tháng"
            [data]="MONTHS"
            [formControl]="formSearch.controls.month"
            label="Chọn tháng"
            (selected)="getData()"
            keyId="id"
            keyName="text"
          >
          </ngx-dropdown>
        </div>
        <div *ngIf="showTuNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
            <mat-label>Từ ngày</mat-label>
            <input
              matInput
              formControlName="fromDate"
              placeholder="Thời gian từ ngày"
              [matDatepicker]="tuNgayPicker"
              class="c-text"
              autocomplete="off"
              class="c-text"
              (dateChange)="onFilterChange()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="tuNgayPicker"
              class="c-text"
            ></mat-datepicker-toggle>
            <mat-datepicker #tuNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="showDenNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Đến ngày</mat-label>
            <input
              matInput
              formControlName="toDate"
              placeholder="Thời gian đến ngày"
              [matDatepicker]="denNgayPicker"
              autocomplete="off"
              class="c-text"
              (dateChange)="onFilterChange()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="denNgayPicker"
              class="c-text"
            ></mat-datepicker-toggle>
            <mat-datepicker #denNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <button
          mat-raised-button
          class="btn-fullscreen"
          id="btn-fullscreen"
          (click)="showFullScreen()"
          type="button"
          matTooltip="Toàn màn hình"
        >
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>

    <div [ngStyle]="this.rowStyles">
      <div class="row small-gutters h-100">
        <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-md-5 col-sm-12 mb-3'">
          <div class="row small-gutters" style="height: calc(25% - 11px)">
            <div
              [ngClass]="
                isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'
              "
            >
              <div class="bg-card p-2 h-100">
                <h4 class="card-title">Tổng số chứng chỉ hành nghề cấp mới</h4>
                <div
                  class="card-value"
                  [style.color]="CARD_COLORS[6]"
                  (click)="
                    onClicked($event, viewDetailCCHNContent, 'CAP_MOI', 'xl')
                  "
                >
                  {{ getNumber(this.sumraryInfo.CAP_MOI) }}
                </div>
              </div>
            </div>
          </div>
          <div style="height: 15px">&nbsp;</div>
          <div class="row small-gutters" style="height: calc(25% - 11px)">
            <div
              [ngClass]="
                isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'
              "
            >
              <div class="bg-card p-2 h-100">
                <h4 class="card-title">Tổng số chứng chỉ hành nghề cấp lại</h4>
                <div
                  class="card-value"
                  [style.color]="CARD_COLORS[3]"
                  (click)="
                    onClicked($event, viewDetailCCHNContent, 'CAP_LAI', 'xl')
                  "
                >
                  {{ getNumber(this.sumraryInfo.CAP_LAI) }}
                </div>
              </div>
            </div>
          </div>
          <div style="height: 15px">&nbsp;</div>
          <div class="row small-gutters" style="height: calc(25% - 11px)">
            <div
              [ngClass]="
                isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'
              "
            >
              <div class="bg-card p-2 h-100">
                <h4 class="card-title">Tổng số chứng chỉ hành nghề thu hồi</h4>
                <div
                  class="card-value"
                  [style.color]="CARD_COLORS[0]"
                  (click)="
                    onClicked($event, viewDetailCCHNContent, 'THU_HOI', 'xl')
                  "
                >
                  {{ getNumber(this.sumraryInfo.THU_HOI) }}
                </div>
              </div>
            </div>
          </div>
          <div style="height: 15px">&nbsp;</div>
          <div class="row small-gutters" style="height: calc(25% - 11px)">
            <div
              [ngClass]="
                isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'
              "
            >
              <div class="bg-card p-2 h-100">
                <h4 class="card-title">
                  Tổng số chứng chỉ hành nghề sắp hết hạn
                </h4>
                <div
                  class="card-value"
                  [style.color]="CARD_COLORS[8]"
                  (click)="
                    onClicked(
                      $event,
                      viewDetailCCHNContent,
                      'SAP_HET_HAN',
                      'xl'
                    )
                  "
                >
                  {{ getNumber(this.sumraryInfo.SAP_HET_HAN) }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div style="height: calc(80% - 7.5px)">
            <div class="bg-chart p-2 h-100">
              <h4 class="chart-title">Thống kê loại văn bằng</h4>
              <app-bar-chart #loaiVanBang></app-bar-chart>
            </div>
          </div> -->
        </div>
        <div [ngClass]="isFitPage ? 'col-7 h-100' : 'col-md-7 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">
              Danh sách chứng chỉ hành nghề được cấp gần nhất
            </h4>
            <div class="mt-5" style="height: calc(100% - 50px)">
              <div class="row small-gutters h-100" style="overflow-y: auto">
                <div
                  [ngClass]="
                    isFitPage
                      ? 'col-3 mb-3'
                      : 'col-lg-3 col-md-4 col-sm-12 mb-3'
                  "
                  *ngFor="let element of this.LIST_CCHN"
                  style="display: flex; align-items: stretch"
                >
                  <div
                    class="p-2 text-white w-100"
                    [style]="getBorderColor(element)"
                  >
                    <h5 class="font-weight-bold">{{ element.HO_TEN }}</h5>
                    <small class="d-block"
                      >Ngày sinh: {{ element.NGAY_SINH }}</small
                    >
                    <small class="d-block"
                      >Tên văn bằng: {{ element.LOAI_VAN_BANG }}</small
                    >
                    <small class="d-block" [style]="getColor(element)"
                      >Loại: {{ element.LOAI_CAP }}</small
                    >
                    <small class="d-block">Số: {{ element.SO_CCHN }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewDetailCCHNContent let-modal>
  <div class="modal-header">
    <h2 class="modal-title mb-0">Danh sách chi tiết</h2>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-ng-modal
    [modal]="modal"
    [ELEMENT_DATA]="ELEMENT_DATA"
    [COLUMNS_SCHEMA]="listHeaderColumnCCHN"
    [pageIndex]="index"
    [length]="length"
    (paginatorOut)="getListCCHNByTrangThai($event)"
  ></app-ng-modal>
</ng-template>
