<div class="bg-plum-plate vh-100">
    <div class="d-flex h-100 justify-content-center align-items-center">
        <div class="login-wrapper mat-app-background">
            <h1 class="title text-center">KHO DỮ LIỆU Y TẾ</h1>
            <h1 class="province font-weight-bold text-center">TỈNH VĨNH LONG</h1>
            <form [formGroup]="loginForm" id="loginForm" (submit)="login()">
                <mat-form-field class="w-100 my-3">
                    <mat-label>Tên tài khoản</mat-label>
                    <input matInput formControlName="username" placeholder="Nhập tên tài khoản">
                    <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                        Vui lòng nhập tên tài khoản
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Mật khẩu</mat-label>
                    <input matInput formControlName="password" type="password" placeholder="Nhập mật khẩu tài khoản">
                    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                        Vui lòng nhập mật khẩu
                    </mat-error>
                </mat-form-field>
                <div class="mt-3">
                    <button type="submit" mat-raised-button color="primary" class="btn-block">Đăng nhập</button>
                </div>
            </form>
        </div>
    </div>
</div>