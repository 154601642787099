<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <!-- <div class="mr-auto">
                    <mat-slide-toggle color="primary" formControlName="isFitPage"
                        (change)="modeChange($event)">Toàn trang</mat-slide-toggle>
                </div> -->
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode " class="mb-0" maxWidth="150px" placeholder="Chọn loại so sánh" [data]="LOAI_SS_LIST" [formControl]="formSearch.controls.loaiSoSanh" label="Chọn loại so sánh" (selected)="getData()" keyId="MA_LSS" keyName="TEN_LSS">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" maxWidth="150px" placeholder="Chọn loại hình" [data]="LOAI_HINH_LIST" [formControl]="formSearch.controls.loaiHinh" label="Chọn loại hình" (selected)="getListCSKCB(); getData()" keyId="MA_LH" keyName="TEN_LH">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn huyện" [data]="DISTRICT_LIST" [formControl]="formSearch.controls.district" label="Chọn huyện" (selected)="getListCSKCB(); getData()" keyId="MA_HUYEN" keyName="TEN_HUYEN">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn Cơ sở y tế" [data]="DON_VI_LIST" [formControl]="formSearch.controls.coSoKCB" label="Chọn  Cơ sở y tế" (selected)="getData()" keyId="MA_DON_VI" keyName="TEN_DON_VI">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" maxWidth="140px" placeholder="Chọn loại ngày" [data]="LOAI_LIST" [formControl]="formSearch.controls.loai" label="Chọn loại ngày" (selected)="getListCSKCB(); getData()" keyId="LOAIID" keyName="TENLOAI">
                    </ngx-dropdown>
                </div>
                <!-- <div class="mr-3">
                    <mat-form-field class="mb-0" appearance="outline" style="width: 150px;">
                        <mat-label>Loại thời gian</mat-label>
                        <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="timeType" (selectionChange)="getData()">
                            <mat-option [value]="0">Theo năm</mat-option>
                            <mat-option [value]="1">Theo tháng</mat-option>
                            <mat-option [value]="2">Theo quý</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="mr-3" *ngIf="formSearch.controls.loai.value == 0">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" maxWidth="150px" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.loai.value == 1">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" maxWidth="150px" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.loai.value == 2">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" maxWidth="150px" placeholder="Chọn quý" [data]="QUARTERS" [formControl]="formSearch.controls.quarter" label="Chọn quý" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div *ngIf="formSearch.controls.loai.value == 3" class="col-lg-1 col-md-2 col-sm-1 p-0"  >
                    <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput formControlName="tungay"  placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" class="c-text"  autocomplete="off" class="c-text"  (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="tuNgayPicker" class="c-text" ></mat-datepicker-toggle>
                        <mat-datepicker #tuNgayPicker ></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="formSearch.controls.loai.value == 3" class="col-lg-1 col-md-2 col-sm-1" >
                    <mat-form-field class="w-100 mb-0" appearance="outline" >
                        <mat-label>Đến ngày</mat-label>
                        <input matInput formControlName="denngay"  placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker"  autocomplete="off" class="c-text" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="denNgayPicker" class="c-text"></mat-datepicker-toggle>
                        <mat-datepicker #denNgayPicker ></mat-datepicker>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>


        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ khám chữa bệnh có BHYT</h4>
                        <!-- <div class="h-100" style="overflow-x: auto;"> -->
                            <!-- <div class="h-100" [style]="getDynamicWidth(this.tkSotXuatHuyet.lineChartData[0], 5)"> -->
                                <app-bar-chart #tlKCBBHYT [isSummary]="true"></app-bar-chart>
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Lượt khám chữa bệnh theo loại khám</h4>
                        <!-- <div class="h-100" style="overflow-x: auto;"> -->
                            <!-- <div class="h-100" [style]="getDynamicWidth(this.tkMacSocChetSXH.barChartData[0], 20)"> -->
                                <app-bar-chart #tkKCBLoaiKham [isSummary]="true"></app-bar-chart>
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">

                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Chi phí khám chữa bệnh </h4>
                        <!-- <div class="h-100" style="overflow-x: auto;"> -->
                            <!-- <div class="h-100" [style]="getDynamicWidth(this.tkDuBaoTCM.lineChartData[0], 5)"> -->
                                <app-bar-chart #tkChiPhiKCB [isSummary]="true" [summaryToMillion]="true"></app-bar-chart>
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>

                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng cấp cứu, tử vong </h4>
                        <!-- <div class="h-100" style="overflow-x: auto;"> -->
                            <!-- <div class="h-100" [style]="getDynamicWidth(this.tkMacChetTCM.barChartData[0], 20)"> -->
                                <app-bar-chart #tkCCTV></app-bar-chart>
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>