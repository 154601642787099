import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaiChinhKeHoachRoutingModule } from './tai-chinh-ke-hoach-routing.module';
import { TrangThietBiComponent } from './trang-thiet-bi/trang-thiet-bi.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaoCaoTongHopComponent } from './bao-cao-tong-hop/bao-cao-tong-hop.component';
import { TaiSanNhaDatComponent } from './tai-san-nha-dat/tai-san-nha-dat.component';
import { DauTuCongComponent } from './dau-tu-cong/dau-tu-cong.component';
import { TaiChinhKeToanComponent } from './tai-chinh-ke-toan/tai-chinh-ke-toan.component';

@NgModule({
  declarations: [TrangThietBiComponent, BaoCaoTongHopComponent,TaiSanNhaDatComponent, DauTuCongComponent ,TaiChinhKeToanComponent],
  imports: [
    CommonModule,
    TaiChinhKeHoachRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class TaiChinhKeHoachModule { }
