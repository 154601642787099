import { apiUrl } from "./../constant/api-url";
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TTKiemNghiemDuocPhamMyPhamService extends BaseService {

  //get 4 the tong
  get4TheTong(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_TONG_4_THE, requestParam);
  }
  //get lấy mẫu
  getMauLay(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_MAU_LAY, requestParam);
  }
  getMauGui(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_MAU_GUI, requestParam);
  }
  getMauLayKhongDat(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_MAU_LAY_KHONG_DAT, requestParam);
  }
  getMauGuiKhongDat(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_MAU_GUI_KHONG_DAT, requestParam);
  }
  getMauGuiTheoCSKB(requestParam): Observable<any> {
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM.GET_MAU_GUI_THEO_CSKCB, requestParam);
  }
  //get 9 the tong
  get9TheTong(requestParam): Observable<any>{
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM_HGI.GET_TONG_9_THE, requestParam);
  }
  getTLDuocMyPhamDat(requestParam): Observable<any>{
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM_HGI.GET_TYLE_KN_DUOC_MYPHAM, requestParam);
  }
  getTLLayDuocMyPhamDat(requestParam): Observable<any>{
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM_HGI.GET_TYLE_LAY_KN_DUOC_MYPHAM, requestParam);
  }
  getTLGuiDuocMyPhamDat(requestParam): Observable<any>{
    return super.get(apiUrl.TT_KN_DUOCPHAM_MYPHAM_HGI.GET_TYLE_GUI_KN_DUOC_MYPHAM, requestParam);
  }
}
