import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhongdalieuComponent } from './phongdalieu/phongdalieu.component';
import { TamthanComponent } from './tamthan/tamthan.component';

const routes: Routes = [
  { path: 'tam-than-kinh-phong-da-lieu', component: PhongdalieuComponent },
  { path: 'tam-than-kinh-phong-da-lieu/thong-tin-chung-phong-da-lieu', component: PhongdalieuComponent },
  { path: 'tam-than-kinh-phong-da-lieu/thong-tin-chung-tam-than', component: TamthanComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhongdalieuRoutingModule { }
