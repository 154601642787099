import { Component, OnInit } from '@angular/core';
import { SITE_TITLE, TEN_TINH } from "src/app/constant/system-constant";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public siteTitle = SITE_TITLE;
  public tenTinh = TEN_TINH;
  userStorage = JSON.parse(sessionStorage.user);
  constructor() { }
  TT_USER: any;

  ngOnInit(): void {
      
   
  this.TT_USER = this.userStorage?.HO_TEN
  }

  fullscreen() {
    var doc = document.documentElement;
    if (doc.requestFullscreen) {
      doc.requestFullscreen();
    }
  }
}
