<div class="main-wrapper bg-ioc">
    <div class="row small-gutters">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
            <div class="bg-chart p-3">
                <h3 class="text-white text-center text-uppercase">Biểu đồ đơn thống kê tình hình ABC</h3>
                <app-bar-chart #chart2 [barChartData]="barChartData2" [barChartLegend]="false"
                    [barChartLabels]="barChartLabels2"></app-bar-chart>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
            <div class="bg-chart p-3">
                <h3 class="text-white text-center text-uppercase">Biểu đồ đôi thống kê tình hình ABC</h3>
                <app-bar-chart #chart1 [barChartData]="barChartData1" [barChartLabels]="barChartLabels1">
                </app-bar-chart>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3 d-flex align-items-stretch">
            <div class="bg-chart p-3 w-100">
                <h3 class="text-white text-center text-uppercase">Biểu đồ đường thống kê tình hình ABC</h3>
                <app-line-chart #chart3 [lineChartData]="lineChartData" [lineChartColors]="lineChartColors"
                    [lineChartLabels]="lineChartLabels" [lineChartLegend]="false">
                </app-line-chart>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3 d-flex align-items-stretch">
            <div class="bg-chart p-3">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-meter-highchart #chart4 [data]="data1" [color]="color1"></app-meter-highchart>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-meter-highchart #chart5 [data]="data2" [color]="color2"></app-meter-highchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>