<div class="main-wrapper">
    <mat-toolbar class="title mb-3">
        <mat-toolbar-row>
            <div class="name-page">DANH MỤC ĐƠN VỊ HÀNH CHÍNH</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-card>
        <form [formGroup]="formSearch" id="formSearch">
            <div class="row gx-5">

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" fxFlex="grow" class="d-block mb-0">
                        <mat-label>Tỉnh/Thành</mat-label>
                        <mat-select formControlName="tinh" msInfiniteScroll (infiniteScroll)="getNextBatch()" [complete]="isFullListTinh" [(ngModel)]="selectedValueTinh">
                            <div>
                                <div>
                                    <input matInput #searchInputTinh (keyup)="onEnter($event)" (keydown)="$event.stopPropagation()" placeholder="Nhập từ khóa" class="search-nested" />
                                    <button mat-icon-button class="clear-search-nested" *ngIf="searchInputTinh.value !== ''" (click)="searchInputTinh.value = ''; resetDsTinhSearchForm()">
                                        <mat-icon> close </mat-icon>
                                    </button>
                                </div>
                            </div>
                            <mat-option [value]="0">Tất cả</mat-option>
                            <mat-option [value]="tinh.ID" *ngFor="let tinh of dsTinh">{{tinh.TEN_TINH}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" fxFlex="grow" class="d-block mb-0">
                        <mat-label>Huyện/Thị/Thành</mat-label>
                        <mat-select formControlName="huyen" msInfiniteScroll (infiniteScroll)="getNextBatch(selectedValueTinh)" [complete]="isFullListHuyen" [(ngModel)]="selectedValueHuyen">
                            <div>
                                <div>
                                    <input matInput #searchInputHuyen (keyup)="onEnter($event, selectedValueTinh)" (keydown)="$event.stopPropagation()" placeholder="Nhập từ khóa" class="search-nested" />
                                    <button mat-icon-button class="clear-search-nested" *ngIf="searchInputHuyen.value !== ''" (click)="searchInputHuyen.value = ''; resetDsHuyenSearchForm(selectedValueTinh)">
                                        <mat-icon> close </mat-icon>
                                    </button>
                                </div>
                            </div>
                            <mat-option [value]="0">Tất cả</mat-option>
                            <mat-option [value]="huyen.ID" *ngFor="let huyen of dsHuyen">{{huyen.TEN_HUYEN}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card>

    <div class="mt-3 mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="stt" class="mat-card">
                <th mat-header-cell *matHeaderCellDef class="text-center">STT</th>
                <td mat-cell *matCellDef="let element; let i = index;" class="text-center">{{ (i + 1) + pageIndex * pageSize }}</td>
            </ng-container>

            <ng-container matColumnDef="maDVHanhChinh">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Mã Đơn Vị Hành Chính </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.maDVHC }} </td>
            </ng-container>

            <ng-container matColumnDef="tenDVHanhChinh">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Tên Đơn Vị Hành Chính </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.tenDVHC }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="d-flex justify-content-end">
            <mat-paginator class="w-100" #paginator showFirstLastButtons [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageOption" (page)="paginatorChange(5, $event)">
            </mat-paginator>
        </div>
    </div>

</div>