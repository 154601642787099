<div class= "main-wrapper bg-ioc vh-100" >
    <div class="b-header" >
        <div class="d-flex justify-content-end mb-3 pt-2 w-100">
            <form class="dark-mode mr-4 w-100" [formGroup]="formSearch" id="formSearch">
                <div class="row align-items-center justify-content-end ">
                    <div *ngIf="!isHuyen" class="col-xl-1 col-lg-2 col-md-3 col-sm-12" >
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Loại hình</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeLoaiHinh()" formControlName="loaihinh" >
                                <mat-option [value]="2">Tất cả</mat-option>
                                <mat-option [value]="0">Công lập</mat-option>
                                <mat-option [value]="1">Tư nhân</mat-option>
                             </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!isHuyen" class="col-xl-2 col-lg-2 col-md-3 col-sm-12" >
                        <div class="ml-auto mr-3 w-100">
                            <ngx-dropdown panelClass="dark-mode" class="mb-0 w-100"  placeholder="Chọn huyện/thị/thành" [data]="listHuyen"  (selected)="onFilterChange()"
                                [formControl]="formSearch.controls.huyen" label="Huyện/Thị/Thành"
                                (selected)="getListCSKCB()" keyId="MA_HUYEN" keyName="TEN_HUYEN">
                            </ngx-dropdown>
                        </div>
                    </div>
                    <div *ngIf="isHuyen" class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <div class="ml-auto mr-3 w-100">
                            <mat-form-field class="mb-0 w-100 " appearance="outline" floatLabel="always">
                                <mat-label>Quận/huyện</mat-label>
                                <input matInput readonly="true" formControlName="tenhuyen" />
                            </mat-form-field>
                        </div>
                        
                    </div> 
                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <ngx-dropdown class="mb-0 w-100" panelClass="dark-mode"  [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn Cơ sở y tế" [data]="listCSKCB" 
                            [label]="!lableCoSoKCB ?'Chọn Cơ sở y tế':'Tất cả'"
                            [formControl]="formSearch.controls.coSoKCB" keyId="MA_DON_VI" keyName="TEN_DON_VI"
                            (selected)="onFilterChangeCSKB()">
                        </ngx-dropdown>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline" >
                            <mat-label>Loại thời gian</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
                                <mat-option [value]="0">Theo năm</mat-option>
                                <mat-option [value]="1">Theo quý </mat-option>
                                <mat-option [value]="2">Theo tháng </mat-option>
                                <mat-option [value]="3">Theo ngày </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNam" class="col-xl-1 col-lg-2 col-md-6 col-sm-12" >
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn năm</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="nam">
                                <mat-option [value]="nam.GIA_TRI_NAM" *ngFor="let nam of listNam">{{nam.GIA_TRI_NAM}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div  *ngIf="showQuy" class="col-xl-1 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn quý</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="quy">
                                <mat-option [value]="quy.GIA_TRI_QUY" *ngFor="let quy of listQuy">{{ quy.GIA_TRI_QUY =='Tất cả'?'Tất cả': 'Qúy ' + quy.GIA_TRI_QUY}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showThang" class="col-xl-1 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="thang">
                                <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showTuNgay" class="col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                            <mat-label>Từ ngày</mat-label>
                            <input matInput formControlName="tuNgay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" class="c-text"  autocomplete="off" class="c-text"  (dateChange)="onFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="tuNgayPicker" class="c-text" ></mat-datepicker-toggle>
                            <mat-datepicker #tuNgayPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showDenNgay" class="col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline" >
                            <mat-label>Đến ngày</mat-label>
                            <input matInput formControlName="denNgay" placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker"  autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
                            <mat-datepicker-toggle matSuffix [for]="denNgayPicker" class="c-text" ></mat-datepicker-toggle>
                            <mat-datepicker #denNgayPicker ></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    
                </div>
            </form>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="!isFullScreen" matTooltip="Toàn màn hình">
                <mat-icon color="primary">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="isFullScreen" matTooltip="Thu nhỏ">
                <mat-icon color="primary">fullscreen_exit</mat-icon>
            </button>
        </div>
    </div>
    <div class="b-body pl-2 pr-2">
        <div class="b-body-top">
            <div class="row small-gutters h-100">
                <div class="col-md-4 h-100 ">
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tổng lượt Khám bệnh</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{SL_KCB.TONG_SL_KHAMBENH}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2" style="max-height: 50%; min-height: 50%;">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Trung ƯƠNG</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{SL_KCB.SL_KHAMBENH_TRUNGUONG}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2" style="max-height: 50%; min-height: 50%;">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Huyện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{SL_KCB.SL_KHAMBENH_CAPHUYEN}}  </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2" style="max-height: 50%; min-height: 50%;">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến tỉnh</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{SL_KCB.SL_KHAMBENH_CAPTINH}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2" style="max-height: 50%; min-height: 50%;">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Xã</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{SL_KCB.SL_KHAMBENH_CAPXA}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100 p-2" >
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tổng lượt điều trị ngoại trú</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{SL_NGOAI_TRU.SL_NGOAI_TRU}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Trung ƯƠNG</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{SL_NGOAI_TRU.SL_NGOAI_TRU_TRUNGUONG}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Huyện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{SL_NGOAI_TRU.SL_NGOAI_TRU_CAPHUYEN}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến tỉnh</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{SL_NGOAI_TRU.SL_NGOAI_TRU_CAPTINH}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Xã</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{SL_NGOAI_TRU.SL_NGOAI_TRU_CAPXA}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 h-100 p-2 " >
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Biểu đồ tổng lượt khám bệnh</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.luotKhamBenh.lineChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChartKCB == 10 ? 'h-100'  : (isWithChartKCB == 50 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-line-chart #luotKhamBenh [isSummary]="true"></app-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Biểu đồ thống kê tổng lượt điều trị nội trú - Ngoại trú</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.noiTruNgoaiTruChart.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChartNT == 10 ? 'h-100'  : (isWithChartNT == 50 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #noiTruNgoaiTruChart [isSummary]="false" ></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="b-body-buttom">
            <div class="row small-gutters h-100">
                <div class="col-md-4 h-100 ">
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tổng lượt điều trị nội trú</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #9b19f5;"> {{SL_NOI_TRU.SL_NOI_TRU}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Trung ƯƠNG</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #9b19f5;"> {{SL_NOI_TRU.SL_NOI_TRU_TRUNGUONG}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến Huyện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #9b19f5;"> {{SL_NOI_TRU.SL_NOI_TRU_CAPHUYEN}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Tuyến tỉnh</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #9b19f5;"> {{SL_NOI_TRU.SL_NOI_TRU_CAPTINH}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Số lượng cuối kỳ</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #9b19f5;"> {{SL_NOITRU_CUOIKY.SL_NOI_TRU_CUOIKY}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Chuyển viện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00FF00;"> {{SL_CHUYENVIEN}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">CA NẶNG XIN VỀ</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #FF1493;"> {{SL_CANANG_XINVE}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Vào viện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0bb4ff;"> {{SL_VAOVIEN}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">lượt cấp cứu</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #ff0c0c;"> {{SL_CAPCUU}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100">
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Ra viện</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #ffa300;"> {{SL_RAVIEN}}  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row small-gutters h-50 p-2">
                                <div class="number-card number-card-content1">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Ca tử vong</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #de25da;"> {{SL_TUVONG}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Biểu đồ thống kê tổng lượt lượt ra/vào viện</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.raVaoNgayChart.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChartRV == 10 ?'h-100'  : (isWithChartRV == 50 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-bar-chart #raVaoNgayChart [isSummary]="false" [xLabelMaxLength]="15"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Biểu đồ thống kê tổng lượt cấp cứu - ca tử vong</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.capCuuTuVongChart.lineChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5 overflow-x">
                            <div [ngClass] = "isWithChartCT == 10 ? 'h-100'  : (isWithChartCT == 50 ? 'h-100 w-chart' : 'h-100 w-chart150') ">
                                <app-line-chart #capCuuTuVongChart [isSummary]="false" [lineChartLegend]=true [xLabelMaxLength]="12"></app-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>