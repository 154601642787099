import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class Api831Service extends BaseService {
  getTKGioiTinh(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.THONG_KE_GIOI_TINH, requestParam);
  }

  getTKHoKhau(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.THONG_KE_HO_KHAU, requestParam);
  }

  getTKHSSK(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.THONG_KE_HSSK, requestParam);
  }

  getTKTienSuBenhTat(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.TK_TIEN_SU_BENH_TAT, requestParam);
  }

  getTKTyLeSayThai(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.TK_TY_LE_SAY_THAI, requestParam);
  }

  getTKTyLePhaThai(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.TK_TY_LE_PHA_THAI, requestParam);
  }
  getTKHSSKTheoDoTuoi(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.THONG_KE_HSSK_THEO_DO_TUOI, requestParam);
  }

  /*
  Tạo lập hssk
  */

  getTKSLHSSK_TaoLap_CapNhat(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.TAO_LAP_HO_SO_SUC_KHOE.THONG_KE_DS_SL_HSSK_TAO_LAP_VA_CAP_NHAT, requestParam);
  }

  getTLHSSK_TaoLap_CapNhat(requestParam?): Observable<any> {
    return super.get(apiUrl.QD831.TAO_LAP_HO_SO_SUC_KHOE.TI_LE_HSSK_CAP_NHAT_VA_TAO_LAP, requestParam);
  }

}
