import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import { Qd831RoutingModule } from './qd831-routing.module';
import { DashboardTaolapHsskComponent } from './dashboard-taolap-hssk/dashboard-taolap-hssk.component';



@NgModule({
  declarations: [DashboardTaolapHsskComponent],
  imports: [
    CommonModule,
    Qd831RoutingModule,
    SharedModule
  ]
})
export class Qd831Module { }
