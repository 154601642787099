<div class= "main-wrapper bg-ioc vh-100 " >
    <div class=" b-header " >
        <div class="d-flex justify-content-end mb-3 pt-2 w-100">
            <form class="dark-mode mr-4 w-100" [formGroup]="formSearch" id="formSearch">
                <div class="row align-items-center justify-content-end ">
                    <div  class="col-xl-2 col-lg-2 col-md-6 col-sm-12" >
                        <mat-form-field class="w-100 mb-0" appearance="outline" >
                            <mat-label>Loại thời gian</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
                                <mat-option [value]="0">Theo năm</mat-option>
                                <mat-option [value]="1">Theo tháng </mat-option>
                                <mat-option [value]="2">Từ ngày đến ngày </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNam" class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn năm</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="nam">
                                <mat-option [value]="nam.GIA_TRI_NAM" *ngFor="let nam of listNam"> Năm {{ nam.GIA_TRI_NAM}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showThang" class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="thang">
                                <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNgay" class="mr-3">
                        <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                          <mat-label>Từ ngày</mat-label>
                          <input
                            matInput
                            formControlName="fromDate"
                            placeholder="Thời gian từ ngày"
                            [matDatepicker]="tuNgayPicker"
                            class="c-text"
                            autocomplete="off"
                            class="c-text"
                            (dateChange)="onFilterChange()"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="tuNgayPicker"
                            class="c-text"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #tuNgayPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNgay" class="mr-3">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                          <mat-label>Đến ngày</mat-label>
                          <input
                            matInput
                            formControlName="toDate"
                            placeholder="Thời gian đến ngày"
                            [matDatepicker]="denNgayPicker"
                            autocomplete="off"
                            class="c-text"
                            (dateChange)="onFilterChange()"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="denNgayPicker"
                            class="c-text"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #denNgayPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                </div>
            </form>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="!isFullScreen" matTooltip="Toàn màn hình">
                <mat-icon color="primary">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="isFullScreen" matTooltip="Thu nhỏ">
                <mat-icon color="primary">fullscreen_exit</mat-icon>
            </button>
        </div>
    </div>
    <div class="b-body pl-2 pr-2">
        <div class="b-body-top">
            <div class="row small-gutters h-100">
                <div class="col-md-6 h-100 ">
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">CHỈ TIÊU PHÁT HIỆN LAO</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{dsTheTong.PHATHIEN_TIEU_CHI}} </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">CHỈ TIÊU LAO KHÁNG THUỐC</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{dsTheTong.KHANGTHUOC_TIEU_CHI}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">ĐIỀU TRỊ LAO CÁC THỂ</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #d2980d;"> {{dsTheTong.CACTHE_TIEU_CHI}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row small-gutters h-50">
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">Thực Hiên PHÁT HIỆN LAO</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #0060ff;"> {{dsTheTong.PHATHIEN_THUC_HIEN}}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">THỰC HIỆN ĐIỀU TRỊ LAO KHÁNG THUỐC</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #00E0BB;"> {{dsTheTong.KHANGTHUOC_THUC_HIEN}}  </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-md-4 w-100 h-100 p-2">
                            <div class="w-100 h-100">
                                <div class="number-card number-card-content1 h-100">
                                    <div class="b-card h-100">
                                        <h3 class="number-card-text">THỰC HIỆN ĐIỀU TRỊ LAO KHÁNG CÁC THỂ</h3>
                                        <div [ngClass]="isFullScreen? 'number-card-number  font-30' : 'number-card-number'" style="color: #d2980d;">  {{dsTheTong.CACTHE_THUC_HIEN}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h3 class="chart-title">Biểu đồ Khám phát hiện Lao</h3>
                        <app-bar-chart #khamPhatHienLao [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="b-body-buttom">
            <div class="row small-gutters h-100">
                <div class="col-md-6 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h3 class="chart-title">Biểu đồ Thu nhận điều trị lao các thể</h3>
                        <app-bar-chart #dieuTriLaoCacThe [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                    </div>
                </div>
                <div class="col-md-6 h-100 p-2" >
                    <div class="bg-chart p-2 h-100">
                        <h3 class="chart-title">Biểu đồ Thu nhận điều trị lao kháng thuốc</h3>
                        <app-bar-chart #dieuTriLaoKhangThuoc [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
