<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3"  [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="nam" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                
                
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
           
        </form>

        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title" style="color:#70ad47">Số nguồn vốn</h4>
                                <div class="card-value" style="color:#70ad47"> {{ nFormatter(this.DOANHTHU.NGUON_VON, 0) }} </div>
                                <h4 class="card-title" style="color:#70ad47">ĐVT: triệu đồng</h4>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title" style="color:#ffc000">Số chỉ tiêu</h4>
                                <div class="card-value" style="color:#ffc000"> {{ nFormatter(this.DOANHTHU.CHI_TIEU, 0) }} </div>
                                <h4 class="card-title" style="color:#ffc000">ĐVT: triệu đồng</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters h-100">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-lg-6 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Thống kê kết quả tổng hợp (đvt: triệu đồng)</h4>
                                <div class="h-100" [style]="getDynamicWidth(this.taiChinhTongHop.barChartData[0], 23)">
                                    <app-bar-chart #taiChinhTongHop [isSummaryVN]="true" [summaryToMillion]="true"></app-bar-chart>
                                </div>
                                
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-lg-6 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Thống kê Tình hình tài chính (đvt: triệu đồng)</h4>
                                <div class="h-100" [style]="getDynamicWidth(this.tinhHinhTaiChinh.barChartData[0], 30)">
                                    <app-bar-chart #tinhHinhTaiChinh [isSummaryVN]="true" [summaryToMillion]="true"></app-bar-chart>
                                </div>
                                
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title" style="color:#5b9bd5">Tổng thặng dư/Thâm hụt</h4>
                                <div class="card-value" style="color:#5b9bd5"> {{ nFormatter(this.DOANHTHU.THANGDU_THAMHUT, 0) }} </div>
                                <h4 class="card-title" style="color:#5b9bd5">ĐVT: triệu đồng</h4>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title" style="color:#f8696b">Tổng chi phí thuế TNDN</h4>
                                <div class="card-value" style="color:#f8696b"> {{ nFormatter(this.DOANHTHU.CHI_PHI_THUE, 0) }} </div>
                                <h4 class="card-title" style="color:#f8696b">ĐVT: triệu đồng</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters h-100">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-lg-6 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Thống kê Kinh Phí hoạt động (đvt: triệu đồng)</h4>
                                <div class="h-100" [style]="getDynamicWidth(this.khinhPhiHoatDong.barChartData[0], 25)">
                                    <app-bar-chart #khinhPhiHoatDong [isSummaryVN]="true" [summaryToMillion]="true"></app-bar-chart>
                                </div>
                            </div>
                        </div>
                        
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-lg-6 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Thống kê hoạt động đầu tư công (đvt: triệu đồng)</h4>
                                <div class="h-100" [style]="getDynamicWidth(this.hoatDongDauTuCong.barChartData[0], 25)">
                                    <app-bar-chart #hoatDongDauTuCong [isSummaryVN]="true" [summaryToMillion]="true"></app-bar-chart>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>