<div class="main-wrapper">
    <mat-toolbar class="title mb-3">
        <mat-toolbar-row>
            <div class="name-page">DANH MỤC ĐƠN VỊ</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-card>
        <form [formGroup]="formSearch" id="formSearch" (submit)="search(null)">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 align-items-center">
                    <mat-form-field class="w-100 mb-0" appearance="outline">
                        <mat-label>Từ khóa</mat-label>
                        <input matInput formControlName="keyword" placeholder="Nhập từ khóa tìm kiếm">
                    </mat-form-field>
                </div>
                <button mat-raised-button color="primary" id="btn-search" type="submit"> Tìm kiếm </button>
            </div>
        </form>
    </mat-card>

    <div class="mt-3 mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="tenDV">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Tên Đơn Vị </th>
                <td mat-cell *matCellDef="let data">
                    <div class="d-flex align-items-center">
                        <button mat-icon-button [style.visibility]="!data.expandable ? 'hidden' : ''" [style.marginLeft.px]="data.level * 32" (click)="search(data); ">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-icon color="primary">
                            {{data.cap == 4 ? 'local_hospital' : 'domain_add'}}
                        </mat-icon>
                        <span class="ml-2">{{data.name}} </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="maDV">
                <th mat-header-cell *matHeaderCellDef class="text-center">Mã Đơn Vị</th>
                <td mat-cell *matCellDef="let data" class="text-center">{{data.maDV}} </td>
            </ng-container>

            <ng-container matColumnDef="cap">
                <th mat-header-cell *matHeaderCellDef class="text-center">Tuyến</th>
                <td mat-cell *matCellDef="let data" class="text-center">{{data.cap}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>