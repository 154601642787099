import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { CDCService } from "src/app/services/trung-tam-cdc.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { SnackbarService } from "src/app/services/snackbar.service";
// import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";

@Component({
  selector: "app-hiv-aids",
  templateUrl: "./hiv-aids.component.html",
  styleUrls: ["./hiv-aids.component.scss"],
})
export class HivAidsComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    khoan: new FormControl(3),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  isWithChart1: any;
  isWithChart2: any;
  isWithChart3: any;
  isWithChart4: any;
  isWithChart5: any;
  isWithChart6: any;
  
  @ViewChild("tongQuanHivTaiTinh", { static: true }) tongQuanHivTaiTinh: BarChartComponent;
  @ViewChild("thongKeMauDuongTinhCacNam", { static: true }) thongKeMauDuongTinhCacNam: BarChartComponent;
  @ViewChild("thongKeHIVTheoDoiTuong", { static: true }) thongKeHIVTheoDoiTuong: BarChartComponent;
  @ViewChild("nghiemTheoGioiTinh", { static: true }) nghiemTheoGioiTinh: BarChartComponent;
  @ViewChild("theoNguyCo", { static: true }) theoNguyCo: BarChartComponent;
  @ViewChild("theoDoTuoi", { static: true }) theoDoTuoi: BarChartComponent;

  slDTDPhatHien: number;
  slDTDQuanLyDieuTri: number;
  slHuyetApPhatHien: number;
  slHuyetApQuanLyDieuTri: number;

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdcService: CDCService,
    private snackbar: SnackbarService,
    
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    
    this.detectMode();
    this.getData();    
    this.thongKeHIVTheoDoiTuong.barChartType = "horizontalBar";
    this.tongQuanHivTaiTinh.barChartOptions.legend.display = false;
    this.thongKeMauDuongTinhCacNam.barChartOptions.legend.display = false;
  }

  public getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if(this.formSearch.controls.khoan.value != null){
      
      params["khoan"] = this.formSearch.controls.khoan.value;
    }
    else{
      params["khoan"] = "0"
    }
    return params;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.tongQuanHivTaiTinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.thongKeMauDuongTinhCacNam.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.nghiemTheoGioiTinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.theoNguyCo.barChartOptions.maintainAspectRatio = !this.isFitPage;
    
    this.theoDoTuoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.thongKeHIVTheoDoiTuong.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.thongKeHIVTheoDoiTuong.barChartOptions.legend.position = "right";
    this.thongKeHIVTheoDoiTuong.barChartOptions.legend.align = "center";
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  public getData(): void {
    this.getTongQuanHivTaiTinh()
    this.getDuongTinhQuaCacNam()
    this.getNguoiNghiemTheoGioiTinh()
    this.getNguoiNhiemTheoNguyCo()
    this.getNguoiNhiemTheoDoTuoi()
    this.getNguoiNghiemTheoDoiTuong()
  }
  onChange(){
    this.getData()
  }
  getTongQuanHivTaiTinh(): void {
    let obj = this.getFilter();
    this.isWithChart1 = 7
    this.subscription.push(this.cdcService.getTongQuanHivTaiTinh(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }

      this.tongQuanHivTaiTinh.barChartLabels = rs.data.map(x => x.NAM);
      let arrLUY_TICH_NHIEM = rs.data.map(x => x.LUY_TICH_NHIEM);
      let arrCON_SONG = rs.data.map(x => x.CON_SONG);
      let arrDANG_DIEU_TRI = rs.data.map(x => x.DANG_DIEU_TRI);
      if(rs.data.length <= 7){
          this.isWithChart1 = 7
        }else if(rs.data.length <= 15){
          this.isWithChart1 = 15
        }else{
          this.isWithChart1 = 0
        }
      this.tongQuanHivTaiTinh.barChartData = [
        { 
          data: arrLUY_TICH_NHIEM, 
          label: "Lũy tích nhiễm",
          backgroundColor: COLOR_PALETTES.PALETTE_2[3],
          
        },
        {
          data: arrCON_SONG, 
          label: "Còn sống",
          backgroundColor: COLOR_PALETTES.PALETTE_6[1],
        },
        {
          data: arrDANG_DIEU_TRI, 
          label: "Đang điều trị",
          backgroundColor: COLOR_PALETTES.PALETTE_6[2],
        }
      ];
      this.tongQuanHivTaiTinh.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] > '0'; 
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getDuongTinhQuaCacNam(): void {
    let obj: any = this.getFilter();
    this.isWithChart2 ="7"
    this.subscription.push(this.cdcService.getDuongTinhQuaCacNam(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }

      this.thongKeMauDuongTinhCacNam.barChartLabels = rs.data.map(x => x.NAM);
      let arrSO_LUONG_XET_NGHIEM = rs.data.map(x => x.SO_LUONG_XET_NGHIEM)
      let arrSO_LUONG_NHIEM = rs.data.map(x => x.SO_LUONG_NHIEM)
      if(rs.data.length <= 7){
          this.isWithChart2 = 7
        }else if(rs.data.length <= 15){
          this.isWithChart2 = 15
        }else{
          this.isWithChart2 = 0
        }
      this.thongKeMauDuongTinhCacNam.barChartData = [
        {
          data: arrSO_LUONG_XET_NGHIEM,
          label: "Số lượt xét nghiệm",
          backgroundColor: COLOR_PALETTES.PALETTE_3[2],
          order: 1
        },
        {
          data: arrSO_LUONG_NHIEM,
          label: "Số lượng nhiệm",
          backgroundColor: COLOR_PALETTES.PALETTE_3[4],
          order: 2
        }
      ];
      this.thongKeMauDuongTinhCacNam.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: rs.data.map(x => x.TY_LE),
        label: "Tỷ lệ",
        borderColor: COLOR_PALETTES.PALETTE_3[3],
        backgroundColor: COLOR_PALETTES.PALETTE_3[3],
        order: 3
      });
      this.thongKeMauDuongTinhCacNam.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
                
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] > '0'; 
              // or >= 1 or !== 0 or ...
            },
            
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  public getNguoiNghiemTheoGioiTinh(): void {
    let obj: any = this.getFilter();
    let arrNam = [];
    let arrNu = [];
    let arrYear = [];
    let listData = [];
    this.subscription.push(
      this.cdcService.getNguoiNghiemTheoGioiTinh(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrNam.push(e.GIOI_TINH_NAM );
              arrNu.push(e.GIOI_TINH_NU);
              arrYear.push(e.NAM);
            });
          }
          if(listData.length <= 7){
            this.isWithChart4 = "7"
          }else if(listData.length <= 15){
            this.isWithChart4 = "10"
          }else{
            this.isWithChart4 = "0"
          }
          //Set label for chart
          this.nghiemTheoGioiTinh.barChartLabels = arrYear;
          //Set data for chart
          this.nghiemTheoGioiTinh.barChartData = [
            {
              data: arrNam,
              label: "Tổng giới tính nam",
              backgroundColor: COLOR_PALETTES.ORANGE_TO_PURPLE[1],
              order:2
            },
            {
              data: arrNu,
              label: "Tổng giới tính nữ",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
              order:1
            },
          ];
          //Set option for chart
          this.nghiemTheoGioiTinh.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                    
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    min: 0,
                    max: 100,
                    display:true,
                    fontColor: "#fff",
                    beginAtZero: true,
                    callback: function(value) {
                        return value + '%';
                    }
                  },
                },
              ],
            },
            
            plugins: {
              
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; // or >= 1 or !== 0 or ...
                },
              },
              plugins: [htmlLegendPlugin],
              
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer.querySelector('ul');
    
      if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        // listContainer.style.margin = 0;
        // listContainer.style.padding = 0;
    
        legendContainer.appendChild(listContainer);
      }
    
      return listContainer;
    };
    
    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);
    
        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }
    
        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
    
        items.forEach(item => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';
    
          li.onclick = () => {
            const {type} = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
            chart.update();
          };
    
          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '20px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '20px';
    
          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          // textContainer.style.margin = 0;
          // textContainer.style.padding = 0;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
    
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);
    
          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    };
  }
  
  public getNguoiNhiemTheoNguyCo(): void {
    let obj: any = this.getFilter();
    let arr = [];
    let arrMau = [];
    let arrTinhDuc = [];
    let arrLayTruyenMeCon = [];
    let arrKhongRo = [];
    let arrNam = [];
    let listData = [];
    this.subscription.push(
      this.cdcService.getNguoiNhiemTheoNguyCo(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrMau.push(e.MAU );
              arrTinhDuc.push(e.TINH_DUC);
              arrLayTruyenMeCon.push(e.LAY_TRUYEN_ME_CON );
              arrKhongRo.push(e.KHONG_RO);
              arrNam.push(e.NAM);
            });
          }
          if(listData.length <= 7){
            this.isWithChart5 = "7"
          }else if(listData.length <= 15){
            this.isWithChart5 = "10"
          }else{
            this.isWithChart5 = "0"
          }
          //Set label for chart
          this.theoNguyCo.barChartLabels = arrNam;
          //Set data for chart
          this.theoNguyCo.barChartData = [
            {
              data: arrMau,
              label: "Mau",
              backgroundColor: "#8b55a3",
              order:1
            },
            {
              data: arrTinhDuc,
              label: "Tình dục",
              backgroundColor: "#e72e3e",
              order:2
            },
            {
              data: arrLayTruyenMeCon,
              label: "Lây truyền mẹ con",
              backgroundColor: "#e86131",
              order:3
            },
            {
              data: arrKhongRo,
              label: "Không rõ",
              backgroundColor: "#e5e434",
              order:4
            },
          ];
          //Set option for chart
          this.theoNguyCo.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    min:0,
                    max:100,
                    display:true,
                    fontColor: "#fff",
                    beginAtZero: true,
                    callback: function(value) {
                        return value + '%';
                    }
                  },
                },
              ],
            },
            
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; // or >= 1 or !== 0 or ...
                },
              },
             
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
   
  }
  public getNguoiNhiemTheoDoTuoi(): void {
    let obj: any = this.getFilter();
    let arrDuoi14 = [];
    let arrDen24 = [];
    let arrDen49 = [];
    let arrTren50 = [];
    let arrNam = [];
    let listData = [];
    this.subscription.push(
      this.cdcService.getNguoiNhiemTheoDoTuoi(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrDuoi14.push(e.DUOI_14 );
              arrDen24.push(e.TU_15_DEN_24);
              arrDen49.push(e.TU_25_DEN_49 );
              arrTren50.push(e.TREN_50);
              arrNam.push(e.NAM);
            });
          }
          if(listData.length <= 7){
            this.isWithChart3 = "7"
          }else if(listData.length <= 15){
            this.isWithChart3 = "10"
          }else{
            this.isWithChart3 = "0"
          }
          //Set label for chart
          this.theoDoTuoi.barChartLabels = arrNam;
          //Set data for chart
          this.theoDoTuoi.barChartData = [
            {
              data: arrDuoi14,
              label: "Dưới 14 tuổi",
              backgroundColor: "#66be50",
              order:1
            },
            {
              data: arrDen24,
              label: "Từ 15 đến 24 tuổi",
              backgroundColor: "#e89632",
              order:2
            },{
              data: arrDen49,
              label: "Từ 25 đến 49 tuổi",
              backgroundColor: "#e72e3e",
              order:3
            },
            {
              data: arrTren50,
              label: "Trên 50 tuổi",
              backgroundColor: "#c94b9c",
              order:4
            },
          ];
          //Set option for chart
          this.theoDoTuoi.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              // labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    min: 0,
                    max: 100,
                    display:true,
                    fontColor: "#fff",
                    beginAtZero: true,
                    callback: function(value) {
                        return value + '%';
                    }
                  },
                },
              ],
            },
            
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
   
  }
  public getNguoiNghiemTheoDoiTuong(): void {
    let obj: any = this.getFilter();
    let arrTDKG = [];
    let arrMSM = [];
    let arrNghiAIDS = [];
    let arrLao = [];
    let arrKhac = [];
    let arrNCMT = [];
    let arrMTSC = [];
    let arrKHONG_RO = []
    let arrPNMT = []
    let arrPNBD = []
    let arrSTI = []
    let arrVCBT = []
    let arrKTNVQS = []
    let arrPhamNhan = []
    let arrHienMau = []
    let arrNam = []
    let listData = [];
    this.subscription.push(
      this.cdcService.getNguoiNghiemTheoDoiTuong(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrTDKG.push(e.TDKG );
              arrMSM.push(e.MSM );
              arrNghiAIDS.push(e.NGHI_AIDS );
              arrLao.push(e.LAO );
              arrKhac.push(e.KHAC );
              arrNCMT.push(e.NCMT );
              arrMTSC.push(e.MTSC );
              arrKHONG_RO.push(e.KHONG_RO );
              arrPNMT.push(e.PNMT );
              arrPNBD.push(e.PNBD );
              arrSTI.push(e.STI );
              arrVCBT.push(e.VCBT );
              arrKTNVQS.push(e.KTNVQS );
              arrPhamNhan.push(e.PHAM_NHAN );
              arrHienMau.push(e.HIEN_MAU );
              arrNam.push(e.NAM );
              console.log(arrTDKG)
            });
          }
          if(listData.length <= 7){
            this.isWithChart5 = "7"
          }else if(listData.length <= 15){
            this.isWithChart5 = "10"
          }else{
            this.isWithChart5 = "0"
          }
          //Set label for chart
          this.thongKeHIVTheoDoiTuong.barChartLabels = arrNam;
          //Set data for chart
          this.thongKeHIVTheoDoiTuong.barChartData = [
            {
              data: arrTDKG,
              label: "TDKG",
              backgroundColor: "#4e79a7",
              datalabels: { display:false },
            },
            {
              data: arrMSM,
              label: "MSM",
              backgroundColor: "#a0cbe8",
              datalabels: { display:false },
            },
            {
              data: arrNghiAIDS,
              label: "NghiAIDS",
              backgroundColor: "#f28e2b",
              datalabels: { display:false },
            },
            {
              data: arrLao,
              label: "Lao",
              backgroundColor: "#59a14f",
              datalabels: { display:false },
            },
            {
              data: arrNCMT,
              label: "NCMT",
              backgroundColor: "#8cd17d",
              datalabels: { display:false },
            },
            {
              data: arrMTSC,
              label: "MTSC",
              backgroundColor: "#b6992d",
              datalabels: { display:false },
            },
            {
              data: arrKHONG_RO,
              label: "Khong ro",
              backgroundColor: "#f1ce63",
              datalabels: { display:false },
            },
            {
              data: arrPNMT,
              label: "PNMT",
              backgroundColor: "#499894",
              datalabels: { display:false },
            },
            {
              data: arrPNBD,
              label: "PNBD",
              backgroundColor: "#86bcb6",
              datalabels: { display:false },
            },
            {
              data: arrSTI,
              label: "STI",
              backgroundColor: "#e15759",
              datalabels: { display:false },
            },
            {
              data: arrVCBT,
              label: "VCBT",
              backgroundColor: "#ff9d9a",
              datalabels: { display:false },
            },
            {
              data: arrKTNVQS,
              label: "KTNVQS",
              backgroundColor: "#79706e",
              datalabels: { display:false },
            },
            {
              data: arrPhamNhan,
              label: "PhamNhan",
              backgroundColor: "#bab0ac",
              datalabels: { display:false },
            },
            {
              data: arrHienMau,
              label: "HienMau",
              backgroundColor: "#d37295",
              datalabels: { display:false },
            },
            {
              data: arrKhac,
              label: "Khac",
              backgroundColor: "#b07aa1",
              datalabels: { display:false },
            },
          ];
         
          //Set option for chart
          this.thongKeHIVTheoDoiTuong.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:true
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                    min:0,
                    max:100,
                    callback: function(value) {
                      return value + '%';
                    }
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    beginAtZero: true,
                    stepSize: 10,
                  },
                },
              ],
            },
            
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; // or >= 1 or !== 0 or ...
                },
              },
             
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
   
  }
  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }
}
