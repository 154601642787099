import { Injectable } from '@angular/core';
import { APIService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    constructor(private apiService: APIService) {

    }

    /**
     * GET method
     * @param args type as query string
     */
    public get(apiUrl: string, args: object = {}, autoShowMessage: boolean = true) {
        return this.apiService.get(apiUrl, args, autoShowMessage);
    }

    /**
     * Post
     * @param apiUrl URL API
     * @param condition
     * @param type form data/json
     */
    public post(apiUrl: string, condition?: any, type?: any) {
        return this.apiService.post(apiUrl, condition, type);
    }

    /**
     * Put
     * @param url custom url for "ADD" api (sample: /add-custom), default: "/add"
     * @param condition
     */
    public put(apiUrl: string, condition?: any) {
        return this.apiService.put(apiUrl, condition);
    }

    /**
     * Delete
     * @param id Id of model
     */
    public delete(apiUrl: string, args: object = {}) {
        return this.apiService.delete(apiUrl, args);
    }
}
