<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <!-- <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn tuần" [data]="WEEK" [formControl]="formSearch.controls.week" label="Chọn tuần" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>

                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form> -->
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center justify-content-end">
              <mat-form-field class="mb-0 mr-2" appearance="outline">
                <mat-label>Loại thời gian</mat-label>
                <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="timeType"
                  (selectionChange)="getData()">
                  <mat-option [value]="0">Theo năm</mat-option>
                  <mat-option [value]="1">Theo tháng</mat-option>
                  <mat-option [value]="2">Theo ngày</mat-option>
                </mat-select>
              </mat-form-field>
              <ngx-dropdown *ngIf="formSearch.controls.timeType.value == 0 || formSearch.controls.timeType.value == 1" panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn năm" [data]="YEARS"
                [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
              </ngx-dropdown>
              
              <div *ngIf="formSearch.controls.timeType.value == 2" class="mr-3">
                    <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput formControlName="fromDate" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" class="c-text"  autocomplete="off" class="c-text"  (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="tuNgayPicker" class="c-text" ></mat-datepicker-toggle>
                        <mat-datepicker #tuNgayPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="formSearch.controls.timeType.value == 2" class="mr-3">
                    <mat-form-field class="w-100 mb-0" appearance="outline" >
                        <mat-label>Đến ngày</mat-label>
                        <input matInput formControlName="toDate" placeholder="Thời gian đến ngày" [matDatepicker]="denNgayPicker"  autocomplete="off" class="c-text" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="denNgayPicker" class="c-text" ></mat-datepicker-toggle>
                        <mat-datepicker #denNgayPicker ></mat-datepicker>
                    </mat-form-field>
                </div>
              <ngx-dropdown *ngIf="formSearch.controls.timeType.value == 1" panelClass="dark-mode" class="mb-0 mr-2"
                placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.month" label="Chọn tháng"
                (selected)="getData()" keyId="id" keyName="text">
              </ngx-dropdown>
              <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button"
                matTooltip="{{ isFullScreen ? 'Thoát toàn màn hình' : 'Toàn màn hình' }}">
                <mat-icon color="primary">{{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
              </button>
            </div>
          </form>

        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Dự báo số ca mắc sốt xuất huyết</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkSotXuatHuyet.lineChartData[0], 8)">
                                <app-line-chart #tkSotXuatHuyet [isSummary]="true"></app-line-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng ca mắc, sốc, tử vong do bệnh sốt xuất huyết</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkMacSocChetSXH.barChartData[0], 16)">
                                <app-bar-chart #tkMacSocChetSXH [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">

                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Dự báo số ca mắc tay chân miệng </h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkDuBaoTCM.lineChartData[0], 5)">
                                <app-line-chart #tkDuBaoTCM [isSummary]="true"></app-line-chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng ca mắc, tử vong do bệnh tay chân miệng </h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkMacChetTCM.barChartData[0], 20)">
                                <app-bar-chart #tkMacChetTCM></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>