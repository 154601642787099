import { TaiChinhKeHoachService } from "./../../../../services/tai-chinh-ke-hoach.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { CommonFunctionService } from './../../../../services/common-function.service';

@Component({
  selector: 'app-bao-cao-tong-hop',
  templateUrl: './bao-cao-tong-hop.component.html',
  styleUrls: ['./bao-cao-tong-hop.component.scss']
})
export class BaoCaoTongHopComponent implements OnInit {
  subscription: Subscription[] = [];
  isFitPage = false;
  public nam: any[] = [];
  public quy: any[] = [];
  public rowStyles: any = {};
  public summaryToMillion = true;
  public isPyramid = false;
  public isSummary = false;
  public isSummaryVN = false;
  public yLabelMaxLength = 0;
  public xLabelMaxLength = 0;
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl("", [Validators.required]),
    quy: new FormControl("", [Validators.required])
  });
  @ViewChild("taiSan", { static: true }) taiSan: BarChartComponent;
  @ViewChild("nguonVon", { static: true }) nguonVon: BarChartComponent;
  public SUMMARY_INFO;
  constructor(
    private taiChinhKeHoachService: TaiChinhKeHoachService,
    private snackbar: SnackbarService,
    private commonService: CommonFunctionService
  ) {
    this.SUMMARY_INFO = { TAISAN_DAUNAM: 0, TAISAN_CUOINAM: 0, NGUONVON_DAUNAM: 0, NGUONVON_CUOINAM: 0 };
  }
  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear()-1;
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2018; i <= currentYear + 1; i++) {
      this.nam.push({ id: i, text: "Năm " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.quy.push({ id: i, text: "Quý " + i });
    }
    this.quy.unshift({ id: -1, text: "Tất cả" });
    this.formSearch.controls["quy"].setValue(-1);
    this.detectMode();

  }
  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.taiSan.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.nguonVon.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.getData();
  }
  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
  public getData(): void {
    this.getTaisan();
    this.getNguonVon();
    this.get4theTong();
  }
  public getFilter() {
    let params = {};
    if (this.formSearch.controls.nam.value) {
      params["nam"] = this.formSearch.controls.nam.value;
    }
    if(this.formSearch.controls.quy.value){
      params["quy"] = this.formSearch.controls.quy.value=="Tất cả"?"":this.formSearch.controls.quy.value;
    }
    return params;
  }
  get4theTong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.taiChinhKeHoachService.getBaoCaoTH4TheTong(obj).subscribe(
        (rs: any) => {
          if (!rs.success || rs.data.length === 0) {
            return false;
          }
          this.SUMMARY_INFO = rs.data[0];
          this.SUMMARY_INFO.TAISAN_DAUNAM = this.nFormatter(
            Number(this.SUMMARY_INFO.TAISAN_DAUNAM),
            1
          );
          this.SUMMARY_INFO.TAISAN_CUOINAM = this.nFormatter(
            Number(this.SUMMARY_INFO.TAISAN_CUOINAM),
            1
          );
          this.SUMMARY_INFO.NGUONVON_DAUNAM = this.nFormatter(
            Number(this.SUMMARY_INFO.NGUONVON_DAUNAM),
            1
          );
          this.SUMMARY_INFO.NGUONVON_CUOINAM = this.nFormatter(
            Number(this.SUMMARY_INFO.NGUONVON_CUOINAM),
            1
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTaisan(): void  {
    let obj = this.getFilter();
    this.subscription.push(

      this.taiChinhKeHoachService.getBaoCaoTHTaiSan(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.taiSan.barChartLabels = rs.data.map(
                  (x) => this.commonService.convertStringToArray(" ", x.TEN_NHOM_BAOCAO, 3)
                );
      let data = rs.data.map(x => x.DAU_NAM);
      let dataCuoiNam = rs.data.map(x => x.CUOI_NAM);
      this.taiSan.barChartData = [
        {
          data: data,
          label: 'Số đầu năm',
          backgroundColor: "#ff1aff",
          datalabels: {
            align: 'center',
            anchor: 'center',
            padding:0,
          },
        },
        {
          data: dataCuoiNam,
          label: 'Số cuối năm',
          backgroundColor: "#4d4dff",
          datalabels: {
            align: 'center',
            anchor: 'center',
            padding:0
          },

        }
      ];
      this.taiSan.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }

        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          //display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: "#fff",
                callback: (value, index, values) => {
                  if (typeof value === "number") {
                    if (value < 0) {
                      // Hiển thị số âm với màu khác và định dạng số tuyệt đối
                      return `-${this.nFormatter(Math.abs(value), 0)}`;
                    } else {
                      // Hiển thị số dương với màu khác
                      return this.nFormatter(value, 1);
                    }
                  }
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: "#fff",
                suggestedMin: 0,
                callback: (value, index, values) => {
                  // using lamda expression to call "this"
                  if (typeof value === "number") {
                    if (value < 0) {
                      // Hiển thị số âm với màu khác và định dạng số tuyệt đối
                      return `-${this.nFormatter(Math.abs(value), 0)}`;
                    } else {
                      // Hiển thị số dương với màu khác
                      return this.nFormatter(value, 1);
                    }
                  }
                  return value;

                },
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",

            formatter: (value, context) => {
              if(value < 0){
                return `-${this.nFormatter(Math.abs(value), 1)}`;
              }
              if (this.isPyramid) {
                return new Intl.NumberFormat("vi-VN").format(Math.abs(value));
              }
              if (this.summaryToMillion) {
                return this.nFormatterVNCurrency(value, 1);
              }
              return new Intl.NumberFormat("vi-VN").format(value);
            },
            clamp: true,
            font: {
              size: 11,
            },
          },
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems, data) => {
              return (data.labels[tooltipItems[0].index] + "").replace(/,/g, " ");
            },
            label: (tooltipItem, data) => {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var currentValue = parseFloat(
                dataset.data[tooltipItem.index].toString()
              );

              if (this.isPyramid) {
                return new Intl.NumberFormat("vi-VN").format(
                  Math.abs(currentValue)
                );
              } else {
                return new Intl.NumberFormat("vi-VN").format(currentValue);
              }
            },
          },
        },
      };

    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  getNguonVon() {
    let obj = this.getFilter();
    this.subscription.push(

      this.taiChinhKeHoachService.getBaoCaoTHNguonVon(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.nguonVon.barChartLabels = rs.data.map(
                  (x) => this.commonService.convertStringToArray(" ", x.TEN_NHOM_BAOCAO, 3)
                );
      let data = rs.data.map(x => x.DAU_NAM);
      let dataCuoiNam = rs.data.map(x => x.CUOI_NAM);
      this.nguonVon.barChartData = [
        {
          data: data,
          label: 'Số đầu năm',
          backgroundColor: "#006400",
          datalabels: {
            align: 'center',
            anchor: 'center',
            padding:15,
          },
        },
        {
          data: dataCuoiNam,
          label: 'Số cuối năm',
          backgroundColor: "#D2691E",
          datalabels: {
            align: 'center',
            anchor: 'center',
            padding:0
          },

        }
      ];
      this.nguonVon.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }

        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          //display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: "#fff",
                callback: (value, index, values) => {
                  if (typeof value === "number") {
                    if (value < 0) {
                      // Hiển thị số âm với màu khác và định dạng số tuyệt đối
                      return `-${this.nFormatter(Math.abs(value), 1)}`;
                    } else {
                      // Hiển thị số dương với màu khác
                      return this.nFormatter(value, 1);
                    }
                  }
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: "#fff",
                suggestedMin: 0,
                callback: (value, index, values) => {
                  // using lamda expression to call "this"
                  if (typeof value === "number") {
                    if (value < 0) {
                      // Hiển thị số âm với màu khác và định dạng số tuyệt đối
                      return `-${this.nFormatter(Math.abs(value), 1)}`;
                    } else {
                      // Hiển thị số dương với màu khác
                      return this.nFormatter(value, 1);
                    }
                  }
                  return value;
                },
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",

            formatter: (value, context) => {
              if(value < 0){
                return `-${this.nFormatter(Math.abs(value), 1)}`;
              }
              if (this.isPyramid) {
                return new Intl.NumberFormat("vi-VN").format(Math.abs(value));
              }
              if (this.summaryToMillion) {
                return this.nFormatterVNCurrency(value, 1);
              }

              return new Intl.NumberFormat("vi-VN").format(value);
            },
            clamp: true,
            font: {
              size: 11,
            },
          },
        },

      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  nFormatterVNCurrency(num, digits?) {
    const lookup = [
      // { value: 1, symbol: "" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỷ" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? new Intl.NumberFormat("vi-VN").format(
          parseFloat((num / item.value).toFixed(digits))
        ) + item.symbol
      : "0";
  }

  nFormatter(num: any, digits: number) {
    if (isNaN(num)) {
      return num;
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỷ" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i: number;
    for (i = lookup.length - 1; i > 0; i--) {
      if (num >= lookup[i].value) {
        break;
      }
    }
    if (i >= 3) {
      return (
        Number(
          (num / lookup[i].value).toFixed(digits).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    } else {
      return (
        Number(
          (num / lookup[i].value).toFixed(0).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    }
  }

}
