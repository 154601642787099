export const SITE_TITLE = 'KHO DỮ LIỆU Y TẾ';
export const TEN_TINH = 'TỈNH VĨNH LONG';

export const MESSAGE_TYPE = {
    "SUCCESS": "success_notification",
    "ERROR": "error_notification",
    "WARNING": "warning_notification"
}

export const MESSAGE_COMMON = {
    "LOGIN_SUCCESS": "Đăng nhập thành công!",
    "LOGIN_FAILED": "Đăng nhập thất bại!",
    "NETWORK_OFFLINE": "Không thể truy cập internet!",
    "GET_DATA_NOT_SUCCESSFUL": "Truy xuất dữ liệu không thành công!",
    "ADD_DATA_SUCCESS": "Thêm dữ liệu thành công!",
    "ADD_DATA_FAILED": "Thêm dữ liệu không thành công!",
    "UPDATE_DATA_SUCCESS": "Cập nhật liệu thành công!",
    "UPDATE_DATA_FAILED": "Cập nhật liệu không thành công!",
    "DELETE_DATA_SUCCESS": "Xóa dữ liệu thành công!",
    "DELETE_DATA_FAILED": "Xóa dữ liệu không thành công!",
    "USER_NOT_FOUND_RETURN_LOGIN": "Không tìm thấy người dùng, vui lòng đăng nhập lại!"
}

export const AUTH = {
    TOKEN_KEY: 'access_token',
    USER_INFO_KEY: 'user'
};

export const POST_BODY_TYPE = {
    FORM_DATA: 0,
    RAW_JSON: 1
};

export const RECORD_STATUS = {
    USING: 0,
    DELETED: 1
};

export const USER_STATUS = {
    BLOCKING: 0,
    USING: 1,
    DELETED: 2
};

export const SEX_CODE = {
    NAM: { value: 1, text: "Nam" },
    NU: { value: 2, text: "Nữ" },
    KHAC: { value: 3, text: "Khác" }
};

export const DATE_PATTERN = {
    NORMAL: 'dd/MM/yyyy',
    SHORT: 'dd/MM/yy',
    NORMAL_WITH_TIME: 'dd/MM/yyyy HH:mm:ss',
    NORMAL_WITH_DATE_TIME: 'HH:mm:ss dd/MM/yyyy',
    NORMAL_WITH_SHORT_TIME: 'dd/MM/yyyy HH:mm',
    SHORT_WITH_TIME: 'dd/MM/yy HH:mm:ss',
    SHORT_WITH_SHORT_TIME: 'dd/MM/yy HH:mm',
    ONLY_YEAR: 'yyyy',
    ONLY_SHORT_YEAR: 'yy',
    ONLY_MONTH: 'MM',
    ONLY_DAY: 'dd',
    ONLY_SHORT_TIME: 'HH:mm',
    ONLY_TIME: 'HH:mm:ss',
    NORMAL_FORMAT: 'yyyy-MM-dd',
    MOMENT_FORMAT: 'YYYY-MM-DD',
    MOMENT_NORMAL: 'DD/MM/YYYY',
    DateFormat: 'yyyyMMddHHmmss',
    PERIOD: 'yyyyMM',
    DATE_FORMAT: 'dd-MM-yyyy',
    DATE_INTEGER: "yyyyMMdd",
    DATE_SIG: "yyyy/MM/dd",
    DATE: "ddMMyyyy",
    FORM_TO_YEAR: "yyyy-yyyy",
};

// Thanks for https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
export const COLOR_PALETTES = {
    RETRO_METRO: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
    DUTCH_FIELD: ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"],
    RIVER_NIGHTS: ["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"],
    SPRING_PASTELS: ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"],
    PALETTE_1: ["#ef476f","#ffd166","#06d6a0","#118ab2","#073b4c"], // https://coolors.co/ef476f-ffd166-06d6a0-118ab2-073b4c
    PALETTE_2: ["#9b5de5","#f15bb5","#fee440","#00bbf9","#00f5d4"], // https://coolors.co/palette/9b5de5-f15bb5-fee440-00bbf9-00f5d4
    PALETTE_3: ["#ff595e","#ffca3a","#8ac926","#1982c4","#6a4c93"], // https://coolors.co/palette/ff595e-ffca3a-8ac926-1982c4-6a4c93
    PALETTE_4: ["#70d6ff","#ff70a6","#ff9770","#ffd670","#e9ff70"], // https://coolors.co/70d6ff-ff70a6-ff9770-ffd670-e9ff70
    PALETTE_5: ["#bce784","#5dd39e","#348aa7","#525174","#513b56"], // https://coolors.co/bce784-5dd39e-348aa7-525174-513b56
    PALETTE_6: ["#0d1345","#f1a26d","#ff0916","#fcdb06","#9a0639","#639f03","#3c0008","#f2f2f2"], // https://coolors.co/0d1345-f1a26d-ff0916-fcdb06-9a0639-639f03-3c0008-f2f2f2
    PALETTE_7: ["#F7A4A4", "#FEBE8C", "#FFFBC1", "#B6E2A1", "#2192FF", "#38E54D", "#9CFF2E", "#FDFF00"], // https://colorhunt.co/palette/f7a4a4febe8cfffbc1b6e2a1, https://colorhunt.co/palette/2192ff38e54d9cff2efdff00
    PALETTE_8: ["#46baec", "#ef476f", "#06d6a0", "#ffd166", "#073b4c"], //https://coolors.co/46baec-ef476f-06d6a0-ffd166-073b4c

    IOC: ["#00ACC1", "#3F51B5", "#C2185B", "#F4511E", "#689F38", "#FFA000", "#00E0BB", "#EF5958", "#408F2C", "#77CC62", "#99D989", "#BBE5B1", "#33BDCD", "#6574C4"],
    BLUES: ["#0000b3", "#0010d9", "#0020ff", "#0040ff", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff"],
    ORANGE_TO_PURPLE: ["#ffb400", "#d2980d", "#a57c1b", "#786028", "#363445", "#48446e", "#5e569b", "#776bcd", "#9080ff"],
    GREY_TO_RED: ["#d7e1ee", "#cbd6e4", "#bfcbdb", "#b3bfd1", "#a4a2a8", "#df8879", "#c86558", "#b04238", "#991f17"]


};

export const ID_TINH = 55 // Tinh Vĩnh Long
export const MA_TINH = 86 // Tinh Vĩnh Long
export const MA_DV_SO_Y_TE = 86000; //Mã đơn vị Sở y tế
