import { Component, Input, OnInit } from "@angular/core";
import { ChartOptions, ChartType } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";
// import * as pluginLabels from "chartjs-plugin-datalabels";
import * as pluginLabels from "chartjs-plugin-labels";
import * as pluginOutLabels from "chartjs-plugin-piechart-outlabels";
import * as Chart from "chart.js";

@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
  @Input()
  public pieChartLabels: Label[] = [];
  @Input()
  public isSummary = false;
  @Input()
  public maxLength = 0;
  @Input()
  public pieChartData: SingleDataSet = [];
  @Input()
  public pieChartLegend = true;
  @Input()
  public pieChartColor = [
    {
      backgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#ff6dd9",
        "#219653",
        "#DA9D28",
      ],
    },
  ];
  public pieChartType: ChartType = "pie";
  @Input()
  public pieChartPlugins = [pluginOutLabels, pluginLabels];

  @Input()
  public pieChartOptions: ChartOptions = {
    layout: {
      padding: {
        top: 35
      }
    },
    responsive: true,
    legend: {
      labels: { fontColor: "#fff",
      generateLabels: (chart) => {
        const originalLabels = Chart.defaults.pie.legend.labels.generateLabels(chart);
        if (this.isSummary) {
          originalLabels.forEach((label) => {
            if (this.maxLength > 0 && label.text.length > this.maxLength ) {
              label.text = label.text.substring(0, this.maxLength) + '...';
            }
          });
          return originalLabels;
        }
        return originalLabels;
      }
    },
      position: "bottom",
    },
    elements: {
      arc: {
        borderColor: "#272A3D",
      },
    },
    plugins: {
      labels: {
        render: "percentage",
        fontColor: "white",
        precision: 1,
        overlap: false
      },
      outlabels: {
        display: false
      }
      // datalabels: {
      //   formatter: function(value, ctx) {
      //     let data = ctx.chart.data.datasets[ctx.datasetIndex].data;
      //     let total = 0;
      //     data.map(data => { total += Number(data); });
      //     return (value * 100 / total).toFixed(2) + '%';
      //   },
      //   display: function (context) {
      //     //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
      //     return (context.dataset.data[context.dataIndex] < 10) ? false : 'auto';
      //   }
      // }
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data["labels"][tooltipItem[0]["index"]].toString();
        },
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var total = 0;
          dataset.data.forEach((element) => { total += element });
          var currentValue = parseFloat(dataset.data[tooltipItem.index].toString());
          var percentage = (currentValue / total) * 100;
          return new Intl.NumberFormat("vi-VN").format(currentValue) + " (" + percentage.toFixed(1) + "%)";
        }
      }
    }
  };

  constructor() {}

  ngOnInit() {}
}
