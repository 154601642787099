import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FunctionService {
    private toggleSubject = new Subject<void>();

    toggleSideBar() {
        this.toggleSubject.next();
    }

    getToggleObservable(){
        return this.toggleSubject.asObservable();
    }
}
