import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NghiepVuYRoutingModule } from './nghiep-vu-y-routing.module';
import { DeAn06Component } from './de-an06/de-an06.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { ThongKeThangComponent } from './kham-chua-benh/thong-ke-thang/thong-ke-thang.component';
import { ThongKeNgayComponent } from './kham-chua-benh/thong-ke-ngay/thong-ke-ngay.component';
import { TinhHinhKcbComponent } from './kham-chua-benh/tinh-hinh-kcb/tinh-hinh-kcb.component';
import { ThongTinChungComponent } from './thong-tin-chung/thong-tin-chung.component';
import { ThongTinChungP2Component } from './thong-tin-chung-p2/thong-tin-chung-p2.component';
import { PhanTichDlKcbComponent } from './phan-tich-dl-kcb/phan-tich-dl-kcb.component';
import { CapCchnComponent } from './cap-cchn/cap-cchn.component';

@NgModule({
  declarations: [ThongTinChungComponent, ThongTinChungP2Component, ThongKeThangComponent, ThongKeNgayComponent, 
                TinhHinhKcbComponent, DeAn06Component, PhanTichDlKcbComponent, CapCchnComponent],
  imports: [
    CommonModule,
    NghiepVuYRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class NghiepVuYModule { }
