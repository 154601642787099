<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <!-- <div class="mr-auto">
                    <mat-slide-toggle color="primary" formControlName="isFitPage"
                        (change)="modeChange($event)">Toàn trang</mat-slide-toggle>
                </div> -->
                
                <div class="ml-auto mr-3" *ngIf="!isHuyen">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn loại hình" [data]="LOAI_HINH_LIST" [formControl]="formSearch.controls.loaiHinh" label="Chọn loại hình" (selected)="getListCSKCB(); getData()" keyId="MA_LH" keyName="TEN_LH">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" [style]="setMarginLeft()">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn huyện" [data]="DISTRICT_LIST" [formControl]="formSearch.controls.district" label="Chọn huyện" (selected)="getListCSKCB(); getData()" keyId="MA_HUYEN" keyName="TEN_HUYEN">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn CSKCB" [data]="DON_VI_LIST" [formControl]="formSearch.controls.coSoKCB" label="Chọn CSKCB" (selected)="getData()" keyId="MA_DON_VI" keyName="TEN_DON_VI">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Loại thời gian</mat-label>
                        <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="timeType" (selectionChange)="getData()">
                            <mat-option [value]="0">Theo năm</mat-option>
                            <mat-option [value]="1">Theo tháng</mat-option>
                            <mat-option [value]="2">Theo quý</mat-option>
                            <mat-option [value]="3">Theo ngày</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value != 3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value == 1">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value == 3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput [matDatepicker]="pickerTuNgay" formControlName="tuNgay" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="pickerTuNgay"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTuNgay></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Đến ngày</mat-label>
                        <input matInput [matDatepicker]="pickerDenNgay" formControlName="denNgay" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="pickerDenNgay"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDenNgay></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value == 2">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn quý" [data]="QUARTERS" [formControl]="formSearch.controls.quarter" label="Chọn quý" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số hồ sơ KCB</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[1]"> {{ getNumber(this.SUMMARY_INFO.HO_SO_RA_VIEN) }} </div>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Hồ sơ có BHYT</h4>
                                <div class="card-value" [style.color]="COST_COLOR[0]"> {{ getNumber(this.SUMMARY_INFO.CO_BAO_HIEM) }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters h-100">
                        <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-lg-5 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Tỷ lệ % KCB có BHYT</h4>
                                <app-pie-chart #khamChuaBenhBaoHiem></app-pie-chart>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-7 h-100' : 'col-lg-7 col-md-12'">
                            <div class="bg-chart p-2 h-100 w-100">
                                <h4 class="chart-title">Tỷ lệ % theo nhóm tuổi</h4>
                                <app-pie-chart #nhomTuoiKCB></app-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê top các bệnh phổ biến</h4>
                        <app-bar-chart #cacBenhPhoBien [isSummary]="true" [yLabelMaxLength]="30"></app-bar-chart>
                    </div>
                </div>
            </div>
        </div>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Hồ sơ không có BHYT</h4>
                                <div class="card-value" [style.color]="COST_COLOR[1]">{{ getNumber(this.SUMMARY_INFO.KHONG_BAO_HIEM) }}</div>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Chi phí khám chữa bệnh</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[4]">{{ this.SUMMARY_INFO.TONG_CHI_PHI }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê top tai nạn thương tích (Đơn vị tính: ca)</h4>
                        <app-line-chart #taiNanThuongTich [lineChartLegend]="false" [isSummary]="true"></app-line-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-3 w-100 h-100">
                        <h4 class="chart-title">Thống kê Top 10 bệnh nguy cơ cao</h4>
                        <app-bar-chart #chiPhiKhamChuaBenh [isSummary]="true"></app-bar-chart>
                        <!-- <app-pie-highchart [paddingTop]="28" #chiPhiKhamChuaBenh></app-pie-highchart> -->
                    </div>
                </div>
            </div>
        </div>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">BHYT thanh toán</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[5]">{{ this.SUMMARY_INFO.TOTAL_BHTT }}</div>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Bệnh nhân tự trả</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[7]">{{ this.SUMMARY_INFO.TOTAL_BNTT }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100 overflow-x">
                        <h4 class="chart-title">Thống kê chi phí khám chữa bệnh theo Cơ sở y tế (đơn vị: triệu đồng)</h4>
                        <div class="h-100" [style]="getDynamicWidth(this.chiPhiKCBTheoDV.barChartData[0], 8)">
                            <app-bar-chart #chiPhiKCBTheoDV [barChartLegend]="false" [summaryToMillion]="true"></app-bar-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="note-container bg-light" *ngIf="!isNoteHidden" [@fadeInOut]>
        <div class="d-flex align-items-center justify-content-between px-3 py-2">
            <div class="content" [innerHTML]="noteContent"></div>
            <button type="button" class="btn btn-link p-0" (click)="isNoteHidden = true">
                <i class="fa-regular fa-circle-xmark"></i>
            </button>
        </div>
    </div>
</div>