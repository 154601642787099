<mat-sidenav-container class="w-100 h-100">
    <mat-sidenav class="w-100 sidebar border-0" #sidenav mode="side" opened="true" [disableClose]="true">
        <!-- <mat-nav-list>
            <div class="profile-card">
                <div class="header">
                    <h2 class="mb-0">{{name}}</h2>
                    <h4>{{email}}</h4>
                </div>
                <img src="https://static.vecteezy.com/system/resources/previews/004/218/321/original/cute-cat-white-pet-cartoon-character-free-vector.jpg" alt="My avatar">
            </div>
        </mat-nav-list>
        <mat-divider></mat-divider> -->

        <mat-nav-list class="pt-4">
            <div class="d-flex flex-column align-items-center">
                <img src="../../../../assets/logo/logo_syt.png" class="logo">
                <h3 class="mb-1 mt-2">{{siteTitle}}</h3>
                <h3 class="mb-3">{{tenTinh}}</h3>
            </div>
        </mat-nav-list>
        <mat-divider></mat-divider>

        <!-- Begin menu -->
        <app-menu [menuList]="menuList"></app-menu>
        <!-- End menu -->
    </mat-sidenav>
</mat-sidenav-container>
