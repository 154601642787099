<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center justify-content-end">
        <mat-form-field class="mb-0 mr-2" appearance="outline">
          <mat-label>Loại thời gian</mat-label>
          <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="timeType"
            (selectionChange)="getData()">
            <mat-option [value]="0">Theo năm</mat-option>
            <mat-option [value]="1">Theo tháng</mat-option>
            <mat-option [value]="2">Theo quý</mat-option>
          </mat-select>
        </mat-form-field>
        <ngx-dropdown panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn năm" [data]="YEARS"
          [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
        </ngx-dropdown>
        <ngx-dropdown *ngIf="formSearch.controls.timeType.value == 2" panelClass="dark-mode" class="mb-0 mr-2"
          placeholder="Chọn quý" [data]="QUARTERS" [formControl]="formSearch.controls.quarter" label="Chọn quý"
          (selected)="getData()" keyId="id" keyName="text">
        </ngx-dropdown>
        <ngx-dropdown *ngIf="formSearch.controls.timeType.value == 1" panelClass="dark-mode" class="mb-0 mr-2"
          placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.month" label="Chọn tháng"
          (selected)="getData()" keyId="id" keyName="text">
        </ngx-dropdown>
        <ngx-dropdown panelClass="dark-mode" class="mb-0 mr-2" placeholder="Tỉnh" [data]="listTinh"
          [formControl]="formSearch.controls.tinh" (selected)="getData()" keyId="ID" keyName="TEN_TINH">
        </ngx-dropdown>
        <ngx-dropdown panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn Huyện" [data]="listHuyen"
          [formControl]="formSearch.controls.huyen" label="Chọn huyện" (selected)="getData()" keyId="ID"
          keyName="TEN_HUYEN">
        </ngx-dropdown>
        <ngx-dropdown *ngIf="showDropdown" panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn Xã"
          [data]="listXa" [formControl]="formSearch.controls.xa" label="Chọn xã" (selected)="getData()" keyId="ID"
          keyName="TEN_XA">
        </ngx-dropdown>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button"
          matTooltip="{{ isFullScreen ? 'Thoát toàn màn hình' : 'Toàn màn hình' }}">
          <mat-icon color="primary">{{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
        </button>
      </div>
    </form>


    <!-- row 1 -->
    <div [ngStyle]="this.rowStyles">

      <div class="row small-gutters">
        <!-- Số hồ sơ sưc khỏe được tạo lập -->
        <div [ngClass]="isFitPage ? 'col-3 h-100' : 'col-xl-3 col-lg-6 col-md-6 col-sm-16 mb-3'">
          <div class="custom-border mt-1 mb-3">
            <div class="bg-card p-2 h-200" style="height: 100px">
              <h4 class="card-title mb-3">
                Số hồ sơ sưc khỏe được tạo lập
              </h4>
              <div class="card-value" style="color:#8eaadb">
                {{ THE.SL_TAOLAP }}
              </div>
            </div>
          </div>
        </div>
        <!-- Số hồ sơ sức khỏe được tạo lập nhân khẩu xác định -->
        <div [ngClass]="isFitPage ? 'col-3 h-100' : 'col-xl-3 col-lg-6 col-md-6 col-sm-16 mb-3'">
          <div class="custom-border mt-1 mb-3">
            <div class="bg-card p-2 h-200" style="height: 100px">
              <h4 class="card-title mb-3">
                Số hồ sơ sức khỏe được tạo lập nhân khẩu xác định
              </h4>
              <div class="card-value" style="color:#8eaadb">
                {{ THE.SL_TAOLAP_XACDINH }}
              </div>
            </div>
          </div>
        </div>
        <!-- Số hồ sơ sức được tạo lập nhân khẩu chưa xác định -->
        <div [ngClass]="isFitPage ? 'col-3 h-100' : 'col-xl-3 col-lg-6 col-md-6 col-sm-16 mb-3'">
          <div class="custom-border mt-1 mb-3">
            <div class="bg-card p-2 h-200" style="height: 100px">
              <h4 class="card-title mb-3">
                Số hồ sơ sức được tạo lập nhân khẩu chưa xác định
              </h4>
              <div class="card-value" style="color:#8eaadb">
                {{ THE.SL_TAOLAP_KHONGXACDINH }}
              </div>
            </div>
          </div>
        </div>
        <!-- Số hồ sơ sức khỏe được cập nhật -->
        <div [ngClass]="isFitPage ? 'col-3 h-100' : 'col-xl-3 col-lg-6 col-md-6 col-sm-16 mb-3'">
          <div class="custom-border mt-1 mb-3">
            <div class="bg-card p-2 h-200" style="height: 100px">
              <h4 class="card-title mb-3">
                Số hồ sơ sức khỏe được cập nhật
              </h4>
              <div class="card-value" style="color:#8eaadb">
                {{ THE.SL_CAPNHAT }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- row 2 -->
    <div [ngStyle]="this.rowStyles">

      <div class="row small-gutters">
        <!-- Thống kê dân số, số lượng hồ sơ sức khỏe được tạo lập và cập nhật -->
        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-8 col-md-8 col-sm-18 mb-3'">
          <div class="custom-border mt-1" style="height: 700px;">
            <div class="bg-chart p-2 h-100" style="overflow: auto">
              <h4 class="chart-title">
                Thống kê dân số, số lượng hồ sơ sức khỏe được tạo lập và cập nhật
              </h4>
              <div style="height: 680px;" [style]="getDynamicWidth(this.TKSLHSSK_TaoLap_CapNhat.barChartData[0], 33)">
                <app-bar-chart #TKSLHSSK_TaoLap_CapNhat></app-bar-chart>
              </div>
            </div>
          </div>
        </div>
        <!-- Tỉ lệ lập hồ sức khỏe được cập nhật -->
        <div [ngClass]="isFitPage ? 'col-6 h-100 mb-3' : 'col-xl-6 col-lg-8 col-md-8 col-sm-18 mb-3'">
          <div class="custom-border mt-1" style="height: 700px;">
            <div class="bg-chart p-2 h-100" style="overflow: auto">
              <h4 class="chart-title">
                Tỉ lệ lập hồ sức khỏe được cập nhật
              </h4>
              <div [style]="getDynamicHeight(this.TLHSSK_TaoLap_CapNhat.barChartData[0], 10)">
                <app-bar-chart #TLHSSK_TaoLap_CapNhat></app-bar-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>