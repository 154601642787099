import { Component, OnInit, ViewChild, HostListener  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { ColorService } from 'src/app/services/color.service';
import { CommonFunctionService } from "src/app/services/common-function.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Spinner } from "src/app/services/spinner.service";
import { MatTableDataSource } from "@angular/material/table";
import { pageSizeOptions } from "src/app/services/config.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM/YYYY',
  },
};
@Component({
  selector: 'app-cong-bo-my-pham',
  templateUrl: './cong-bo-my-pham.component.html',
  styleUrls: ['./cong-bo-my-pham.component.scss'],
  providers: [
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
      { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS] }
    ]
})
export class CongBoMyPhamComponent implements OnInit {
  subscription: Subscription[] = [];

  @ViewChild("ctySanXuatTheoHuyen", { static: true }) ctySanXuatTheoHuyen: BarChartComponent;
  @ViewChild("sanPhamTheoCTySanXuat", { static: true }) sanPhamTheoCTySanXuat: BarChartComponent;
  @ViewChild("nhanHangTheoCTySanXuat", { static: true }) nhanHangTheoCTySanXuat: BarChartComponent;
  formSearch = new FormGroup({
    loai: new FormControl(0),
    nam: new FormControl(new Date().getFullYear()),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    fromDate: new FormControl(new Date().toISOString()),
    toDate: new FormControl(new Date().toISOString())
  });
  
  documentElement: any;
  isFullScreen = false;
  params: any;
  keyword = "";
  currentPage = 1;
  totalPage: number;
  rowStyles: any = {};
  rowStylesFull: any = {};
  timeOut: any;
  listThang = [];
  listNam = [];
  listQuy = [];
  isFitPage = false;
  dsTheTong: any;
  showNam = false;
  showThang = false;
  showQuy = false;
  showTuNgay = false;
  showDenNgay = false;
  loai: string;
  dataSource!: MatTableDataSource<any>;
  pageIndex: number = 0;
  index: number = 0;
  length: number;
  pageOption = pageSizeOptions;
  pageSizeDefault: number = this.pageOption[0];
  ELEMENT_DATA: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRefs: any[] = [];
  listHeaderColumnGCN: any[] = [
    {
      key: "TEN",
      label: "TÊN",
    },
    {
      key: "NGAY_CAP",
      label: "NGÀY CẤP",
    },
    {
      key: "TEN_CTY_SANXUAT",
      label: "TÊN CTY SẢN XUẤT",
    },
  ];


  constructor(
    private snackbar: SnackbarService,
    private congBoMyPhamService: AggService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonFunctionService,
    private modalService: NgbModal,
    private spinner: Spinner,
    )
  { 
    // this.dateAdapter.setLocale("vi-VN")
    this.dsTheTong = {
      SL_NHAN_HANG: 0,
      SL_NHAN_HANG_MOI_TRONG_THANG: 0,
      TONG_MA_CTY_SANXUAT: 0,
      TONG_SO_SAN_MOI_TRONG_THANG: 0,
      TONG_SO_SP: 0,
      TONG_SO_SP_MOI_TRONG_THANG: 0
    };
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    
  }
  ngOnInit(): void {
    
    this.documentElement = document.documentElement;
    this.getListNam()
    this.getListThang()
    this.getListQuy()
    this.getData() 
    this.detectMode()
    this.onFilterChangeCombobox()
  }
  getFilter(){
      let loai_cbx = this.formSearch.get("loai").value;
      let nam  = this.formSearch.get("nam").value;
      let thang  = this.formSearch.get("thang").value == "Tất cả"?"0": this.formSearch.get("thang").value;
      let quy = this.formSearch.get("quy").value == "Tất cả"?"0": this.formSearch.get("quy").value;
      let tungay = this.formatDate(this.formSearch.get("fromDate").value);
      let denngay = this.formatDate(this.formSearch.get("toDate").value);
      let result  =  { 
        loai_cbx: loai_cbx,
        nam: nam,
        thang:thang,
        quy: quy,
        tungay: tungay,
        denngay: denngay
      };
      return result;
  }
  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  toggleFullScreen() {
    if (!this.isFullScreen) {
      
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  onFullscreenChange(event) {
    let mainView = document.getElementsByClassName("main-wrapper")[0];
    if (!document.fullscreenElement) {
      // Xử lý khi thoát khỏi chế độ toàn màn hình
      mainView.classList.add("vh-100");
    }
  }
  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== 'responsive';
    this.ctySanXuatTheoHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.sanPhamTheoCTySanXuat.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.nhanHangTheoCTySanXuat.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  ngAfterViewInit() {}
  onFilterChange (){
    this.getFilter()
    this.getData();
  }
  getListNam(){
    this.subscription.push(
      this.congBoMyPhamService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListThang(){
    this.subscription.push(
      this.congBoMyPhamService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListQuy(){
    this.subscription.push(
      this.congBoMyPhamService.getListQuy().subscribe(
        (rs) => {
          this.listQuy = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getDS6TheTong(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.congBoMyPhamService.getTongThe(obj).subscribe(
        (rs) => {
          this.dsTheTong = {
            SL_NHAN_HANG: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NHAN_HANG),
            SL_NHAN_HANG_MOI_TRONG_THANG: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NHAN_HANG_MOI_TRONG_THANG),
            TONG_MA_CTY_SANXUAT: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_MA_CTY_SANXUAT),
            TONG_SO_SAN_MOI_TRONG_THANG: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_SO_SAN_MOI_TRONG_THANG),
            TONG_SO_SP: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_SO_SP),
            TONG_SO_SP_MOI_TRONG_THANG: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_SO_SP_MOI_TRONG_THANG),
            
          }
          console.log(this.dsTheTong)
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getCTySanXuatTheoHuyen() {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.congBoMyPhamService.getCTySanXuatTheoHuyen(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.ctySanXuatTheoHuyen.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
          let data = rs.data.map(x => x.SL_CONGTY);
          this.ctySanXuatTheoHuyen.barChartData = [
            { 
              data: data, 
              label: 'Tổng số công ty', 
              backgroundColor: "#1982c4" ,
              datalabels: {
                align: 'center',
                anchor: 'center',
                padding:15,
              },
            }
          ];
          this.ctySanXuatTheoHuyen.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                     major: {
                      enabled: true, // <-- This is the key line
                    },
                    maxTicksLimit: 12,
                     
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      console.log(lines)
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; 
                  // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        })
    );
  }
  getSanPhamTheoCTySanXuat() {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.congBoMyPhamService.getSanPhamTheoCTySanXuat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.sanPhamTheoCTySanXuat.barChartLabels = rs.data.map((x) =>x.TEN_CTY.replace(/Công ty/gi, "CTY")
          );
          let data = rs.data.map(x => x.SL_SANPHAM);
          this.sanPhamTheoCTySanXuat.barChartData = [
            { 
              data: data, 
              label: 'Tổng số sản phẩm', 
              backgroundColor: "#8b55a3" ,
              datalabels: {
                align: 'center',
                anchor: 'center',
                padding:15,
              },
            }
          ];
          this.sanPhamTheoCTySanXuat.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                     major: {
                      enabled: true, // <-- This is the key line
                    },
                    maxTicksLimit: 12,
                     
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; 
                  // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        })
    );
  }
  getNhanHangTheoCTySanXuat() {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.congBoMyPhamService.getNhanHangTheoCTySanXuat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.nhanHangTheoCTySanXuat.barChartLabels = rs.data.map((x) =>x.TEN_CTY.replace(/Công ty/gi, "CTY")
          );
          let data = rs.data.map(x => x.SL_NHAN_HANG);
          this.nhanHangTheoCTySanXuat.barChartData = [
            { 
              data: data, 
              label: 'Tổng số nhãn hàng', 
              backgroundColor: "#e86131" ,
              datalabels: {
                align: 'center',
                anchor: 'center',
                padding:15,
              },
            }
          ];
          this.nhanHangTheoCTySanXuat.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                     major: {
                      enabled: true, // <-- This is the key line
                    },
                    maxTicksLimit: 12,
                     
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; 
                  // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        })
    );
  }
  getData() {
   this.getDS6TheTong()
   this.getCTySanXuatTheoHuyen()
   this.getSanPhamTheoCTySanXuat()
   this.getNhanHangTheoCTySanXuat()
  }

  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    
    if(loai == 0){
      debugger
      this.showNam = true
      this.showQuy = false
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 2 ){
      this.showNam = true
      this.showQuy = true
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 1){
      this.showNam = true
      this.showQuy = false
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else{
      this.showNam = false
      this.showQuy = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }

  onClicked(data: any, content: any, id: string, size: string) {
    switch (id) {
      case "NHAN_HANG": {
        this.loai = 'NHAN_HANG';
        break;
      }
      case "SAN_PHAM": {
        this.loai = 'SAN_PHAM';
        break;
      }
      case "CTY": {
        this.loai = 'CTY';
        break;
      }
      case "TONG_NHAN_HANG": {
        this.loai = 'TONG_NHAN_HANG';
        break;
      }
      case "TONG_SAN_PHAM": {
        this.loai = 'TONG_SAN_PHAM';
        break;
      }
      case "TONG_CTY": {
        this.loai = 'TONG_CTY';
        break;
      }
    }
    this.getListCongBoMPByTrangThai();
    this.openModal(content, id, size);
  }

  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, {
      ariaLabelledBy: id,
      size: size ?? "md",
    });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  getListCongBoMPByTrangThai(event?: any) {
    if (!event && this.pageIndex != 0) {
      this.paginator.pageIndex = 0;
    } else if (event) {
      this.paginator = event;
    }
    let loai_cbx = this.formSearch.get("loai").value;
    let year = this.formSearch.controls.nam.value;
    let month = this.formSearch.controls.thang.value;
    let quy = this.formSearch.get("quy").value == "Tất cả"?"0": this.formSearch.get("quy").value;
    let tungay = this.formatDate(this.formSearch.get("fromDate").value);
    let denngay = this.formatDate(this.formSearch.get("toDate").value);
    let page = event ? event.pageIndex : this.pageIndex;
    var obj = {
      loai_cbx: loai_cbx,
      nam: year,
      thang: month,
      quy: quy,
      tungay: tungay,
      denngay: denngay,
      loai: this.loai,
      page: page + 1,
      size: this.paginator ? this.paginator.pageSize : this.pageSizeDefault,
    };
    this.spinner.show();
    this.subscription.push(
      this.congBoMyPhamService.getTongTheChiTiet(obj).subscribe(
        (rs) => {
          this.ELEMENT_DATA = rs.data;
          this.dataSource.data = this.ELEMENT_DATA;
          this.spinner.hide();

          this.index = obj.page - 1;
          this.length = rs.total_row;
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );

    return event;
  }
}
