import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class TaiChinhKeHoachService extends BaseService {

  getSoLieuTkeTong(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.SO_LIEU_TK_TONG, requestData);
  }

  getTongTTBDonVi(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TONG_TTB_DON_VI, requestData);
  }

  getTTBTheoTrangThai(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TTB_TRANG_THAI, requestData);
  }

  getTTBTheoNguonGoc(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TTB_NGUON_GOC, requestData);
  }

  getTTBTheoNamSuDung(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TTB_NAM_SU_DUNG, requestData);
  }

  getCTYTLuotKhamBenh(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_LUOT_KHAM_BENH, requestData);
  }

  getCTYTDieuTriNoiTru(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_DIEUTRI_NOITRU, requestData);
  }

  getCTYTTaiNanGiaoThong(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_TNGT, requestData);
  }
  getCTYTTinhHinhDichBenh(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_THDB, requestData);
  }
  getCTYTTinhHinhNhiemBenhHIVAIDSTV(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_NHIEM_BENH_HIV_AIDS_TV, requestData);
  }

  getTTBListTBBByDonVi(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TTB_LIST_TTB_BY_DONVI, requestData);
  }
    getDSTAISANTheoNam(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_THEONAM, requestData);
  }
  getDSTAISANLayDienTichDat(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_DIENTICH_DAT, requestData);
  }
  getDSTAISANLayHienTrangNha(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_HIENTRANG_NHA, requestData);
  }
  getDSTAISANLayNguyenGiaNhaVaDat(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_NGUYENGIA_NHA_DAT, requestData);
  }
  getDSTAISANLayHienTrangCongTrinh(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_HIENTRANG_CONGTRINH, requestData);
  }
  getDSNamDuaVaoSuDung(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.NAM_DUA_VAO_SUDUNG, requestData);
  }
  getDSTAISANLayDienTichDatVaNha(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_TAISAN_LAY_DIENTICH_DAT_VA_NHA, requestData);
  }
  getDSDonVi(): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.DANHSACH_ID_TEN_DONVI);
  }
  getBaoCaoTH4TheTong(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.BAO_CAO_TH_4_THE_TONG, requestData);
  }
  getBaoCaoTHTaiSan(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.BAO_CAO_TH_TAI_SAN, requestData);
  }
  getBaoCaoTHNguonVon(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.BAO_CAO_TH_NGUON_VON, requestData);
  }
  getSoLieuThongKeDuAn(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.SO_LIEU_THONG_KE_DU_AN, requestData);
  }
  getSoLieuThongKeDuAnTheoLoai(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.SO_LIEU_THONG_KE_DU_AN_THEO_LOAI, requestData);
  }
  getATVSTP(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.CTYT_TINH_HINH_ATVSTP, requestData);
  }
  getTaiChinhKeToanTong(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TAICHINH_KETOAN_TONG, requestData);
  }
  getTinhHinhTaiChinh(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.TINHHINH_TAICHINH, requestData);
  }
  getKinhPhiHoatDong(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.KINH_PHI_HOAT_DONG, requestData);
  }
  getHoatDongDauTu(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.HOAT_DONG_DAU_TU, requestData);
  }
  getHoatDongTaiChinhTongHop(requestData): Observable<any> {
    return super.get(apiUrl.TAI_CHINH_KE_HOACH.HOATDONG_TAICHIH_TONGHOP, requestData);
  }

}
