import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { ColorService } from 'src/app/services/color.service';
import { AggService } from "src/app/services/agg.service";
import { CommonFunctionService } from './../../../../services/common-function.service';
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";
@Component({
  selector: 'app-tamthan',
  templateUrl: './tamthan.component.html',
  styleUrls: ['./tamthan.component.scss']
})
export class TamthanComponent implements OnInit {
  subscription: Subscription[] = [];
  @ViewChild("dvBenhNhanQuanLy", { static: true }) dvBenhNhanQuanLy: BarChartComponent;
  @ViewChild("dvBenhNhanPhatHien", { static: true }) dvBenhNhanPhatHien: BarChartComponent;
  @ViewChild("dvBenhNhanChuaKhoi", { static: true }) dvBenhNhanChuaKhoi: BarChartComponent;
  @ViewChild("tkBenhNhanPhatHien", { static: true }) tkBenhNhanPhatHien: PieChartComponent;
  @ViewChild("tkBenhNhanQuanLy", { static: true }) tkBenhNhanQuanLy: PieChartComponent;
  @ViewChild("tkBenhNhanChuaKhoi", { static: true }) tkBenhNhanChuaKhoi: PieChartComponent;
  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl(new Date().getFullYear()),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    loai: new FormControl(1),
    tungay:  new FormControl(new Date().toISOString()),
    denngay:  new FormControl(new Date().toISOString()),
  });
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  isFullScreen = false;
  rowStyles: any = {};
  rowStylesFull: any = {};
  listThang = [];
  listNam = [];
  showNam = true;
  showThang = false;
  showTuNgay = false;
  showDenNgay = false;
  TSBENHMOI: number;
  TSBQUANLY: number;
  TSBCHUAKHOI: number;
  screenWidth: number;
  screenHeight: number;
  classInvisible = 1;
  lableMonth = true;

  constructor(
    private snackbar: SnackbarService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    private aggService : AggService,
    private commonService: CommonFunctionService
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    //this.formSearch.controls["month"].setValue([1,2,3,4,5,6,7,8,9,10,11,12]);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.getData();
    this.dvBenhNhanQuanLy.barChartOptions.legend.display = true;
    this.dvBenhNhanQuanLy.barChartOptions.legend.position = "top";
    this.dvBenhNhanQuanLy.barChartOptions.legend.align = "center";

    this.dvBenhNhanPhatHien.barChartOptions.legend.display = true;
    this.dvBenhNhanPhatHien.barChartOptions.legend.position = "top";
    this.dvBenhNhanPhatHien.barChartOptions.legend.align = "center";

    this.dvBenhNhanChuaKhoi.barChartOptions.legend.display = true;
    this.dvBenhNhanChuaKhoi.barChartOptions.legend.position = "top";
    this.dvBenhNhanChuaKhoi.barChartOptions.legend.align = "center";

    this.tkBenhNhanPhatHien.pieChartOptions.legend.position = "top";
    this.tkBenhNhanQuanLy.pieChartOptions.legend.position = "top";
    this.tkBenhNhanChuaKhoi.pieChartOptions.legend.position = "top";
    this.lableMonth = true;
    this.onFilterChangeCombobox();
    this.detectMode();

  }
  getData(): void {
    this.getTSBenhNhanTamThan();
    this.getDonViBNQuanLy();
    this.getDonViBNPhatHien();
    this.getDonViBNChuaKhoi();
    this.getTkBenhNhanChuaKhoi();
    this.getTkBenhNhanPhatHien();
    this.getTkBenhNhanQuanLy();
 }

 getTSBenhNhanTamThan(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getTSTamThan(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         this.TSBENHMOI = rs.data[0].TSBENHMOI;
         this.TSBQUANLY = rs.data[0].TSBQUANLY;
         this.TSBCHUAKHOI = rs.data[0].TSBCHUAKHOI;
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }
 public getNumber(value: any) {
   return new Intl.NumberFormat("vi-VN").format(Math.round(value));
 }

 getDonViBNQuanLy(): void {
  // debugger
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThanDonVi(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.dvBenhNhanQuanLy.barChartData = [];
           return false;
         }
         this.dvBenhNhanQuanLy.barChartLabels = rs.data.map(
           (x) => this.commonService.convertStringToArray(" ", x.TEN_DON_VI, 2)
         );

         this.dvBenhNhanQuanLy.barChartData = [
           {
             data: rs.data.map((x) => x.TAMTHANQL),
             label: "Tâm thần phân liệt",
             backgroundColor: this.CONST_COLOR[4],
           },
           {
             data: rs.data.map((x) => x.DONGKINHQL),
             label: "Động kinh",
             backgroundColor: this.CONST_COLOR[15],
           }
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }
 getDonViBNChuaKhoi(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThanDonVi(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.dvBenhNhanChuaKhoi.barChartData = [];
           return false;
         }
         this.dvBenhNhanChuaKhoi.barChartLabels = rs.data.map(
           (x) => this.commonService.convertStringToArray(" ", x.TEN_DON_VI, 2)
         );

         this.dvBenhNhanChuaKhoi.barChartData = [
           {
             data: rs.data.map((x) => x.TAMTHANCK),
             label: "Tâm thần",
             backgroundColor: this.CONST_COLOR[3],
           },
           {
             data: rs.data.map((x) => x.DONGKINHCK),
             label: "Động kinh",
             backgroundColor: this.CONST_COLOR[4],
           }
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }

 getDynamicWidth(obj: any, percent: number) {
   let array: any[] = obj ? obj.data : [];
   let length = array ? array.length : 1;
   return "width: " + length * percent + "%";
 }
 getDonViBNPhatHien(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThanDonVi(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.dvBenhNhanPhatHien.barChartData = [];
           return false;
         }
         this.dvBenhNhanPhatHien.barChartLabels = rs.data.map(
           (x) => this.commonService.convertStringToArray(" ", x.TEN_DON_VI, 2)
         );

         this.dvBenhNhanPhatHien.barChartData = [
           {
             data: rs.data.map((x) => x.TAMTHANMOI),
             label: "Tâm thần phân liệt",
             backgroundColor: this.CONST_COLOR[7],
           },
           {
             data: rs.data.map((x) => x.DONGKINHMOI),
             label: "Động kinh",
             backgroundColor: this.CONST_COLOR[1],
           },
           {
             data: rs.data.map((x) => x.TRAMCAMMOI),
             label: "Trầm cảm",
             backgroundColor: this.CONST_COLOR[2],
           }
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }
 CONST_COLOR: any[] = [
   COLOR_PALETTES.PALETTE_4[3],
   COLOR_PALETTES.GREY_TO_RED[6],
   COLOR_PALETTES.RIVER_NIGHTS[6],
   COLOR_PALETTES.ORANGE_TO_PURPLE[1],
   COLOR_PALETTES.RIVER_NIGHTS[4],
   COLOR_PALETTES.GREY_TO_RED[3],
   COLOR_PALETTES.PALETTE_1[1],
   COLOR_PALETTES.PALETTE_1[2],
   COLOR_PALETTES.PALETTE_1[4],
   COLOR_PALETTES.PALETTE_1[3],
   COLOR_PALETTES.PALETTE_2[1],
   COLOR_PALETTES.PALETTE_2[2],
   COLOR_PALETTES.PALETTE_2[4],
   COLOR_PALETTES.PALETTE_2[3],
   COLOR_PALETTES.PALETTE_3[1],
   COLOR_PALETTES.PALETTE_3[2],
   COLOR_PALETTES.PALETTE_3[4],
   COLOR_PALETTES.PALETTE_3[3],
 ];



 public getFilter() {
   let loai = this.formSearch.get("loai").value;
   let nam  = this.formSearch.get("nam").value;
   let thang  = this.formSearch.get("thang").value.toString() == "Tất cả"?"": this.formSearch.get("thang").value.toString();
   let tungay = this.formatDate(this.formSearch.get("tungay").value).toString();
   let denngay = this.formatDate(this.formSearch.get("denngay").value).toString();
   let result = {};
   if(loai == 1){
     result = {
       nam: nam,
       thang: "",
       tungay: "",
       denngay: ""
     }
   }else if (loai == 2){
     result = {
       nam: nam,
       thang: thang,
       tungay: "",
       denngay: ""
     }
   } else {
     result = {
       nam: "",
       thang: "",
       tungay: tungay,
       denngay: denngay
     }
   }
   console.log(result)
   return result;
 }
 formatDate(date) {
   var d = new Date(date),
       month = '' + (d.getMonth() + 1),
       day = '' + d.getDate(),
       year = d.getFullYear();

   if (month.length < 2)
       month = '0' + month;
   if (day.length < 2)
       day = '0' + day;

   return [day,month,year].join('-');
 }

 onFilterChangeCombobox(){
   let loai = this.formSearch.get("loai").value
   if(loai == 1 ){
     this.showNam = true
     this.showThang = false
     this.showTuNgay = false
     this.showDenNgay = false
   }else if(loai == 2){
     this.showNam = true
     this.showThang = true
     this.showTuNgay = false
     this.showDenNgay = false
   }else{
     this.showNam = false
     this.showThang = false
     this.showTuNgay = true
     this.showDenNgay = true
   }
   this.getData()
 }
 onFilterChange (){
   this.detectMode();
   this.getData();
 }

 detectMode() {
   let mode = this.activatedRoute.snapshot.params.mode;
   this.isFitPage = mode === null || mode !== "responsive";
   this.formSearch.controls.isFitPage.setValue(this.isFitPage);
   this.buildStyles();
   this.dvBenhNhanPhatHien.barChartOptions.maintainAspectRatio = !this.isFitPage;
   this.dvBenhNhanQuanLy.barChartOptions.maintainAspectRatio = !this.isFitPage;
   this.dvBenhNhanChuaKhoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
   this.tkBenhNhanPhatHien.pieChartType = "doughnut"
   this.tkBenhNhanPhatHien.pieChartOptions.maintainAspectRatio = !this.isFitPage;
   this.tkBenhNhanQuanLy.pieChartOptions.maintainAspectRatio = !this.isFitPage;
   this.tkBenhNhanChuaKhoi.pieChartOptions.maintainAspectRatio = !this.isFitPage;

 }


 showFullScreen() {
   document.documentElement.requestFullscreen();
 }

 buildStyles() {
   this.rowStyles = {};
   if (this.isFitPage) {
     let others =
       16 + // padding top
       43.75 +
       16 + // form height and its margin bottom
       16; // 2 rows spacing
     let rowHeight = "calc((100% - " + others + "px) / 2)";
     this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
   }
 }
 getTkBenhNhanPhatHien(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThan(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.tkBenhNhanPhatHien.pieChartData = [];
           return false;
         }
         let tamThan = data[0].TAMTHANMOI;
         let dongKinh = data[0].DONGKINHMOI;
         let tramCam = data[0].TRAMCAMMOI;

         this.tkBenhNhanPhatHien.pieChartData = [
           tamThan,
           dongKinh,
           tramCam,
         ];
         this.tkBenhNhanPhatHien.pieChartLabels = [
           "Tâm thân phân liệt",
           "Động kinh",
           "Trầm cảm",
         ];
         this.tkBenhNhanPhatHien.pieChartColor = [
           { backgroundColor: COLOR_PALETTES.PALETTE_1 },
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }
 getTkBenhNhanQuanLy(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThan(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.tkBenhNhanQuanLy.pieChartData = [];
           return false;
         }
         let tamThan = data[0].TAMTHANQL;
         let dongKinh = data[0].DONGKINHQL;

         this.tkBenhNhanQuanLy.pieChartData = [
           tamThan,
           dongKinh
         ];
         this.tkBenhNhanQuanLy.pieChartLabels = [
           "Tâm thân phân liệt",
           "Động kinh"
         ];
         this.tkBenhNhanQuanLy.pieChartColor = [
           { backgroundColor: COLOR_PALETTES.PALETTE_4 },
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }
 getTkBenhNhanChuaKhoi(): void {
   let obj = this.getFilter();
   this.subscription.push(
     this.aggService.getCTTamThan(obj).subscribe(
       (rs: any) => {
         if (!rs.success) {
           return false;
         }
         let data = rs.data;
         if (data.length == 0) {
           this.tkBenhNhanChuaKhoi.pieChartData = [];
           return false;
         }
         let tamThan = data[0].TAMTHANCK;
         let dongKinh = data[0].DONGKINHCK;

         this.tkBenhNhanChuaKhoi.pieChartData = [
           tamThan,
           dongKinh
         ];
         this.tkBenhNhanChuaKhoi.pieChartLabels = [
           "Tâm thân",
           "Động kinh"
         ];
         this.tkBenhNhanChuaKhoi.pieChartColor = [
           { backgroundColor: COLOR_PALETTES.PALETTE_1 },
         ];
       },
       (err) => {
         this.snackbar.showError(
           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
           MESSAGE_TYPE.ERROR
         );
       }
     )
   );
 }


}
