import { NotfoundComponent } from './../layouts/notfound/notfound.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForbiddenComponent } from '../layouts/forbidden/forbidden.component';

const routes: Routes = [
  { path: 'dang-nhap', component: LoginComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', component: NotfoundComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
