<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3 ">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Loại thời gian</mat-label>
            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
              <mat-option [value]="1">Theo năm </mat-option>
              <mat-option [value]="2">Theo tháng </mat-option>
              <mat-option [value]="3">Theo ngày </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showNam" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showThang" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.thang" [label]="!lableMonth ?'Chọn tháng': 'Tất cả' " (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showTuNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Từ ngày</mat-label>
            <input matInput formControlName="tungay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="tuNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #tuNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="showDenNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Đến ngày</mat-label>
            <input matInput formControlName="denngay" placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker" autocomplete="off" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="denNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #denNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>

      <!-- ROW 1 -->
      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="d-flex flex-column w-100 h-100">
                      <div class="h-50">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" >Tổng số bệnh nhân mới phát hiện bệnh</h4>
                              <div class="card-value" style="color:#70ad47; font-size:2.5vw ;"> {{ getNumber(this.TSBENHMOI) }} </div>
                          </div>
                      </div>
                      <hr class="border-0 my-2" />
                      <div class="h-50">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Tổng số bệnh nhân quản lý</h4>
                              <div class="card-value" style="color:#ffc000; font-size:2.5vw ;"> {{ getNumber(this.TSBQUANLY) }} </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters h-100">
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <!-- <div [ngClass]="isFitPage ? 'col-8 h-100' : 'col-xl-8 col-lg-6 col-md-6 col-sm-12 mb-3'"> -->
                          <div class="bg-chart p-2 h-100">
                              <h4 class="chart-title">Thống kê bệnh nhân mới phát hiện</h4>
                              <app-pie-chart #tkBenhNhanPhatHien [isSummary]="true" [maxLength]="20"></app-pie-chart>
                          </div>
                      <!-- </div> -->
                      </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">Thống kê bệnh nhân quản lý</h4>
                          <app-pie-chart #tkBenhNhanQuanLy [isSummary]="true" [maxLength]="20"></app-pie-chart>
                        </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">Đơn vị bệnh nhân phát hiện</h4>
                          <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.dvBenhNhanPhatHien.barChartData[0], 35)">
                              <app-bar-chart #dvBenhNhanPhatHien [isSummary]="true"></app-bar-chart>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- ROW 2 -->

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="d-flex flex-column w-100 h-100">
                      <div class="h-50">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" >Tổng số bệnh nhân chữa khỏi ổn định</h4>
                              <div class="card-value" style="color:#5b9bd5; font-size:2.5vw ;"> {{ getNumber(this.TSBCHUAKHOI) }} </div>
                          </div>
                      </div>
                      <hr class="border-0 my-2" />
                      <!-- <div class="h-50">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" style="color:#f8696b">Tổng chi phí thuế TNDN</h4>
                              <div class="card-value" style="color:#f8696b"> {{ nFormatter(this.DOANHTHU.TONGCHIPHITHUTNDN, 0) }} </div>
                          </div>
                      </div> -->
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters h-100">
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">Thống kê bệnh nhân chữa khỏi ổn định</h4>
                          <app-pie-chart #tkBenhNhanChuaKhoi [isSummary]="true" [maxLength]="20"></app-pie-chart>
                        </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">đơn vị bệnh nhân quản lý</h4>
                          <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.dvBenhNhanQuanLy.barChartData[0], 35)">
                              <app-bar-chart #dvBenhNhanQuanLy [isSummary]="true"></app-bar-chart>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-lg-4 col-md-12'">
                        <!-- <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">đơn vị bệnh nhân lây truyền qua đường tình dục</h4>
                          <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.dvBenhNhanChuaKhoi.barChartData[0], 20)">
                              <app-bar-chart #dvBenhNhanChuaKhoi [isSummary]="true"></app-bar-chart>
                            </div>
                          </div>
                        </div> -->
                        <div class="bg-chart p-2 h-100">
                          <h4 class="chart-title">Đơn vị bệnh nhân chữa khỏi ổn định</h4>
                          <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.dvBenhNhanChuaKhoi.barChartData[0], 35)">
                              <app-bar-chart #dvBenhNhanChuaKhoi [isSummary]="true"></app-bar-chart>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
