import { ThongTinCanBoComponent } from './thong-tin-can-bo/thong-tin-can-bo.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "", component: ThongTinCanBoComponent },
  { path: "thong-tin-can-bo", component: ThongTinCanBoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ToChucCanBoRoutingModule {}
