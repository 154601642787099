import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CDCService extends BaseService {
  getListNam(): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_NAM);
  }

  getListThang(): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_THANG);
  }

  getDS6TheTong(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_6_THE_TONG, requestParam);
  }

  getTKKhamPhatHienLao(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_KHAMBENH_PHATHIEN_LAO, requestParam);
  }

  getTKDieuTriLaoKhangThe(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DIEUTRI_LAO_KHANGTHUOC, requestParam);
  }

  getTKDieuTriLaoCacThe(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DIEUTRI_LAO_CACTHE, requestParam);
  }

  getTLPhatHienTangHuyetApCapHuyen(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TL_PHAT_HIEN_TANG_HUYET_AP_HUYEN, requestParam);
  }

  getTLPhatHienDaiThaoDuongCapHuyen(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TL_PHAT_HIEN_DAI_THAO_DUONG_HUYEN, requestParam);
  }

  getTLTuVong(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TL_TU_VONG, requestParam);
  }

  getTLPhatHienTangHuyetApCapTinh(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TL_PHAT_HIEN_TANG_HUYET_AP_TINH, requestParam);
  }

  getTLPhatHienDaiThaoDuongCapTinh(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TL_PHAT_HIEN_DAI_THAO_DUONG_TINH, requestParam);
  }

  getSLBNHuyetApDaiThaoDuong(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.SL_BN_HUYET_AP_DAI_THAO_DUONG, requestParam);
  }

  gettKeDuBaoSXH(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DU_BAO_SXH, requestParam);
  }

  gettKeDuBaoSXHHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DU_BAO_SXH_HGI, requestParam);
  }

  gettKeMacSocChetSXH(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_MAC_SOC_CHET_SXH, requestParam);
  }
  gettKeMacSocChetSXHHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_MAC_SOC_CHET_SXH_HGI, requestParam);
  }

  gettKeDuBaoTCM(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DU_BAO_TCM, requestParam);
  }

  gettKeDuBaoTCMHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DU_BAO_TCM_HGI, requestParam);
  }

  gettKeMacChetTCM(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_MAC_CHET_TCM, requestParam);
  }
  gettKeMacChetTCMHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_MAC_CHET_TCM_HGI, requestParam);
  }


  gettKeTQKiemDichYTe(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_TQ_KIEM_DICH_Y_TE, requestParam);
  }

  gettKeXuatNhapQuaCanh(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_XUAT_NHAP_QUA_CANH, requestParam);
  }

  gettKePhuongTien(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_PHUONG_TIEN, requestParam);
  }

  // gettKePhuongTienXuatCanh(requestParam): Observable<any> {
  //   return super.get(apiUrl.TRUNG_TAM_CDC.TK_PHUONG_TIEN_XUAT_CANH, requestParam);
  // }

  // gettKePhuongTienQuaCanh(requestParam): Observable<any> {
  //   return super.get(apiUrl.TRUNG_TAM_CDC.TK_PHUONG_TIEN_QUA_CANH, requestParam);
  // }
  getTongQuanHivTaiTinh(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.TONG_QUAN_HIV_TAI_TINH, requestParam);
  }
  getDuongTinhQuaCacNam(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.MAU_DUONG_TINH_QUA_CAC_NAM, requestParam);
  }
  getNguoiNghiemTheoDoiTuong(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.NGUOI_NGHIEM_THEO_DOI_TUONG, requestParam);
  }
  getNguoiNghiemTheoGioiTinh(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.NGUOI_NGHIEM_THEO_GIOI_TINH, requestParam);
  }
  getNguoiNhiemTheoNguyCo(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.NGUOI_NHIEM_THEO_NGUY_CO, requestParam);
  }
  getNguoiNhiemTheoDoTuoi(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV.NGUOI_NHIEM_THEO_DO_TUOI, requestParam);
  }
  getHdXetNghiem(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_HOAT_DONG_XET_NGHIEM, requestParam);
  }
  getHdSKSS(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_HOAT_DONG_SKSS, requestParam);
  }
  getHdCDHA(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_HOAT_DONG_CDHA, requestParam);
  }
  getHdKSK(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_HOAT_DONG_KSK, requestParam);
  }
  getHdTiemChung(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_HOAT_DONG_TIEM_CHUNG, requestParam);
  }

  //Suy dinh dưỡng
  getTyLeSuyDinhDuong(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.SUY_DINH_DUONG.TY_LE_SUY_DINH_DUONG, requestParam);
  }
  getSuyDinhDuong5Va2(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.SUY_DINH_DUONG.HOAT_DONG_SUY_DINH_DUONG, requestParam);
  }
  getSucKhoeSinhSan(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.SUC_KHOE_SINH_SAN, requestParam);
  }
  getVitaminATreEm(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.VITAMIN_A_TRE_EM, requestParam);
  }
  getVitaminABaMe(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.VITAMIN_A_BA_ME, requestParam);
  }
  //benh khong lay hgi
  getTLPhatHienTangHuyetApCapHuyenHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.TL_PHAT_HIEN_TANG_HUYET_AP_HUYEN, requestParam);
  }

  getTLPhatHienDaiThaoDuongCapHuyenHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.TL_PHAT_HIEN_DAI_THAO_DUONG_HUYEN, requestParam);
  }

  getTLTuVongHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.TL_TU_VONG, requestParam);
  }

  getTLPhatHienTangHuyetApCapTinhHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.TL_PHAT_HIEN_TANG_HUYET_AP_TINH, requestParam);
  }

  getTLPhatHienDaiThaoDuongCapTinhHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.TL_PHAT_HIEN_DAI_THAO_DUONG_TINH, requestParam);
  }

  getSLBNHuyetApDaiThaoDuongHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.BENH_KHONG_LAY_HGI.SL_BN_HUYET_AP_DAI_THAO_DUONG, requestParam);
  }

  // HIV - HGI
  getTongQuanHivTaiTinhHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.TONG_QUAN_HIV_TAI_TINH_HGI, requestParam);
  }
  getDuongTinhQuaCacNamHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.MAU_DUONG_TINH_QUA_CAC_NAM_HGI, requestParam);
  }
  getNguoiNghiemTheoDoiTuongHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.NGUOI_NGHIEM_THEO_DOI_TUONG_HGI, requestParam);
  }
  getNguoiNghiemTheoGioiTinhHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.NGUOI_NGHIEM_THEO_GIOI_TINH_HGI, requestParam);
  }
  getNguoiNhiemTheoNguyCoHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.NGUOI_NHIEM_THEO_NGUY_CO_HGI, requestParam);
  }
  getNguoiNhiemTheoDoTuoiHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.HIV_HGI.NGUOI_NHIEM_THEO_DO_TUOI_HGI, requestParam);
  }

  // Phong chong lao - HGI
  getDS6TheTongHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_6_THE_TONG_HGI, requestParam);
  }

  getTKKhamPhatHienLaoHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_KHAMBENH_PHATHIEN_LAO_HGI, requestParam);
  }

  getTKDieuTriLaoKhangTheHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DIEUTRI_LAO_KHANGTHUOC_HGI, requestParam);
  }

  getTKDieuTriLaoCacTheHGI(requestParam): Observable<any> {
    return super.get(apiUrl.TRUNG_TAM_CDC.TK_DIEUTRI_LAO_CACTHE_HGI, requestParam);
  }
}
