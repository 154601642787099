import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';

@Component({
  selector: 'app-tiem-chung',
  templateUrl: './tiem-chung.component.html',
  styleUrls: ['./tiem-chung.component.scss']
})
export class TiemChungComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};

  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required])
  });

  @ViewChild("tcTreDuoi1Tuoi", { static: true }) tcTreDuoi1Tuoi: BarChartComponent;
  @ViewChild("vgbTreDuoi1Tuoi", { static: true }) vgbTreDuoi1Tuoi: BarChartComponent;
  @ViewChild("tiemMoRong", { static: true }) tiemMoRong: BarChartComponent;
  @ViewChild("tiemDTP", { static: true }) tiemDTP: BarChartComponent;
  @ViewChild("tiemViemNaoNhatBan", { static: true }) tiemViemNaoNhatBan: BarChartComponent;
  @ViewChild("tiemVATPhuNuCoThai", { static: true }) tiemVATPhuNuCoThai: BarChartComponent;

  constructor(
    private aggService: AggService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }

    this.detectMode();

    this.tcTreDuoi1Tuoi.barChartOptions.legend.display = false;
    this.vgbTreDuoi1Tuoi.barChartOptions.legend.display = false;
    this.tiemMoRong.barChartOptions.legend.display = false;
    this.tiemDTP.barChartOptions.legend.display = false;
    this.tiemViemNaoNhatBan.barChartOptions.legend.display = false;
    this.tiemVATPhuNuCoThai.barChartOptions.legend.display = false;

    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tcTreDuoi1Tuoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.vgbTreDuoi1Tuoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tiemMoRong.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tiemDTP.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tiemViemNaoNhatBan.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tiemVATPhuNuCoThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public getFilter() {
    let year = this.formSearch.controls.year.value ?? "";
    let month = this.formSearch.controls.month.value ?? "";
    return { nam: year, thang: month};
  }

  public getData(): void {
    this.tiemChungTreDuoi1Tuoi();
    this.tiemViemGanBTreDuoi1Tuoi();
    this.tiemMoRongTre18Thang();
    this.tiemDTPTre18Thang();
    this.tkTiemViemNaoNhatBan();
    this.tkTiemVATPhuNuCoThai();
  }

  // using PALETTE_3: 2, 3
  public tiemChungTreDuoi1Tuoi(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemChungTreDuoi1Tuoi(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.tcTreDuoi1Tuoi.barChartLabels = [];
      this.tcTreDuoi1Tuoi.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.tcTreDuoi1Tuoi.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tcTreDuoi1Tuoi.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện",
        backgroundColor: COLOR_PALETTES.PALETTE_3[2],
        order: 2
      });
      this.tcTreDuoi1Tuoi.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.PALETTE_3[3],
        backgroundColor: COLOR_PALETTES.PALETTE_3[3],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_3: 4, 1
  public tiemViemGanBTreDuoi1Tuoi(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemViemGanBTreDuoi1Tuoi(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.vgbTreDuoi1Tuoi.barChartLabels = [];
      this.vgbTreDuoi1Tuoi.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.vgbTreDuoi1Tuoi.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.vgbTreDuoi1Tuoi.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện",
        backgroundColor: COLOR_PALETTES.PALETTE_3[4],
        order: 2
      });
      this.vgbTreDuoi1Tuoi.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.PALETTE_3[1],
        backgroundColor: COLOR_PALETTES.PALETTE_3[1],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using DUTCH_FIELD: 6, 7
  public tiemMoRongTre18Thang(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemMoRongTre18Thang(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.tiemMoRong.barChartLabels = [];
      this.tiemMoRong.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.tiemMoRong.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tiemMoRong.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[6],
        order: 2
      });
      this.tiemMoRong.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.DUTCH_FIELD[7],
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[7],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_2: 3, 4
  public tiemDTPTre18Thang(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemDTPTre18Thang(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.tiemDTP.barChartLabels = [];
      this.tiemDTP.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.tiemDTP.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tiemDTP.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện",
        backgroundColor: COLOR_PALETTES.PALETTE_2[3],
        order: 2
      });
      this.tiemDTP.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.PALETTE_2[4],
        backgroundColor: COLOR_PALETTES.PALETTE_2[4],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using RIVER_NIGHTS: 2, 1
  public tkTiemViemNaoNhatBan(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemViemNaoNhatBan(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.tiemViemNaoNhatBan.barChartLabels = [];
      this.tiemViemNaoNhatBan.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.tiemViemNaoNhatBan.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tiemViemNaoNhatBan.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện mũi 2",
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[2],
        order: 2
      });
      this.tiemViemNaoNhatBan.barChartData.push({
        data: result.map(x => x.TY_LE_MUI_3),
        label: "Tỷ lệ thực hiện mũi 3",
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[4],
        order: 2
      });
      this.tiemViemNaoNhatBan.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.RIVER_NIGHTS[1],
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[1],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using RIVER_NIGHTS: 5, 8
  public tkTiemVATPhuNuCoThai(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTiemVATPhuNuCoThai(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.tiemVATPhuNuCoThai.barChartLabels = [];
      this.tiemVATPhuNuCoThai.barChartData = [];
      if (!(result && result.length > 0)) {
        return;
      }
      result = result.filter(x => x.TY_LE > 0 || x.TY_LE_CAN_DAT > 0);
      this.tiemVATPhuNuCoThai.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tiemVATPhuNuCoThai.barChartData.push({
        data: result.map(x => x.TY_LE),
        label: "Tỷ lệ thực hiện",
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[5],
        order: 2
      });
      this.tiemVATPhuNuCoThai.barChartData.push({
        type: "line",
        datalabels: { align: "top" },
        fill: false,
        data: result.map(x => x.TY_LE_CAN_DAT),
        label: "Chỉ tiêu đặt ra",
        borderColor: COLOR_PALETTES.RIVER_NIGHTS[8],
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[8],
        order: 1
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  private  reduceLabel(label: string) {
    return label.replace(/huyện/gi, "")
                .replace(/thị xã/gi, "TX. ")
                .replace(/thành phố/gi, "TP. ");
  }
  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
