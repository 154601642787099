import { ColorService } from "src/app/services/color.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { LineChartComponent } from "../../../../shared/components/chart-js/line-chart/line-chart.component";

import { CDCService } from "../../../../services/trung-tam-cdc.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CommonFunctionService } from "src/app/services/common-function.service";
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM/YYYY',
  },
};
@Component({
  selector: "app-benh-truyen-nhiem-hgi",
  templateUrl: "./benh-truyen-nhiem-hgi.component.html",
  styleUrls: ["./benh-truyen-nhiem-hgi.component.scss"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS] }
  ]
})
export class BenhTruyenNhiemHGIComponent implements OnInit {
  subscription: Subscription[] = [];
  public isFullScreen = false;
  isFitPage = false;
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  rowStyles: any = {};
  
  public showDropdown: boolean = false;

  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    fromDate: new FormControl(new Date().toISOString()),
    toDate: new FormControl(new Date().toISOString())
  });

  
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.PALETTE_3[0],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[3],
    COLOR_PALETTES.RIVER_NIGHTS[2],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
    COLOR_PALETTES.RIVER_NIGHTS[3],
    COLOR_PALETTES.RIVER_NIGHTS[4],
  ];

  @ViewChild("tkSotXuatHuyet", { static: true })
  tkSotXuatHuyet: LineChartComponent;
  @ViewChild("tkMacSocChetSXH", { static: true })
  tkMacSocChetSXH: BarChartComponent;
  @ViewChild("tkDuBaoTCM", { static: true })
  tkDuBaoTCM: LineChartComponent;
  @ViewChild("tkMacChetTCM", { static: true })
  tkMacChetTCM: BarChartComponent;

    constructor(
      private activatedRoute: ActivatedRoute,
      private snackbar: SnackbarService,
      private cdcService: CDCService,
      private colorService: ColorService,
      private commonService: CommonFunctionService
    ) {}
  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }

    this.detectMode();
    this.getData();
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tkSotXuatHuyet.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkSotXuatHuyet.lineChartOptions.scales.xAxes[0].scaleLabel.labelString =
      "Tuần";
    this.tkMacSocChetSXH.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkDuBaoTCM.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkDuBaoTCM.lineChartOptions.scales.xAxes[0].scaleLabel.labelString =
      "Tuần";
    this.tkMacChetTCM.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  getFilter() {
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let timeType = this.formSearch.controls.timeType.value;
    let tungay = this.formatDate(this.formSearch.get("fromDate").value);
    let denngay = this.formatDate(this.formSearch.get("toDate").value);
    let result = { loai: timeType, nam: year, thang: month, tungay: tungay, denngay: denngay};
   
    return result;
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  getData(): void {
    this.gettKeMacSocChetSXHHGI();
    this.gettKeMacChetTCMHGI();
    this.gettKeDuBaoSXHHGI();
    this.gettKeDuBaoTCMHGI();
  }

  gettKeDuBaoSXHHGI(): void {
    let obj: any = this.getFilter();
    let nam = this.formSearch.controls.timeType.value
    this.subscription.push(
      this.cdcService.gettKeDuBaoSXHHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          if(nam == 0){
            this.tkSotXuatHuyet.lineChartLabels = rs.data.map((x) => x.THANG);
          }else{
            this.tkSotXuatHuyet.lineChartLabels = rs.data.map((x) => x.NGAY);
          }
          
          let arrDuBao = rs.data.map((x) => Math.round(x.DU_BAO_DICH));
          let arrNam2022 = rs.data.map((x) => Math.round(x.NAM_1));
          let arrNam2023 = rs.data.map((x) => Math.round(x.NAM_2));
          let arrTrungBinh = rs.data.map((x) => Math.round(x.TRUNG_BINH));

          this.tkSotXuatHuyet.lineChartData = [
            {
              data: arrDuBao,
              label: "Số lượng dự báo",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                this.CONST_COLOR[2],
                0.3
              ),
              borderColor: this.CONST_COLOR[2],
            },
            {
              data: arrNam2022,
              label: "Số lượng năm 2022",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[3],
            },
            {
              data: arrNam2023,
              label: "Số lượng năm 2023",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[9],
            },
            {
              data: arrTrungBinh,
              label: "Số lượng trung bình",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[10],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  gettKeMacSocChetSXHHGI(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeMacSocChetSXHHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkMacSocChetSXH.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_HUYEN,
              2
            )
          );
          // this.tkMacSocChetSXH.barChartLabels = rs.data.map((x) => x.TEN_HUYEN);
          let arrMac = rs.data.map((x) => Math.round(x.MAC));
          let arrSoc = rs.data.map((x) => Math.round(x.SOC));
          let arrChet = rs.data.map((x) => Math.round(x.CHET));
          this.tkMacSocChetSXH.barChartData = [
            {
              data: arrMac,
              label: "Số lượng ca mắc",
              backgroundColor: this.CONST_COLOR[7],
            },
            {
              data: arrSoc,
              label: "Số lượng ca sốc",
              backgroundColor: this.CONST_COLOR[9],
            },
            {
              data: arrChet,
              label: "Số lượng ca tử vong",
              backgroundColor: this.CONST_COLOR[8],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  gettKeDuBaoTCMHGI(): void {
    let obj: any = this.getFilter();
    let nam = this.formSearch.controls.timeType.value
    this.subscription.push(
      this.cdcService.gettKeDuBaoTCMHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          if(nam == 0){
            this.tkDuBaoTCM.lineChartLabels = rs.data.map((x) => x.THANG);
          }else{
            this.tkDuBaoTCM.lineChartLabels = rs.data.map((x) => x.NGAY);
          }
          let arrDuBao = rs.data.map((x) => Math.round(x.DU_BAO_DICH));
          let arrNam2022 = rs.data.map((x) => Math.round(x.NAM_2022));
          let arrNam2023 = rs.data.map((x) => Math.round(x.NAM_2023));
          let arrTrungBinh = rs.data.map((x) => Math.round(x.TRUNG_BINH));

          this.tkDuBaoTCM.lineChartData = [
            {
              data: arrDuBao,
              label: "Số lượng dự báo",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                this.CONST_COLOR[11],
                0.3
              ),
              borderColor: this.CONST_COLOR[11],
            },
            {
              data: arrNam2022,
              label: "Số lượng năm 2022",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[8],
            },
            {
              data: arrNam2023,
              label: "Số lượng năm 2023",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[4],
            },
            {
              data: arrTrungBinh,
              label: "Số lượng trung bình",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[2],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  gettKeMacChetTCMHGI(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeMacChetTCMHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkMacChetTCM.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_HUYEN,
              2
            )
          );
          // this.tkMacChetTCM.barChartLabels = rs.data.map((x) => x.TEN_HUYEN);
          let arrMac = rs.data.map((x) => Math.round(x.MAC));
          let arrChet = rs.data.map((x) => Math.round(x.CHET));
          this.tkMacChetTCM.barChartData = [
            {
              data: arrMac,
              label: "Số lượng ca mắc",
              backgroundColor: this.CONST_COLOR[5],
            },
            {
              data: arrChet,
              label: "Số lượng ca tử vong",
              backgroundColor: this.CONST_COLOR[4],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
