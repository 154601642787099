import { Api831Service } from "./../../../services/api-831.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { SnackbarService } from "src/app/services/snackbar.service";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { ChartOptions } from "chart.js";
import { Label } from "ng2-charts";
import { ChartDataSets } from "chart.js";
import { DmChungService } from "src/app/services/dm-chung.service";

@Component({
  selector: "app-qd831",
  templateUrl: "./qd831.component.html",
  styleUrls: ["./qd831.component.scss"],
})
export class QD831Component implements OnInit {
  subscription: Subscription[] = [];
  ELEMENT_DATA = [];
  chartLabels = [];
  tongHoKhau: number;
  tongHSSK: number;
  @ViewChild("barChart", { static: true }) barChart: BarChartComponent;
  dataTienSuBenhTat = [];
  labelTienSuBenhTat = [];
  tyLeSayThai: string;
  tyLePhaThai: string;

  @ViewChild("chart", { static: true }) chart: BarChartComponent;
  public barChartLabels: Label[] = [];
  public barChartData: ChartDataSets[] = [];

  listHuyen = [];
  listXa = [];
  params = {};

  constructor(
    private snackbar: SnackbarService,
    private qd831Service: Api831Service,
    private dmChungService: DmChungService
  ) {}

  ngOnInit(): void {
    this.loadDataAllChart();
    this.getListHuyen();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  async getTKGioiTinh(params?: any) {
    const rs = await this.qd831Service.getTKGioiTinh(params).toPromise();
    let listData = [];
    let arrData = [];
    let arrLabel = [];

    listData = rs.data;
    if (listData && listData.length > 0) {
      listData.forEach((element) => {
        arrData.push(element.VALUE);
        arrLabel.push(element.LABEL);
      });
    }
    this.ELEMENT_DATA = arrData;
    this.chartLabels = arrLabel;
  }

  getTKHoKhau(params?: any) {
    this.subscription.push(
      this.qd831Service.getTKHoKhau(params).subscribe(
        (rs) => {
          this.tongHoKhau = rs.data[0].VALUE;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getTKHSSK(params?: any) {
    this.subscription.push(
      this.qd831Service.getTKHSSK(params).subscribe(
        (rs) => {
          this.tongHSSK = rs.data[0].VALUE;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getTKTienSuBenhTat(params?: any) {
    let listDataTSBT = [];
    let arrData = [];
    let arrLabel = [];

    this.subscription.push(
      this.qd831Service.getTKTienSuBenhTat(params).subscribe(
        (rs) => {
          listDataTSBT = rs.data;
          listDataTSBT.sort((a, b) => {
            return a.VALUE <= b.VALUE ? 1 : -1;
          });
          if (listDataTSBT && listDataTSBT.length > 0) {
            listDataTSBT.forEach((e) => {
              arrData.push(e.VALUE);
              arrLabel.push(e.LABEL);
            });
          }
          this.barChart.barChartLabels = arrLabel;
          this.barChart.barChartData = [];
          this.barChart.barChartData.push({
            data: arrData,
            label: "Số lượng",
            backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
            hoverBackgroundColor: COLOR_PALETTES.DUTCH_FIELD[2],
          });
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  bieuDoThongKeBenhTat(params?: any) {
    this.getTKTienSuBenhTat(params);
    this.barChart.barChartType = "horizontalBar";
    let barChartOptions: ChartOptions = {
      responsive: true,
      legend: {
        labels: { fontColor: "#fff" },
      },
      scales: {
        xAxes: [
          {
            gridLines: { display: false },
            ticks: { fontColor: "#fff" },
          },
        ],
        yAxes: [
          {
            gridLines: { display: false },
            ticks: {
              fontColor: "#fff",
              suggestedMin: 0,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: "center",
          align: "center",
          color: "white",
          padding: {
            left: 400,
          },
          font: {
            weight: "bold",
          },
          formatter: Math.round,
        },
      },
    };
    this.barChart.barChartOptions = barChartOptions;
  }

  tkTyLeSayThai(params?: any) {
    this.subscription.push(
      this.qd831Service.getTKTyLeSayThai(params).subscribe(
        (rs) => {
          this.tyLeSayThai = rs.data[0].VALUE;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkTyLePhaThai(params?: any) {
    this.subscription.push(
      this.qd831Service.getTKTyLePhaThai(params).subscribe(
        (rs) => {
          this.tyLePhaThai = rs.data[0].VALUE;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKHSSKTheoDoTuoi(params?: any) {
    this.subscription.push(
      this.qd831Service.getTKHSSKTheoDoTuoi(params).subscribe(
        (rs) => {
          let listData = rs.data;
          let arrData = [];
          if (listData.length == 0) {
            return false;
          }

          this.barChartData = [];
          this.barChartLabels = [];
          listData.forEach((element) => {
            arrData.push(element.value);
            this.barChartLabels.push(element.label);
          });
          this.barChartData.push({
            data: arrData,
            label: "Số lượng",
            backgroundColor: "#FF6D28",
            hoverBackgroundColor: COLOR_PALETTES.DUTCH_FIELD[5],
          });
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  //load dữ liệu filter huyện
  getListHuyen() {
    var obj = {
      size: 20,
      idTinh: 60,
    };
    // if (id !== null && id !== 0) {
    //   obj["idTinh"] = id;
    // }
    this.subscription.push(
      this.dmChungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.listHuyen = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  //load dữ liệu filter xã
  getListXa(idHuyen?: number) {
    var obj = {
      size: 20,
    };
    if (idHuyen !== null && idHuyen !== 0) {
      obj["idHuyen"] = idHuyen;
    }
    this.subscription.push(
      this.dmChungService.getListXa(obj).subscribe(
        (rs) => {
          this.listXa = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  loadDataAllChart(params?: any) {
    this.getTKHoKhau(params);
    this.getTKGioiTinh(params);
    this.getTKHSSK(params);
    this.bieuDoThongKeBenhTat(params);
    this.tkTyLeSayThai(params);
    this.tkTyLePhaThai(params);
    this.tKHSSKTheoDoTuoi(params);
  }

  onFilterHuyenChange(event) {
    this.params = {};
    if (event.value === 0) {
      this.loadDataAllChart(this.params);
      this.listXa = [];
    } else if (event.value !== 0) {
      this.getListXa(event.value);
      this.params["ma_huyen"] = this.listHuyen.find(
        (x) => x.ID == event.value
      ).MA_HUYEN;
      this.loadDataAllChart(this.params);
    }
  }

  onFilterXaChange(event) {
    if (event.value !== 0) {
      this.params["ma_xa"] = this.listXa.find((x) => x.ID == event.value).MA_XA;
      this.loadDataAllChart(this.params);
    } else {
      delete this.params["ma_xa"];
      this.loadDataAllChart(this.params);
    }
  }
}
