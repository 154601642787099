import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ID_TINH,
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";
import { TTKiemNghiemDuocPhamMyPhamService } from "src/app/services/tt-kiem-nghiem-duoc-pham-my-pham.service";
import { DmChungService } from 'src/app/services/dm-chung.service';
import { CommonFunctionService } from "src/app/services/common-function.service";

@Component({
  selector: 'app-tt-kiem-nghiem-duocpham-mypham-hgi',
  templateUrl: './tt-kiem-nghiem-duocpham-mypham-hgi.component.html',
  styleUrls: ['./tt-kiem-nghiem-duocpham-mypham-hgi.component.scss']
})
export class TtKiemNghiemDuocphamMyphamHgiComponent implements OnInit {

  subscription: Subscription[] = [];
  isNoneChart = false;
  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    huyen: new FormControl(""),
    tenhuyen: new FormControl(null),
    loai: new FormControl(0),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    fromDate:  new FormControl(new Date().toISOString()),
    toDate:  new FormControl(new Date().toISOString())
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  isHuyen= false;
  listHuyen = [];
  listQuy = [];
  listThang = [];
  listNam = [];
  showNam = true;
  showThang = false;
  showQuy = false;
  showNgay = false;
  SL_9_THE_TONG:  any = {};
  public DON_VI_LIST: any = [];
  public isLayMau = false;
  public isLayGui = false;
  public isMauLayKhongDat = false;
  public isMauGuiKhongDat = false;
  userStorage = JSON.parse(sessionStorage.user);
  @ViewChild("tlMauKN", { static: true })
  tlMauKN: PieChartComponent;
  @ViewChild("tlMauKNGui", { static: true })
  tlMauKNGui: PieChartComponent;
  @ViewChild("tlMauKNLay", { static: true })
  tlMauKNLay: PieChartComponent;

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.PALETTE_4[2],
    COLOR_PALETTES.RETRO_METRO[5],
    COLOR_PALETTES.ORANGE_TO_PURPLE[8],
    COLOR_PALETTES.IOC[6],
    COLOR_PALETTES. BLUES[4],
  ];

  KN_COLOR: any[] = [
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  KN_LAY_COLOR: any[] = [
    COLOR_PALETTES.IOC[6],
    COLOR_PALETTES. BLUES[4],
  ]

  KN_GUI_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_4[2],
    COLOR_PALETTES.RETRO_METRO[5],
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private TTKiemNghiemDPMP: TTKiemNghiemDuocPhamMyPhamService,
    private commonService: CommonFunctionService
  ) {
    this.SL_9_THE_TONG = {
      MAU_THUOC:0,
      MAU_DUOCLIEU_THUOCCOTRUYEN:0,
      MAU_MYPHAM:0,
      MAU_THUOC_GUI:0,
      MAU_DUOCLIEU_THUOCCOTRUYEN_GUI:0,
      MAU_MYPHAM_GUI:0,
      MAU_THUOC_LAY:0,
      MAU_DUOCLIEU_THUOCCOTRUYEN_LAY:0,
      MAU_MYPHAM_LAY:0,
    }

  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear-1);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    this.onFilterChangeLoai();
    this.getDsHuyen();
    this.getListThang();
    this.getListQuy();
    this.getListNam();
    this.detectMode();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.tlMauKN.pieChartOptions.maintainAspectRatio =
    !this.isFitPage;
    this.tlMauKNGui.pieChartOptions.maintainAspectRatio =
    !this.isFitPage;
    this.tlMauKNLay.pieChartOptions.maintainAspectRatio =
    !this.isFitPage;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }
  getDsHuyen() {
    var obj = {
      keyword: "",
      page: 1,
      size: 20,
      idTinh: ID_TINH,
    };
    this.subscription.push(
      this.dmchungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.listHuyen = rs.data;
          if (this.userStorage?.LEVEL_USER_GIA_TRI == "Admin" || this.userStorage?.MA_DON_VI == 89000) {
            this.isHuyen= false
            this.listHuyen.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
          } else {
            this.formSearch.controls.huyen.setValue(
              this.userStorage?.MA_HUYEN
            );
            this.formSearch.controls.tenhuyen.setValue(
              this.listHuyen.find(element => element.MA_HUYEN == this.userStorage?.MA_HUYEN)?.TEN_HUYEN
            );
            this.formSearch.controls.coSoKCB.setValue(
              [this.userStorage?.MA_DON_VI]
            );
            this.isHuyen= true
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListThang(){
    this.subscription.push(
      this.dmchungService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListQuy(){
    this.subscription.push(
      this.dmchungService.getListQuy().subscribe(
        (rs) => {
          this.listQuy = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListNam(){
    this.subscription.push(
      this.dmchungService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );

  }

  getData(): void {
    this.get9TheTong();
    this.tleMauKN();
    this.tleMauKNLay();
    this.tleMauKNGui();
  }

  onFilterChangeLoai(){
    let loai = this.formSearch.get("loai").value
    if(loai == 0){
      this.showNam = true
      this.showNgay = false
      this.showThang = false
      this.showQuy = false
    }else if(loai == 1 ){
      this.showNam = true
      this.showQuy = true
      this.showNgay = false
      this.showThang = false
    }else if(loai == 2){
      this.showNam = true
      this.showNgay = false
      this.showQuy = false
      this.showThang = true
    }else{
      this.showNam = false
      this.showNgay = true
      this.showThang = false
      this.showQuy = false
    }
    this.getData()
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  getFilter() {
    let loai = this.formSearch.get("loai").value;
    let nam = "";
    let thang = "";
    let huyen = "";
    let quy ="";
    let tungay="";
    let denngay = "";
    if (this.userStorage?.LEVEL_USER_GIA_TRI == "Admin" || this.userStorage?.MA_DON_VI == 89000) {
      huyen = this.formSearch.controls.huyen.value == ""?"":this.formSearch.controls.huyen.value;
    }else{
      huyen = this.userStorage?.MA_HUYEN
    }
    if(loai == 0){
      nam  = this.formSearch.get("year").value;
    }
    else if(loai == 1){
      nam  = this.formSearch.get("year").value;
      quy = this.formSearch.get("quy").value == "Tất cả"?"": this.formSearch.get("quy").value
    }
    else if(loai == 2){
      nam  = this.formSearch.get("year").value;
      thang  = this.formSearch.get("thang").value == "Tất cả"?"": this.formSearch.get("thang").value;      
    }
    else{
      tungay = this.formatDate(this.formSearch.get("fromDate").value);
      denngay = this.formatDate(this.formSearch.get("toDate").value);
    }
      let result  =  {
        nam: nam,
        thang:thang,
        quy: quy,
        huyen: huyen,
        tungay: tungay,
        denngay: denngay
      };
      return result;

  }
  get9TheTong(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.TTKiemNghiemDPMP.get9TheTong(obj).subscribe(
        (rs) => {
          this.SL_9_THE_TONG = {
            MAU_THUOC: new Intl.NumberFormat("vi-VN").format(rs.data[0].KIEMNGHIEM_THUOC),
            MAU_DUOCLIEU_THUOCCOTRUYEN: new Intl.NumberFormat("vi-VN").format(rs.data[0].KIEMNGHIEM_DUOCLIEU_COTRUYEN),
            MAU_MYPHAM: new Intl.NumberFormat("vi-VN").format(rs.data[0].KIEMNGHIEM_MYPHAM),
            MAU_THUOC_GUI: new Intl.NumberFormat("vi-VN").format(rs.data[0].GUI_THUOC),
            MAU_DUOCLIEU_THUOCCOTRUYEN_GUI: new Intl.NumberFormat("vi-VN").format(rs.data[0].GUI_DUOCLIEU_COTRUYEN),
            MAU_MYPHAM_GUI: new Intl.NumberFormat("vi-VN").format(rs.data[0].GUI_MYPHAM),
            MAU_THUOC_LAY: new Intl.NumberFormat("vi-VN").format(rs.data[0].LAY_THUOC),
            MAU_DUOCLIEU_THUOCCOTRUYEN_LAY: new Intl.NumberFormat("vi-VN").format(rs.data[0].LAY_DUOCLIEU_COTRUYEN),
            MAU_MYPHAM_LAY: new Intl.NumberFormat("vi-VN").format(rs.data[0].LAY_MYPHAM),
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  public tleMauKN(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.TTKiemNghiemDPMP.getTLDuocMyPhamDat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tlMauKN.pieChartData = [];
            return false;
          }
          let chitieu = data
            .map((x) => x.CHI_TIEU)
            .reduce(function (a, b) {
              return a + b;
            });
          let tyledat = data
            .map((x) => x.TYLE_DAT)
            .reduce(function (a, b) {
              return a + b;
            });
          let chuadat = chitieu > tyledat ? chitieu - tyledat : 0;
          this.tlMauKN.pieChartData = [chuadat, tyledat];
          this.tlMauKN.pieChartLabels = ["Chưa đạt", "Tỷ lệ đạt"];
          this.tlMauKN.pieChartColor = [
            { backgroundColor: this.KN_COLOR },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  public tleMauKNLay(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.TTKiemNghiemDPMP.getTLLayDuocMyPhamDat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tlMauKN.pieChartData = [];
            return false;
          }
          let chitieu = data
            .map((x) => x.CHI_TIEU)
            .reduce(function (a, b) {
              return a + b;
            });
          let tyledat = data
            .map((x) => x.TYLE_DAT)
            .reduce(function (a, b) {
              return a + b;
            });
          let chuadat = chitieu > tyledat ? chitieu - tyledat : 0;
          this.tlMauKNLay.pieChartData = [chuadat, tyledat];
          this.tlMauKNLay.pieChartLabels = ["Chưa đạt", "Tỷ lệ đạt"];
          this.tlMauKNLay.pieChartColor = [
            { backgroundColor: this.KN_LAY_COLOR },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  public tleMauKNGui(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.TTKiemNghiemDPMP.getTLGuiDuocMyPhamDat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tlMauKN.pieChartData = [];
            return false;
          }
          let chitieu = data
            .map((x) => x.CHI_TIEU)
            .reduce(function (a, b) {
              return a + b;
            });
          let tyledat = data
            .map((x) => x.TYLE_DAT)
            .reduce(function (a, b) {
              return a + b;
            });
          let chuadat = chitieu > tyledat ? chitieu - tyledat : 0;
          this.tlMauKNGui.pieChartData = [chuadat, tyledat];
          this.tlMauKNGui.pieChartLabels = ["Chưa đạt", "Tỷ lệ đạt"];
          this.tlMauKNGui.pieChartColor = [
            { backgroundColor: this.KN_GUI_COLOR },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }


  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

}
