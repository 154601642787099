import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Spinner } from 'src/app/services/spinner.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { LineChartComponent } from 'src/app/shared/components/chart-js/line-chart/line-chart.component';
import { PieChartComponent } from 'src/app/shared/components/chart-js/pie-chart/pie-chart.component';
import { PieHighchartComponent } from 'src/app/shared/components/highcharts/pie-highchart/pie-highchart.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DmChungService } from 'src/app/services/dm-chung.service';

@Component({
  selector: 'app-thong-ke-thang',
  templateUrl: './thong-ke-thang.component.html',
  styleUrls: ['./thong-ke-thang.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // :enter is alias to 'void => *'
      transition(':enter', [ style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
      // :leave is alias to '* => void'
      transition(':leave', [animate(500, style({ opacity: 0 }))])
    ])
  ]
})
export class ThongKeThangComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public noteContent;
  public isFitPage = false;
  public rowStyles: any = {};
  public isNoteHidden = true;
  public YEARS: any[] = [];
  public DISTRICT_LIST: any[] = [];
  public DON_VI_LIST: any = [];
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    district: new FormControl(""),
    coSoKCB: new FormControl(""),
    year: new FormControl("")
  });

  @ViewChild("suDungDVKT", { static: true }) suDungDVKT: BarChartComponent;
  @ViewChild("soHoSoBHYT", { static: true }) soHoSoBHYT: LineChartComponent;
  @ViewChild("gioiTinhKCB", { static: true }) gioiTinhKCB: PieChartComponent;
  @ViewChild("soNgayDieuTri", { static: true }) soNgayDieuTri: BarChartComponent;
  @ViewChild("cacBenhPhoBien", { static: true }) cacBenhPhoBien: BarChartComponent;
  @ViewChild("taiNanThuongTich", { static: true }) taiNanThuongTich: LineChartComponent;
  @ViewChild("chiPhiKhamChuaBenh", { static: true }) chiPhiKhamChuaBenh: PieHighchartComponent;
  @ViewChild("soCaCapCuu", { static: true }) soCaCapCuu: BarChartComponent;
  @ViewChild("soCaTuVong", { static: true }) soCaTuVong: LineChartComponent;

  public listDichVuKyThuat: any[] = [];
  public listBenhPhoBien: any[] = [];

  constructor(
    private aggService: AggService,
    private dmService: DmChungService,
    private spinner: Spinner,
    private snackbar: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let currentYear = new Date().getFullYear();
    for (let i = 2021; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    this.formSearch.controls["year"].setValue(currentYear);

    this.detectMode();

    this.suDungDVKT.barChartOptions.scales.xAxes[0].stacked = true;
    this.suDungDVKT.barChartOptions.scales.yAxes[0].stacked = true;
    this.suDungDVKT.barChartOptions.legend.position = 'right';

    this.gioiTinhKCB.pieChartOptions.legend.position = "right";

    this.cacBenhPhoBien.barChartOptions.scales.xAxes[0].stacked = true;
    this.cacBenhPhoBien.barChartOptions.scales.yAxes[0].stacked = true;
    this.cacBenhPhoBien.barChartOptions.legend.position = 'right';

    this.chiPhiKhamChuaBenh.seriesName = "Tỷ lệ";

    this.soCaCapCuu.barChartType = "horizontalBar";

    this.getDistricts(() => {
      this.getData()
    });
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {}

  getDistricts(callback?) {
    var obj = { size: 20, idTinh: 60 };
    this.subscription.push(this.dmService.getListHuyen(obj).subscribe((rs: any) => {
      this.DISTRICT_LIST = (rs.data.length == 0) ? [] : rs.data;
      this.DISTRICT_LIST.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
      if (callback) { callback(); }
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  getDanhSachCoSoKhamChuaBenh() {
    this.formSearch.controls.coSoKCB.setValue("");
    var maHuyen = this.formSearch.controls.district.value;
    if (!maHuyen) {
      this.DON_VI_LIST = [];
      return false;
    }
    this.subscription.push(this.dmService.getListCoSoKCB({ maHuyen: maHuyen }).subscribe((rs: any) => {
      if (rs.data.length == 0) {
        this.DON_VI_LIST = [];
        return false;
      }
      this.DON_VI_LIST = rs.data;
      this.DON_VI_LIST.unshift({ MA_DON_VI: "", TEN_DON_VI: "Tất cả" });
      this.getData();
    }, err => {
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  public detectMode() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   let mode = params['mode'];
    // });
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== 'responsive';
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.suDungDVKT.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.soHoSoBHYT.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.gioiTinhKCB.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.soNgayDieuTri.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.cacBenhPhoBien.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.taiNanThuongTich.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    // this.chiPhiKhamChuaBenh.chartOptions.maintainAspectRatio = !this.isFitPage;
    this.soCaCapCuu.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.soCaTuVong.lineChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  public getFilter() {
    let year = this.formSearch.controls.year.value;
    let tuNgay = '01/01/' + year;
    let denNgay = '31/12/' + year;
    let maHuyen = this.formSearch.controls.district.value;
    let maDonVi = this.formSearch.controls.coSoKCB.value;
    let result = { tuNgay: tuNgay, denNgay: denNgay, maHuyen: maHuyen ?? "", maDonVi: maDonVi ?? "" };
    // console.log(result);
    return result;
  }

  public getData(): void {
    this.tKeSuDungDVKT();
    this.tKeSoHoSoBHYT();
    this.tKeGioiTinhKhamChuaBenh();
    this.tKeSoNgayDieuTri();
    this.tKeCacBenhPhoBien();
    this.tKeTaiNanThuongTich();
    this.tKeChiPhiKhamChuaBenh();
    this.tKeSoCaCapCuu();
    this.tKeSoCaTuVong();
  }

  // using DUTCH_FIELD
  public tKeSuDungDVKT(): void {
    let obj: any = this.getFilter();
    obj.rowNum = 3;
    this.subscription.push(this.aggService.thongkeSuDungDVKT(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let result = rs.data;
      let months = result.map(x => x.MONTH_DT).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.suDungDVKT.barChartLabels = months.map(x => x);
      let names = result.map(x => x.MA_NHOM).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.setListDichVuKyThuat(names, result);
      this.suDungDVKT.barChartData = [];
      let index = 0;
      names.forEach(name => {
        let data = [];
        months.forEach(month => {
          let arr = result.filter(x => x.MONTH_DT == month && x.MA_NHOM == name);
          data = (arr.length > 0) ? data.concat(arr.map(x => x.SO_LUONG)) : data.concat(0);
        });
        this.suDungDVKT.barChartData.push({
          data: data, 
          label: name, 
          backgroundColor: COLOR_PALETTES.DUTCH_FIELD[index++]
        });
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_1: 3
  public tKeSoHoSoBHYT(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeSoHoSoBHYT(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.soHoSoBHYT.lineChartLabels = rs.data.map(x => x.MONTH_DT);
      let data = rs.data.map(x => x.SO_HO_SO);
      this.soHoSoBHYT.lineChartData = [
        { data: data, label: 'Số hồ sơ', fill: false, pointRadius: 5, lineTension: 0, 
          datalabels: { align: 'top', anchor: 'start' }
        }
      ];
      this.soHoSoBHYT.lineChartColors = [{ borderColor: COLOR_PALETTES.PALETTE_1[3] } ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_4
  public tKeGioiTinhKhamChuaBenh(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeGioiTinhKCB(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let data = rs.data;
      if (data.length == 0) {
        this.gioiTinhKCB.pieChartData = [];
        return false;
      }
      let namSL = data.map(x => x.GT_NAM).reduce(function(a, b){ return a + b; });
      let nuSL = data.map(x => x.GT_NU).reduce(function(a, b){ return a + b; });

      this.gioiTinhKCB.pieChartData = [namSL, nuSL];
      this.gioiTinhKCB.pieChartLabels = ["Nam", "Nữ"];
      this.gioiTinhKCB.pieChartColor = [{ backgroundColor: COLOR_PALETTES.PALETTE_4 }];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using SPRING_PASTELS
  public tKeSoNgayDieuTri(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeSoNgayDieuTri(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.soNgayDieuTri.barChartLabels = rs.data.map(x => x.MONTH_DT);
      let data = rs.data.map(x => x.TOTAL_NGAY_DTRI);
      this.soNgayDieuTri.barChartData = [
        { data: data, label: 'Số ngày điều trị', backgroundColor: COLOR_PALETTES.SPRING_PASTELS[0] }
      ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using RETRO_METRO
  public tKeCacBenhPhoBien(): void {
    let obj: any = this.getFilter();
    obj.rowNum = 3;
    this.subscription.push(this.aggService.thongkeCacBenhPhoBien(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let result = rs.data;
      let months = result.map(x => x.MONTH_DT).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.cacBenhPhoBien.barChartLabels = months.map(x => x);
      let names = result.map(x => x.MA_BENH).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.setListBenhPhoBien(names, result);
      this.cacBenhPhoBien.barChartData = [];
      let index = COLOR_PALETTES.RETRO_METRO.length;
      names.forEach(name => {
        let data = [];
        months.forEach(month => {
          let arr = result.filter(x => x.MONTH_DT == month && x.MA_BENH == name);
          data = (arr.length > 0) ? data.concat(arr.map(x => x.SO_LUONG)) : data.concat(0);
        });
        this.cacBenhPhoBien.barChartData.push({
          data: data, 
          label: name, 
          backgroundColor: COLOR_PALETTES.RETRO_METRO[--index]
        });
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using RIVER_NIGHTS
  public tKeTaiNanThuongTich(): void {
    let obj: any = this.getFilter();
    obj.rowNum = 3;
    this.subscription.push(this.aggService.thongkeTaiNanThuongTich(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let result = rs.data;
      let months = result.map(x => x.MONTH_DT).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.taiNanThuongTich.lineChartLabels = months.map(x => x);
      let names = result.map(x => x.TEN_TNTT).filter((item, pos, self) => { return self.indexOf(item) === pos; });
      this.taiNanThuongTich.lineChartData = [];
      this.taiNanThuongTich.lineChartColors = [];
      let index = 0;
      names.forEach(name => {
        let data = [];
        months.forEach(month => {
          let arr = result.filter(x => x.MONTH_DT == month && x.TEN_TNTT == name);
          data = (arr.length > 0) ? data.concat(arr.map(x => x.SO_LUONG)) : data.concat(0);
        });
        this.taiNanThuongTich.lineChartData.push({
          data: data, label: name, fill: false, pointRadius: 5, lineTension: 0,
          datalabels: { align: 'top', anchor: 'start' }
        });
        this.taiNanThuongTich.lineChartColors.push({ borderColor: COLOR_PALETTES.RIVER_NIGHTS[index++] });
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_3
  public tKeChiPhiKhamChuaBenh(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeChiPhiKhamChuaBenh(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let data = rs.data;
      if (data.length == 0) {
        this.chiPhiKhamChuaBenh.data = [];
        this.chiPhiKhamChuaBenh.refreshChart();
        return false;
      }
      let benhNhanTra = data.map(x => x.TOTAL_BNTT).reduce(function(a, b){ return a + b; });
      let benhNhanCungTra = data.map(x => x.TOTAL_BNCCT).reduce(function(a, b){ return a + b; });
      let baoHiemChiTra = data.map(x => x.TOTAL_BHTT).reduce(function(a, b){ return a + b; });
      let nguonKhac = data.map(x => x.TOTAL_NGUONKHAC).reduce(function(a, b){ return a + b; });

      this.chiPhiKhamChuaBenh.data = [
        { name: "Bệnh nhân trả", y: benhNhanTra, x: ' VNĐ' },
        { name: "Bệnh nhân cùng chi trả", y: benhNhanCungTra, x: ' VNĐ' },
        { name: "Bảo hiểm chi trả", y: baoHiemChiTra, x: ' VNĐ' },
        { name: "Nguồn khác", y: nguonKhac, x: ' VNĐ' }
      ];
      this.chiPhiKhamChuaBenh.refreshChart();
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_1: 0
  public tKeSoCaCapCuu(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeSoCaCapCuu(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.soCaCapCuu.barChartLabels = rs.data.map(x => 'Tháng ' + x.MONTH_DT);
      let data = rs.data.map(x => x.SO_LUONG);
      this.soCaCapCuu.barChartData = [
        { data: data, label: 'Số ca cấp cứu', backgroundColor: COLOR_PALETTES.PALETTE_1[0] }
      ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_5: 1
  public tKeSoCaTuVong(): void {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.thongkeSoCaTuVong(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.soCaTuVong.lineChartLabels = rs.data.map(x => x.MONTH_DT);
      let data = rs.data.map(x => x.SO_LUONG);
      this.soCaTuVong.lineChartData = [
        { data: data, label: 'Số ca tử vong', fill: true, pointRadius: 5, lineTension: 0, 
          datalabels: { align: 'top', anchor: 'start' }, 
          backgroundColor: this.soCaTuVong.hexToRGB(COLOR_PALETTES.PALETTE_5[1], 0.3)
        }
      ];
      this.soCaTuVong.lineChartColors = [{ borderColor: COLOR_PALETTES.PALETTE_5[1] } ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  private setListDichVuKyThuat(names, array) {
    this.listDichVuKyThuat = [];
    names.forEach((name) => {
      array.forEach((item) => {
        let isFound = this.listDichVuKyThuat.find(x => x.MA_NHOM == name) != null;
        if (name == item.MA_NHOM && !isFound) {
          this.listDichVuKyThuat.push({ "MA_NHOM": name, "TEN_NHOM": item.TEN_NHOM });
        }
      })
    });
  }

  private setListBenhPhoBien(names, array) {
    this.listBenhPhoBien = [];
    names.forEach((name) => {
      array.forEach((item) => {
        let isFound = this.listBenhPhoBien.find(x => x.MA_BENH == name) != null;
        if (name == item.MA_BENH && !isFound) {
          this.listBenhPhoBien.push({ "MA_BENH": name, "TEN_BENH": item.TEN_BENH });
        }
      })
    });
  }

  showNoteDVKT() {
    this.isNoteHidden = false;
    let html = '';
    this.listDichVuKyThuat.forEach(item => {
      html += '<small class="mr-3"><b>Nhóm ' + item.MA_NHOM + '</b>: ' + item.TEN_NHOM +'</small>';
    });
    this.noteContent = html;
  }

  showNoteBenh() {
    this.isNoteHidden = false;
    let html = '';
    this.listBenhPhoBien.forEach(item => {
      html += '<small class="mr-3"><b>' + item.MA_BENH + '</b>: ' + item.TEN_BENH +'</small>';
    });
    this.noteContent = html;
  }

  // modeChange(event: MatSlideToggleChange) {
  //   if (event.checked) {
  //     window.location.href = 'ioc/kham-chua-benh/dieu-hanh-thang';
  //   } else {
  //     window.location.href = 'ioc/kham-chua-benh/dieu-hanh-thang/responsive';
  //   }
  //   return event;
  // }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + (16 * 2)); // 2 rows spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 3)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
}
