<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3"  [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0"  placeholder="Chọn CSKCB" [data]="DON_VI_LIST" [formControl]="formSearch.controls.coSoKCB" label="Chọn CSKCB" (selected)="getData()" keyId="MA_DON_VI" keyName="TEN_DON_VI">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" >
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
           
        </form>

        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số cán bộ</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[0]"> {{SL_4_THE_TONG.TONG_CAN_BO}} </div>
                            </div>
                        </div>
                        <!-- <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số bác sĩ trên vạn dân </h4>
                                <div class="card-value" [style.color]="CONST_COLOR[2]"> 9,5 </div>
                            </div>
                        </div> -->
                        <div style="height: 15px;" class="w-100">&nbsp;</div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">số lượng nam</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[1]"> {{SL_4_THE_TONG.TONG_NAM}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng nữ</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[3]"> {{SL_4_THE_TONG.TONG_NU}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng cán bộ theo trình độ chuyên môn</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div *ngIf="!isNoneChart" class="none-form">
                                <b>Dữ liệu chưa có!</b>
                            </div>
                            <div class="h-100" [style]="getDynamicWidth(this.trinhDoChuyenMon.barChartData[0], 20)">
                                <app-bar-chart #trinhDoChuyenMon [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng cán bộ theo trình độ quản lý nhà nước</h4>
                        <div *ngIf="!isNoneChartNN" class="none-form">
                            <b>Dữ liệu chưa có!</b>
                        </div>
                        <app-bar-chart #quanLyNhaNuoc></app-bar-chart>
                        <!-- <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.quanLyNhaNuoc.barChartData[0], 20)">
                                <app-bar-chart #quanLyNhaNuoc [isSummary]="true"></app-bar-chart>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>



        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ công chức/viên chức - nhân viên hợp đồng</h4>
                        <div *ngIf="!isNoneChartHD" class="none-form">
                            <b>Dữ liệu chưa có!</b>
                        </div>
                        <app-pie-chart #tyLeVienChucHopDong [isSummary]="true" [maxLength]="20"></app-pie-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Số lượng nhân sự chuyên môn theo đơn vị</h4>
                            <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                                <div *ngIf="!isNoneChartBS" class="none-form">
                                    <b>Dữ liệu chưa có!</b>
                                </div>
                                <div class="h-100" [style]="getDynamicWidth(this.tkbs.barChartData[0], 20)">
                                    <app-bar-chart #tkbs [isSummary]="true"></app-bar-chart>
                                </div>
                            </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ cán bộ theo trình độ chính trị</h4>
                        <div *ngIf="!isNoneChartHD" class="none-form">
                            <b>Dữ liệu chưa có!</b>
                        </div>
                        <app-pie-chart #tyLeTrinhDoChinhTri></app-pie-chart>
                    </div>
                </div>
                <!-- <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ cán bộ theo trình độ chính trị</h4>
                        <div *ngIf="!isNoneChartCT" class="none-form">
                            <b>Dữ liệu chưa có!</b>
                        </div>
                        <app-pie-chart #tyLeTrinhDoChinhTri></app-pie-chart>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>