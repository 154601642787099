import { apiUrl } from "./../constant/api-url";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DmChungService extends BaseService {

  //Danh mục quốc tịch
  getListQuocTich(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_QUOC_TICH, requestParam);
  }
  //Danh mục dân tộc
  getListDanToc(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_DAN_TOC, requestParam);
  }
  //Danh mục tôn giáo
  getListTonGiao(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_TON_GIAO, requestParam);
  }
  //Danh mục đơn vị
  getListDonVi(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_DON_VI, requestParam);
  }
  //Danh mục cơ sở khám chữa bệnh
  getListCoSoKCB(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_CO_SO_KCB, requestParam);
  }
  //Danh mục tinh
  getListTinh(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_TINH, requestParam);
  }
  //Danh mục huyện
  getListHuyen(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_HUYEN, requestParam);
  }
  //Danh mục xã
  getListXa(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_XA, requestParam);
  }
  //Danh mục kỳ
  getListYear(requestParam): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DM_KY, requestParam);
  }
  // CREATE BY HEN
  // DATE 02/02/2022
  // get danh sách nam 

  getListNam(): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_NAM);
  }
  getListThang(): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_THANG);
  }
  getListQuy(): Observable<any> {
    return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_QUY);
  }
  
}
