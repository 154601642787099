import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpService {
    constructor(private http: HttpClient) { }

    get(url: string, options?: any): Observable<any> {
        return this.http.get<any>(url, options);
    }

    request(url: string, method: string, options: any): Observable<any> {
        return this.http.request<any>(method, url, options);
    }
}