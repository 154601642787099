import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrungTamGiamDinhPhapYRoutingModule } from './tt-phap-y-routing.module';
import { TTPhapYComponent } from './tt-phap-y/tt-phap-y.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [TTPhapYComponent],
  imports: [
    CommonModule,
    TrungTamGiamDinhPhapYRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class TrungTamGiamDinhPhapYModule { }
