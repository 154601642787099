import { KiemDichYTeComponent } from './kiem-dich-y-te/kiem-dich-y-te.component';
import { BenhTruyenNhiemComponent } from './benh-truyen-nhiem/benh-truyen-nhiem.component';
import { BenhTruyenNhiemHGIComponent } from './benh-truyen-nhiem-hgi/benh-truyen-nhiem-hgi.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BenhKhongLayComponent } from './benh-khong-lay/benh-khong-lay.component';
import { TiemChungComponent } from './tiem-chung/tiem-chung.component';
import { TinhHinhPhongChongLaoComponent } from './tinh-hinh-phong-chong-lao/tinh-hinh-phong-chong-lao.component';
import { HivAidsComponent } from './hiv-aids/hiv-aids.component';
import { XetNghiemPkdkComponent } from './xet-nghiem-pkdk/xet-nghiem-pkdk.component';
import { SuyDinhDuongComponent } from './suy-dinh-duong/suy-dinh-duong.component';
import { SucKhoeSinhSanComponent } from './suc-khoe-sinh-san/suc-khoe-sinh-san.component';
import { VitaminAComponent } from './vitamin-a/vitamin-a.component';
import { VitaminAHgiComponent } from './vitamin-a-hgi/vitamin-a-hgi.component';
import { BenhKhongLayHgiComponent } from './benh-khong-lay-hgi/benh-khong-lay-hgi.component';
import { TiemChungHgiComponent } from './tiem-chung-hgi/tiem-chung-hgi.component';
import { HivAidsHGIComponent } from './hiv-aids-hgi/hiv-aids-hgi.component';
import { TinhHinhPhongChongLaoHgiComponent } from './tinh-hinh-phong-chong-lao-hgi/tinh-hinh-phong-chong-lao-hgi.component';

const routes: Routes = [
  { path: '', component: TiemChungComponent },
  { path: 'tiem-chung', component: TiemChungComponent },
  { path: 'tinh-hinh-phong-chong-lao', component: TinhHinhPhongChongLaoComponent },
  { path: 'benh-khong-lay', component: BenhKhongLayComponent },
  { path: 'benh-truyen-nhiem', component: BenhTruyenNhiemComponent },
  { path: 'benh-truyen-nhiem-hgi', component: BenhTruyenNhiemHGIComponent },
  { path: 'kiem-dich-y-te', component: KiemDichYTeComponent },
  { path: 'hiv', component: HivAidsComponent },
  { path: 'xet-nghiem-pkdk', component: XetNghiemPkdkComponent },
  { path: 'suy-dinh-duong', component: SuyDinhDuongComponent },
  { path: 'suc-khoe-sinh-san', component: SucKhoeSinhSanComponent },
  { path: 'vitamin-a', component: VitaminAComponent},
  { path: 'vitamin-a-hgi', component: VitaminAHgiComponent},
  { path: 'benh-khong-lay-hgi', component: BenhKhongLayHgiComponent},
  { path: 'tiem-chung-hgi', component: TiemChungHgiComponent },
  { path: 'hiv-hgi', component: HivAidsHGIComponent },
  { path: 'tinh-hinh-phong-chong-lao-hgi', component: TinhHinhPhongChongLaoHgiComponent}
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CdcRoutingModule { }
