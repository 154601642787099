import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DanhMucDungChungRoutingModule } from './danh-muc-dung-chung-routing.module';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { DanhMucDanTocComponent } from './pages/danh-muc-dan-toc/danh-muc-dan-toc.component';
import { DanhMucTonGiaoComponent } from './pages/danh-muc-ton-giao/danh-muc-ton-giao.component';
import { DanhMucDonViComponent } from './pages/danh-muc-don-vi/danh-muc-don-vi.component';
import { DanhMucDvHanhChinhComponent } from './pages/danh-muc-dv-hanh-chinh/danh-muc-dv-hanh-chinh.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';


@NgModule({
  declarations: [DanhMucDanTocComponent, DanhMucTonGiaoComponent, DanhMucDonViComponent, DanhMucDvHanhChinhComponent],
  imports: [
    CommonModule,
    DanhMucDungChungRoutingModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatPaginatorModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
  ],
  exports: [
    MatFormFieldModule,
    MatToolbarModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatPaginatorModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
  ]
})
export class DanhMucDungChungModule { }
