<div class="snakebar">
    <div class="d-flex align-items-center justify-content-between">
        <div> Kho dữ liệu Y tế tỉnh Vĩnh Long </div>
        <div class="dismiss">
            <button mat-icon-button (click)="snackBarRef.dismiss()">
                <mat-icon> close </mat-icon>
            </button>
        </div>
    </div>
    <div class="data py-3" [innerHTML]="data.ms"> </div>
</div>