<div class="bg-plum-plate vh-100">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <div style="font-size: 200px; margin-bottom: 110px;">403</div>
            
            <h2 class="error-details">
              Bạn chưa được phân quyền để xem chức năng này, vui lòng liên hệ quản trị viên để được phân quyền!
            </h2>
            <div class="error-actions">
              <a class="btn btn-primary" [routerLink]="['/']">
                🛖 Chuyển đến trang chủ
              </a>
              <a class="btn btn-secondary"
                ><span class="glyphicon glyphicon-envelope"></span>📱 Liên hệ hỗ
                trợ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  