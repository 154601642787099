import { Component, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { AggService } from "src/app/services/agg.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Spinner } from "src/app/services/spinner.service";
import { DATE_PATTERN, MESSAGE_COMMON, MESSAGE_TYPE, SEX_CODE } from "../../../constant/system-constant";
import jwt_decode from "jwt-decode";
import { FunctionService } from "src/app/modules/thongkebaocao/viewreports/FunctionService";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  private subscription: Subscription[] = [];
  private subscription2: Subscription;
  private modalRefs: any[] = [];
  public dateFormat = DATE_PATTERN.NORMAL;
  public userDetail: any;

  public USER_INFO: any;
  public LIST_SEX: any[];
  public LIST_USER_LEVEL;
  public LIST_DON_VI;
  public updateUserForm = new FormGroup({
    usercode: new FormControl(null, [Validators.required]),
    username: new FormControl(null),
    fullname: new FormControl(null, [Validators.required]),
    birthday: new FormControl(null, [Validators.required]),
    sex: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required]),
    office: new FormControl(null, [Validators.required])
  });

  public updatePasswordForm = new FormGroup({
    usercode: new FormControl(null, [Validators.required]),
    username: new FormControl(null),
    password: new FormControl(null, [Validators.required]),
    newpassword: new FormControl(null, [Validators.required]),
    repassword: new FormControl(null, [Validators.required])
  });

  constructor(
    private aggService: AggService,
    private authService: AuthenticationService,
    private spinner: Spinner,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    private functionService: FunctionService
  ) { }

  ngOnInit() {
    this.subscription2 = this.functionService.getToggleObservable().subscribe(() => {
      this.toggleSideBar();
    });
    this.USER_INFO = JSON.parse(this.authService.getUserInfor());
    this.LIST_SEX = Object.keys(SEX_CODE).reduce<any[]>((accumulator, key) => {
      return accumulator.concat(SEX_CODE[key]);
    }, []);
    this.getListDonVi();

    // check token exp
    setInterval(() => {
      let token = jwt_decode(this.authService.getToken());
      if (Date.now() >= (token.exp * 1000)) {
        console.log("Expired token");
        this.logout();
      }
      // console.log("now: " + Date.now());
      // console.log("token: " + (token.exp * 1000));
    }, 300000); // 5p ~ 300000
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
    this.subscription2.unsubscribe();
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }

  logout() {
    this.authService.removeToken();
  }

  public getListDonVi(callback?) {
    this.spinner.show();
    this.subscription.push(this.aggService.layDanhSachDonVi({}).subscribe(rs => {
      this.LIST_DON_VI = rs.data;
      this.spinner.hide();
      if (callback) { callback(); }
    }, err => {
      this.spinner.hide();
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  public getUserDetail(usercode, callback?) {
    this.spinner.show();
    this.subscription.push(this.aggService.getUserDetail({ ma_user: usercode }).subscribe(rs => {
      this.spinner.hide();
      if (callback) { callback(rs); }
    }, err => {
      this.spinner.hide();
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  openModalViewDetail(content, id, size?) {
    let maUser = this.USER_INFO.MA_USER;
    if (!maUser) {
      this.snackbar.showError(MESSAGE_COMMON.USER_NOT_FOUND_RETURN_LOGIN, MESSAGE_TYPE.ERROR);
      this.logout();
    }
    this.getUserDetail(maUser, (rs) => {
      this.userDetail = rs.data;
      console.log(rs.data);
      this.openModal(content, id, size);
    });
  }

  public getListCapNguoiDung(callback?) {
    this.spinner.show();
    this.subscription.push(this.aggService.layDanhSachCapNguoiDung({}).subscribe(rs => {
      this.LIST_USER_LEVEL = rs.data;
      this.spinner.hide();
      if (callback) { callback(); }
    }, err => {
      this.spinner.hide();
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  setUpdateUserFormData() {
    this.getListCapNguoiDung(() => {
      let user = this.USER_INFO;
      this.updateUserForm.patchValue({
        usercode: user.MA_USER,
        username: user.USERNAME,
        fullname: user.HO_TEN,
        birthday: user.NGAY_SINH,
        sex: parseInt(user.GIOI_TINH),
        phone: user.SDT,
        email: user.MAIL,
        office: user.MA_DON_VI
      });
    });
  }

  updateUser() {
    if (!this.updateUserForm.valid) {
      return false;
    }
    let userInfo = this.USER_INFO;
    let birthday = new Date(this.updateUserForm.controls.birthday.value);
    var user = {
      MA_USER: this.updateUserForm.controls.usercode.value,
      HO_TEN: this.updateUserForm.controls.fullname.value,
      NGAY_SINH: birthday.toLocaleDateString("en-US"),
      GIOI_TINH: this.updateUserForm.controls.sex.value + '',
      SDT: this.updateUserForm.controls.phone.value,
      MAIL: this.updateUserForm.controls.email.value,
      MA_DON_VI: this.updateUserForm.controls.office.value,
      STATUS: userInfo.STATUS,
      LEVEL_USER: userInfo.LEVEL_USER,
      MA_DON_VI_UPDATE: userInfo.MA_DON_VI,
      LEVEL_USER_UPDATE: userInfo.LEVEL_USER
    };
    this.spinner.show();
    this.subscription.push(this.aggService.updateUserInfo(user).subscribe(rs => {
      this.closeModal("updateModal");
      this.spinner.hide();
      this.snackbar.showMessage(MESSAGE_COMMON.UPDATE_DATA_SUCCESS, MESSAGE_TYPE.SUCCESS);
      setTimeout(() => {
        this.authService.removeToken();
      }, 900);
    }, err => {
      this.spinner.hide();
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  setUpdatePasswordFormData() {
    let user = this.USER_INFO;
    this.updatePasswordForm.patchValue({
      usercode: user.MA_USER,
      username: user.USERNAME
    });
  }

  updatePassword() {
    if (!this.updatePasswordForm.valid) {
      return false;
    }
    let userInfo = this.USER_INFO;
    var obj = {
      MA_USER: this.updatePasswordForm.controls.usercode.value,
      OLD_PASSWORD: this.updatePasswordForm.controls.password.value,
      NEW_PASSWORD: this.updatePasswordForm.controls.newpassword.value,
      RE_ENTER_PASSWORD: this.updatePasswordForm.controls.repassword.value
    };
    this.spinner.show();
    this.subscription.push(this.aggService.updateUserPassword(obj).subscribe(rs => {
      this.closeModal("passwordModal");
      this.spinner.hide();
      this.snackbar.showMessage(MESSAGE_COMMON.UPDATE_DATA_SUCCESS, MESSAGE_TYPE.SUCCESS);
      setTimeout(() => {
        this.authService.removeToken();
      }, 900);
    }, err => {
      this.spinner.hide();
      this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
    }));
  }

  // Common function
  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, { ariaLabelledBy: id, size: size ?? 'md' });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  closeModal(id) {
    let modalRef = null;
    this.modalRefs.forEach(element => {
      Object.keys(element).forEach((key, index) => {
        if (id == key) {
          modalRef = element[key];
          return true;
        }
      });
    });
    if (modalRef != null) {
      modalRef.close();
    }
  }
  // End: Common function
}
