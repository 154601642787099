import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from "../shared/components/snackbar/error-snackbar/error-snackbar.component";
import { MessageSnackbarComponent } from "../shared/components/snackbar/message-snackbar/message-snackbar.component";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) { }

    showMessage(message: string, panelClass: string) {
        this.snackBar.openFromComponent(MessageSnackbarComponent, {
            data: {
                ms: message
            },
            panelClass,
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        });
    }

    showError(message: string, panelClass: string) {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            data: {
                ms: message
            },
            panelClass,
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }
}
