import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { QD4210Component } from './qd4210/qd4210.component';
import { QD831Component } from './qd831/qd831.component';
import { MohinhtongtheComponent } from './mohinhtongthe/mohinhtongthe.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'dashboard', component: HomeComponent },
  // { path: 'dashboard/831', component: QD831Component },
  { path: 'dashboard/qd-831', loadChildren: () => import('./qd831/qd831.module').then((m) => m.Qd831Module) },
  { path: 'dashboard/mo-hinh-tong-the', component: MohinhtongtheComponent },
  { path: 'dashboard/4210', component: QD4210Component },
  { path: 'dashboard/cdc', loadChildren: () => import('./cdc/cdc.module').then((m) => m.CdcModule) },
  { path: 'dashboard/nghiep-vu-y', loadChildren: () => import('./nghiep-vu-y/nghiep-vu-y.module').then((m) => m.NghiepVuYModule) },
  { path: 'dashboard/nghiep-vu-duoc', loadChildren: () => import('./nghiep-vu-duoc/nghiep-vu-duoc.module').then((m) => m.NghiepVuDuocModule) },
  { path: 'dashboard/van-ban-dieu-hanh', loadChildren: () => import('./van-ban-dieu-hanh/van-ban-dieu-hanh.module').then((m) => m.VanBanDieuHanhModule) },
  { path: 'dashboard/tai-chinh-ke-hoach', loadChildren: () => import('./tai-chinh-ke-hoach/tai-chinh-ke-hoach.module').then((m) => m.TaiChinhKeHoachModule) },
  { path: 'dashboard/to-chuc-can-bo', loadChildren: () => import('./to-chuc-can-bo/to-chuc-can-bo.module').then((m) => m.ToChucCanBoModule) },
  { path: 'dashboard', loadChildren: () => import('./giam-dinh-y-khoa/giam-dinh-y-khoa.module').then((m) => m.GiamDinhYKhoaModule) },
  { path: 'dashboard/chi-cuc-dan-so', loadChildren: () => import('./chi-cuc-dan-so/chi-cuc-dan-so.module').then((m) => m.ChiCucDanSoModule) },
  { path: 'dashboard/chi-cuc-atvstp', loadChildren: () => import('./chi-cuc-atvstp/chi-cuc-atvstp.module').then((m) => m.ChiCucAtvstpModule) },
  { path: 'dashboard', loadChildren: () => import('./tt-giam-dinh-phap-y/tt-phap-y.module').then((m) => m.TrungTamGiamDinhPhapYModule) },
  { path: 'dashboard', loadChildren: () => import('./cong-tac-y-te/ytedashboard.module').then((m) => m.YTeDashboardModule) },
  { path: 'dashboard', loadChildren: () => import('./thanh-tra-syt/thanh-tra-syt.module').then((m) => m.ThanhTraSYTModule) },
  { path: 'dashboard/tt-kiem-nghiem-duoc-pham-my-pham', loadChildren: () => import('./tt-kiem-nghiem-duocpham-mypham/tt-kiem-nghiem-duoc-pham-my-pham.module').then((m) => m.TTKiemNghiemDuocPhamMyPhamModule) },
  { path: 'dashboard', loadChildren: () => import('./phongdalieu/phongdalieu.module').then((m) => m.PhongdalieuModule) },
  { path: 'dashboard', loadChildren: () => import('./trung-tam-kiem-nghiem/trungtamkiemnghiem.module').then((m) => m.TrungTamKiemNghiemModule) },
  { path: 'dashboard/chi-cuc-dan-so-hgi', loadChildren: () => import('./chi-cuc-dan-so-hgi/chi-cuc-dan-so-hgi.module').then((m) => m.ChiCucDanSoHGIModule) },
  { path: 'dashboard', loadChildren: () => import('./tt-giam-dinh-phap-y-hgi/tt-phap-y-hgi.module').then((m) => m.TrungTamGiamDinhPhapYHGIModule)},
  { path: 'dashboard', loadChildren: () => import('./giam-dinh-y-khoa-hgi/giam-dinh-y-khoa-hgi.module').then((m) => m.GiamDinhYKhoaHGIModule)},
  { path: 'dashboard', loadChildren: () => import('./tt-kiem-nghiem-duocpham-mypham-hgi/tt-kiem-nghiem-duocpham-mypham-hgi.module').then((m) => m.TTKiemNghiemDuocPhamMyPhamHGIModule)},

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
