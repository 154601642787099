import { TaiChinhKeHoachService } from "./../../../../services/tai-chinh-ke-hoach.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { CommonFunctionService } from "src/app/services/common-function.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Spinner } from "src/app/services/spinner.service";
import { pageSizeOptions } from "src/app/services/config.service";
import { DmChungService } from 'src/app/services/dm-chung.service';

@Component({
  selector: "app-trang-thiet-bi",
  templateUrl: "./trang-thiet-bi.component.html",
  styleUrls: ["./trang-thiet-bi.component.scss"],
})
export class TrangThietBiComponent implements OnInit {
  subscription: Subscription[] = [];
  modalRefs: any[] = [];
  dataSource!: MatTableDataSource<any>;
  listDataSource: any[];
  pageIndex: number = 0;
  index: number = 0;
  length: number;
  pageOption = pageSizeOptions;
  pageSizeDefault: number = this.pageOption[0];
  ELEMENT_DATA: any[] = [];
  // @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listHeaderColumnTTBTheoDonVi: any[] = [
    {
      key: "TEN_HIEN_THI",
      label: "TÊN ĐƠN VỊ",
    },
    {
      key: "TEN_TBYT",
      label: "TÊN THIẾT BỊ",
    },
    {
      key: "MODEL",
      label: "MODEL",
    },
    {
      key: "NAM_SD",
      label: "NĂM SỬ DỤNG",
    },
    {
      key: "NGUON_GOC",
      label: "NGUỒN GỐC",
    },
    {
      key: "NOI_SD",
      label: "NƠI SỬ DỤNG",
    },
    {
      key: "TRANG_THAI_THIET_BI",
      label: "TRẠNG THÁI",
    },
  ];

  maDonVi: string;
  trangThaiThietBi: number;
  nguonGocThietBi: number;

  isFitPage = false;
  public DON_VI_LIST: any = [];
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    maDonVi: new FormControl([""]),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_1[0],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.PALETTE_4[1],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[3],
    COLOR_PALETTES.RIVER_NIGHTS[2],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
  ];
  summaryInfo = {
    TONG: 0,
    TOT: 0,
    HU: 0,
    KHAC: 0,
    NGAN_SACH: 0,
    DU_AN: 0,
    DON_VI: 0,
    TAI_TRO: 0,
  };

  @ViewChild("tKeTongTTB", { static: true })
  tKeTongTTB: BarChartComponent;
  @ViewChild("tKeTTBTrangThai", { static: true })
  tKeTTBTrangThai: BarChartComponent;
  @ViewChild("tKeTTBNguonGoc", { static: true })
  tKeTTBNguonGoc: BarChartComponent;
  @ViewChild("tKeTTBNamSuDung", { static: true })
  tKeTTBNamSuDung: BarChartComponent;

  lableDonVi = true;
  lableCoSoKCB = false;
  listDonVi = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private taiChinhKeHoachService: TaiChinhKeHoachService,
    private snackbar: SnackbarService,
    private commonService: CommonFunctionService,
    private modalService: NgbModal,
    private spinner: Spinner,
    private dmchungService: DmChungService,
  ) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    this.detectMode();
    this.getListCSKCB();
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tKeTongTTB.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeTongTTB.barChartOptions.legend.display = false;
    this.tKeTTBTrangThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeTTBTrangThai.barChartOptions.legend.position = "top";
    this.tKeTTBTrangThai.barChartOptions.legend.align = "start";
    this.tKeTTBTrangThai.barChartOptions.scales.xAxes[0].stacked = true;
    this.tKeTTBTrangThai.barChartOptions.scales.yAxes[0].stacked = true;
    this.tKeTTBNguonGoc.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeTTBNguonGoc.barChartOptions.legend.position = "top";
    this.tKeTTBNguonGoc.barChartOptions.legend.align = "start";
    this.tKeTTBNguonGoc.barChartOptions.scales.xAxes[0].stacked = true;
    this.tKeTTBNguonGoc.barChartOptions.scales.yAxes[0].stacked = true;
    this.tKeTTBNamSuDung.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeTTBNamSuDung.barChartOptions.legend.position = "top";
    this.tKeTTBNamSuDung.barChartOptions.legend.align = "start";
    this.tKeTTBNamSuDung.barChartOptions.scales.xAxes[0].stacked = true;
    this.tKeTTBNamSuDung.barChartOptions.scales.yAxes[0].stacked = true;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  getFilter() {
    let params = {};
    if (this.formSearch.controls.maDonVi.value) {
      params["madonvi"] = this.formSearch.controls.maDonVi.value.toString();
    }
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }

    return params;
  }

  getListCSKCB() {
    this.lableCoSoKCB = true
    let obj = {
      maHuyen: "",
      loaiHinh: ""
    };
    this.subscription.push(
      this.dmchungService.getListCoSoKCB(obj).subscribe(
        (rs: any) => {
          if (rs.data.length == 0) {
            this.listDonVi = [];
            return false;
          }
          this.listDonVi = rs.data;
          this.listDonVi.forEach((element, index) => {
            if (element.MA_DON_VI == "89000") this.listDonVi.splice(index, 1);
          });
        },
        (err) => {
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }

  public getData(): void {
    this.getSoLieuTKeTong();
    this.tKeTongTTBTheoDonVi();
    this.tKeTTBTheoTrangThai();
    this.tKeTTBTheoNguonGoc();
    this.tKeTTBTheoNamSuDung();
  }

  onFilterChangeDonVi(){
    this.lableDonVi= false
    this.detectMode
    this.getData();
  }

  getSoLieuTKeTong(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.taiChinhKeHoachService.getSoLieuTkeTong(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return;
          }
          this.summaryInfo = rs.data[0];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeTongTTBTheoDonVi(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.taiChinhKeHoachService.getTongTTBDonVi(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.listDataSource = rs.data;
          this.tKeTongTTB.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện đa khoa/gi, "BVĐK")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám đa khoa/gi, "PKĐK")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT"),
              2
            )
          );
          let data = rs.data.map((x) => Math.round(x.SO_LUONG));
          this.tKeTongTTB.barChartData = [
            {
              data: data,
              label: "Số trang thiết bị",
              backgroundColor: COLOR_PALETTES.PALETTE_5[2],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeTTBTheoTrangThai(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.taiChinhKeHoachService.getTTBTheoTrangThai(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tKeTTBTrangThai.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện đa khoa/gi, "BVĐK")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám đa khoa/gi, "PKĐK")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT"),
              2
            )
          );
          let arrTot = rs.data.map((x) => Math.round(x.TOT));
          let arrHu = rs.data.map((x) => Math.round(x.HU));
          let arrKhac = rs.data.map((x) => Math.round(x.KHAC));
          this.tKeTTBTrangThai.barChartData = [
            {
              data: arrTot,
              label: "Trạng thái tốt",
              backgroundColor: this.CONST_COLOR[2],
            },
            {
              data: arrHu,
              label: "Trạng thái hư",
              backgroundColor: this.CONST_COLOR[1],
            },
            {
              data: arrKhac,
              label: "Trạng thái khác",
              backgroundColor: this.CONST_COLOR[3],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeTTBTheoNguonGoc(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.taiChinhKeHoachService.getTTBTheoNguonGoc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tKeTTBNguonGoc.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện đa khoa/gi, "BVĐK")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám đa khoa/gi, "PKĐK")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT"),
              2
            )
          );
          let arrNS = rs.data.map((x) => Math.round(x.NGAN_SACH));
          let arrDA = rs.data.map((x) => Math.round(x.DU_AN));
          let arrDV = rs.data.map((x) => Math.round(x.DON_VI));
          let arrTT = rs.data.map((x) => Math.round(x.TAI_TRO));
          this.tKeTTBNguonGoc.barChartData = [
            {
              data: arrNS,
              label: "Ngân sách",
              backgroundColor: this.CONST_COLOR[4],
            },
            {
              data: arrDA,
              label: "Dự án",
              backgroundColor: this.CONST_COLOR[5],
            },
            {
              data: arrTT,
              label: "Tài trợ",
              backgroundColor: this.CONST_COLOR[6],
            },
            {
              data: arrDV,
              label: "Đơn vị",
              backgroundColor: this.CONST_COLOR[9],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeTTBTheoNamSuDung(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.taiChinhKeHoachService.getTTBTheoNamSuDung(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tKeTTBNamSuDung.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện đa khoa/gi, "BVĐK")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám đa khoa/gi, "PKĐK")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT"),
              2
            )
          );
          let arrDuoi5nam = rs.data.map((x) => Math.round(x.DUOI_5_NAM));
          let arr5Den10nam = rs.data.map((x) => Math.round(x.TU_5_DEN_10_NAM));
          let arrTren10nam = rs.data.map((x) => Math.round(x.TREN_10_NAM));
          this.tKeTTBNamSuDung.barChartData = [
            {
              data: arrDuoi5nam,
              label: "Dưới 5 năm",
              backgroundColor: this.CONST_COLOR[7],
            },
            {
              data: arr5Den10nam,
              label: "Từ 5 đến 10 năm",
              backgroundColor: this.CONST_COLOR[8],
            },
            {
              data: arrTren10nam,
              label: "Trên 10 năm",
              backgroundColor: this.CONST_COLOR[9],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  onClicked(data: any, content: any, id: string, size: string) {
    switch (id) {
      case "TTBTheoDonVi": {
        this.listDataSource.forEach((e) => {
          var str = this.commonService.convertStringToArray(
            " ",
            e.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
              .replace(/bệnh viện đa khoa/gi, "BVĐK")
              .replace(/bệnh viện/gi, "BV")
              .replace(/phòng khám đa khoa/gi, "PKĐK")
              .replace(/phòng khám/gi, "PK")
              .replace(/trạm y tế/gi, "TYT"),
            2
          );
          if (JSON.stringify(str) === JSON.stringify(data.label)) {
            this.maDonVi = e.MA_DON_VI;
          }
        });
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = null;
        break;
      }
      case "tongTTB": {
        this.maDonVi = null;
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = null;
        break;
      }
      case "trangThaiTot": {
        this.maDonVi = null;
        this.trangThaiThietBi = 1;
        this.nguonGocThietBi = null;
        break;
      }
      case "trangThaiHu": {
        this.maDonVi = null;
        this.trangThaiThietBi = 2;
        this.nguonGocThietBi = null;
        break;
      }
      case "trangThaiKhac": {
        this.maDonVi = null;
        this.trangThaiThietBi = 3;
        this.nguonGocThietBi = null;
        break;
      }
      case "nguonGocNS": {
        this.maDonVi = null;
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = 1;
        break;
      }
      case "nguonGocDA": {
        this.maDonVi = null;
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = 2;
        break;
      }
      case "nguonGocDV": {
        this.maDonVi = null;
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = 3;
        break;
      }
      case "nguonGocTT": {
        this.maDonVi = null;
        this.trangThaiThietBi = null;
        this.nguonGocThietBi = 4;
        break;
      }
    }
    this.getListTTBByDonVi();
    this.openModal(content, id, size);
  }

  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, {
      ariaLabelledBy: id,
      size: size ?? "md",
    });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  closeModal(id) {
    let modalRef = null;
    this.modalRefs.forEach((element) => {
      Object.keys(element).forEach((key, index) => {
        if (id == key) {
          modalRef = element[key];
          return true;
        }
      });
    });
    if (modalRef != null) {
      modalRef.close();
    }
  }

  getListTTBByDonVi(event?: any) {
    if (!event && this.pageIndex != 0) {
      this.paginator.pageIndex = 0;
    } else if (event) {
      this.paginator = event;
    }
    let year = this.formSearch.controls.year.value;
    let page = event ? event.pageIndex : this.pageIndex;
    var obj = {
      nam: year,
      maDonVi: this.maDonVi ?? "",
      trangThai: this.trangThaiThietBi ?? "",
      nguonGoc: this.nguonGocThietBi ?? "",
      page: page + 1,
      size: this.paginator ? this.paginator.pageSize : this.pageSizeDefault,
    };
    this.spinner.show();
    this.subscription.push(
      this.taiChinhKeHoachService.getTTBListTBBByDonVi(obj).subscribe(
        (rs) => {
          this.ELEMENT_DATA = rs.data;
          this.dataSource.data = this.ELEMENT_DATA;
          this.spinner.hide();

          this.index = obj.page - 1;
          this.length = rs.total_row;
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );

    return event;
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
