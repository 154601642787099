import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class CommonFunctionService extends BaseService {
  private modalService: NgbModal;
  private modalRefs: any[] = [];
  formatter = new Intl.NumberFormat("vn-VI", {
    style: "currency",
    currency: "VND",
  });

  dateToStringVN(momentDate: moment.Moment) {
    let date = momentDate.toDate();
    let month = "" + (date.getMonth() + 1).toString();
    let day = "" + date.getDate().toString();
    const year = date.getFullYear().toString();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      obj[key] &&
        typeof obj[key] === "object" &&
        (obj[key] = this.removeEmpty(obj[key]));
      (obj[key] === "" || obj[key] === null || obj[key] === undefined) &&
        delete obj[key];
    });
    return Object.keys(obj).length > 0 ? obj : undefined;
  }

  getStringDateTime() {
    let date = new Date();
    let month = "" + (date.getMonth() + 1).toString();
    let day = "" + date.getDate().toString();
    let year = date.getFullYear().toString();
    let hour = date.getHours().toString();
    let min = date.getMinutes().toString();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) day = "0" + hour;
    if (min.length < 2) day = "0" + min;
    return [day, month, year, hour, min].join("");
  }
  
  convertStringToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = "";
    let result = [];
    array.forEach((element) => {
      temp += element + " ";
      count++;
      if (count == step) {
        result.push(temp.trim());
        count = 0;
        temp = "";
      }
    });
    if (temp != "") {
      result.push(temp);
    }
    return result;
  }
  
}
