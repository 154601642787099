import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from "src/app/constant/system-constant";
import { DmChungService } from "src/app/services/dm-chung.service";
import { KhamChuaBenhService } from "src/app/services/kham-chua-benh.service";
import { NumberFormatService } from "src/app/services/number-format.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";

@Component({
  selector: "app-tinh-hinh-kcb",
  templateUrl: "./tinh-hinh-kcb.component.html",
  styleUrls: ["./tinh-hinh-kcb.component.scss"],
})
export class TinhHinhKcbComponent implements OnInit {
  subscription: Subscription[] = [];

  @ViewChild("benhNhanTheoTuoiChart", { static: true })
  benhNhanTheoTuoiChart: PieChartComponent;
  @ViewChild("benhNhanBHYTChart", { static: true })
  benhNhanBHYTChart: PieChartComponent;
  @ViewChild("loaiBenhChart", { static: true })
  loaiBenhChart: BarChartComponent;
  @ViewChild("benhNhanTTChart", { static: true })
  benhNhanTTChart: BarChartComponent;
  @ViewChild("baoHiemTTChart", { static: true })
  baoHiemTTChart: BarChartComponent;
  @ViewChild("dichVuKyThuatChart", { static: true })
  dichVuKyThuatChart: BarChartComponent;
  formSearch = new FormGroup({
    year: new FormControl(new Date().getFullYear()),
    donVi: new FormControl(""),
  });

  documentElement: any;
  isFullScreen = false;

  params: any;
  listCSYT = [];
  keyword = "";
  currentPage = 1;
  totalPage: number;
  isFullListCSYT = false;
  timeOut: any;
  listDichVuKyThuat: any[] = [];
  isNoteHidden = true;
  noteContent: any;
  rowStyles: any = {};

  constructor(
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private khamChuaBenhService: KhamChuaBenhService,
    private numberFormatService: NumberFormatService
  ) {}

  ngOnInit(): void {
    this.params = {
      nam: this.formSearch.get("year").value,
    };
    this.documentElement = document.documentElement;
    this.getData(this.params);
    this.getListCSYT();
    this.rowStyles =  { 'height': '54vh'};
    this.dichVuKyThuatChart.barChartOptions.scales.xAxes[0].stacked = true;
    this.dichVuKyThuatChart.barChartOptions.scales.yAxes[0].stacked = true;
    this.dichVuKyThuatChart.barChartOptions.maintainAspectRatio = false;
    this.dichVuKyThuatChart.barChartOptions.legend = {
      labels: { fontColor: "#fff" },
      position: "right",
    };
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  toggleFullScreen() {
    if (!this.isFullScreen) {
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
    this.buildStyles();
  }

  buildStyles() {
    if (this.isFullScreen) {
      let others = (43.75 + 16 // form height and its margin bottom
                    + 8); // rows spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight};
    }
  }

  getListCSYT(keyword?: string, page?: number) {
    let param = {
      keyword: keyword ?? "",
      page: page ?? 1,
    };
    this.subscription.push(
      this.dmchungService.getListDonVi(param).subscribe(
        (rs) => {
          this.listCSYT = this.listCSYT.concat(rs.data);
          this.totalPage = rs.total_page;
          this.isFullListCSYT = this.totalPage <= this.currentPage ?? true;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  //hàm get dữ liệu khi scroll combobox CSYT
  getNextBatch() {
    this.currentPage += 1;
    this.getListCSYT(this.keyword, this.currentPage);
  }

  //hàm load dữ liệu search trong combobox CSYT
  onEnter(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.keyword = event.target.value;
      this.listCSYT = [];
      this.currentPage = 1;
      this.getListCSYT(this.keyword, this.currentPage);
    }, 300);
  }

  //clear text search
  resetCSYTSearchForm() {
    this.currentPage = 1;
    this.listCSYT = [];
    this.getListCSYT("", this.currentPage);
  }

  onFilterChange() {
    this.params = {};
    this.params = {
      nam: this.formSearch.get("year").value,
    };
    if (
      this.formSearch.get("donVi").value != undefined &&
      this.formSearch.get("donVi").value != 0
    ) {
      this.params["maCSKCB"] = this.formSearch.get("donVi").value;
    }
    this.getData(this.params);
  }

  getData(params?: any) {
    this.tkBenhNhanTheoTuoi(params);
    this.tkTheoLoaiBenhPhoBien(params);
    this.tkBenhNhanThamGiaBHYT(params);
    this.tkChiPhiBenhNhanThanhToan(params);
    this.tkSuDungDVKT(params);
  }

  tkBenhNhanTheoTuoi(params?: any) {
    let listData = [];
    let arrLabel = [];
    let arrData = [];

    this.subscription.push(
      this.khamChuaBenhService.getBenhNhanTheoTuoi(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrLabel.push(e.LABEL);
              arrData.push(e.VALUE);
            });
          }
          //Set label for chart
          this.benhNhanTheoTuoiChart.pieChartLabels = arrLabel;
          //Set data for chart
          this.benhNhanTheoTuoiChart.pieChartData = [];
          this.benhNhanTheoTuoiChart.pieChartData = arrData;

          //Set option for chart
          this.benhNhanTheoTuoiChart.pieChartOptions = {
            tooltips: {
              callbacks: {
                title: function (tooltipItem, data) {
                  return data["labels"][tooltipItem[0]["index"]].toString();
                },
                label: function (tooltipItem, data) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(data["datasets"][0]["data"][tooltipItem["index"]])
                  );
                  // return data['datasets'][0]['data'][tooltipItem['index']].toString();
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              position: "right",
            },
            elements: {
              arc: {
                borderColor: "#272A3D",
              },
            },
            plugins: {
              labels: {
                render: "percentage",
                fontColor: "#fff",
                precision: 1,
                position: "outside",
                textMargin: 5,
                outsidePadding: 4,
              },
              outlabels: {
                display: false,
                textAlign: "center",
                text: "%v (%p)",
                backgroundColor: false,
                font: {
                  minSize: 10,
                  maxSize: 14,
                },
                stretch: 15,
              },
            },
            layout: {
              padding: {
                left: 20,
                bottom: 20,
                top: 35
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkTheoLoaiBenhPhoBien(params?: any) {
    let listData = [];
    let arrLabel = [];
    let arrData = [];

    this.subscription.push(
      this.khamChuaBenhService.getBenhNhanTheoLoaiBenh(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrLabel.push(e.MA_LOAI);
              arrData.push(e.SO_LUONG);
            });
          }
          //Set label for chart
          this.loaiBenhChart.barChartLabels = arrLabel;
          //Set data for chart
          this.loaiBenhChart.barChartData = [];
          this.loaiBenhChart.barChartData = [
            { data: arrData, backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[3] },
          ];

          //Set option for chart
          this.loaiBenhChart.barChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(data["datasets"][0]["data"][tooltipItem["index"]])
                  );
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawTicks: false,
                    color: COLOR_PALETTES.GREY_TO_RED[4],
                    borderDash: [3, 3],
                    drawBorder: false,
                  },
                  ticks: {
                    padding: 8,
                    fontColor: "#fff",
                    suggestedMin: 0,
                    callback: (value, index, values) => {
                      return new Intl.NumberFormat("vi-VN").format(
                        Number(value)
                      );
                    },
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                font: {
                  size: 10
                },
                formatter: function (value, context) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(context.dataset.data[context.dataIndex])
                  );
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkBenhNhanThamGiaBHYT(params?: any) {
    let data;
    let arrLabel = ["Số hồ sơ có BHYT", "Số hồ sơ không có BHYT"];
    let arrData = [];

    this.subscription.push(
      this.khamChuaBenhService.getKhamBHYT(params).subscribe(
        (rs) => {
          data = rs.data;
          arrData.push(data.SL_CO_BHYT);
          arrData.push(data.SL_KHONG_BHYT);
          //Set label for chart
          this.benhNhanBHYTChart.pieChartLabels = arrLabel;
          //Set data for chart
          this.benhNhanBHYTChart.pieChartData = [];
          this.benhNhanBHYTChart.pieChartData = arrData;
          this.benhNhanBHYTChart.pieChartColor = [
            {
              backgroundColor: [
                COLOR_PALETTES.DUTCH_FIELD[1],
                COLOR_PALETTES.DUTCH_FIELD[0],
              ],
            },
          ];
          //Set option for chart
          this.benhNhanBHYTChart.pieChartOptions = {
            tooltips: {
              callbacks: {
                title: function (tooltipItem, data) {
                  return data["labels"][tooltipItem[0]["index"]].toString();
                },
                label: function (tooltipItem, data) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(data["datasets"][0]["data"][tooltipItem["index"]])
                  );
                  // return data['datasets'][0]['data'][tooltipItem['index']].toString();
                },
              },
            },
            responsive: true,            
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              position: "right",
            },
            elements: {
              arc: {
                borderColor: "#272A3D",
              },
            },
            plugins: {
              labels: {
                render: "percentage",
                fontColor: "#fff",
                precision: 1,
                position: "outside",
                textMargin: 5,
              },
              outlabels: {
                display: false,
                textAlign: "center",
                text: "%v (%p)",
                backgroundColor: false,
                font: {
                  minSize: 12,
                  maxSize: 18,
                },
                stretch: 15,
              },
            },
            layout: {
              padding: {
                left: 20,
                bottom: 20,
                top: 35
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkChiPhiBenhNhanThanhToan(params?: any) {
    let listData = [];
    let arrLabel = [];
    let arrData = [];

    this.subscription.push(
      this.khamChuaBenhService.getChiPhiBenhNhanThanhToan(params).subscribe(
        (rs) => {
          listData = rs.data;
          this.tkChiPhiBaoHiemThanhToan(rs.data);
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrLabel.push(e.THANG);
              arrData.push(e.TONG_BNTT);
            });
          }
          //Set label for chart
          this.benhNhanTTChart.barChartLabels = arrLabel;
          //Set data for chart
          this.benhNhanTTChart.barChartData = [];
          this.benhNhanTTChart.barChartData = [
            { data: arrData, backgroundColor: COLOR_PALETTES.RETRO_METRO[6] },
          ];

          this.benhNhanTTChart.barChartType = "horizontalBar";
          //Set option for chart
          this.benhNhanTTChart.barChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            tooltips: {
              callbacks: {
                title: function (tooltipItem, data) {
                  return 'Tháng ' + data["labels"][tooltipItem[0]["index"]].toString();
                },
                label: function (tooltipItem, data) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(data["datasets"][0]["data"][tooltipItem["index"]])
                  );
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    color: COLOR_PALETTES.GREY_TO_RED[4],
                    borderDash: [3, 3],
                    drawTicks: false,
                    drawBorder: false,
                  },
                  ticks: {
                    padding: 8,
                    beginAtZero: true,
                    fontColor: "#fff",
                    callback: (value, index, values) => {
                      return this.numberFormatService.nFormatter(value, 2);
                    },
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Tháng",
                    fontColor: "#fff",
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                clamp: true,
                align: "end",
                clip: false,
                formatter: function (value, context) {
                  return new Intl.NumberFormat("vi-VN").format(
                    Number(context.dataset.data[context.dataIndex])
                  );
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkChiPhiBaoHiemThanhToan(data?: []) {
    let arrLabel = [];
    let arrData = [];
    if (data && data.length > 0) {
      data.forEach((e) => {
        arrLabel.push(e["THANG"]);
        arrData.push(e["TONG_BHTT"]);
      });
    }
    // console.log(arrData)
    //Set label for chart
    this.baoHiemTTChart.barChartLabels = arrLabel;
    //Set data for chart
    this.baoHiemTTChart.barChartData = [];
    this.baoHiemTTChart.barChartData = [
      { data: arrData, backgroundColor: COLOR_PALETTES.RETRO_METRO[6] },
    ];

    this.baoHiemTTChart.barChartType = "horizontalBar";
    //Set option for chart
    this.baoHiemTTChart.barChartOptions = {
      layout: {
        padding: {
          top: 35
        }
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return 'Tháng ' + data["labels"][tooltipItem[0]["index"]].toString();
          },
          label: function (tooltipItem, data) {
            return new Intl.NumberFormat("vi-VN").format(
              Number(data["datasets"][0]["data"][tooltipItem["index"]])
            );
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        labels: { fontColor: "#fff" },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true,
              color: COLOR_PALETTES.GREY_TO_RED[4],
              borderDash: [3, 3],
              drawTicks: false,
              drawBorder: false,
            },
            ticks: {
              padding: 8,
              beginAtZero: true,
              fontColor: "#fff",
              callback: (value, index, values) => {
                return this.numberFormatService.nFormatter(value, 2);
              },
            },
          },
        ],
        yAxes: [
          {
            gridLines: { display: false },
            ticks: {
              fontColor: "#fff",
              suggestedMin: 0,
            },
            scaleLabel: {
              display: true,
              labelString: "Tháng",
              fontColor: "#fff",
            },
          },
        ],
      },
      plugins: {
        labels: false,
        datalabels: {
          color: "#fff",
          clamp: true,
          align: "end",
          clip: false,
          formatter: function (value, context) {
            return new Intl.NumberFormat("vi-VN").format(
              Number(context.dataset.data[context.dataIndex])
            );
          },
        },
      },
    };
  }

  tkSuDungDVKT(params?: any) {
    let param = {
      year: params["nam"],
      maCoSo: params["maCSKCB"] !== undefined ? params["maCSKCB"] : "",
      rowNum: 5,
    };
    this.subscription.push(
      this.khamChuaBenhService.getSuDungDVKT(param).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let result = rs.data;
          let months = result
            .map((x) => x.MONTH_DT)
            .filter((item, pos, self) => {
              return self.indexOf(item) === pos;
            });
          this.dichVuKyThuatChart.barChartLabels = months.map((x) => x);
          let names = result
            .map((x) => x.MA_NHOM)
            .filter((item, pos, self) => {
              return self.indexOf(item) === pos;
            });
          this.setListDichVuKyThuat(names, result);
          this.dichVuKyThuatChart.barChartData = [];
          let index = 0;
          names.forEach((name) => {
            let data = [];
            months.forEach((month) => {
              let arr = result.filter(
                (x) => x.MONTH_DT == month && x.MA_NHOM == name
              );
              data =
                arr.length > 0
                  ? data.concat(arr.map((x) => x.SO_LUONG))
                  : data.concat(0);
            });
            this.dichVuKyThuatChart.barChartData.push({
              data: data,
              label: name,
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[index++],
            });
          });
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  setListDichVuKyThuat(names, array) {
    this.listDichVuKyThuat = [];
    names.forEach((name) => {
      array.forEach((item) => {
        let isFound =
          this.listDichVuKyThuat.find((x) => x.MA_NHOM == name) != null;
        if (name == item.MA_NHOM && !isFound) {
          this.listDichVuKyThuat.push({
            MA_NHOM: name,
            TEN_NHOM: item.TEN_NHOM,
          });
        }
      });
    });
  }

  showNoteDVKT() {
    this.isNoteHidden = false;
    let html = "";
    this.listDichVuKyThuat.forEach((item) => {
      html +=
        '<small class="mr-3"><b>Nhóm ' +
        item.MA_NHOM +
        "</b>: " +
        item.TEN_NHOM +
        "</small>";
    });
    this.noteContent = html;
    console.log(this.noteContent);
  }
}
