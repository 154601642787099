import { KhamChuaBenhService } from "src/app/services/kham-chua-benh.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  MESSAGE_COMMON,
  MESSAGE_TYPE,
  COLOR_PALETTES,
  MA_DV_SO_Y_TE,
} from "src/app/constant/system-constant";
import { DmChungService } from "src/app/services/dm-chung.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
@Component({
  selector: "app-phan-tich-dl-kcb",
  templateUrl: "./phan-tich-dl-kcb.component.html",
  styleUrls: ["./phan-tich-dl-kcb.component.scss"],
})
export class PhanTichDlKcbComponent implements OnInit {
  private subscription: Subscription[] = [];
  isFitPage = false;
  rowStyles: any = {};

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  DISTRICT_LIST: any[] = [];
  DON_VI_LIST: any = [];
  LOAI_HINH_LIST: any[] = [];
  LOAI_SS_LIST: any[] = [];
  LOAI_LIST: any[] = [];

  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(1, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    quarter: new FormControl(1),
    district: new FormControl(""),
    coSoKCB: new FormControl(""),
    loaiHinh: new FormControl(0),
    loaiSoSanh: new FormControl(0),
    loai: new FormControl(0),
    tungay:  new FormControl(new Date().toISOString()),
    denngay:  new FormControl(new Date().toISOString()),
  });

  @ViewChild("tlKCBBHYT", { static: true })
  tlKCBBHYT: BarChartComponent;
  @ViewChild("tkKCBLoaiKham", { static: true })
  tkKCBLoaiKham: BarChartComponent;
  @ViewChild("tkChiPhiKCB", { static: true })
  tkChiPhiKCB: BarChartComponent;
  @ViewChild("tkCCTV", { static: true })
  tkCCTV: BarChartComponent;

  constructor(
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private khamChuaBenhService: KhamChuaBenhService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }

    this.LOAI_HINH_LIST = [
      { MA_LH: 2, TEN_LH: "Tất cả" },
      { MA_LH: 0, TEN_LH: "Công lập" },
      { MA_LH: 1, TEN_LH: "Tư nhân" },
    ];

    this.LOAI_SS_LIST = [
      { MA_LSS: 0, TEN_LSS: "Cùng kỳ" },
      { MA_LSS: 1, TEN_LSS: "Liền kề" },
    ];
    this.LOAI_LIST = [
      { LOAIID: 0, TENLOAI: "Theo năm" },
      { LOAIID: 1, TENLOAI: "Theo tháng" },
      { LOAIID: 2, TENLOAI: "Theo quí" },
      { LOAIID: 3, TENLOAI: "Theo ngày" },
    ];

    this.detectMode();
    this.getDistricts(() => {
      this.getData();
      this.getListCSKCB();
    });
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tlKCBBHYT.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tlKCBBHYT.barChartOptions.legend.position = "right";
    // this.tlKCBBHYT.barChartOptions.legend.reverse = true;
    this.tkKCBLoaiKham.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkKCBLoaiKham.barChartOptions.legend.position = "right";
    // this.tkKCBLoaiKham.barChartOptions.legend.reverse = true;
    this.tkChiPhiKCB.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkChiPhiKCB.barChartOptions.legend.position = "right";
    // this.tkChiPhiKCB.barChartOptions.legend.reverse = true;
    this.tkCCTV.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkCCTV.barChartOptions.legend.position = "right";
    // this.tkCCTV.barChartOptions.legend.reverse = true;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if (
      this.formSearch.controls.month.value &&
      this.formSearch.controls["loai"].value == 1
    ) {
      params["thang"] = this.formSearch.controls.month.value;
    }
    if (
      this.formSearch.controls.quarter.value &&
      this.formSearch.controls["loai"].value == 2
    ) {
      params["qui"] = this.formSearch.controls.quarter.value;
    }

    if (this.formSearch.controls.coSoKCB.value) {
      params["maCSKCB"] = this.formSearch.controls.coSoKCB.value.toString();
    }

    if (this.formSearch.controls.loaiHinh.value != 2) {
      params["loaiHinh"] = this.formSearch.controls.loaiHinh.value;
    }

    if (this.formSearch.controls.district.value) {
      params["maHuyen"] = this.formSearch.controls.district.value;
    }

    if(this.formSearch.controls.tungay.value &&
      this.formSearch.controls["loai"].value == 3){
        params["tungay"] = this.formatDate(this.formSearch.controls.tungay.value);
    }
    if(this.formSearch.controls.denngay.value &&
      this.formSearch.controls["loai"].value == 3){
        params["denngay"] = this.formatDate(this.formSearch.controls.denngay.value);
      }
    // if (this.formSearch.controls.loaiSoSanh.value ) {
    params["loaiSS"] = this.formSearch.controls.loaiSoSanh.value;
    params["loai"] = this.formSearch.controls.loai.value;
    // }
    console.log(params)
    return params;

  }

  getDistricts(callback?) {
    var obj = { size: 20, idTinh: 60 };
    this.subscription.push(
      this.dmchungService.getListHuyen(obj).subscribe(
        (rs: any) => {
          this.DISTRICT_LIST = rs.data.length == 0 ? [] : rs.data;
          this.DISTRICT_LIST.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
          if (callback) {
            callback();
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getListCSKCB() {
    this.formSearch.controls.coSoKCB.reset();
    let maHuyen = this.formSearch.controls.district.value;
    let loaiHinh = this.formSearch.controls.loaiHinh.value;
    // if (!maHuyen) {
    //   this.DON_VI_LIST = [];
    //   return false;
    // }
    let params = { maHuyen: maHuyen };
    if (loaiHinh !== 2) {
      params["loaiHinh"] = loaiHinh;
    }

    this.subscription.push(
      this.dmchungService.getListCoSoKCB(params).subscribe(
        (rs: any) => {
          if (rs.data.length == 0) {
            this.DON_VI_LIST = [];
            return false;
          }
          this.DON_VI_LIST = rs.data;
          this.DON_VI_LIST.forEach((element, index) => {
            if (element.MA_DON_VI == MA_DV_SO_Y_TE)
              this.DON_VI_LIST.splice(index, 1);
          });
        },
        (err) => {
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }

  getData() {
    this.tLeKCBBHYT();
    this.tKeKCBTheoLoaiKham();
    this.tKeChiPhiKCB();
    this.tKeCapCuuTuVong();
  }

  tLeKCBBHYT(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.khamChuaBenhService.getTLKCBCoBHYT(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tlKCBBHYT.barChartLabels = rs.data.map((x) => x.TT_BHYT);
          this.tlKCBBHYT.barChartData = [
            {
              data: rs.data.map((x) => Math.round(x.TG_TRUOC)),
              label: rs.data[0].LABEL_TRUOC,
              backgroundColor: "#a79b2f",
            },
            {
              data: rs.data.map((x) => Math.round(x.TG_HIEN_TAI)),
              label: rs.data[0].LABEL_HIEN_TAI,
              backgroundColor: "#617f36",
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeKCBTheoLoaiKham(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.khamChuaBenhService.getTKKCBLoaiKham(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkKCBLoaiKham.barChartLabels = rs.data.map((x) => x.LOAI_KCB);
          this.tkKCBLoaiKham.barChartData = [
            {
              data: rs.data.map((x) => Math.round(x.TG_TRUOC)),
              label: rs.data[0].LABEL_TRUOC,
              backgroundColor: "#b69deb",
            },
            {
              data: rs.data.map((x) => Math.round(x.TG_HIEN_TAI)),
              label: rs.data[0].LABEL_HIEN_TAI,
              backgroundColor: "#825cd3",
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeChiPhiKCB(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.khamChuaBenhService.getTKChiPhiKCB(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }

          this.tkChiPhiKCB.barChartLabels = rs.data.map((x) => x.LOAI);
          this.tkChiPhiKCB.barChartData = [
            {
              data: rs.data.map((x) => Math.round(x.TG_TRUOC)),
              label: rs.data[0].LABEL_TRUOC,
              backgroundColor: "#91be38",
            },
            {
              data: rs.data.map((x) => Math.round(x.TG_HIEN_TAI)),
              label: rs.data[0].LABEL_HIEN_TAI,
              backgroundColor: "#118a44",
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeCapCuuTuVong(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.khamChuaBenhService.getTKCapCuuTuVong(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkCCTV.barChartLabels = rs.data.map((x) => x.LOAI);
          this.tkCCTV.barChartData = [
            {
              data: rs.data.map((x) => Math.round(x.TG_TRUOC)),
              label: rs.data[0].LABEL_TRUOC,
              backgroundColor: "#abccec",
            },
            {
              data: rs.data.map((x) => Math.round(x.TG_HIEN_TAI)),
              label: rs.data[0].LABEL_HIEN_TAI,
              backgroundColor: "#5899da",
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}
