import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { MESSAGE_COMMON, MESSAGE_TYPE } from "src/app/constant/system-constant";
import { pageSizeOptions } from "src/app/services/config.service";
import { DmChungService } from "src/app/services/dm-chung.service";
import { PaginatorService } from "src/app/services/paginator.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Spinner } from "src/app/services/spinner.service";

@Component({
  selector: "app-danh-muc-ton-giao",
  templateUrl: "./danh-muc-ton-giao.component.html",
  styleUrls: ["./danh-muc-ton-giao.component.scss"],
})
export class DanhMucTonGiaoComponent implements OnInit {
  private subscription: Subscription[] = [];
  public formSearch = new FormGroup({
    keyword: new FormControl(null),
  });
  public pageEvent: PageEvent;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number;
  public pageOption = pageSizeOptions;
  public totalPage: number;
  public ELEMENT_DATA: any[] = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ["stt", "maTG", "tenTG"];

  constructor(
    private dmChungService: DmChungService,
    private spinner: Spinner,
    private snackbar: SnackbarService,
    private translator: PaginatorService,
    private cdRef: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    this.search();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.translator.translatePaginator(this.paginator);
    // this.paginator.nextPage = () =>
    //   this.paginatorChange(this.pageIndex + 1, this.paginator.pageSize, 1);
    // this.paginator.previousPage = () =>
    //   this.paginatorChange(this.pageIndex - 1, this.paginator.pageSize, 2);
    // this.paginator.lastPage = () =>
    //   this.paginatorChange(this.length - 1, this.paginator.pageSize, 3);
    // this.paginator.firstPage = () =>
    //   this.paginatorChange(0, this.paginator.pageSize, 4);
    // this.cdRef.detectChanges();
  }

  // public paginatorChange(page, pageSize, type) {
  //   switch (type) {
  //     case 1:
  //       this.pageIndex++;
  //       this.search();
  //       this.resetPageSize();
  //       break;
  //     case 2:
  //       this.pageIndex--;
  //       this.search();
  //       this.resetPageSize();
  //       break;
  //     case 3:
  //       this.pageIndex = this.totalPage - 1;
  //       this.search();
  //       this.resetPageSize();
  //       break;
  //     case 4:
  //       this.pageIndex = 0;
  //       this.search();
  //       this.resetPageSize();
  //       break;
  //     case 5:
  //       this.pageIndex = 0;
  //       this.search();
  //       this.resetPageSize();
  //       break;
  //     case 6:
  //       this.search();
  //       this.resetPageSize();
  //   }
  // }

  // public resetPageSize() {
  //   setTimeout(() => {
  //     this.paginator.pageIndex = this.pageIndex;
  //     this.dataSource.paginator.length = this.length;
  //   }, 500);
  // }

  public search(event?: PageEvent) {
    let keyword = this.formSearch.controls.keyword.value;
    let page = event ? event.pageIndex : this.pageIndex;
    let size = event ? event.pageSize : this.pageSize;
    var obj = {
      keyword: keyword ?? "",
      page: page + 1,
      size: size,
    };
    this.spinner.show();
    this.subscription.push(
      this.dmChungService.getListTonGiao(obj).subscribe(
        (rs) => {
          this.ELEMENT_DATA = rs.data;
          this.dataSource.data = this.ELEMENT_DATA;
          // this.pageIndex = page;
          // this.pageSize = size;
          // this.length = rs.total_row;
          // this.totalPage = rs.total_page;
          // this.resetPageSize();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    return event;
  }
}
