import { CapGcnComponent } from './cap-gcn/cap-gcn.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CapCchnComponent } from './cap-cchn/cap-cchn.component';
import { CongBoMyPhamComponent } from './cong-bo-my-pham/cong-bo-my-pham.component';
import { CapCchnHgiComponent } from './cap-cchn-hgi/cap-cchn-hgi.component';
import { CapGcnHGIComponent } from './cap-gcn-hgi/cap-gcn-hgi.component';
const routes: Routes = [
  { path: '', component: CapCchnComponent },
  { path: 'cap-cchn', component: CapCchnComponent },
  { path: 'cap-gcn', component: CapGcnComponent },
  { path: 'cap-gcn-hgi', component: CapGcnHGIComponent },
  { path: 'cong-bo-my-pham', component: CongBoMyPhamComponent },
  { path: 'cap-cchn-hgi', component: CapCchnHgiComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NghiepVuDuocRoutingModule { }
