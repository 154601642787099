import { PieChartComponent } from './../../../../shared/components/chart-js/pie-chart/pie-chart.component';
import { ToChucCanBoService } from "./../../../../services/to-chuc-can-bo.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
  MA_DV_SO_Y_TE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";

@Component({
  selector: "app-thong-tin-can-bo",
  templateUrl: "./thong-tin-can-bo.component.html",
  styleUrls: ["./thong-tin-can-bo.component.scss"],
})
export class ThongTinCanBoComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    coSoKCB: new FormControl(-1),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  SL_4_THE_TONG:  any = {};
  public DON_VI_LIST: any = [];
  public isNoneChart = false;
  public isNoneChartNN = false;
  public isNoneChartHD = false;
  public isNoneChartCT = false;
  public isNoneChartBS = false;
  userStorage = JSON.parse(sessionStorage.user);
  @ViewChild("trinhDoChuyenMon", { static: true })
  trinhDoChuyenMon: BarChartComponent;
  @ViewChild("tkbs", { static: true })
  tkbs: BarChartComponent;
  @ViewChild("quanLyNhaNuoc", { static: true })
  quanLyNhaNuoc: BarChartComponent;
  @ViewChild("tyLeVienChucHopDong", { static: true })
  tyLeVienChucHopDong: PieChartComponent;
  @ViewChild("tyLeVienChucGioiTinh", { static: true })
  tyLeVienChucGioiTinh: PieChartComponent;
  @ViewChild("tyLeTrinhDoChinhTri", { static: true })
  tyLeTrinhDoChinhTri: PieChartComponent;

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private toChucCanBoService: ToChucCanBoService
  ) {
    this.SL_4_THE_TONG = {
      TONG_CAN_BO:0,
      BS_VAN_DAN:0,
      TONG_NU:0,
      TONG_NAM:0
    }

  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    this.getListCSKCB()
    this.detectMode();
    this.getData();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tkbs.barChartOptions.legend.display = false;

    this.tkbs.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.trinhDoChuyenMon.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.trinhDoChuyenMon.barChartOptions.legend.display = false;
    this.quanLyNhaNuoc.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.quanLyNhaNuoc.barChartOptions.legend.display = false;
    this.tyLeVienChucHopDong.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeVienChucHopDong.pieChartOptions.legend.position = "right";
    this.tyLeTrinhDoChinhTri.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeTrinhDoChinhTri.pieChartOptions.legend.position = "right";
    this.tyLeTrinhDoChinhTri.pieChartType = "doughnut";
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

 

  getData(): void {
    this.get4TheTong();
    this.tKeTheoTrinhDoChuyenMon();
    this.tKeTheoQuanLyNhaNuoc();
    this.tlVienChucHopDong();
    // this.tlVienChucGioiTinh();
    this.tlTrinhDoChinhTri();
    this.getTongBs();
  }
  getListCSKCB() {
    this.formSearch.controls.coSoKCB.reset();
    this.subscription.push(
      this.toChucCanBoService.getDanhMucDonVi().subscribe(
        (rs: any) => {
          if (rs.data.length == 0) {
            this.DON_VI_LIST = [];
            return false;
          }
          
          this.DON_VI_LIST = rs.data;
          // this.DON_VI_LIST.forEach((element, index) => {
          //   if (element.MA_DON_VI == MA_DV_SO_Y_TE)
          //     this.DON_VI_LIST.splice(index, 1);
          // });
          this.DON_VI_LIST.unshift({MA_DON_VI: -1, TEN_DON_VI: "Tất cả"});
          this.getData();
        },
        (err) => {
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }
  getFilter() {
    let params = {};
    console.log(this.formSearch.controls.coSoKCB.value);
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if (this.formSearch.controls.coSoKCB.value) {
      params["maDonVi"] = this.formSearch.controls.coSoKCB.value.toString();
    }
    else if(this.userStorage?.MA_DON_VI.toString() !== MA_DV_SO_Y_TE.toString()) {
      params["maDonVi"] = this.userStorage?.MA_DON_VI 
    }
    else{
      params["maDonVi"] = -1
    }

    // console.log(params)
    return params;

  }
  tKeTheoTrinhDoChuyenMon(): void {
    let obj: any = this.getFilter();
    this.isNoneChart= true;
    // obj.rowNum = 10;
    this.subscription.push(
      this.toChucCanBoService.getTrinhDoChuyenMon(obj).subscribe(
        (rs: any) => {
          if(rs.data.length == 0){
            this.isNoneChart= false;
          }
          if (!rs.success) {
            return false;
          }
          this.trinhDoChuyenMon.barChartLabels = rs.data.map(
            (x) => x.TRINH_DO_CHUYEN_MON
          );
          let data = rs.data.map((x) => Math.round(x.SL));
          
          this.trinhDoChuyenMon.barChartData = [
            {
              data: data,
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[3],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTongBs(): void {
    let obj: any = this.getFilter();
    this.isNoneChartBS= true;
    // obj.rowNum = 10;
    this.subscription.push(
      this.toChucCanBoService.getTongBs(obj).subscribe(
        (rs: any) => {
          if(rs.data.length == 0){
            this.isNoneChartBS= false;
          }
          if (!rs.success) {
            return false;
          }
          this.tkbs.barChartLabels = rs.data.map(
            (x) => x.LOAI_CHUYEN_MON
          );
          let data = rs.data.map((x) => Math.round(x.SO_LUONG));
          
          this.tkbs.barChartData = [
            {
              data: data,
              backgroundColor: "#c7980d",
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeTheoQuanLyNhaNuoc(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.isNoneChartNN = true;
    this.subscription.push(
      this.toChucCanBoService.getQuanLyNhaNuoc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          // check dũ liệu rỗng
          if(rs.data.length == 0){
            this.isNoneChartNN = false;
          }
          this.quanLyNhaNuoc.barChartLabels = rs.data.map(
            (x) => x.QUAN_LY_NHA_NUOC
          );
          let data = rs.data.map((x) => Math.round(x.SL));
          this.quanLyNhaNuoc.barChartData = [
            {
              data: data,
              backgroundColor: COLOR_PALETTES.RETRO_METRO[6],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tlVienChucHopDong(): void {
    let obj = this.getFilter();
    this.isNoneChartHD = true;
    this.subscription.push(
      this.toChucCanBoService.getTLVienChucHopDong(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          // check dũ liệu rỗng
          if(rs.data.length == 0){
            this.isNoneChartHD = false;
          }
          let data = rs.data;
          // if(data.length == 0){
          //   this.snackbar.showMessage("Dữ liệu biểu đồ 'Tỷ lệ viên chức - hợp đồng' chưa có dữ liệu", MESSAGE_TYPE.ERROR);
          //   // this.snackbar.showError(
          //   //   "Dữ liệu biểu đồ 'Tỷ lệ viên chức - hợp đồng' chưa có dữ liệu",
          //   //   MESSAGE_TYPE.SUCCESS
          //   // );
          // }
          if (data.length == 0) {
            this.tyLeVienChucHopDong.pieChartData = [];
            return false;
          }
          let tyLeVienChuc = data[0].TYLE_VC;
          let tyLeHopDong = data[0].TYLE_HD;

          this.tyLeVienChucHopDong.pieChartData = [
            tyLeVienChuc,
            tyLeHopDong,
          ];
          this.tyLeVienChucHopDong.pieChartLabels = [
            "Công chức/viên chức",
            "Nhân viên hợp đồng",
          ];
          this.tyLeVienChucHopDong.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_2 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tlVienChucGioiTinh(): void {
    let obj = this.getFilter();
    
    this.subscription.push(
      this.toChucCanBoService.getTLVienChucGioiTinh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tyLeVienChucGioiTinh.pieChartData = [];
            return false;
          }
          let tyLeNam = data[0].TYLE_NAM;
          let tyLeNu = data[0].TYLE_NU;

          this.tyLeVienChucGioiTinh.pieChartData = [
            tyLeNam,
            tyLeNu,
          ];
          this.tyLeVienChucGioiTinh.pieChartLabels = [
            "Nam",
            "Nữ",
          ];
          this.tyLeVienChucGioiTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.SPRING_PASTELS },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  
  tlTrinhDoChinhTri(): void {
    let obj = this.getFilter();
    this.isNoneChartCT = true;
    this.subscription.push(
      this.toChucCanBoService.getTLTrinhDoChinhTri(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          if(rs.data[0].CC == null && rs.data[0].CHUA == null && rs.data[0].TC == null && rs.data[0].SC == null){
            this.isNoneChartCT = false;
          }
          let data = rs.data;
          // check dũ liệu rỗng
         
          if (data.length == 0) {
            this.tyLeTrinhDoChinhTri.pieChartData = [];
            return false;
          }
          let tyLeCaoCap = data[0].CC;
          let tyLeTrungCap = data[0].TC;
          let tyLeSoCap = data[0].SC;
          let tyLeChua = data[0].CHUA;

          this.tyLeTrinhDoChinhTri.pieChartData = [
            tyLeCaoCap,
            tyLeTrungCap,
            tyLeSoCap,
            tyLeChua,
          ];
          this.tyLeTrinhDoChinhTri.pieChartLabels = [
            "Cao cấp",
            "Trung cấp",
            "Sơ cấp",
            "Chưa có",
          ];
          this.tyLeTrinhDoChinhTri.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_1 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  get4TheTong(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.toChucCanBoService.get4TheTong(obj).subscribe(
        (rs) => {
          this.SL_4_THE_TONG = {
            TONG_CAN_BO: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_CAN_BO),
            BS_VAN_DAN: new Intl.NumberFormat("vi-VN").format(rs.data[0].BS_VAN_DAN),
            TONG_NAM: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_NAM),
            TONG_NU: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_NU)
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
