import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import themes from 'highcharts/themes/dark-unica';
themes(Highcharts);

@Component({
  selector: 'app-pie-highchart',
  templateUrl: './pie-highchart.component.html',
  styleUrls: ['./pie-highchart.component.scss']
})
export class PieHighchartComponent implements OnInit {

  @Input()
  public paddingTop: number = 0;

  chart: Chart;

  chartOptions: Highcharts.Options = {};

  data: any[] = [];

  seriesName: string = "n/a"; // ex: 'Browser share'

  showInLegend = true;

  // sample
  // public sample: any[] = [
  //   ['Firefox', 45.0],
  //   ['IE', 26.8],
  //   {
  //     name: 'Chrome',
  //     y: 12.8,
  //     sliced: true,
  //     selected: true
  //   },
  //   ['Safari', 8.5],
  //   ['Opera', 6.2],
  //   ['Others', 0.7]
  // ];
  // end sample

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.chartOptions = {
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        style: {
          fontFamily: 'roboto'
        }
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            //format: '{point.name}: {point.percentage:.1f}%', // can use html tag, ex: <b>{point.name}</b>
            // format: '{point.percentage:.1f}% ({point.y})',
            formatter: function() {
              return this.point.percentage.toFixed(1) + '%' 
                + ' (' + Intl.NumberFormat("vi-VN").format(Math.round(this.point.y)) + (this.point.x ?? '') + ')' ;
            },
            style: { fontWeight: 'normal', fontSize: '11px' },
            // overflow: 'allow',
            // crop: false,
            padding: 0
          },
          showInLegend: this.showInLegend,
          borderWidth: 0,
          size: "100%"
        }
      },
      series: [{
        type: 'pie',
        name: this.seriesName,
        data: this.data // see sample
      }],
      legend: {
        itemStyle: {
          fontWeight: 'normal',
        },
        itemMarginTop: 3,
        itemMarginBottom: 3,
        backgroundColor: null,
        // width: '100%',
        align: 'center',
        padding: 0,
        margin: 10,
        y: 13
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    };
    this.chart = new Chart(this.chartOptions);
  }

  refreshChart() {
    this.chartOptions.series = [{
      type: 'pie',
      name: this.seriesName,
      data: this.data
    }];
    this.chart = new Chart(this.chartOptions);
  }
}
