import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../authentication.service";
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    public router: Router,
    public authService: AuthenticationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let currentPath = state.url.substring(1, state.url.length);
    if (sessionStorage.getItem("menu") == null) {
      this.authService.removeToken();
      return false;
    } else {
      if (sessionStorage.getItem("menu").includes(currentPath)) {
        if(currentPath == "" && sessionStorage.getItem("menu").includes('dashboard')){
          //this.router.navigate(["/dashboard"]);
        }
        return true;
      } else {
        this.router.navigate(["/forbidden"]);
        return false;
      }
    }
  }
}
