import { Component, OnInit, ViewChild, HostListener  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import {CDCService} from 'src/app/services/trung-tam-cdc.service'
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { ColorService } from 'src/app/services/color.service';

@Component({
  selector: 'app-tinh-hinh-phong-chong-lao',
  templateUrl: './tinh-hinh-phong-chong-lao.component.html',
  styleUrls: ['./tinh-hinh-phong-chong-lao.component.scss'],
})
export class TinhHinhPhongChongLaoComponent implements OnInit {
  subscription: Subscription[] = [];

  @ViewChild("khamPhatHienLao", { static: true }) khamPhatHienLao: BarChartComponent;
  @ViewChild("dieuTriLaoCacThe", { static: true }) dieuTriLaoCacThe: BarChartComponent;
  @ViewChild("dieuTriLaoKhangThuoc", { static: true }) dieuTriLaoKhangThuoc: BarChartComponent;
  formSearch = new FormGroup({
    nam: new FormControl(new Date().getFullYear()),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    
  });
  
  documentElement: any;
  isFullScreen = false;
  params: any;
  keyword = "";
  currentPage = 1;
  totalPage: number;
  rowStyles: any = {};
  rowStylesFull: any = {};
  timeOut: any;
  listThang = [];
  listNam = [];
  isFitPage = false;
  dsTheTong: any;

  constructor(
    private snackbar: SnackbarService,
    private cdcService: CDCService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    
    )
  { 
    this.dateAdapter.setLocale("vi-VN")
    this.dsTheTong = {
      KHANGTHUOC_TIEU_CHI: 0,
      KHANGTHUOC_THUC_HIEN: 0,
      PHATHIEN_TIEU_CHI: 0,
      PHATHIEN_THUC_HIEN: 0,
      CACTHE_TIEU_CHI: 0,
      CACTHE_THUC_HIEN: 0
    };
    
  }
  ngOnInit(): void {
    
    this.documentElement = document.documentElement;
    this.getListNam()
    this.getListThang()
    this.onFilterChange() 
    this.detectMode();
  }
  
  getFilter(){
      let nam  = this.formSearch.get("nam").value;
      let thang  = this.formSearch.get("thang").value == "Tất cả"?"": this.formSearch.get("thang").value;
      let result  =  { 
        nam: nam,
        thang:thang,
      };
      return result;
  }
  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  toggleFullScreen() {
    if (!this.isFullScreen) {
      
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  onFullscreenChange(event) {
    let mainView = document.getElementsByClassName("main-wrapper")[0];
    if (!document.fullscreenElement) {
      // Xử lý khi thoát khỏi chế độ toàn màn hình
      mainView.classList.add("vh-100");
    }
  }
  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== 'responsive';
    this.buildStyles();
    this.khamPhatHienLao.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.dieuTriLaoCacThe.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.dieuTriLaoKhangThuoc.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  buildStyles() {
    let mainView = document.getElementsByClassName("main-wrapper")[0];
   
    this.rowStyles = {};
    mainView.classList.remove("vh-100");
    
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + (16 * 2)); // 2 rows spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
      mainView.classList.add("vh-100");
     
    }
    
  }
  ngAfterViewInit() {}
  onFilterChange (){
    this.getFilter()
    this.getData();
  }
  getListNam(){
    this.subscription.push(
      this.cdcService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListThang(){
    this.subscription.push(
      this.cdcService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getDS6TheTong(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getDS6TheTong(obj).subscribe(
        (rs) => {
          this.dsTheTong = {
            KHANGTHUOC_TIEU_CHI: new Intl.NumberFormat("vi-VN").format(rs.data[0].KHANGTHUOC_TIEU_CHI),
            KHANGTHUOC_THUC_HIEN: new Intl.NumberFormat("vi-VN").format(rs.data[0].KHANGTHUOC_THUC_HIEN),
            PHATHIEN_TIEU_CHI: new Intl.NumberFormat("vi-VN").format(rs.data[0].PHATHIEN_TIEU_CHI),
            PHATHIEN_THUC_HIEN: new Intl.NumberFormat("vi-VN").format(rs.data[0].PHATHIEN_THUC_HIEN),
            CACTHE_TIEU_CHI: new Intl.NumberFormat("vi-VN").format(rs.data[0].CACTHE_TIEU_CHI),
            CACTHE_THUC_HIEN: new Intl.NumberFormat("vi-VN").format(rs.data[0].CACTHE_THUC_HIEN),
            
          }
          console.log(this.dsTheTong)
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKKhamPhatHienLao() {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getTKKhamPhatHienLao(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.khamPhatHienLao.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
          let data = rs.data.map(x => x.TIEU_CHI);
          let dataThucHien = rs.data.map(x => x.THUC_HIEN);
          this.khamPhatHienLao.barChartData = [
            { 
              data: data, 
              label: 'Chỉ tiêu', 
              backgroundColor: "#da7e78" ,
              datalabels: {
                align: 'top',
                anchor: 'end',
                padding:15,
              },
            },
            {
              data: dataThucHien, 
              label: 'Thực hiện', 
              backgroundColor:"#e3bb3d",
              datalabels: {
                align: 'top',
                anchor: 'end',
                padding:0,
              },
             
            }
          ];
          this.khamPhatHienLao.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                     
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; 
                  // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        })
    );
  }
  getTKDieuTriLaoCacThe() {
    let obj = this.getFilter();
    this.subscription.push(this.cdcService.getTKDieuTriLaoCacThe(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.dieuTriLaoCacThe.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
      let data = rs.data.map(x => x.TIEU_CHI);
      let dataThucHien = rs.data.map(x => x.THUC_HIEN);
      this.dieuTriLaoCacThe.barChartData = [
        { 
          data: data, 
          label: 'Chỉ tiêu', 
          backgroundColor: "#0060ff",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: dataThucHien, 
          label: 'Thực hiện', 
          backgroundColor: "#ffa300",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:0
          },
          
        }
      ];
      this.dieuTriLaoCacThe.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; 
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  getTKDieuTriLaoKhangThuoc() {
    let obj = this.getFilter();
    this.subscription.push(this.cdcService.getTKDieuTriLaoKhangThe(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }

      this.dieuTriLaoKhangThuoc.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
      let data = rs.data.map(x => x.TIEU_CHI);
      let dataThucHien = rs.data.map(x => x.THUC_HIEN);
      this.dieuTriLaoKhangThuoc.barChartData = [
        { 
          data: data, 
          label: 'Chỉ tiêu', 
          backgroundColor: "#9b19f5",
          
          datalabels: {
            align: 'end',
            anchor: 'end',
          },
          
        },
        {
          data: dataThucHien, 
          label: 'Thực hiện', 
          backgroundColor: "#0bb4ff",
          datalabels: {
            align: 'end',
            anchor: 'end',
            color:"#fff"
          }
        }
      ];
      this.dieuTriLaoKhangThuoc.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; 
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    return result;
  }
  getData() {
   this.getDS6TheTong()
   this.getTKKhamPhatHienLao()
   this.getTKDieuTriLaoCacThe()
   this.getTKDieuTriLaoKhangThuoc()
  }
}
