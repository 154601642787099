<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
      <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
          <div class="d-flex align-items-center">
              <div class="ml-auto mr-3">
                  <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="nam" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                  </ngx-dropdown>
              </div>
              <div class="mr-3">
                <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="quy" [formControl]="formSearch.controls.quy" label="Chọn quý" (selected)="getData()" keyId="id" keyName="text">
                </ngx-dropdown>
              </div>
              <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                  <mat-icon color="primary">fullscreen</mat-icon>
              </button>
          </div>
      </form>


      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Tổng tài sản đầu năm</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[0]"> {{ this.SUMMARY_INFO?.TAISAN_DAUNAM }} </div>
                          </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Tổng tài sản cuối năm</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[1]"> {{ this.SUMMARY_INFO?.TAISAN_CUOINAM }} </div>
                          </div>
                      </div>
                      <div style="height: 15px;" class="w-100">&nbsp;</div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Tổng nguồn vốn đầu năm</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[2]"> {{ this.SUMMARY_INFO?.NGUONVON_DAUNAM }} </div>
                          </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Tổng nguồn vốn cuối năm</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[3]"> {{ this.SUMMARY_INFO?.NGUONVON_CUOINAM }} </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-8 h-100' : 'col-xl-8 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                  <h4 class="chart-title">BÁO CÁO TỔNG HỢP Tài sản (Đơn vị : Tỷ đồng)</h4>
                  <div class="h-100 pt-5" style="overflow-y: auto;">
                    <div class="h-100" style="width: 350%;">
                      <app-bar-chart #taiSan ></app-bar-chart>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-12 h-100 mt-3' : 'col-xl-12 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                  <h4 class="chart-title">BÁO CÁO TỔNG HỢP Nguồn vốn (Đơn vị : Tỷ đồng)</h4>
                  <div class="h-100 pt-5" style="overflow-y: auto;">
                    <div class="h-100" style="width: 180%;">
                      <app-bar-chart #nguonVon ></app-bar-chart>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
