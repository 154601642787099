import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { apiUrl } from "../constant/api-url";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KhamChuaBenhService extends BaseService {

  getTongSoHoSo(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_TONG_SO_HO_SO, requestParam);
  }

  getHoSoVaoVien(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_VAO_VIEN, requestParam);
  }
  
  getCapCuu(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_CAP_CUU, requestParam);
  }
  
  getTuVong(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_TU_VONG, requestParam);
  }
    
  getKhamTheoNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_KHAM_THEO_NGAY, requestParam);
  }

  getRaVaoTheoNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_RA_VAO_THEO_NGAY, requestParam);
  }
  
  getNoiTruNgoaiTruNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_NOI_TRU_NGOAI_TRU_NGAY, requestParam);
  }

  getKhamBHYTNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_KHAM_BHYT_NGAY, requestParam);
  }
  
  getCapCuuTuVongNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_CAP_CUU_TU_VONG_NGAY, requestParam);
  }
  
  getTaiNanThuongTichNgay(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_TAI_NAN_THUONG_TICH_NGAY, requestParam);
  }
    
  getKhamBHYT(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_KHAM_BHYT, requestParam);
  }
    
  getBenhNhanTheoTuoi(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_BENH_NHAN_THEO_TUOI, requestParam);
  }
      
  getBenhNhanTheoLoaiBenh(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_BENH_NHAN_THEO_LOAI_BENH, requestParam);
  }
      
  getChiPhiBenhNhanThanhToan(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_CHI_PHI_BENH_NHAN_THANH_TOAN, requestParam);
  }

  getSuDungDVKT(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SU_DUNG_DVKT, requestParam);
  }

  getTLKCBCoBHYT(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TL_KCB_CO_BHYT, requestParam);
  }

  getTKKCBLoaiKham(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_KCB_LOAI_KHAM, requestParam);
  }

  getTKChiPhiKCB(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_CHI_PHI_KCB, requestParam);
  }

  getTKCapCuuTuVong(requestParam?): Observable<any> {
    return super.get(apiUrl.KHAM_CHUA_BENH.TK_CAP_CUU_TU_VONG, requestParam);
  }
}
