import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() menuList: any[];
  constructor() { }

  ngOnInit(): void {
  }

  isShow(menu) {
    if (menu.isShow === undefined) {
      menu["isShow"] = true;
    } else {
      menu["isShow"] = !menu["isShow"];
    }
  }
}
