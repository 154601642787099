<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <mat-form-field class="max-width-160px mb-0" appearance="outline">
                        <mat-label>Loại thời gian</mat-label>
                        <mat-select panelClass="dark-mode" formControlName="timeType">
                            <mat-option [value]="0">Theo năm </mat-option>
                            <mat-option [value]="2">Theo quý </mat-option>
                            <mat-option [value]="1">Theo tháng </mat-option>
                            <mat-option [value]="3">Theo ngày </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="this.formSearch.controls['timeType'].value != 3" class="mr-3">
                    <div class="max-width-160px">
                        <ngx-dropdown panelClass="dark-mode" class="w-100 mb-0" placeholder="Chọn năm" [data]="YEARS"
                            [formControl]="$any(formSearch.controls['nam'])" label="Chọn năm" (selected)="getData()" 
                            keyId="id" keyName="text">
                        </ngx-dropdown>
                    </div>
                </div>
                <div *ngIf="this.formSearch.controls['timeType'].value == 2" class="mr-3">
                    <div class="max-width-160px">
                        <ngx-dropdown panelClass="dark-mode" class="w-100 mb-0" placeholder="Chọn quy" [data]="QUARTERS"
                            [formControl]="$any(formSearch.controls['quy'])" label="Chọn quý" (selected)="getData()" keyId="id"
                            keyName="text"></ngx-dropdown>
                    </div>
                </div>
                <div *ngIf="this.formSearch.controls['timeType'].value == 1" class="mr-3">
                    <div class="max-width-160px">
                        <ngx-dropdown panelClass="dark-mode" class="w-100 mb-0" placeholder="Chọn tháng" [data]="MONTHS"
                            [formControl]="$any(formSearch.controls['thang'])" label="Chọn tháng" (selected)="getData()" keyId="id"
                            keyName="text"></ngx-dropdown>
                    </div>
                </div>
                <div *ngIf="this.formSearch.controls['timeType'].value == 3" class="mr-3">
                    <mat-form-field class="max-width-160px mb-0" panelClass="dark-mode" appearance="outline">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput formControlName="tuNgay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker"
                            autocomplete="off" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="tuNgayPicker"></mat-datepicker-toggle>
                        <mat-datepicker #tuNgayPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="this.formSearch.controls['timeType'].value == 3" class="mr-3">
                    <mat-form-field class="max-width-160px mb-0" panelClass="dark-mode" appearance="outline">
                        <mat-label>Đến ngày</mat-label>
                        <input matInput formControlName="denNgay" placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker"
                            autocomplete="off" (dateChange)="getData()">
                        <mat-datepicker-toggle matSuffix [for]="denNgayPicker"></mat-datepicker-toggle>
                        <mat-datepicker #denNgayPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" [data]="LIST_TINH" label="Chọn tỉnh" placeholder="Chọn tỉnh"
                        (selected)="getListHuyen($event.value); getData();" [formControl]="$any(formSearch.controls['maTinh'])" 
                        keyId="MA_TINH" keyName="TEN_TINH">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn huyện" [data]="LIST_HUYEN"
                        [formControl]="$any(formSearch.controls['maHuyen'])" label="Chọn huyện" 
                        (selected)="getListXa($event.value); getData();" keyId="MA_HUYEN" keyName="TEN_HUYEN">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn xã" [data]="LIST_XA"
                        [formControl]="$any(formSearch.controls['maXa'])" label="Chọn xã" (selected)="getData()" 
                        keyId="MA_XA" keyName="TEN_XA">
                    </ngx-dropdown>
                </div>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>
        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ tăng dân số tự nhiên so với năm trước đó</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100">
                                <app-bar-chart #tkTyLeDanSo [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ giới tính khi sinh so với năm trước đó</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100">
                                <app-bar-chart #tkTyLeDanSoGioiTinh [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tổng các biện pháp tránh thai so với năm trước đó</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100">
                                <app-bar-chart #tkTyLeDanSoTranhThai [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW 2 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê số lượng người trong độ tuổi lao động</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100">
                                <app-bar-chart #tkTyLeDanSoTuoiLD [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tỷ lệ trình độ văn hóa của người dân</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100">
                                <app-bar-chart #tkTyLeDanSoTDVH [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>