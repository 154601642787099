<div class="main-wrapper">
    <mat-toolbar class="title mb-3">
        <mat-toolbar-row>
            <div class="name-page">DANH MỤC DÂN TỘC</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-card>
        <form [formGroup]="formSearch" id="formSearch" (submit)="search()">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 align-items-center">
                    <mat-form-field class="w-100 mb-0" appearance="outline">
                        <mat-label>Từ khóa</mat-label>
                        <input matInput formControlName="keyword" placeholder="Nhập từ khóa tìm kiếm">
                    </mat-form-field>
                </div>
                <button mat-raised-button color="primary" id="btn-search" type="submit"> Tìm kiếm </button>
            </div>
        </form>
    </mat-card>

    <div class="mt-3 mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="stt" class="mat-card">
                <th mat-header-cell *matHeaderCellDef class="text-center">STT</th>
                <td mat-cell *matCellDef="let element; let i = index;" class="text-center">{{ (i + 1) + pageIndex * pageSize }}</td>
            </ng-container>

            <ng-container matColumnDef="maDT">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Mã Dân Tộc </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.MA_DAN_TOC }} </td>
            </ng-container>

            <ng-container matColumnDef="tenDT">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Tên Dân Tộc </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.TEN_DAN_TOC }} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef> Thao tác </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                    <button mat-icon-button matTooltip="Cập nhật" 
                        (click)="setUpdateFormData(element.MA_KHOA); 
                                 openModal(updateModalContent, 'updateModal', 'lg')">
                        <mat-icon color="primary">edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Xóa" 
                        (click)="setDeleteFormData(element.MA_KHOA, element.TEN_KHOA); 
                                 openModal(deleteModalContent, 'deleteModal')">
                        <mat-icon color="warn"> delete </mat-icon>
                    </button>
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="d-flex justify-content-end">
            <mat-paginator class="w-100" #paginator showFirstLastButtons [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageOption" (page)="pageEvent = search($event)">
            </mat-paginator>
        </div>
    </div>
</div>