<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Loại thời gian</mat-label>
                        <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="timeType" 
                                (selectionChange)="getData()">
                            <mat-option [value]="0">Theo năm</mat-option>
                            <mat-option [value]="1">Theo tháng</mat-option>
                            <mat-option [value]="2">Theo quý</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value != 3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS"
                        [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()"
                        keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value == 1">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn tháng" [data]="MONTHS"
                        [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()"
                        keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3" *ngIf="formSearch.controls.timeType.value == 2">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn quý" [data]="QUARTERS"
                        [formControl]="formSearch.controls.quarter" label="Chọn quý" (selected)="getData()"
                        keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Tuyến</mat-label>
                        <mat-select panelClass="dark-mode" placeholder="Chọn tuyến" formControlName="tuyen" 
                                (selectionChange)="getData()">
                            <mat-option value="">Tất cả</mat-option>
                            <mat-option [value]="1">Trung ương</mat-option>
                            <mat-option [value]="2">Tỉnh/Thành phố</mat-option>
                            <mat-option [value]="3">Huyện/Thị/Thành</mat-option>
                            <mat-option [value]="4">Xã/Phường/Thị trấn</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" 
                        type="button" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng lượt khám bằng CCCD gắn chip</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[1]"> {{ getNumber(this.sumraryInfo.SL_KCB_CCCD) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng lượt thông báo lưu trú</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[2]"> {{ getNumber(this.sumraryInfo.THONG_BAO_LUU_TRU) }} </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 15px;">&nbsp;</div>
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng lượt liên thông giấy KSK LÁI XE</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[3]"> {{ getNumber(this.sumraryInfo.LTDL_GIAY_KSK_LAI_XE) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng lượt cài đặt VNeID mức độ 2</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[4]"> {{ getNumber(this.sumraryInfo.CCVC_DK_VNEID_MUC_2) }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tình hình thực hiện nhiệm vụ đề án 06 (đơn vị: %)</h4>
                        <app-bar-chart #nhiemVuDeAn06></app-bar-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê KCB bằng thẻ CCCD gắn chip tích hợp BHYT</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.kcbCCCD.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 w-100 pt-5" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.kcbCCCD.barChartData[0], 12)">
                                    <app-bar-chart #kcbCCCD [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tình hình thực hiện lưu trú trực tuyến</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.luuTru.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5" style="overflow-y: auto;overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.luuTru.barChartData[0], 12)">
                                <app-bar-chart #luuTru [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê dữ liệu Giấy khám sức khỏe lái xe</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.giayKhamSucKhoe.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5" style="overflow-x: auto;overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.giayKhamSucKhoe.barChartData[0], 12)">
                                <app-bar-chart #giayKhamSucKhoe [isSummary]="true"></app-bar-chart>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê nhân viên y tế cài đặt VNeID mức độ 2</h4>
                        <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.caiDatVneID.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div>
                        <div class="h-100 pt-5" style="overflow-x: auto;overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.caiDatVneID.barChartData[0], 12)">
                                <app-bar-chart #caiDatVneID [isSummary]="true"></app-bar-chart>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>