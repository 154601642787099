import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { COLOR_PALETTES, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { CommonFunctionService } from 'src/app/services/common-function.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TaiChinhKeHoachService } from 'src/app/services/tai-chinh-ke-hoach.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';

@Component({
  selector: 'app-dau-tu-cong',
  templateUrl: './dau-tu-cong.component.html',
  styleUrls: ['./dau-tu-cong.component.scss']
})
export class DauTuCongComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};

  public currentDate = new Date();
  public YEARS: any[] = [];

  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl(null, [Validators.required]),
  });

  public CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_1[0],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.PALETTE_4[1],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[3],
    COLOR_PALETTES.RIVER_NIGHTS[2],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
  ];

  public SUMMARY_INFO: any;
  @ViewChild("tKeTrungHan", { static: true }) tKeTrungHan: BarChartComponent;
  @ViewChild("tKeDaiHan", { static: true }) tKeDaiHan: BarChartComponent;

  constructor(
    private aggService: TaiChinhKeHoachService,
    private snackbar: SnackbarService) {
  }

  ngOnInit(): void {
    let currentYear = this.currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue([currentYear]);
    for (let i = currentYear; i > 1970; i--) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    this.detectMode();
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.buildStyles();

    this.tKeTrungHan.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeTrungHan.barChartOptions.legend.display = false;
    this.tKeDaiHan.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tKeDaiHan.barChartOptions.legend.display = false;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 1 row spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  public getFilter() {
    return this.formSearch.getRawValue();
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public thongTinTongQuan(): void {
    let filter = this.getFilter();
    let obj = { nam: filter.nam.join(',') };
    if (!obj.nam) {
      return;
    }
    this.subscription.push(this.aggService.getSoLieuThongKeDuAn(obj).subscribe((rs: any) => {
      if (!rs.success || rs.data.length === 0) {
        return;
      }
      this.SUMMARY_INFO = rs.data;
    }, (err) => {
        this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
      }
    ));
  }

  tKeSoDuAnTheoLoai(): void {
    let filter = this.getFilter();
    let obj = { nam: filter.nam.join(',') };
    if (!obj.nam) {
      return;
    }
    this.subscription.push(
      this.aggService.getSoLieuThongKeDuAnTheoLoai(obj).subscribe((rs: any) => {
        if (!rs.success) {
          return false;
        }
        this.tKeTrungHan.barChartLabels = rs.data.map((x) => x.TEN_DU_AN);
        this.tKeTrungHan.barChartData = [
          {
            data: rs.data.map((x) => x.DU_AN_TRUNG),
            label: "Số dự án",
            backgroundColor: this.CONST_COLOR[2],
          },
        ];
        this.tKeDaiHan.barChartLabels = rs.data.map((x) => x.TEN_DU_AN);
        this.tKeDaiHan.barChartData = [
          {
            data: rs.data.map((x) => x.DU_AN_DAI),
            label: "Số dự án",
            backgroundColor: this.CONST_COLOR[3],
          },
        ];
      }, (err) => {
        this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
      }
    ));
  }

  public getData(): void {
    this.thongTinTongQuan();
    this.tKeSoDuAnTheoLoai();
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
