<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3 ">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Loại thời gian</mat-label>
            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
              <mat-option [value]="1">Theo năm </mat-option>
              <mat-option [value]="2">Theo tháng </mat-option>
              <mat-option [value]="3">Theo ngày </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showNam" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showThang" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.thang" [label]="!lableMonth ?'Chọn tháng': 'Tất cả' " (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showTuNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Từ ngày</mat-label>
            <input matInput formControlName="tungay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="tuNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #tuNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="showDenNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Đến ngày</mat-label>
            <input matInput formControlName="denngay" placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker" autocomplete="off" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="denNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #denNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm chủng cho trẻ dưới 1 tuổi</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tcTreDuoi1Tuoi.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.tcTreDuoi1Tuoi.barChartData[0], 16)">
                              <app-bar-chart #tcTreDuoi1Tuoi [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #tcTreDuoi1Tuoi [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm viêm gan B cho trẻ dưới 1 tuổi</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.vgbTreDuoi1Tuoi.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.vgbTreDuoi1Tuoi.barChartData[0], 16)">
                              <app-bar-chart #vgbTreDuoi1Tuoi [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #vgbTreDuoi1Tuoi [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm mở rộng cho trẻ 18 tháng</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tiemMoRong.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.tiemMoRong.barChartData[0], 16)">
                              <app-bar-chart #tiemMoRong [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #tiemMoRong [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm DPT cho trẻ 18 tháng</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tiemDTP.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.tiemDTP.barChartData[0], 16)">
                              <app-bar-chart #tiemDTP [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #tiemDTP [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm viêm não nhật bản</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tiemViemNaoNhatBan.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.tiemViemNaoNhatBan.barChartData[0], 16)">
                              <app-bar-chart #tiemViemNaoNhatBan [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #tiemViemNaoNhatBan [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê tiêm VAT phụ nữ có thai</h4>
                      <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                          <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tiemVATPhuNuCoThai.barChartData">
                              <span class="shape" [style]="'background-color: ' + (item.backgroundColor)"></span>
                              <span class="ml-1">{{ item.label }}</span>
                          </div>
                      </div>
                      <div class="h-100 pt-5" style="overflow-y: auto;">
                          <div class="h-100" [style]="getDynamicWidth(this.tiemVATPhuNuCoThai.barChartData[0], 16)">
                              <app-bar-chart #tiemVATPhuNuCoThai [isSummary]="true"></app-bar-chart>
                          </div>
                          <!-- <div class="h-100" style="width: 180%;">
                              <app-bar-chart #tiemVATPhuNuCoThai [isSummary]="true"></app-bar-chart>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
