import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class NumberFormatService extends BaseService {

  nFormatter(num: any, digits: number) {
    if (isNaN(num)){
      return num;
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỷ" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i: number;
    for (i = lookup.length - 1; i > 0; i--) {
      if (num >= lookup[i].value){
        break;
      }
    }
    return Number((num/lookup[i].value).toFixed(digits).replace(rx, "$1")).toLocaleString('vi') + lookup[i].symbol;
  }
}
