import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TTKiemNghiemDuocPhamMyPhamHGIRoutingModule } from './tt-kiem-nghiem-duocpham-mypham-hgi-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { TtKiemNghiemDuocphamMyphamHgiComponent } from './tt-kiem-nghiem-duocpham-mypham-hgi/tt-kiem-nghiem-duocpham-mypham-hgi.component';
@NgModule({
  declarations: [ TtKiemNghiemDuocphamMyphamHgiComponent ],
  imports: [
    CommonModule,
    TTKiemNghiemDuocPhamMyPhamHGIRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class TTKiemNghiemDuocPhamMyPhamHGIModule { }
