<div class="bg-plum-plate vh-100">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <div style="font-size: 200px; margin-bottom: 110px;">404</div>
          
          <h2 class="error-details">
            Đã xảy ra lỗi, không tìm thấy trang bạn yêu cầu!
          </h2>
          <div class="error-actions">
            <a class="btn btn-primary" [routerLink]="['/']">
              🛖 Chuyển đến trang chủ
            </a>
            <a class="btn btn-secondary"
              ><span class="glyphicon glyphicon-envelope"></span>📱 Liên hệ hỗ
              trợ
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
