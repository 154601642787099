import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthenticationService } from "../authentication.service";
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public authService: AuthenticationService
  ) {}

  canActivate(): boolean {
    if (
      this.authService.getToken() != null &&
      this.authService.getToken() != undefined &&
      this.authService.getToken() != ""
    )
      return true;
    else {
      this.router.navigate(["/dang-nhap"]);
      return false;
    }
  }
}
