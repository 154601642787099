import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardTaolapHsskComponent } from './dashboard-taolap-hssk/dashboard-taolap-hssk.component';


const routes: Routes = [
  { path: "dashboard-taolap-hssk", component: DashboardTaolapHsskComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Qd831RoutingModule { }
