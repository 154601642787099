import { ColorService } from "src/app/services/color.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { LineChartComponent } from "./../../../../shared/components/chart-js/line-chart/line-chart.component";
import { CDCService } from "./../../../../services/trung-tam-cdc.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CommonFunctionService } from "src/app/services/common-function.service";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";

@Component({
  selector: "app-benh-truyen-nhiem",
  templateUrl: "./benh-truyen-nhiem.component.html",
  styleUrls: ["./benh-truyen-nhiem.component.scss"],
})
export class BenhTruyenNhiemComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    week: new FormControl(""),
  });

  YEARS: any[] = [];
  WEEK: any[] = [];
  rowStyles: any = {};

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.PALETTE_3[0],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[3],
    COLOR_PALETTES.RIVER_NIGHTS[2],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
    COLOR_PALETTES.RIVER_NIGHTS[3],
    COLOR_PALETTES.RIVER_NIGHTS[4],
  ];

  @ViewChild("tkSotXuatHuyet", { static: true })
  tkSotXuatHuyet: LineChartComponent;
  @ViewChild("tkMacSocChetSXH", { static: true })
  tkMacSocChetSXH: BarChartComponent;
  @ViewChild("tkDuBaoTCM", { static: true })
  tkDuBaoTCM: LineChartComponent;
  @ViewChild("tkMacChetTCM", { static: true })
  tkMacChetTCM: BarChartComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private cdcService: CDCService,
    private colorService: ColorService,
    private commonService: CommonFunctionService
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    for (let i = 1; i <= 53; i++) {
      this.WEEK.push({ id: i, text: "Tuần " + i });
    }
    this.WEEK.unshift({ id: 0, text: "Tất cả" });
    this.formSearch.controls["week"].setValue(0);

    this.detectMode();
    this.getData();
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tkSotXuatHuyet.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkSotXuatHuyet.lineChartOptions.scales.xAxes[0].scaleLabel.labelString =
      "Tuần";
    this.tkMacSocChetSXH.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkDuBaoTCM.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkDuBaoTCM.lineChartOptions.scales.xAxes[0].scaleLabel.labelString =
      "Tuần";
    this.tkMacChetTCM.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if (
      this.formSearch.controls.week.value &&
      this.formSearch.controls.week.value != 0
    ) {
      params["tuan"] = this.formSearch.controls.week.value;
    }

    return params;
  }

  getData(): void {
    this.tKeDuBaoSXH();
    this.tKeMacSocChetSXH();
    this.tKeDuBaoTCM();
    this.tKeMacChetTCM();
  }

  tKeDuBaoSXH(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeDuBaoSXH(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }

          this.tkSotXuatHuyet.lineChartLabels = rs.data.map((x) => x.TUAN);
          let arrDuBao = rs.data.map((x) => Math.round(x.DU_BAO_DICH));
          let arrNam2022 = rs.data.map((x) => Math.round(x.NAM_2022));
          let arrNam2023 = rs.data.map((x) => Math.round(x.NAM_2023));
          let arrTrungBinh = rs.data.map((x) => Math.round(x.TRUNG_BINH));

          this.tkSotXuatHuyet.lineChartData = [
            {
              data: arrDuBao,
              label: "Số lượng dự báo",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                this.CONST_COLOR[2],
                0.3
              ),
              borderColor: this.CONST_COLOR[2],
            },
            {
              data: arrNam2022,
              label: "Số lượng năm 2022",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[3],
            },
            {
              data: arrNam2023,
              label: "Số lượng năm 2023",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[9],
            },
            {
              data: arrTrungBinh,
              label: "Số lượng trung bình",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[10],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeMacSocChetSXH(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeMacSocChetSXH(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkMacSocChetSXH.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_HUYEN,
              2
            )
          );
          // this.tkMacSocChetSXH.barChartLabels = rs.data.map((x) => x.TEN_HUYEN);
          let arrMac = rs.data.map((x) => Math.round(x.MAC));
          let arrSoc = rs.data.map((x) => Math.round(x.SOC));
          let arrChet = rs.data.map((x) => Math.round(x.CHET));
          this.tkMacSocChetSXH.barChartData = [
            {
              data: arrMac,
              label: "Số lượng ca mắc",
              backgroundColor: this.CONST_COLOR[7],
            },
            {
              data: arrSoc,
              label: "Số lượng ca sốc",
              backgroundColor: this.CONST_COLOR[9],
            },
            {
              data: arrChet,
              label: "Số lượng ca tử vong",
              backgroundColor: this.CONST_COLOR[8],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeDuBaoTCM(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeDuBaoTCM(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }

          this.tkDuBaoTCM.lineChartLabels = rs.data.map((x) => x.TUAN);
          let arrDuBao = rs.data.map((x) => Math.round(x.DU_BAO_DICH));
          let arrNam2022 = rs.data.map((x) => Math.round(x.NAM_2022));
          let arrNam2023 = rs.data.map((x) => Math.round(x.NAM_2023));
          let arrTrungBinh = rs.data.map((x) => Math.round(x.TRUNG_BINH));

          this.tkDuBaoTCM.lineChartData = [
            {
              data: arrDuBao,
              label: "Số lượng dự báo",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                this.CONST_COLOR[11],
                0.3
              ),
              borderColor: this.CONST_COLOR[11],
            },
            {
              data: arrNam2022,
              label: "Số lượng năm 2022",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[8],
            },
            {
              data: arrNam2023,
              label: "Số lượng năm 2023",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[4],
            },
            {
              data: arrTrungBinh,
              label: "Số lượng trung bình",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: this.CONST_COLOR[2],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeMacChetTCM(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeMacChetTCM(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkMacChetTCM.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_HUYEN,
              2
            )
          );
          // this.tkMacChetTCM.barChartLabels = rs.data.map((x) => x.TEN_HUYEN);
          let arrMac = rs.data.map((x) => Math.round(x.MAC));
          let arrChet = rs.data.map((x) => Math.round(x.CHET));
          this.tkMacChetTCM.barChartData = [
            {
              data: arrMac,
              label: "Số lượng ca mắc",
              backgroundColor: this.CONST_COLOR[5],
            },
            {
              data: arrChet,
              label: "Số lượng ca tử vong",
              backgroundColor: this.CONST_COLOR[4],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
