<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
      <!-- <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
          <div class="d-flex align-items-center">
              <div class="ml-auto mr-3">
                  <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                  </ngx-dropdown>
              </div>
              <div class="mr-3">
                  <mat-form-field class="mb-0" appearance="outline">
                      <mat-label>Loại thời gian</mat-label>
                      <mat-select panelClass="dark-mode" placeholder="Chọn loại thời gian" formControlName="month" (selectionChange)="getData()">
                          <mat-option [value]="0">Tất cả</mat-option>
                          <mat-option [value]="3">Báo cáo 3 tháng</mat-option>
                          <mat-option [value]="6">Báo cáo 6 tháng</mat-option>
                          <mat-option [value]="9">Báo cáo 9 tháng</mat-option>
                          <mat-option [value]="12">Báo cáo 12 tháng</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                  <mat-icon color="primary">fullscreen</mat-icon>
              </button>
          </div>
      </form> -->
      <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
        <div class="d-flex align-items-center">
          <div class="ml-auto mr-3 ">
            <mat-form-field class="w-100 mb-0" appearance="outline">
              <mat-label>Loại thời gian</mat-label>
              <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
                <mat-option [value]="1">Theo năm </mat-option>
                <mat-option [value]="2">Theo tháng </mat-option>
                <mat-option [value]="3">Theo ngày </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="showNam" class="mr-3">
            <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
          </div>
          <div *ngIf="showThang" class="mr-3">
            <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.thang" [label]="!lableMonth ?'Chọn tháng': 'Tất cả' " (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
          </div>
          <div *ngIf="showTuNgay" class="mr-3">
            <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
              <mat-label>Từ ngày</mat-label>
              <input matInput formControlName="tungay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
              <mat-datepicker-toggle matSuffix [for]="tuNgayPicker"></mat-datepicker-toggle>
              <mat-datepicker #tuNgayPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="showDenNgay" class="mr-3">
            <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
              <mat-label>Đến ngày</mat-label>
              <input matInput formControlName="denngay" placeholder="Thời gian từ ngày" [matDatepicker]="denNgayPicker" autocomplete="off" (dateChange)="onFilterChange()">
              <mat-datepicker-toggle matSuffix [for]="denNgayPicker"></mat-datepicker-toggle>
              <mat-datepicker #denNgayPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
            <mat-icon color="primary">fullscreen</mat-icon>
          </button>
        </div>
      </form>


      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">SỐ NGƯỜI TĂNG HUYẾT ÁP ĐƯỢC PHÁT HIỆN</h4>
                              <div class="card-value" style="font-size:2.3vw" [style.color]="CONST_COLOR[0]"> {{ getNumber(this.slHuyetApPhatHien) }} </div>
                          </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">SỐ NGƯỜI ĐÁI THÁO ĐƯỜNG ĐƯỢC PHÁT HIỆN</h4>
                              <div class="card-value" style="font-size:2.3vw" [style.color]="CONST_COLOR[2]"> {{ getNumber(this.slDTDPhatHien) }} </div>
                          </div>
                      </div>
                      <div style="height: 15px;" class="w-100">&nbsp;</div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">SỐ NGƯỜI TĂNG HUYẾT ÁP ĐƯỢC QUẢN LÝ ĐIỀU TRỊ</h4>
                              <div class="card-value" style="font-size:2.3vw" [style.color]="CONST_COLOR[1]"> {{ getNumber(this.slHuyetApQuanLyDieuTri) }} </div>
                          </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">SỐ NGƯỜI ĐÁI THÁO ĐƯỜNG ĐƯỢC QUẢN LÝ ĐIỀU TRỊ</h4>
                              <div class="card-value" style="font-size:2.3vw" [style.color]="CONST_COLOR[3]"> {{ getNumber(this.slDTDQuanLyDieuTri) }} </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Tỷ lệ phát hiện bệnh tăng huyết áp</h4>
                      <div class="h-100  overflow-x">
                          <div [ngClass] = "isWithChart == 1 ? 'h-100' : 'h-100 w-chart' ">
                              <app-bar-chart #tangHuyetApCapHuyen [isSummary]="true"></app-bar-chart>
                          </div>
                      </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Tỷ lệ phát hiện/ điều trị bệnh đái tháo đường</h4>
                      <div class="h-100  overflow-x">
                          <div [ngClass] = "isWithChart1 == 1 ? 'h-100' : 'h-100 w-chart' ">
                              <app-bar-chart #daiThaoDuongCapHuyen></app-bar-chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!-- ROW 2 -->

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Tỷ lệ tử vong do bệnh không lây nhiễm</h4>
                      <app-pie-chart #tyLeTuVong [isSummary]="true" [maxLength]="20"></app-pie-chart>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Tỷ lệ phát hiện bệnh tăng huyết áp toàn tỉnh</h4>
                      <app-pie-chart #tangHuyetApCapTinh></app-pie-chart>
                  </div>

              </div>
              <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Tỷ lệ phát hiện bệnh đái tháo đường toàn tỉnh</h4>
                      <app-pie-chart #daiThaoDuongCapTinh></app-pie-chart>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
