import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChiCucDanSoRoutingModule } from './chi-cuc-dan-so-routing.module';
import { ThongKeDanSoComponent } from './thong-ke-dan-so/thong-ke-dan-so.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { PhanTichSoLieuDanSoComponent } from './phan-tich-so-lieu-dan-so/phan-tich-so-lieu-dan-so.component';


@NgModule({
  declarations: [ThongKeDanSoComponent, PhanTichSoLieuDanSoComponent],
  imports: [
    CommonModule,
    ChiCucDanSoRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class ChiCucDanSoModule { }
