<!-- <app-header (toggleSideBarForMe)="sideBarToggler($event)"></app-header>

<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer></app-footer> -->

<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen" [disableClose]="true">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content class="d-flex flex-column vh-100" style="background-color: #1f1e30">
        <app-header id="main-header" class="header" (toggleSideBarForMe)="sideBarToggler($event)"></app-header>
        <router-outlet></router-outlet>
        <!-- <app-footer class="mt-auto"></app-footer> -->
    </mat-drawer-content>
</mat-drawer-container>