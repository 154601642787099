import { PieChartComponent } from 'src/app/shared/components/chart-js/pie-chart/pie-chart.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE, MA_TINH, ID_TINH } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';
import { MatPaginator } from "@angular/material/paginator";
import { pageSizeOptions } from "src/app/services/config.service";
import { Spinner } from "src/app/services/spinner.service";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DmChungService } from 'src/app/services/dm-chung.service';
import { VNDateAdapter } from 'src/environments/datepicker-apdapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cap-gcn-hgi',
  templateUrl: './cap-gcn-hgi.component.html',
  styleUrls: ['./cap-gcn-hgi.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: VNDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'vi-VN'},
    DatePipe
  ]
})
export class CapGcnHGIComponent implements OnInit {

  private subscription: Subscription[] = [];
  public isFitPage = true;

  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    loai: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    quy: new FormControl(),
    thang: new FormControl(),
    huyen: new FormControl(0),
    xa: new FormControl(0),
    tungay: new FormControl(new Date().toISOString()),
    denngay: new FormControl(new Date().toISOString()),
  });
  showSearch: any[] = [false, false, false, false];
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public QUYS: any[] = [];
  rowStyles: any = {};
  THANHTRA: any = {};
  listQuy: any = [];
  listThang: any = [];
  listHuyen: any = [];
  listXa: any = [];

  loai: string;
  showNam = true;
  showThang = false;
  showQuy = false;
  showTuNgay = false;
  showDenNgay = false;

  public CARD_COLORS: any[] = COLOR_PALETTES.DUTCH_FIELD;
  public sumraryInfo = { TONG: 0, CAPMOI: 0, CAPLAI: 0, THUHOI: 0, SAPHETHAN: 0 };
  public LIST_GCN: any[] = [];

  @ViewChild("tyLeHTTC", { static: true }) tyLeHTTC: PieChartComponent;
  @ViewChild("tkSoLuongCoSoTheoHuyen", { static: true })
  tkSoLuongCoSoTheoHuyen: BarChartComponent;

  dataSource!: MatTableDataSource<any>;
  pageIndex: number = 0;
  index: number = 0;
  length: number;
  pageOption = pageSizeOptions;
  pageSizeDefault: number = this.pageOption[0];
  ELEMENT_DATA: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRefs: any[] = [];
  listHeaderColumnGCN: any[] = [
    {
      key: "TEN_CO_SO",
      label: "TÊN CƠ SỞ",
    },
    {
      key: "HO_TEN",
      label: "HỌ TÊN",
    },
    {
      key: "SO_GCN_DKKD",
      label: "Số GCN",
    },
    {
      key: "LOAI_CAP_GCN",
      label: "LOẠI",
    },
    {
      key: "HINH_THUC_TO_CHUC",
      label: "HÌNH THỨC",
    },
  ];

  constructor(
    private aggService: AggService,
    private snackbar: SnackbarService,
    private spinner: Spinner,
    private modalService: NgbModal,
    private dmchungService: DmChungService
  ) { 
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    this.formSearch.controls["year"].setValue(currentYear);
    this.formSearch.controls["thang"].setValue(currentMonth.toString());
    this.formSearch.controls["quy"].setValue((Math.ceil(currentMonth / 3)).toString());
    for (let i = currentYear; i >= 2020; i--) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    this.detectMode();
    this.tkSoLuongCoSoTheoHuyen.barChartOptions.legend.display = false;
    this.getData();
    this.getListQuy();
    this.getListThang();
    this.getListHuyen();
    this.reloadShowSearch();
  }
  public reloadShowSearch() {
    // 0:Năm/1:Quý/2:Tháng/3:TuNgayDenNgay:
    var valueLoai = this.formSearch.get("loai").value;
    switch (valueLoai) {
      case 0: // theo năm
        this.showSearch = [true, false, false, false];
        break;
      case 1: // theo tháng
        this.showSearch = [true, true, false, false];
        break;
      case 2: // theo quý
        this.showSearch = [true, false, true, false];
        break;
      case 3: // theo ngày
        this.showSearch = [false, false, false, true];
        break;
      default:
        this.showSearch = [false, false, false, false];
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tyLeHTTC.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeHTTC.pieChartOptions.legend.position = "right";
    this.tyLeHTTC.pieChartType = "doughnut";
    this.tkSoLuongCoSoTheoHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 0); // no row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 1)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  public getFilter() {
    let params = {};
    params["loai_cbx"] = this.formSearch.get("loai").value

    params["nam"] = (params["loai_cbx"] == 0 || params["loai_cbx"] == 1 || params["loai_cbx"] == 2) ? this.formSearch.controls.year.value : "0";
    params["thang"] = params["loai_cbx"] == 1 ? (this.formSearch.get("thang").value == "Tất cả" ? "0" : this.formSearch.get("thang").value) : "0";
    params["quy"] = params["loai_cbx"] == 2 ? (this.formSearch.get("quy").value == "Tất cả" ? "0" : this.formSearch.get("quy").value) : "0";
    params["tungay"] = params["loai_cbx"] == 3 ? this.formatDate(this.formSearch.get("tungay").value).toString() : "01-05-2024";
    params["denngay"] = params["loai_cbx"] == 3 ? this.formatDate(this.formSearch.get("denngay").value).toString() : "31-05-2024";
    params["ma_huyen"] = this.formSearch.get("huyen").value;
    params["ma_xa"] = params["ma_huyen"] == 0 ? 0 : this.formSearch.get("xa").value;
    params["ma_tinh"] = MA_TINH;
    return params;
  }

  tyLehinhThucToChuc(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.tkTyLehinhThucToChucHGI(obj).subscribe(
        (rs: any) => {
          this.tyLeHTTC.pieChartData = [];
          this.tyLeHTTC.pieChartLabels = [];
          if (!rs.success || rs.data.length == 0) {
            return false;
          }
          rs.data.forEach((element) => {
            this.tyLeHTTC.pieChartLabels.push(element.HINH_THUC_TO_CHUC);
            this.tyLeHTTC.pieChartData.push(element.TILE);
          });
          this.tyLeHTTC.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkSLCoSoTheoHuyen() {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.tkSoLuongCoSoTheoHuyenHGI(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      let result = rs.data;
      this.tkSoLuongCoSoTheoHuyen.barChartLabels = [];
      this.tkSoLuongCoSoTheoHuyen.barChartData = [];
      if (!(result && result.length > 0)) {  
        return;
      }
      result = result.filter(x => x.TEN_HUYEN != "");
      this.tkSoLuongCoSoTheoHuyen.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tkSoLuongCoSoTheoHuyen.barChartData.push({
        data: result.map(x => x.TONG),
        label: "Tổng số cở sở đã cấp tính đến tháng hiện tại",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[6],
        datalabels: {align: "end", anchor:"end", clamp: true}
      });
      this.tkSoLuongCoSoTheoHuyen.barChartData.push({
        data: result.map(x => x.THANG),
        label: "Số lượng cấp mới trong tháng",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
        datalabels: {align: "end", anchor:"end", clamp: true}
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getData(): void {
    this.thongKeTongQuan();
    this.tkSLCoSoTheoHuyen();
    this.tyLehinhThucToChuc();
    this.danhSachCCHNMoiCap();
  }
  
  private reduceLabel(label: string) {
    return label.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT");
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
 
  public thongKeTongQuan(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.soLieuTongQuanGCNHGI(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      this.sumraryInfo = rs.data[0];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  

  public danhSachCCHNMoiCap(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.danhSachGCNHGI(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      this.LIST_GCN = rs.data;
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  public getColor(element: any) {
    let style = "color: ";
    return style + (element.MA_LOAI_CAP == "M" ? this.CARD_COLORS[6] : this.CARD_COLORS[3]);
  }

  public getBorderColor(element: any) {
    let style = "border: 1px solid ";
    return style + (element.LOAI_CAP_GCN == "Mới" ? this.CARD_COLORS[3] : this.CARD_COLORS[3]);
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  onClicked(data: any, content: any, id: string, size: string) {
    switch (id) {
      case "TONG": {
        this.loai = 'TG';
        break;
      }
      case "CAP_MOI": {
        this.loai = 'M';
        break;
      }
      case "CAP_LAI": {
        this.loai = 'L';
        break;
      }
      case "THU_HOI": {
        this.loai = 'T';
        break;
      }
      case "SAPHETHAN":{
        this.loai = 'HH';
        break;
      }
    }
    this.getListGCNByTrangThai();
    this.openModal(content, id, size);
  }

  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, {
      ariaLabelledBy: id,
      size: size ?? "md",
    });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  getListGCNByTrangThai(event?: any) {
    if (!event && this.pageIndex != 0) {
      this.paginator.pageIndex = 0;
    } else if (event) {
      this.paginator = event;
    }
    let loai_cbx = this.formSearch.get("loai").value;
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let tungay = this.formatDate(this.formSearch.get("tuNgay").value);
    let denngay = this.formatDate(this.formSearch.get("denNgay").value);
    let page = event ? event.pageIndex : this.pageIndex;
    var obj = {
      loai_cbx: loai_cbx,
      nam: year,
      thang: month,
      tungay: tungay,
      denngay: denngay,
      loai: this.loai,
      page: page + 1,
      size: this.paginator ? this.paginator.pageSize : this.pageSizeDefault,
    };
    this.spinner.show();
    this.subscription.push(
      this.aggService.danhSachGCNTheoLoaiHGI(obj).subscribe(
        (rs) => {
          this.ELEMENT_DATA = rs.data;
          this.dataSource.data = this.ELEMENT_DATA;
          this.spinner.hide();

          this.index = obj.page - 1;
          this.length = rs.total_row;
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );

    return event;
  }


  getListQuy() {
    this.subscription.push(
      this.dmchungService.getListQuy().subscribe(
        (rs) => {
          this.listQuy = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListThang() {
    this.subscription.push(
      this.dmchungService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListHuyen(init: boolean = false) {
    var obj = {
      idTinh: ID_TINH,
    };

    this.subscription.push(
      this.dmchungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.listHuyen = rs.data;

          if (this.formSearch.get("huyen").value == 0) {
            var tempData = [];
            tempData.push({ ID: 0, MA_HUYEN: '0', TEN_HUYEN: 'Tất cả'});
            for (var index = 0; index < this.listHuyen.length; index++) {
              tempData.push(this.listHuyen[index]);
            }
            this.listHuyen = tempData;
          }
          if (init) {
            this.formSearch.controls["huyen"].setValue(this.listHuyen[0].MA_HUYEN);
            this.getListXa(this.listHuyen[0].MA_HUYEN, init);
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListXa(maHuyen: number, init: boolean = false) {
    let index = this.listHuyen.findIndex(x => x.MA_HUYEN === maHuyen);
    var obj = { idHuyen: this.listHuyen[index].ID };

    this.subscription.push(
      this.dmchungService.getListXa(obj).subscribe(
        (rs) => {
          this.listXa = rs.data;
          var tempData = [];
          if (maHuyen == 0) {
            this.listXa.push({ ID: 0, MA_XA: '0', TEN_XA: 'Tất cả', ID_HUYEN: 0, TEN_HUYEN: ''});
          } else {
            tempData.push({ ID: 0, MA_XA: '0', TEN_XA: 'Tất cả', ID_HUYEN: 0, TEN_HUYEN: '' });
            for (var index = 0; index < this.listXa.length; index++) {
              tempData.push(this.listXa[index]);
            }
            this.listXa = tempData;
          }
          this.formSearch.controls["xa"].setValue(this.listXa[0].MA_XA);
          if (init) {
            this.getData();
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}
