import { PieChartComponent } from '../../../../shared/components/chart-js/pie-chart/pie-chart.component';
import { CDCService } from "src/app/services/trung-tam-cdc.service";
import { ToChucCanBoService } from "../../../../services/to-chuc-can-bo.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ID_TINH,
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";

import { DmChungService } from 'src/app/services/dm-chung.service';
import { CommonFunctionService } from "src/app/services/common-function.service";
import { LineChartComponent } from "src/app/shared/components/chart-js/line-chart/line-chart.component";
@Component({
  selector: 'app-suy-dinh-duong',
  templateUrl: './suy-dinh-duong.component.html',
  styleUrls: ['./suy-dinh-duong.component.scss']
})
export class SuyDinhDuongComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    coSoKCB: new FormControl(-1),
    huyen: new FormControl(""),
    tenhuyen: new FormControl(null),
    loai: new FormControl(0),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  isHuyen= false;
  listHuyen = [];
  listQuy = [];
  listThang = [];
  listNam = [];
  showNam = true;
  showThang = false;
  showQuy = false;
  SL_4_THE_TONG:  any = {};
  public DON_VI_LIST: any = [];
  public istyLeSuyDinhDuong = false;
  public issuyDinhDuongDuoi52= false;
  public istyLeSuyDinhDuongTre = false;
  public isMauLayKhongDat = false;
  public isMauGuiKhongDat = false;
  userStorage = JSON.parse(sessionStorage.user);
  @ViewChild("tyLeSuyDinhDuong", { static: true })
  tyLeSuyDinhDuong: LineChartComponent;
  @ViewChild("suyDinhDuongDuoi52", { static: true })  suyDinhDuongDuoi52: BarChartComponent;
  @ViewChild("tyLeSuyDinhDuongTre", { static: true }) tyLeSuyDinhDuongTre: LineChartComponent;
  @ViewChild("hoGDCanBoChamSoc", { static: true })  hoGDCanBoChamSoc: BarChartComponent;
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private suyDinhDuong: CDCService,
    private commonService: CommonFunctionService
  ) {
    this.SL_4_THE_TONG = {
      MAU_LAY:0,
      MAU_GUI:0,
      MAULAY_BC_VIEN:0,
      MAUGUI_BC_VIEN:0
    }

  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear-1);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    this.getDsHuyen();
    this.getListThang();
    this.getListNam();
    this.detectMode();
    this.getData()
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tyLeSuyDinhDuong.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    // this.tyLeSuyDinhDuong.lineChartOptions.legend.display = false;
    
    this.suyDinhDuongDuoi52.barChartOptions.maintainAspectRatio = !this.isFitPage;

    this.tyLeSuyDinhDuongTre.lineChartOptions.maintainAspectRatio = !this.isFitPage;

    
    this.hoGDCanBoChamSoc.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.hoGDCanBoChamSoc.barChartOptions.legend.display = false;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }
  getDsHuyen() {
    var obj = {
      keyword: "",
      page: 1,
      size: 20,
      idTinh: ID_TINH,
    };
    this.subscription.push(
      this.dmchungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.listHuyen = rs.data;
          if (this.userStorage?.LEVEL_USER_GIA_TRI == "Admin" || this.userStorage?.MA_DON_VI == 89000) {
            this.isHuyen= false
            this.listHuyen.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
          } else {
            this.formSearch.controls.huyen.setValue(
              this.userStorage?.MA_HUYEN
            );
            this.formSearch.controls.tenhuyen.setValue(
              this.listHuyen.find(element => element.MA_HUYEN == this.userStorage?.MA_HUYEN)?.TEN_HUYEN
            );
            this.formSearch.controls.coSoKCB.setValue(
              [this.userStorage?.MA_DON_VI]
            );
            this.isHuyen= true
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }  
  getListThang(){
    this.subscription.push(
      this.dmchungService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  
  getListNam(){
    this.subscription.push(
      this.dmchungService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }

  getData(): void {
    this.getTyLeSuyDinhDuong()
    this.getSuyDinhDuongDuoi5Va2()
  }


  getFilter() {
    let nam = "";
    let thang = "";
    nam  = this.formSearch.get("year").value;
    thang  = this.formSearch.get("thang").value == "Tất cả"?"": this.formSearch.get("thang").value;
      let result  =  { 
        nam: nam,
        thang:thang
      };
      return result;

  }

  getTyLeSuyDinhDuong() {
    let obj: any = this.getFilter();
    this.istyLeSuyDinhDuong = true;
    const arrNam: string[] = [];
    const arrNheCan: number[] = [];
    const arrThapCoi: number[] = [];
    const arrGayCon: number[] = [];
    this.subscription.push(
      this.suyDinhDuong.getTyLeSuyDinhDuong(obj).subscribe(
        (rs) => {
          rs.data.forEach(item => {
            arrNam.push(item.NAM);
            arrNheCan.push(item.NHE_CAN);
            arrThapCoi.push(item.THAP_COI);
            arrGayCon.push(item.GAY_COM);
          });
          if(arrNam.length == 0 ){
            this.istyLeSuyDinhDuong = false
          }
          //Set label for chart
          this.tyLeSuyDinhDuong.lineChartLabels = arrNam
          //Set data for chart
          this.tyLeSuyDinhDuong.lineChartData = [
            {
              data: arrNheCan,
              label: "Nhẹ Cân",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#118AB2",
            },
            {
              data: arrThapCoi,
              label: "Thấp còi",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#FF9770"
            },
            {
              data: arrGayCon,
              label: "Gầy con",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#FF9870"
            }
          ];

          this.tyLeSuyDinhDuong.lineChartColors = [
            { borderColor: "#118AB2" },
            { borderColor: "#FF9770" },
            { borderColor: "#FF9870" },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getSuyDinhDuongDuoi5Va2(){
    let obj: any = this.getFilter();
    this.issuyDinhDuongDuoi52 = true;
    this.subscription.push(
      this.suyDinhDuong.getSuyDinhDuong5Va2(obj).subscribe(
        (rs) => {
          if (rs.data.length == 0){
            this.issuyDinhDuongDuoi52= false;
            this.suyDinhDuongDuoi52.barChartLabels = []
          }
          else{
            this.issuyDinhDuongDuoi52 = true;
            this.suyDinhDuongDuoi52.barChartLabels = rs.data.map(x => this.commonService.convertStringToArray(" ", x.TEN_HIEN_THI, 2));
            this.tyLeSuyDinhDuongTre.lineChartLabels= rs.data.map(x => this.commonService.convertStringToArray(" ", x.TEN_HIEN_THI, 1));
            this.hoGDCanBoChamSoc.barChartLabels = rs.data.map(x => this.commonService.convertStringToArray(" ", x.TEN_HIEN_THI, 2));
          }
       
          let soLuongDuoi2t = rs.data.map(x=>x.DUOI_2_TUOI_BI_SDD);
          let soLuongDuoi5t = rs.data.map(x=>x.DUOI_5_TUOI_BI_SDD);
          let soLuongDuoi5TuVong = rs.data.map(x=>x.DUOI_5_TUOI_TU_VONG);
          this.suyDinhDuongDuoi52.barChartData = [
            {
              data: soLuongDuoi2t,
              label: "Suy dinh dưỡng dưới 2 tuổi",
              backgroundColor: "#741b47",
              datalabels: {align: "end", anchor:"end", clamp: true}
            },
            {
              data:  soLuongDuoi5t,
              label: "Suy dinh dưỡng dưới 5 tuổi",
              backgroundColor: "#c27ba0",
              datalabels: {align: "end", anchor:"end", clamp: true}
            }
            ,
            {
              data:  soLuongDuoi5TuVong,
              label: "Trẻ em dưới 5 tuổi tử vong",
              backgroundColor: "#b45f06",
              datalabels: {align: "end", anchor:"end", clamp: true}
            }
          ]
          

          ///lấy theo ty lệ suy dinh dưỡng
          let arrsinh2500G =[]
          let arrDuocBuMeSom =[]
          let arrDuocBuMe6Thang =[]
          rs.data.forEach(item => {
            arrsinh2500G.push(item.TL_SINH_DUOI_2500G);
            arrDuocBuMeSom.push(item.TL_TRE_DUOC_BU_ME_SOM);
            arrDuocBuMe6Thang.push(item.TL_TRE_DUOC_BU_ME_TRONG_6_THANG);
          });
           this.tyLeSuyDinhDuongTre.lineChartData = [
            {
              data: arrsinh2500G,
              label: "Tỷ lệ sinh dưới 2.5kg",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#118AB2",
            },
            {
              data: arrDuocBuMeSom,
              label: "Tỷ lệ trẻ được bú mẹ sơm",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#b45f06"
            },
            {
              data: arrDuocBuMe6Thang,
              label: "Tỷ lệ trẻ được bú mẹ trong 6 tháng",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#FF9870"
            }
          ];

          this.tyLeSuyDinhDuong.lineChartColors = [
            { borderColor: "#118AB2" },
            { borderColor: "#b45f06" },
            { borderColor: "#FF9870" },
          ];

          // Hộ gia đình được cán bộ chăm sốc

          let soLuongDuoiCS = rs.data.map(x=>x.HO_GIA_DINH_DUOC_CAN_BO_CHAM_SOC);
          this.hoGDCanBoChamSoc.barChartData = [
            {
              data: soLuongDuoiCS,
              label: "Hộ gia đình được cán bộ chăm sóc",
              backgroundColor: "#8e7cc3",
              datalabels: {align: "end", anchor:"end", clamp: true}
            },
          ]
        
        }, err => {
            this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
          }
      ))
  }

  
  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
