import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Spinner {
    private _selector: string = 'spinner-loading';
    private _element: HTMLElement;

    constructor() {
        this._element = document.getElementById(this._selector);
    }

    public show(): void {
        if (!this._element) {
            this._element = document.getElementById(this._selector);
        }
        if (this._element) {
            this._element.style['display'] = 'block';
        }
    }

    public hide(delay: number = 500): void {
        setTimeout(() => {
            if (!this._element) {
                this._element = document.getElementById(this._selector);
            }
            if (this._element) {
                this._element.style['display'] = 'none';
            }
        }, delay);
    }

    public hideByCondition(condition: any,delay: number = 0): void {
        setTimeout(() => {
            let canHide = true;
            Object.keys(condition).forEach(p => {
                if(condition[p] === false) canHide = false;
            });
            if(canHide){
                if (!this._element) {
                    this._element = document.getElementById(this._selector);
                }
                if (this._element) {
                    this._element.style['display'] = 'none';
                }
            }
        }, delay);
    }
}