import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AUTH, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Spinner } from 'src/app/services/spinner.service';
import jwt_decode from "jwt-decode";
import { VlgService } from "src/app/services/vlg.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('otpModalContent') otpModalContent;
  private subscription: Subscription[] = [];
  private modalRefs: any[] = [];
  public user_name: string = '';
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  public formOTP = new FormGroup({
    username: new FormControl(""),
    otp: new FormControl("",[Validators.required])
  });
  constructor(
    private router: Router,
    private aggService: AggService,
    private vlgService: VlgService,
    private spinner: Spinner,
    private snackbar: SnackbarService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  // public login() {
  //   if (!this.loginForm.valid) {
  //     return false;
  //   }
  //   let username = this.loginForm.controls.username.value;
  //   let password = this.loginForm.controls.password.value;
  //   var obj = {
  //     username: username,
  //     password: password
  //   };
  //   this.spinner.show();
  //   this.subscription.push(this.aggService.login(obj).subscribe(data => {
  //     this.snackbar.showMessage(MESSAGE_COMMON.LOGIN_SUCCESS, MESSAGE_TYPE.SUCCESS);
  //     sessionStorage.setItem(AUTH.TOKEN_KEY, data.token);
  //     this.setUserInfor(data.token);
  //     this.setMenuUser();
  //     this.spinner.hide();
  //     setTimeout(() => {
  //       // this.router.navigate(['/ioc/kham-chua-benh/dieu-hanh-ngay']);
  //       //this.router.navigate(['dashboard/nghiep-vu-y/thong-tin-chung-1']);
  //       this.router.navigate(['/']);
  //     }, 500);
  //   }, err => {
  //     this.spinner.hide();
  //     this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
  //   }));
  // }

  public login() {
    if (!this.loginForm.valid) {
      return false;
    }
    let username = this.loginForm.controls.username.value;
    let password = this.loginForm.controls.password.value;
    var obj = {
      username: username,
      password: password,
    };
    this.spinner.show();
    var use_otp = '0';
    var sdt = '';
    this.subscription.push(
      this.aggService.login(obj).subscribe(
        (data) => {
          use_otp = data.otp == null ? '' : data.otp;
          sdt = data.sdt == null ? '' : data.sdt;
          if(use_otp == '1' && sdt != '999999999'){
            this.spinner.hide();
            this.reset();
            this.formOTP.controls['username'].setValue(username);
            this.user_name = username;
            this.OpenModalOTP();
          }else{
            this.snackbar.showMessage(MESSAGE_COMMON.LOGIN_SUCCESS, MESSAGE_TYPE.SUCCESS);
            sessionStorage.setItem(AUTH.TOKEN_KEY, data.token);
            this.setUserInfor(data.token);
            this.setMenuUser();
            this.spinner.hide();
            setTimeout(() => {
              this.router.navigate(["/"]);
            }, 500);
          }
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.LOGIN_FAILED,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  public OpenModalOTP(){
      this.openModal(this.otpModalContent, 'otpModal', 'md');
  }

  //Lưu thông tin user vào sessionStorage
  setUserInfor(token: string) {
    const decodedToken = jwt_decode(token);
    const userId =  decodedToken.MA_USER;
    var obj = { ma_user: userId};
    this.subscription.push(
      this.aggService.getUserDetail(obj).subscribe(
        (rs) => {
          sessionStorage.setItem(AUTH.USER_INFO_KEY, JSON.stringify(rs.data));
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  //Lưu list menu theo user
  setMenuUser() {
    this.subscription.push(
      this.aggService.layDsMenuIoc({}).subscribe(
        (rs) => {
          try {
            let listMenu = rs.data;
            let treeMenu = this.listToTree(listMenu);
            this.changeEmptyArrayToUndefined(treeMenu);
            sessionStorage.setItem("menu", JSON.stringify(treeMenu[0].children));
          } catch (error) {
            console.log(error);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }
  changeEmptyArrayToUndefined(list) {
    var k;
    if (list instanceof Object) {
      for (k in list) {
        if (list.hasOwnProperty(k)) {
          list.sort((a, b) => a.sort - b.sort)
          if (list[k].children.length == 0) {
            list[k].children = undefined;
          }
          this.changeEmptyArrayToUndefined(list[k].children);
        }
      }
    }
  }
  listToTree(list) {
    var map = {},
      objMenu,
      roots = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].MA_MENU] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    
    for (i = 0; i < list.length; i += 1) {
      objMenu = list[i];
      if (objMenu.MA_MENU_CHA !== 0) {
        list[map[objMenu.MA_MENU_CHA]].children.push({
          sort: objMenu.SAP_XEP,
          name: objMenu.TEN_MENU,
          route: objMenu.DUONG_DAN,
          children: objMenu.children,
          icon: objMenu.ICON,
          
        });
      } else {
        roots.push({
          name: objMenu.TEN_MENU,
          route: objMenu.DUONG_DAN,
          children: objMenu.children,
          icon: objMenu.ICON,
          sort: objMenu.SAP_XEP
        });
      }
    }
    return roots;
  }

  reset() {
    this.formOTP.reset();
  }

  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, { ariaLabelledBy: id, size: size ?? "md" });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  closeModal(id) {
    console.log("closed");
    let modalRef = null;
    this.modalRefs.forEach((element) => {
      Object.keys(element).forEach((key, index) => {
        if (id == key) {
          modalRef = element[key];
          return true;
        }
      });
    });
    if (modalRef != null) {
      modalRef.close();
    }
  }

  public checkOTP() {
    if(!this.formOTP.valid){
      return false;
    }

    var obj = {
      username: this.formOTP.controls.username.value,
      otp: this.formOTP.controls.otp.value,
    }

    //this.spinner.show();
    this.subscription.push(
      this.vlgService.checkOTP(obj).subscribe(
        (data) => {
          this.closeModal("otpModal");
          this.snackbar.showMessage(
            MESSAGE_COMMON.LOGIN_SUCCESS,
            MESSAGE_TYPE.SUCCESS
          );
            this.snackbar.showMessage(MESSAGE_COMMON.LOGIN_SUCCESS, MESSAGE_TYPE.SUCCESS);
            sessionStorage.setItem(AUTH.TOKEN_KEY, data.token);
            this.setUserInfor(data.token);
            this.setMenuUser();
            //this.spinner.hide();
          setTimeout(() => {
            this.router.navigate(["/"]);
          }, 500);
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }
}
