<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center justify-content-end">
                <ngx-dropdown class="mb-0 dark-mode mr-3" placeholder="Chọn năm" [multiple]="true"
                    [data]="YEARS" [formControl]="formSearch.controls.nam" (selected)="getData()"
                    label="Chọn năm" keyId="id" keyName="text">
                </ngx-dropdown>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100" *ngIf="this.SUMMARY_INFO">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số dự án</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[0]"> 
                                    {{ this.SUMMARY_INFO.TONG_DU_AN == null ? 0 : this.SUMMARY_INFO.TONG_DU_AN }} </div>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số dự án trung hạn</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[2]">
                                    {{ this.SUMMARY_INFO.DU_AN_TRUNG == null ? 0 : this.SUMMARY_INFO.DU_AN_TRUNG }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100 overflow-x">
                        <h4 class="chart-title">Thống kê dự án trung hạn theo loại</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeTrungHan.barChartData[0], 20)">
                                <app-bar-chart #tKeTrungHan [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="d-flex flex-column w-100 h-100" *ngIf="this.SUMMARY_INFO">
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số dự án dài hạn</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[3]"> 
                                    {{ this.SUMMARY_INFO.DU_AN_DAI == null ? 0 : this.SUMMARY_INFO.DU_AN_DAI }} </div>
                            </div>
                        </div>
                        <hr class="border-0 my-2" />
                        <div class="h-50">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng số dự án chậm tiến độ</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[1]">
                                    {{ this.SUMMARY_INFO.DU_AN_CHAM == null ? 0 : this.SUMMARY_INFO.DU_AN_CHAM }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-10 h-100' : 'col-xl-10 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100 overflow-x">
                        <h4 class="chart-title">Thống kê dự án dài hạn theo loại</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeDaiHan.barChartData[0], 20)">
                                <app-bar-chart #tKeDaiHan [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>