import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SITE_TITLE, TEN_TINH } from "src/app/constant/system-constant";
import { AggService } from "src/app/services/agg.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public siteTitle = SITE_TITLE;
  public tenTinh = TEN_TINH;
  @ViewChild("sidenav") sidenav: MatSidenav;
  private subscription: Subscription[] = [];
  menuList = [];

  constructor(private router: Router, private aggService: AggService) { }

  ngOnInit() {
    this.showSubMenu();
    this.loadMenu();
  }
  loadMenu() {
    this.menuList = JSON.parse(sessionStorage.getItem("menu"));
  }
  private showSubMenu() { }
}