import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { SnackbarService } from "src/app/services/snackbar.service";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { LineChartComponent } from "src/app/shared/components/chart-js/line-chart/line-chart.component";
import { FormControl, FormGroup } from "@angular/forms";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { ColorService } from "src/app/services/color.service";
import { DmChungService } from "src/app/services/dm-chung.service";
import { KhamChuaBenhService } from "src/app/services/kham-chua-benh.service";

@Component({
  selector: "app-thong-ke-ngay",
  templateUrl: "./thong-ke-ngay.component.html",
  styleUrls: ["./thong-ke-ngay.component.scss"],
})
export class ThongKeNgayComponent implements OnInit {
  subscription: Subscription[] = [];
  @ViewChild("khamTheoNgayChart", { static: true })
  khamTheoNgayChart: LineChartComponent;
  @ViewChild("raVaoNgayChart", { static: true })
  raVaoNgayChart: BarChartComponent;
  @ViewChild("noiTruNgoaiTruNgayChart", { static: true })
  noiTruNgoaiTruNgayChart: BarChartComponent;
  @ViewChild("khamBHYTChart", { static: true })
  khamBHYTChart: LineChartComponent;
  @ViewChild("capCuuTuVongChart", { static: true })
  capCuuTuVongChart: LineChartComponent;
  @ViewChild("taiNanThuongTichChart", { static: true })
  taiNanThuongTichChart: LineChartComponent;

  formSearch = new FormGroup({
    year: new FormControl(new Date().getFullYear()),
    month: new FormControl(new Date().getMonth() + 1),
    donVi: new FormControl(""),
  });
  documentElement: any;
  isFullScreen = false;
  tongSoHoSo: any;
  tongSoLuotKham: any;
  vaoVien: any;
  raVien: any;
  luotCapCuu: any;
  luotTuVong: any;
  params: any;
  listCSYT = [];
  keyword = "";
  currentPage = 1;
  totalPage: number;
  isFullListCSYT = false;
  timeOut: any;
  luotNoiTru: any;
  luotNgoaiTru: any;
  coBHYT: any;
  khongBHYT: any;
  rowStyles: any = {};
  sumTaiNanThuongTich = 0;
  taiNanThuongTich: any;

  listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(
    private snackbar: SnackbarService,
    private colorService: ColorService,
    private dmchungService: DmChungService,
    private khamChuaBenhService: KhamChuaBenhService,
  ) {}

  ngOnInit(): void {
    this.params = {
      nam: this.formSearch.get("year").value,
      thang: this.formSearch.get("month").value,
    };
    this.documentElement = document.documentElement;
    this.getData(this.params);
    this.getListCSYT();
    this.rowStyles =  { 'height': '35vh'};
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  getData(params?: any) {
    this.tkTongSoHoSo(params);
    // this.tkHoSoVaoVien(params);
    this.tkCapCuu(params);
    this.tkTuVong(params);
    this.tkKhamTheoNgay(params);
    this.tkRaVaoTheoNgay(params);
    this.tkNoiTruNgoaiTruNgay(params);
    this.tkKhamBHYTNgay(params);
    this.tkCapCuuTuVongNgay(params);
    this.tkTaiNanThuongTichNgay(params);
    this.tkKhamBHYT(params);
  }

  toggleFullScreen() {
    if (!this.isFullScreen) {
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
    this.buildStyles();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFullScreen) {
      let others = (16 // padding top
                    + 43.75 + 16 // form height and its margin bottom
                    + (8 * 2)); // 2 rows spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 3)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  tkTongSoHoSo(params?: any) {
    this.subscription.push(
      this.khamChuaBenhService.getTongSoHoSo(params).subscribe(
        (rs) => {
          this.tongSoLuotKham = new Intl.NumberFormat("vi-VN").format(
            rs.data.SL_KHAM
          );
          // this.hoSoNoiTru = new Intl.NumberFormat("vi-VN").format(
          //   rs.data.SL_NOI_TRU
          // );
          // this.hoSoNgoaiTru = new Intl.NumberFormat("vi-VN").format(
          //   rs.data.SL_NGOAI_TRU
          // );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // tkHoSoVaoVien(params?: any) {
  //   this.subscription.push(
  //     this.khamChuaBenhService.getHoSoVaoVien(params).subscribe(
  //       (rs) => {
  //         this.vaoVien = new Intl.NumberFormat("vi-VN").format(rs.data.VALUE);
  //       },
  //       (err) => {
  //         this.snackbar.showError(
  //           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
  //           MESSAGE_TYPE.ERROR
  //         );
  //       }
  //     )
  //   );
  // }

  tkCapCuu(params?: any) {
    this.subscription.push(
      this.khamChuaBenhService.getCapCuu(params).subscribe(
        (rs) => {
          this.luotCapCuu = new Intl.NumberFormat("vi-VN").format(rs.total_row);
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkTuVong(params?: any) {
    this.subscription.push(
      this.khamChuaBenhService.getTuVong(params).subscribe(
        (rs) => {
          this.luotTuVong = new Intl.NumberFormat("vi-VN").format(rs.total_row);
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkKhamTheoNgay(params?: any) {
    let listData = [];
    let arrData = [];
    let arrLabel = [];
    let param = Object.assign({}, params);
    param["maLoaiKCB"] = 1;

    this.subscription.push(
      this.khamChuaBenhService.getKhamTheoNgay(param).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrData.push(e.SO_LUOT_KHAM);
              arrLabel.push(e.NGAY);
            });
          }
          this.khamTheoNgayChart.lineChartLabels = arrLabel;
          this.khamTheoNgayChart.lineChartData = [
            {
              data: arrData,
              label: "Số lượt khám",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                COLOR_PALETTES.BLUES[4],
                0.3
              ),
            },
          ];
          this.khamTheoNgayChart.lineChartColors = [
            { borderColor: COLOR_PALETTES.BLUES[4] },
          ];
          this.khamTheoNgayChart.lineChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkRaVaoTheoNgay(params?: any) {
    let listData = [];
    let arrLuotRa = [];
    let arrLuotVao = [];
    let arrDay = [];
    let tongRaVien = 0;
    let tongVaoVien = 0;

    this.subscription.push(
      this.khamChuaBenhService.getRaVaoTheoNgay(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrLuotRa.push(e.SO_LUONG_RA);
              tongRaVien += e.SO_LUONG_RA;
              arrLuotVao.push(e.SO_LUONG_VAO);
              tongVaoVien += e.SO_LUONG_VAO;
              arrDay.push(e.NGAY);
            });
          }
          this.raVien = new Intl.NumberFormat("vi-VN").format(tongRaVien);
          this.vaoVien = new Intl.NumberFormat("vi-VN").format(tongVaoVien);

          //Set label for chart
          this.raVaoNgayChart.barChartLabels = arrDay;
          //Set data for chart
          this.raVaoNgayChart.barChartData = [
            {
              data: arrLuotRa,
              label: "Lượt ra viện",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
            },
            {
              data: arrLuotVao,
              label: "Lượt vào viện",
              backgroundColor: COLOR_PALETTES.ORANGE_TO_PURPLE[1],
            },
          ];
          //Set option for chart
          this.raVaoNgayChart.barChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '1'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkNoiTruNgoaiTruNgay(params?: any) {
    let listData = [];
    let arrNoiTru = [];
    let arrNgoaiTru = [];
    let arrDay = [];
    let tongNoiTru = 0;
    let tongNgoaiTru = 0;

    this.subscription.push(
      this.khamChuaBenhService.getNoiTruNgoaiTruNgay(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrNoiTru.push(e.SL_NOI_TRU);
              tongNoiTru += e.SL_NOI_TRU;
              arrNgoaiTru.push(e.SL_NGOAI_TRU);
              tongNgoaiTru += e.SL_NGOAI_TRU;
              arrDay.push(e.NGAY);
            });
          }
          this.luotNoiTru = new Intl.NumberFormat("vi-VN").format(tongNoiTru);
          this.luotNgoaiTru = new Intl.NumberFormat("vi-VN").format(tongNgoaiTru);
          //Set label for chart
          this.noiTruNgoaiTruNgayChart.barChartLabels = arrDay;
          //Set data for chart
          this.noiTruNgoaiTruNgayChart.barChartData = [
            {
              data: arrNoiTru,
              label: "Nội trú",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[4],
            },
            {
              data: arrNgoaiTru,
              label: "Ngoại trú",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
            },
          ];
          //Set option for chart
          this.noiTruNgoaiTruNgayChart.barChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawTicks: false,
                    color: COLOR_PALETTES.GREY_TO_RED[4],
                    borderDash: [3, 3],
                    drawBorder: false,
                  },
                  stacked: true,
                  ticks: {
                    padding: 8,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '1'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkKhamBHYTNgay(params?: any) {
    let listData = [];
    let arrCoBHYT = [];
    let arrKhongBHYT = [];
    let arrDay = [];

    this.subscription.push(
      this.khamChuaBenhService.getKhamBHYTNgay(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrCoBHYT.push(e.SL_CO_BH);
              arrKhongBHYT.push(e.SL_KHONG_BH);
              arrDay.push(e.NGAY);
            });
          }
          //Set label for chart
          this.khamBHYTChart.lineChartLabels = arrDay;
          //Set data for chart
          this.khamBHYTChart.lineChartData = [
            {
              data: arrCoBHYT,
              label: "Có BHYT",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: COLOR_PALETTES.BLUES[4],
            },
            {
              data: arrKhongBHYT,
              label: "Không BHYT",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              borderColor: COLOR_PALETTES.DUTCH_FIELD[5],
            },
          ];

          this.khamBHYTChart.lineChartColors = [
            { borderColor: COLOR_PALETTES.BLUES[4] },
            { borderColor: COLOR_PALETTES.DUTCH_FIELD[5] },
          ];
          //Set option for chart
          this.khamBHYTChart.lineChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkCapCuuTuVongNgay(params?: any) {
    let listData = [];
    let arrCapCuu = [];
    let arrTuVong = [];
    let arrDay = [];

    this.subscription.push(
      this.khamChuaBenhService.getCapCuuTuVongNgay(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrCapCuu.push(e.SL_CAP_CUU);
              arrTuVong.push(e.SL_TU_VONG);
              arrDay.push(e.NGAY);
            });
          }
          //Set label for chart
          this.capCuuTuVongChart.lineChartLabels = arrDay;
          //Set data for chart
          this.capCuuTuVongChart.lineChartData = [
            {
              data: arrCapCuu,
              label: "Lượt cấp cứu",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
            },
            {
              data: arrTuVong,
              label: "Lượt tử vong",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
            },
          ];

          this.capCuuTuVongChart.lineChartColors = [
            { borderColor: "#EB1D36" },
            { borderColor: "#de25da" },
          ];

          //Set option for chart
          this.capCuuTuVongChart.lineChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkTaiNanThuongTichNgay(params?: any) {
    let listData = [];
    let ngay = [];
    let tenTNTT = [];

    this.subscription.push(
      this.khamChuaBenhService.getTaiNanThuongTichNgay(params).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            ngay = listData
              .map((x) => x.NGAY)
              .filter((item, pos, self) => {
                return self.indexOf(item) === pos;
              });
            tenTNTT = listData
              .map((x) => x.TEN_TNTT)
              .filter((item, pos, self) => {
                return self.indexOf(item) === pos;
              });
            this.taiNanThuongTichChart.lineChartData = [];
            this.taiNanThuongTichChart.lineChartColors = [];
            let index = 0;
            tenTNTT.forEach((name) => {
              if (name != null) {
                let data = [];
                ngay.forEach((day) => {
                  let arr = listData.filter(
                    (x) => x.NGAY == day && x.TEN_TNTT == name
                  );
                  data =
                    arr.length > 0
                      ? data.concat(arr.map((x) => x.SO_LUONG))
                      : data.concat(0);
                });
                this.taiNanThuongTichChart.lineChartData.push({
                  data: data,
                  label: name,
                  fill: false,
                  pointRadius: 2,
                  lineTension: 0,
                  datalabels: { align: "top", anchor: "start" },
                });
                this.taiNanThuongTichChart.lineChartColors.push({
                  borderColor: COLOR_PALETTES.DUTCH_FIELD[index++],
                });
              }
            });
            listData.forEach((e) => {
              this.sumTaiNanThuongTich = e.SO_LUONG != null ? this.sumTaiNanThuongTich + e.SO_LUONG : this.sumTaiNanThuongTich
            });
            this.taiNanThuongTich = new Intl.NumberFormat("vi-VN").format(this.sumTaiNanThuongTich);
          }
          //Set label for chart
          this.taiNanThuongTichChart.lineChartLabels = ngay;

          //Set option for chart
          this.taiNanThuongTichChart.lineChartOptions = {
            layout: {
              padding: {
                top: 35
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: "#fff" },
                  scaleLabel: {
                    display: true,
                    labelString: "Ngày",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  onFilterChange() {
    this.params = {};
    this.params = {
      nam: this.formSearch.get("year").value,
      thang: this.formSearch.get("month").value,
    };
    if (
      this.formSearch.get("donVi").value != undefined &&
      this.formSearch.get("donVi").value != 0
    ) {
      this.params["maCSKCB"] = this.formSearch.get("donVi").value;
    }
    this.getData(this.params);
  }

  getListCSYT(keyword?: string, page?: number) {
    let param = {
      keyword: keyword ?? "",
      page: page ?? 1,
    };
    this.subscription.push(
      this.dmchungService.getListDonVi(param).subscribe(
        (rs) => {
          this.listCSYT = this.listCSYT.concat(rs.data);
          this.totalPage = rs.total_page;
          this.isFullListCSYT = this.totalPage <= this.currentPage ?? true;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  //hàm get dữ liệu khi scroll combobox CSYT
  getNextBatch() {
    this.currentPage += 1;
    this.getListCSYT(this.keyword, this.currentPage);
  }

  //hàm load dữ liệu search trong combobox CSYT
  onEnter(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.keyword = event.target.value;
      this.listCSYT = [];
      this.currentPage = 1;
      this.getListCSYT(this.keyword, this.currentPage);
    }, 300);
  }

  //clear text search
  resetCSYTSearchForm() {
    this.currentPage = 1;
    this.listCSYT = [];
    this.getListCSYT("", this.currentPage);
  }

  //Thống kê số hồ sơ khám BHYT
  tkKhamBHYT(params?: any) {
    let param = Object.assign({}, params);
    param["maLoaiKCB"] = 1;
    this.subscription.push(
      this.khamChuaBenhService.getKhamBHYT(param).subscribe(
        (rs) => {
          this.coBHYT = new Intl.NumberFormat("vi-VN").format(
            rs.data.SL_CO_BHYT
          );
          this.khongBHYT = new Intl.NumberFormat("vi-VN").format(
            rs.data.SL_KHONG_BHYT
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}
