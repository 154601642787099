import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import {CDCService} from 'src/app/services/trung-tam-cdc.service';

@Component({
  selector: 'app-suc-khoe-sinh-san',
  templateUrl: './suc-khoe-sinh-san.component.html',
  styleUrls: ['./suc-khoe-sinh-san.component.scss']
})
export class SucKhoeSinhSanComponent implements OnInit {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public iskhamThai = false;
  listThang = [];
  isWithChart1: any;
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required])
  });
  @ViewChild("khamThai", { static: true }) khamThai: BarChartComponent;
  @ViewChild("canBoDoDe", { static: true }) canBoDoDe: BarChartComponent;
  @ViewChild("chamSocSauSinh", { static: true }) chamSocSauSinh: BarChartComponent;
  @ViewChild("phaThai", { static: true }) phaThai: BarChartComponent;
  @ViewChild("tuVongMe", { static: true }) tuVongMe: BarChartComponent;
  @ViewChild("tuVongDuoi1Tuoi", { static: true }) tuVongDuoi1Tuoi: BarChartComponent;


  constructor(
    private cdcService: CDCService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() ; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    this.MONTHS.push({id:-1,text:"Tất cả"});
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.detectMode();
    this.khamThai.barChartOptions.legend.display = false;
    this.canBoDoDe.barChartOptions.legend.display = false;
    this.chamSocSauSinh.barChartOptions.legend.display = false;
    this.phaThai.barChartOptions.legend.display = false;
    this.tuVongMe.barChartOptions.legend.display = false;
    this.tuVongDuoi1Tuoi.barChartOptions.legend.display = false;
    this.getData();
  }
  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.khamThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.canBoDoDe.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.chamSocSauSinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.phaThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tuVongMe.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tuVongDuoi1Tuoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
  public getFilter() {
    let year = this.formSearch.controls.year.value ?? "";
    let month = this.formSearch.controls.month.value ?? "";
    return { nam: year, thang: month};
  }
  public getData(): void {
    this.getkhamThai();
    this.getCanBoDoDe();
    this.getChamSocSauSinh();
    this.getPhaThai();
    this.getTuVongDuoi1Tuoi();
    this.geTuVongMe();
    this.getListThang();
  }
  getListThang(){
    this.subscription.push(
      this.cdcService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }
  getkhamThai(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.khamThai.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.KHAM_THAI_TREN_4_LAN);
          this.khamThai.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[2],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getCanBoDoDe(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.canBoDoDe.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.DUOC_CAN_BO_CO_KY_NANG_DO_DE);
          this.canBoDoDe.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[1],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getChamSocSauSinh(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.chamSocSauSinh.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.DUOC_CHAM_SOC_SAU_SINH);
          this.chamSocSauSinh.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[3],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getPhaThai(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.phaThai.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.PHA_THAI);
          this.phaThai.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[4],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  geTuVongMe(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tuVongMe.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.TU_VONG_ME);
          this.tuVongMe.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[5],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getTuVongDuoi1Tuoi(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getSucKhoeSinhSan(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tuVongDuoi1Tuoi.barChartLabels = rs.data.map(x => this.convertToArray(" ", x.TEN_VIET_TAT, 1));
          let data = rs.data.map(x => x.TU_VONG_DUOI_1_TUOI);
          this.tuVongDuoi1Tuoi.barChartData = [
            {
              data : data,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[6],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}
