import { PhanTichDlKcbComponent } from './phan-tich-dl-kcb/phan-tich-dl-kcb.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeAn06Component } from './de-an06/de-an06.component';
import { ThongKeNgayComponent } from './kham-chua-benh/thong-ke-ngay/thong-ke-ngay.component';
import { ThongKeThangComponent } from './kham-chua-benh/thong-ke-thang/thong-ke-thang.component';
import { TinhHinhKcbComponent } from './kham-chua-benh/tinh-hinh-kcb/tinh-hinh-kcb.component';
import { ThongTinChungP2Component } from './thong-tin-chung-p2/thong-tin-chung-p2.component';
import { ThongTinChungComponent } from './thong-tin-chung/thong-tin-chung.component';
import { CapCchnComponent } from './cap-cchn/cap-cchn.component';

const routes: Routes = [
  { path: '', component: ThongTinChungComponent }, // mặc PATH định rỗng là  DM1
  { path: 'dashboard/nghiep-vu-y/thong-tin-chung-1', component: ThongTinChungComponent },
  { path: 'thong-tin-chung-2', component: ThongTinChungP2Component },
  { path: 'thong-tin-chung-2/:mode', component: ThongTinChungP2Component },
  { path: 'dieu-hanh-thang', component: ThongKeThangComponent },
  { path: 'dieu-hanh-thang/:mode', component: ThongKeThangComponent },
  { path: 'dieu-hanh-ngay', component: ThongKeNgayComponent },
  { path: 'tinh-hinh-kcb', component: TinhHinhKcbComponent },
  { path: 'tinh-hinh-thuc-hien-de-an-06', component: DeAn06Component },
  { path: 'phan-tich-du-lieu-kcb', component: PhanTichDlKcbComponent },
  { path: 'cap-cchn', component: CapCchnComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NghiepVuYRoutingModule { }
