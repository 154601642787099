import { PieChartComponent } from '../../../../shared/components/chart-js/pie-chart/pie-chart.component';
import { ToChucCanBoService } from "../../../../services/to-chuc-can-bo.service";
import { DmChungService } from 'src/app/services/dm-chung.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { arrayBuffer } from 'stream/consumers';
import { NumberFormatService } from 'src/app/services/number-format.service';
import { TaiChinhKeHoachService } from "./../../../../services/tai-chinh-ke-hoach.service";
import { CommonFunctionService } from './../../../../services/common-function.service';
@Component({
  selector: "app-tai-chinh-ke-toan",
  templateUrl: "./tai-chinh-ke-toan.component.html",
  styleUrls: ["./tai-chinh-ke-toan.component.scss"],
})
export class TaiChinhKeToanComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl("", [Validators.required]),
    
    
  });

  nam: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  SL_4_THE_TONG:  any = {};
  public DON_VI_LIST: any = [];
  DOANHTHU: any;
  showNam = true;
  showThang = false;
  listThang = [];
  listNam = [];
  public isNoneChart = false;
  public isNoneChartNN = false;
  public isNoneChartHD = false;
  public isNoneChartCT = false;
  public isNoneChartBS = false;
  userStorage = JSON.parse(sessionStorage.user);

  @ViewChild("thongKeThangDuTheoLoai", { static: true }) thongKeThangDuTheoLoai: PieChartComponent;
  @ViewChild("thangDuThamHut", { static: true }) thangDuThamHut: BarChartComponent;


  @ViewChild("taiChinhTongHop", { static: true }) taiChinhTongHop: BarChartComponent;
  
  @ViewChild("khinhPhiHoatDong", { static: true }) khinhPhiHoatDong: BarChartComponent;
  @ViewChild("tinhHinhTaiChinh", { static: true }) tinhHinhTaiChinh: BarChartComponent;
  @ViewChild("hoatDongDauTuCong", { static: true }) hoatDongDauTuCong: BarChartComponent;
 

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private taiChinhKeHoachService: TaiChinhKeHoachService,
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private toChucCanBoService: ToChucCanBoService,
    private dateAdapter: DateAdapter<Date>,
    private numberFormatService: NumberFormatService,
    private commonService: CommonFunctionService
  ) {
    this.dateAdapter.setLocale("vi-VN")
    this.DOANHTHU = {
      TONGDOANHTHU: 0,
      TONGCHIPHI: 0,
      TONGTHANGDUTHAMHUT:0,
      TONGCHIPHITHUTNDN:0
    }
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear()-1;
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2018; i <= currentYear + 1; i++) {
      this.nam.push({ id: i, text: "Năm " + i });
    }
    this.taiChinhTongHop.barChartOptions.legend = { display: false };
    this.taiChinhTongHop.barChartType = "horizontalBar";

    this.detectMode();
    this.getListNam()
    this.getListThang()
    this.getData();

  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.taiChinhTongHop.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.khinhPhiHoatDong.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tinhHinhTaiChinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.hoatDongDauTuCong.barChartOptions.maintainAspectRatio = !this.isFitPage;



  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }
  onFilterChange(){
    
  }
 

  getData(): void {
    this.getTaiChinhKeToanTong()
    this.getTinhHinhTaiChinh()
    this.getHoatDongDauTuCong()
    this.getKinhPhiHoatDong()
    this.getTaiChinhTongHop()
    // this.getThongKeThangDuTheoLoai()
    
    // this.getThangDuThamHut()
    // this.getHoatDongSanXuat()
    
    
  }

  
  getListNam(){
    this.subscription.push(
      this.dmchungService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getListThang(){
    this.subscription.push(
      this.dmchungService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getFilter() {
    let params = {};
    if (this.formSearch.controls.nam.value) {
      params["nam"] = this.formSearch.controls.nam.value;
    }
    return params;

  }
  
  getTaiChinhKeToanTong(){
    let obj = this.getFilter();
    let objDoangthu = []
    this.subscription.push(
      this.taiChinhKeHoachService.getTaiChinhKeToanTong(obj).subscribe(
        (rs: any) => {
          if (!rs.success || rs.data.length === 0) {
            return false;
          }
          objDoangthu  = rs.data;
          this.DOANHTHU = {
              NGUON_VON: this.nFormatter( Number(objDoangthu[0].NGUON_VON), 1),
              CHI_TIEU: this.nFormatter( Number(objDoangthu[0].CHI_TIEU), 1),
              THANGDU_THAMHUT: this.nFormatter( Number(objDoangthu[0].THANGDU_THAMHUT), 1),
              CHI_PHI_THUE: this.nFormatter( Number(objDoangthu[0].CHI_PHI_THUE), 1),
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTinhHinhTaiChinh(): void {
    let obj = this.getFilter();
    this.subscription.push(this.taiChinhKeHoachService.getTinhHinhTaiChinh(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.tinhHinhTaiChinh.barChartLabels = rs.data.map(x => x.TEN_LOAI);
      let arrHienTai = rs.data.map(x => x.HIENTAI);
      let arrCungKy = rs.data.map(x => x.CUNGKY);
      this.tinhHinhTaiChinh.barChartData = [
        { 
          data: arrHienTai, 
          label: "Hiện tại",
          backgroundColor: "#741b47",
          datalabels: {
            align: 'center',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: arrCungKy,
          label: "Cùng kỳ",
          backgroundColor: "#dd7e6b",
          datalabels: {
            align: 'center',
            anchor: 'end',
            padding:0
          },
        }
      ];
      this.tinhHinhTaiChinh.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
        },
        responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: '#fff',
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#fff",
              maxRotation: 0,
              minRotation: 0,
            },
            afterTickToLabelConversion: function(data) {
              // custom function to split labels into multiple lines
              let yLabels = data.ticks;
              yLabels.forEach(function (value, index, array) {
                let lines = value.split(' ');
                array[index] = [];
                let i = 0;
                while (lines.length > 0) {
                  array[index].push(lines.shift());
                  if (i > 0) {
                    array[index][i] = ' ' + array[index][i];
                  }
                  i++;
                }
              });
              data.ticks = [].concat.apply([], data.ticks);
            },
            gridLines: { display: false },
            stacked: false,
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: "#fff",
              beginAtZero: true,
            },
            gridLines: { display: false },
            stacked: false,
          },
        ],
      },
      plugins: {
        labels: false,
        datalabels: {
          color: "#fff",
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0; 
          },
        },
      },
    };
  }, err => {
    this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
  }));
  }

  getTaiChinhTongHop(): void {
    let obj = this.getFilter();
    this.subscription.push(this.taiChinhKeHoachService.getHoatDongTaiChinhTongHop(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.taiChinhTongHop.barChartLabels = rs.data.map((x) => x.LOAI_HOAT_DONG);
      let arrHienTai = rs.data.map((x) => x.HIENTAI);
      let arrCungKy = rs.data.map((x) => x.CUNGKY);
      this.taiChinhTongHop.barChartData = [
        { 
          data: arrHienTai, 
          label: "Hiện tại",
          backgroundColor: "#118dff",
          datalabels: {
            align: 'center',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: arrCungKy,
          label: "Cùng kỳ",
          backgroundColor: "#11249d",
          datalabels: {
            align: 'center',
            anchor: 'end',
            padding:0
          },
        }
      ];
      this.taiChinhTongHop.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
        },
        responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: '#fff',
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#fff",
              maxRotation: 0,
              minRotation: 0,
            },
            // afterTickToLabelConversion: function(data) {
            //   // custom function to split labels into multiple lines
            //   let yLabels = data.ticks;
            //   yLabels.forEach(function (value, index, array) {
            //     let lines = value.split(' ');
            //     array[index] = [];
            //     let i = 0;
            //     while (lines.length > 0) {
            //       array[index].push(lines.shift());
            //       if (i > 0) {
            //         array[index][i] = ' ' + array[index][i];
            //       }
            //       i++;
            //     }
            //   });
            //   data.ticks = [].concat.apply([], data.ticks);
            // },
            gridLines: { display: false },
            stacked: false,
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: "#fff",
              beginAtZero: true,
            },
            gridLines: { display: false },
            stacked: false,
          },
        ],
      },
      plugins: {
        labels: false,
        datalabels: {
          color: "#fff",
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0; 
          },
        },
      },
    };
  }, err => {
    this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
  }));
  }
  getKinhPhiHoatDong(): void {
    let obj = this.getFilter();
    this.subscription.push(this.taiChinhKeHoachService.getKinhPhiHoatDong(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.khinhPhiHoatDong.barChartLabels = rs.data.map(x => x.TEN_LOAI);
      let arrKinhPhi = rs.data.map(x => x.KINHPHI);
      this.khinhPhiHoatDong.barChartData = [
        { 
          data: arrKinhPhi, 
          label: "Kinh phí",
          backgroundColor: "#351c75",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:15,
            
          },
        }
      ];
      this.khinhPhiHoatDong.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; 
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
    
  }
  
  getHoatDongDauTuCong(): void {
    debugger
    let obj = this.getFilter();
    this.subscription.push(this.taiChinhKeHoachService.getHoatDongDauTu(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.hoatDongDauTuCong.barChartLabels = rs.data.map(x => x.LOAI_DU_AN);
      let arrDaiHan = rs.data.map(x => x.DU_AN_DAI );
      let arrTrungHan = rs.data.map(x => x.DU_AN_TRUNG);

      this.hoatDongDauTuCong.barChartData = [
        {
          data: arrDaiHan,
          label: "Dài hạn",
          backgroundColor: COLOR_PALETTES.ORANGE_TO_PURPLE[1],
        },
        {
          data: arrTrungHan,
          label: "Trung hạn",
          backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
        },
      ];
      this.hoatDongDauTuCong.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: { fontColor: "#fff" },
          display:true
        },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: { 
                fontColor: "#fff",
                maxRotation: 0,
                minRotation: 0,
              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "",
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: true,
              ticks: {
                display:false,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
    
  
  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
  private reduceLabel(label: string) {
    return label.replace(/huyện/gi, "")
                .replace(/thị xã/gi, "TX. ")
                .replace(/thành phố/gi, "TP. ");
  }
  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  nFormatter(num: any, digits: number) {
    if (isNaN(num)) {
      return num;
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " nghìn đồng" },
      { value: 1e6, symbol: " triệu đồng" },
      // { value: 1e9, symbol: " tỷ" },
      // { value: 1e12, symbol: "T" },
      // { value: 1e15, symbol: "P" },
      // { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i: number;
    for (i = lookup.length - 1; i > 0; i--) {
      if (num >= lookup[i].value) {
        break;
      }
    }
    if (i >= 3) {
      return (
        Number(
          (num / lookup[i].value).toFixed(digits).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    } else {
      return (
        Number(
          (num / lookup[i].value).toFixed(0).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    }
  }

}
