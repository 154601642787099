<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class=" mr-1">
        <p style="color: whitesmoke;font-weight: 600;font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;font-size: 15px;">Tổng: {{ donViCount }}</p>
        </div>

        <div class="ml-auto mr-3">

          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Chọn đơn vị quản lý</mat-label>
            <mat-select
              panelClass="dark-mode"
              (selectionChange)="onFilterChange()"
              formControlName="donvi"
            >
            <mat-option [value]="donvi?.ID" *ngFor="let donvi of listDonVi">{{ donvi?.TEN_DON_VI }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Năm đưa vào sử dụng" [data]="YEARS" [formControl]="formSearch.controls.year" label="Năm đưa vào sử dụng" (selected)="getData()" keyId="id" keyName="text" allowClear="true">
          </ngx-dropdown>
        </div>

        <button
          mat-raised-button
          class="btn-fullscreen"
          id="btn-fullscreen"
          (click)="toggleFullScreen()"
          *ngIf="!isFullScreen"
          matTooltip="Toàn màn hình"
        >
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
        <button
          mat-raised-button
          class="btn-fullscreen"
          id="btn-fullscreen"
          (click)="toggleFullScreen()"
          *ngIf="isFullScreen"
          matTooltip="Thu nhỏ"
        >
          <mat-icon color="primary">fullscreen_exit</mat-icon>
        </button>
      </div>
    </form>
    <div [ngStyle]="this.rowStyles">
      <div class="row small-gutters h-100">
        <div
          [ngClass]="
            isFitPage
              ? 'col-12 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "
        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">THỐNG KÊ TÌNH HÌNH DIỆN TÍCH ĐẤT VÀ DIỆN TÍCH ĐẤT ĐÃ SỬ DỤNG XÂY NHÀ</h4>
            <div class="legend d-flex align-items-center justify-content-center flex-wrap">
              <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tKeTSLAYDIENTICHDATVANHA.lineChartData">
                  <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                  <span class="ml-1">{{ item.label }}</span>
              </div>
          </div>
            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 110%">
                <app-line-chart #tKeTSLAYDIENTICHDATVANHA></app-line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div [ngStyle]="this.rowStyles" >
      <div class="row small-gutters h-100" >
        <div
          [ngClass]="
            isFitPage
              ? 'col-4 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "

        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">
              Biểu đồ thể hiện diện tích đất(đơn vị: m2)
            </h4>

            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 170%">
              <app-bar-chart #tKeTSTHEONAM [isSummaryVN]="true"></app-bar-chart>
              </div>

            </div>

          </div>

        </div>

        <div
          [ngClass]="
            isFitPage
              ? 'col-4 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "
        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">
              Biểu đồ thể hiện diện tích phần nhà(đơn vị: m2)
            </h4>

            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 170%">
              <app-bar-chart
                #tKeTSLAYDIENTICHDAT
                [isSummaryVN]="true"
              ></app-bar-chart>
            </div>
            </div>
          </div>
        </div>

        <div
          [ngClass]="
            isFitPage
              ? 'col-4 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "
        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Biểu đồ thể hiện giá trị tài sản đất</h4>

            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 170%">
              <app-bar-chart
                #tKeTSLAYNGUYENGIA
                [isSummaryVN]="true"
              ></app-bar-chart>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div [ngStyle]="this.rowStyles">
      <div class="row small-gutters h-100">
        <div
          [ngClass]="
            isFitPage
              ? 'col-6 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "
        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Biểu đồ thể hiện hiện trạng sử dụng nhà</h4>

            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 120%">
              <app-bar-chart
                #tKeTSLAYHIENTRANGNHA
                [isSummaryVN]="true"
              ></app-bar-chart>
            </div>
            </div>
          </div>
        </div>

        <div
          [ngClass]="
            isFitPage
              ? 'col-6 h-100'
              : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'
          "
        >
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">
              Biểu đồ thể hiện hiện trạng công trình gắn liền với đất
            </h4>

            <div class="h-100 pt-2" style="overflow-y: auto">
              <div class="h-100" style="width: 120%">
              <app-bar-chart
                #tKeTSLAYHIENTRANGCONGTRINH
                [isSummaryVN]="true"
              ></app-bar-chart>
            </div>
            </div>
          </div>
        </div>




      </div>
    </div>

  </div>
</div>
