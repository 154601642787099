import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AUTH } from '../constant/system-constant';

@Injectable()
export class AuthenticationService {
    constructor(protected http: HttpClient) {

    }

    getToken() {
        return sessionStorage.getItem(AUTH.TOKEN_KEY);
    }

    //Lấy thông tin user lưu trên sessionStorage
    getUserInfor(): any{
        return sessionStorage.getItem(AUTH.USER_INFO_KEY);
    }

    removeToken() {
        sessionStorage.clear();
        window.location.href = "/dang-nhap";
    }
}