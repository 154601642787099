import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TTPhapYHgiComponent } from "./tt-phap-y-hgi/tt-phap-y-hgi.component";

const routes: Routes = [
    {path: '', component: TTPhapYHgiComponent},
    {path: 'tt-phap-y-hgi', component: TTPhapYHgiComponent},
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class TrungTamGiamDinhPhapYHGIRoutingModule {}