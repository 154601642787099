import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';

@Component({
  selector: 'app-de-an06',
  templateUrl: './de-an06.component.html',
  styleUrls: ['./de-an06.component.scss']
})
export class DeAn06Component implements OnInit {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public QUARTERS: any[] = [];
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    quarter: new FormControl(""),
    tuyen: new FormControl("")
  });

  public CARD_COLORS: any[] = COLOR_PALETTES.PALETTE_7;
  public sumraryInfo = { CCVC_DK_VNEID_MUC_2: 0, LTDL_GIAY_KSK_LAI_XE: 0,
                         SL_KCB_CCCD: 0, THONG_BAO_LUU_TRU: 0 };
  @ViewChild("nhiemVuDeAn06", { static: true }) nhiemVuDeAn06: BarChartComponent;
  @ViewChild("kcbCCCD", { static: true }) kcbCCCD: BarChartComponent;
  @ViewChild("luuTru", { static: true }) luuTru: BarChartComponent;
  @ViewChild("giayKhamSucKhoe", { static: true }) giayKhamSucKhoe: BarChartComponent;
  @ViewChild("caiDatVneID", { static: true }) caiDatVneID: BarChartComponent;

  constructor(
    private aggService: AggService,
    private snackbar: SnackbarService,
  ) {
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }

    this.detectMode();

    this.nhiemVuDeAn06.barChartType = "horizontalBar";
    this.nhiemVuDeAn06.barChartOptions.legend.display = false;
    // this.kcbCCCD.barChartType = "horizontalBar";
    this.kcbCCCD.barChartOptions.scales.xAxes[0].stacked = true;
    this.kcbCCCD.barChartOptions.scales.yAxes[0].stacked = true;
    // this.kcbCCCD.barChartOptions.legend.position = 'top';
    this.kcbCCCD.barChartOptions.legend.display = false;
    this.luuTru.barChartOptions.legend.display = false;
    this.luuTru.barChartOptions.scales.xAxes[0].stacked = true;
    this.luuTru.barChartOptions.scales.yAxes[0].stacked = true;
    this.giayKhamSucKhoe.barChartOptions.legend.display = false;
    this.giayKhamSucKhoe.barChartOptions.scales.xAxes[0].stacked = true;
    this.giayKhamSucKhoe.barChartOptions.scales.yAxes[0].stacked = true;
    this.caiDatVneID.barChartOptions.legend.display = false;
    this.caiDatVneID.barChartOptions.scales.xAxes[0].stacked = true;
    this.caiDatVneID.barChartOptions.scales.yAxes[0].stacked = true;
    this.caiDatVneID.barChartOptions.scales.yAxes[0].ticks.minRotation = 0;
    this.caiDatVneID.barChartOptions.scales.yAxes[0].ticks.maxRotation = 0;
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.nhiemVuDeAn06.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.nhiemVuDeAn06.barChartOptions.layout.padding["right"] = 35;
    this.kcbCCCD.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.luuTru.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.giayKhamSucKhoe.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.caiDatVneID.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public getFilter() {
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let quarter = this.formSearch.controls.quarter.value;
    let timeType = this.formSearch.controls.timeType.value;
    let tuyen = this.formSearch.controls.tuyen.value;
    let result = { nam: year, thang: "", qui: "", tuyen: tuyen ?? "" };
    switch (timeType) {
      case 1: // theo tháng
        result = { nam: "", thang: month, qui: "", tuyen: tuyen ?? "" };
        break;
      case 2: // theo quý
        result = { nam: "", thang: "", qui: quarter, tuyen: tuyen ?? "" };
        break;
    }

    return result;
  }

  public getData(): void {
    this.thongKe4ChiTieu();
    this.tinhHinhThucHienDeAn06();
    this.kcbBangCCCD();
    this.luuTruTrucTuyen();
    this.giayKhamSucKhoeLaiXe();
    this.nvCaiDatVNeID();
  }

  public thongKe4ChiTieu(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKe4ChiTieu(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      this.sumraryInfo = rs.data[0];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using PALETTE_3[2]
  public tinhHinhThucHienDeAn06(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.thongKeTinhHinhThucHienDeAn06(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      this.nhiemVuDeAn06.barChartLabels = rs.data.map(x => this.convertToArray(' ', x.NHIEMVU, 4));
      let data = rs.data.map(x => x.TYLE);
      this.nhiemVuDeAn06.barChartData = [
        { data: data, label: 'Tỷ lệ', backgroundColor: COLOR_PALETTES.PALETTE_3[2], datalabels: {align: "end", anchor: "end", clamp: true} }
      ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using DUTCH_FIELD: 0, 1
  public kcbBangCCCD(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.khamChuaBenhBangCCCDTichHopBHYT(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      let result = rs.data;
      this.kcbCCCD.barChartLabels = [];
      this.kcbCCCD.barChartData = [];
      if (!(result && result.length > 0)) {  
        return;
      }
      result = result.filter(x => x.SL_KCB_CCCD > 0 || x.CHUA_LUOT_KHAM_BHYT > 0);
      this.kcbCCCD.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_CSYT), 1));
      
      this.kcbCCCD.barChartData.push({
        data: result.map(x => x.CHUA_LUOT_KHAM_BHYT),
        label: "Lượt khám khác",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[0]
      });
      this.kcbCCCD.barChartData.push({
        data: result.map(x => x.SL_KCB_CCCD),
        label: "Số lượng KCB bằng CCCD",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1]
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using DUTCH_FIELD: 2, 3
  // public luuTruTrucTuyen(): void {
  //   let obj: any = this.getFilter();
  //   this.isWithChart1="1"
  //   this.subscription.push(this.aggService.luuTruTrucTuyenDeAn06(obj).subscribe((rs: any) => {
  //     if (!rs.success) {
  //       return;;
  //     }
  //     let result = rs.data;
  //     this.luuTru.barChartLabels = [];
  //     this.luuTru.barChartData = [];
  //     if (!(result && result.length > 0)) {  
  //       return;
  //     }
  //     if(result.length <=10){
  //       this.isWithChart1 = "1"
  //     }else if(result.length <=20){
  //       this.isWithChart1 = "2"
  //     }else{
  //       this.isWithChart1 = "3"
  //     }
  //     result = result.filter(x => x.THONG_BAO_LUU_TRU > 0 || x.CHUA_THONG_BAO_LUU_TRU > 0);
  //     this.luuTru.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_CSYT), 1));
  //     this.luuTru.barChartData = [
  //       {
  //         data: result.map(x => x.CHUA_THONG_BAO_LUU_TRU),
  //         label: "Chưa khai báo",
  //         backgroundColor: COLOR_PALETTES.DUTCH_FIELD[3]
  //       },
  //       {
  //         data: result.map(x => x.THONG_BAO_LUU_TRU),
  //         label: "Đã khai báo",
  //         backgroundColor: COLOR_PALETTES.DUTCH_FIELD[2]
  //       }
  //     ]
  //   }, err => {
  //     this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
  //   }));
  // }
  luuTruTrucTuyen() {
    let obj = this.getFilter();
    this.subscription.push(this.aggService.luuTruTrucTuyenDeAn06(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.luuTru.barChartLabels = rs.data.map(x => x.TEN_CSYT);
      let data = rs.data.map(x => x.THONG_BAO_LUU_TRU);
      let dataThucHien = rs.data.map(x => x.CHUA_THONG_BAO_LUU_TRU);
      this.luuTru.barChartData = [
        { 
          data: dataThucHien, 
          label: 'Chưa khai báo', 
          backgroundColor: "#0060ff",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: data, 
          label: 'Đã khai báo', 
          backgroundColor: "#ffa300",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:0
          },
          
        }
      ];
      this.luuTru.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }
         
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,
                 
              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; 
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  // using DUTCH_FIELD: 4, 5
  public giayKhamSucKhoeLaiXe(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.giayKhamSucKhoeLaiXe(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      let result = rs.data;
      this.giayKhamSucKhoe.barChartLabels = [];
      this.giayKhamSucKhoe.barChartData = [];
      if (!(result && result.length > 0)) {  
        return;
      }
      result = result.filter(x => x.LTDL_GIAY_KSK_LAI_XE > 0 || x.CHUA_LTDL_GIAY_KSK_LAI_XE > 0);
      this.giayKhamSucKhoe.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_CSYT), 1));
      this.giayKhamSucKhoe.barChartData.push({
        data: result.map(x => x.CHUA_LTDL_GIAY_KSK_LAI_XE),
        label: "Chưa liên thông",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[5]
      });
      this.giayKhamSucKhoe.barChartData.push({
        data: result.map(x => x.LTDL_GIAY_KSK_LAI_XE),
        label: "Đã liên thông",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[4]
      });
      
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  // using RIVER_NIGHTS: 1, 2
  public nvCaiDatVNeID(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.nvCaiDatVNeIDMucDo2(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      let result = rs.data;
      this.caiDatVneID.barChartLabels = [];
      this.caiDatVneID.barChartData = [];
      if (!(result && result.length > 0)) {  
        return;
      }
      result = result.filter(x => x.CCVC_DK_VNEID_MUC_2 > 0 || x.CHUA_CCVC_DK_VNEID_MUC_2 > 0);
      this.caiDatVneID.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_CSYT), 1));
      this.caiDatVneID.barChartData.push({
        data: result.map(x => x.CHUA_CCVC_DK_VNEID_MUC_2),
        label: "Chưa cài đặt",
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[1]
      });
      this.caiDatVneID.barChartData.push({
        data: result.map(x => x.CCVC_DK_VNEID_MUC_2),
        label: "Đã cài đặt",
        backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[2]
      });
      
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  private reduceLabel(label: string) {
    return label.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT");
  }
  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
