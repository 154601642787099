import { ChiCucAtvstpModule } from './chi-cuc-atvstp/chi-cuc-atvstp.module';
import { ChiCucDanSoModule } from './chi-cuc-dan-so/chi-cuc-dan-so.module';
import { ToChucCanBoModule } from './to-chuc-can-bo/to-chuc-can-bo.module';
import { TaiChinhKeHoachModule } from './tai-chinh-ke-hoach/tai-chinh-ke-hoach.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { QD831Component } from './qd831/qd831.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QD4210Component } from './qd4210/qd4210.component';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NghiepVuYModule } from './nghiep-vu-y/nghiep-vu-y.module';
import { CdcModule } from './cdc/cdc.module';
import { NghiepVuDuocModule } from './nghiep-vu-duoc/nghiep-vu-duoc.module';
import { TrungTamGiamDinhPhapYModule } from './tt-giam-dinh-phap-y/tt-phap-y.module';
import { GiamDinhYKhoaModule } from './giam-dinh-y-khoa/giam-dinh-y-khoa.module';
import { MohinhtongtheComponent } from './mohinhtongthe/mohinhtongthe.component';
import { Qd831Module } from './qd831/qd831.module';
import { PhongdalieuModule } from './phongdalieu/phongdalieu.module';
import { TrungTamKiemNghiemModule } from './trung-tam-kiem-nghiem/trungtamkiemnghiem.module';
import { TrungTamGiamDinhPhapYHGIModule } from './tt-giam-dinh-phap-y-hgi/tt-phap-y-hgi.module';
import { GiamDinhYKhoaHGIModule } from './giam-dinh-y-khoa-hgi/giam-dinh-y-khoa-hgi.module';
import { TTKiemNghiemDuocPhamMyPhamHGIModule } from './tt-kiem-nghiem-duocpham-mypham-hgi/tt-kiem-nghiem-duocpham-mypham-hgi.module';
@NgModule({
  declarations: [QD831Component, QD4210Component, HomeComponent, MohinhtongtheComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    CdcModule,
    NghiepVuYModule,
    NghiepVuDuocModule,
    TaiChinhKeHoachModule,
    ToChucCanBoModule,
    ChiCucDanSoModule,
    TrungTamGiamDinhPhapYModule,
    GiamDinhYKhoaModule,
    Qd831Module,
    SharedModule,
    PhongdalieuModule,
    TrungTamKiemNghiemModule,
    TrungTamGiamDinhPhapYHGIModule,
    GiamDinhYKhoaHGIModule,
    TTKiemNghiemDuocPhamMyPhamHGIModule,
  ], exports: [
    QD831Component, QD4210Component
  ]
})
export class DashboardModule { }
