import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { Subscription } from "rxjs";
import {
  MA_DV_SO_Y_TE,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { DmChungService } from "src/app/services/dm-chung.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Spinner } from "src/app/services/spinner.service";

@Component({
  selector: "app-danh-muc-don-vi",
  templateUrl: "./danh-muc-don-vi.component.html",
  styleUrls: ["./danh-muc-don-vi.component.scss"],
})
export class DanhMucDonViComponent implements OnInit {
  private subscription: Subscription[] = [];
  public formSearch = new FormGroup({
    keyword: new FormControl(null),
  });
  public length: number;
  public ELEMENT_DATA: any[] = [];
  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ["tenDV", "maDV", "cap"];
  public listTreeData: NodeData[] = [];
  public nodeData: NodeData;
  private transformer = (node: NodeData, level: number) => {
    return {
      expandable: node.isParent === 1,
      name: node.name,
      maDV: node.maDV,
      cap: node.cap,
      maDVCha: node.maDVCha,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  isExpanded: boolean;

  constructor(
    private dmChungService: DmChungService,
    private spinner: Spinner,
    private snackbar: SnackbarService
  ) {
    this.dataSource.data = this.listTreeData;
  }

  ngOnInit(): void {
    this.search(null);
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public search(data: FlatNode) {
    let parent = new NodeData();
    if (data === null) {
      parent.maDV = MA_DV_SO_Y_TE;
    } else {
      parent.maDV = data.maDV;
      parent.maDVCha = data.maDVCha;
      parent.name = data.name;
    }
    let keyword = this.formSearch.controls.keyword.value;
    let obj = {
      keyword: keyword ?? "",
      size: 50,
    };

    if ((keyword === null || keyword === '' ) &&  data === null) {
      obj['donViCha'] = MA_DV_SO_Y_TE;
      this.spinner.show();
    } else if (data !== null ){
      obj['donViCha'] = data.maDV;
    }
    this.subscription.push(
      this.dmChungService.getListDonVi(obj).subscribe(
        (rs) => {
          let arr = [];
          rs.data.forEach((e) => {
            //Convert response API sang dữ liệu listTreeData
            this.nodeData = {
              name: e.TEN_DON_VI,
              maDV: e.MA_DON_VI,
              cap: e.CAP,
              maDVCha: e.DON_VI_CHA,
              isParent: e.IS_PARENT,
              children: null,
            };
            arr.push(this.nodeData);
          });
          if (parent.maDV == MA_DV_SO_Y_TE) {
            this.listTreeData = arr;
            this.dataSource.data = this.listTreeData;
          } else {
            //Update lại list data và lấy flatNode mới
            let node = this.listTreeData.find((x) => x.maDV == parent.maDV);
            node.children = arr;
            this.dataSource.data = this.listTreeData;
            let flatNode = this.treeControl.dataNodes.find(
              (x) => x.maDV == node.maDV
            );
            //Check trạng thái đóng mở biến tạm để gán lại cho node cha
            this.isExpanded === true
              ? this.treeControl.expand(flatNode)
              : this.treeControl.collapse(flatNode);
            //Đóng/mở node cha
            this.treeControl.toggle(flatNode);
            //Gán trạng thái đóng mở node cha biến tạm
            this.isExpanded = this.treeControl.isExpanded(flatNode);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}

export class NodeData {
  name: string;
  maDV: number;
  cap: number;
  maDVCha: number;
  isParent: number;
  children: NodeData[];
}

export class FlatNode {
  expandable: boolean;
  name: string;
  maDV: number;
  cap: number;
  maDVCha: number;
  level: number;
}
