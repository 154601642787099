import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { TiemChungComponent } from './tiem-chung/tiem-chung.component';
import { CdcRoutingModule } from './cdc-routing.module';
import { TinhHinhPhongChongLaoComponent } from './tinh-hinh-phong-chong-lao/tinh-hinh-phong-chong-lao.component';
import { BenhKhongLayComponent } from './benh-khong-lay/benh-khong-lay.component';
import { BenhTruyenNhiemComponent } from './benh-truyen-nhiem/benh-truyen-nhiem.component';
import { BenhTruyenNhiemHGIComponent } from './benh-truyen-nhiem-hgi/benh-truyen-nhiem-hgi.component';
import { KiemDichYTeComponent } from './kiem-dich-y-te/kiem-dich-y-te.component';
import {HivAidsComponent} from './hiv-aids/hiv-aids.component';
import { XetNghiemPkdkComponent } from './xet-nghiem-pkdk/xet-nghiem-pkdk.component'
import { SuyDinhDuongComponent } from './suy-dinh-duong/suy-dinh-duong.component';
import { SucKhoeSinhSanComponent } from './suc-khoe-sinh-san/suc-khoe-sinh-san.component';
import { VitaminAComponent } from './vitamin-a/vitamin-a.component';
import { VitaminAHgiComponent } from './vitamin-a-hgi/vitamin-a-hgi.component';
import { BenhKhongLayHgiComponent } from './benh-khong-lay-hgi/benh-khong-lay-hgi.component';
import { TiemChungHgiComponent } from './tiem-chung-hgi/tiem-chung-hgi.component'
import { HivAidsHGIComponent } from './hiv-aids-hgi/hiv-aids-hgi.component';
import { TinhHinhPhongChongLaoHgiComponent } from './tinh-hinh-phong-chong-lao-hgi/tinh-hinh-phong-chong-lao-hgi.component';
@NgModule({
  declarations: [TiemChungComponent, TinhHinhPhongChongLaoComponent, BenhKhongLayComponent, BenhTruyenNhiemComponent, KiemDichYTeComponent, HivAidsComponent, XetNghiemPkdkComponent, SuyDinhDuongComponent, SucKhoeSinhSanComponent, VitaminAComponent, VitaminAHgiComponent, BenhKhongLayHgiComponent, TiemChungHgiComponent, BenhTruyenNhiemHGIComponent, HivAidsHGIComponent, TinhHinhPhongChongLaoHgiComponent,],
  imports: [
    CommonModule,
    CdcRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class CdcModule { }
