<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
      <form class="dark-mode mr-4 mb-3 w-100" [formGroup]="formSearch" id="formSearch">
        <div class="row d-flex align-items-center">
            <div class="ml-auto mr-3 ">
              <mat-form-field class="w-100 mb-0" appearance="outline" >
                <mat-label>Loại thời gian</mat-label>
                <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
                    <mat-option [value]="1">Theo tuần </mat-option>
                    <mat-option [value]="2">Theo tháng </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="showNam" class=" mr-3">
              <ngx-dropdown (selectionChange)="onFilterChange()" panelClass="dark-mode" class="mb-0" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
              </ngx-dropdown>
            </div>
            <div *ngIf="showTuan" class="mr-3">
              <ngx-dropdown (selectionChange)="onFilterChange()" panelClass="dark-mode" class="mb-0" placeholder="Chọn tuần" [data]="WEEK" [formControl]="formSearch.controls.week" label="Chọn tuần" (selected)="getData()" keyId="id" keyName="text">
              </ngx-dropdown>
            </div>
            <div *ngIf="showThang" class="mr-3">
              <ngx-dropdown (selectionChange)="onFilterChange()" panelClass="dark-mode" class="mb-0" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()" keyId="id" keyName="text">
              </ngx-dropdown>
            </div>

          <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
              <mat-icon color="primary">fullscreen</mat-icon>
          </button>
        </div>
    </form>

      <!-- ROW 1 -->

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-2 h-100 px-3' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                      <div [ngClass]="isFitPage ? 'h-100 w-100' : 'mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Số lượng người nhập cảnh</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[0]"> {{ getNumber(this.slNhapCanh) }} </div>
                          </div>
                      </div>
                      <div style="height: 15px;" class="w-100">&nbsp;</div>
                      <div [ngClass]="isFitPage ? 'h-100 w-100' : 'mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Số lượng người bị covid khi nhập cảnh</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[2]"> {{ getNumber(this.slNhapCanhNhiemCovid) }} </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê số lượng người xuất, nhập, quá cảnh</h4>
                      <app-bar-chart #tkXuatNhapQuaCanh [isSummary]="true"></app-bar-chart>
                  </div>
              </div>

              <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê số phương tiện nhập cảnh</h4>
                      <app-bar-chart #tkPhuongTienNhapCanh [isSummary]="true"></app-bar-chart>
                  </div>

              </div>
          </div>
      </div>

      <!-- ROW 2 -->

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-2 h-100 px-3' : 'col-xl-2 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                      <div [ngClass]="isFitPage ? 'h-100 w-100' : 'mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Số lượng người xuất cảnh</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[1]"> {{ getNumber(this.slXuatCanh) }} </div>
                          </div>
                      </div>
                      <div style="height: 15px;" class="w-100">&nbsp;</div>
                      <div [ngClass]="isFitPage ? 'h-100 w-100' : 'mb-3'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title">Số lượng người quá cảnh</h4>
                              <div class="card-value" [style.color]="CONST_COLOR[3]"> {{ getNumber(this.slQuaCanh) }} </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê số phương tiện xuất cảnh</h4>
                      <app-bar-chart #tkPhuongTienXuatCanh [isSummary]="true"></app-bar-chart>
                  </div>
              </div>

              <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-3'">
                  <div class="bg-chart p-2 h-100">
                      <h4 class="chart-title">Thống kê số phương tiện quá cảnh</h4>
                      <app-bar-chart #tkPhuongTienQuaCanh [isSummary]="true"></app-bar-chart>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
