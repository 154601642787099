import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { apiUrl } from "../constant/api-url";
import { POST_BODY_TYPE } from "../constant/system-constant";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class AggService extends BaseService {
    login(requestData): Observable<any> {
        return super.post(apiUrl.USERS.LOGIN, requestData, POST_BODY_TYPE.FORM_DATA);
    }

    // User API
    getDanhSachUsers(requestData): Observable<any> {
        return super.get(apiUrl.USERS.DANH_SACH_USER, requestData);
    }

    getUserDetail(requestData): Observable<any> {
        return super.get(apiUrl.USERS.LAY_CHI_TIET_USER, requestData);
    }

    // createUser(requestData): Observable<any> {
    //     return super.post(apiUrl.USERS.THEM_USER, requestData);
    // }

    // updateUser(requestData): Observable<any> {
    //     return super.put(apiUrl.USERS.CAP_NHAT_USER, requestData);
    // }

    updateUserInfo(requestData): Observable<any> {
        return super.put(apiUrl.USERS.CAP_NHAT_THONG_TIN_USER, requestData);
    }

    updateUserPassword(requestData): Observable<any> {
        return super.put(apiUrl.USERS.CAP_NHAT_MAT_KHAU, requestData);
    }

    // deleteUser(username): Observable<any> {
    //     return super.delete(apiUrl.USERS.XOA_USER, username);
    // }
    // End: User API

    // 4210 API
    /* Danh mục nhóm chi phí */
    // getDanhMucNhomChiPhi(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_NHOM_CHI_PHI, requestData);
    // }

    // createDanhMucNhomChiPhi(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_NHOM_CHI_PHI, requestData);
    // }

    // updateDanhMucNhomChiPhi(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_NHOM_CHI_PHI, requestData);
    // }

    // deleteDanhMucNhomChiPhi(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_NHOM_CHI_PHI, requestData);
    // }

    /* Danh mục nhóm chi phí dịch vụ kỹ thuật */
    // getDanhMucNhomChiPhiDichVuKyThuat(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_NHOM_CHI_PHI_DICH_VU_KY_THUAT, requestData);
    // }

    // createDanhMucNhomChiPhiDichVuKyThuat(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_NHOM_CHI_PHI_DICH_VU_KY_THUAT, requestData);
    // }

    // updateDanhMucNhomChiPhiDichVuKyThuat(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_NHOM_CHI_PHI_DICH_VU_KY_THUAT, requestData);
    // }

    // deleteDanhMucNhomChiPhiDichVuKyThuat(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_NHOM_CHI_PHI_DICH_VU_KY_THUAT, requestData);
    // }

    /* Danh mục khoa */
    // getDanhMucKhoa(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_DANH_MUC_KHOA, requestData);
    // }

    // createDanhMucKhoa(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_DANH_MUC_KHOA, requestData);
    // }

    // updateDanhMucKhoa(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_DANH_MUC_KHOA, requestData);
    // }

    // deleteDanhMucKhoa(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_DANH_MUC_KHOA, requestData);
    // }

    /* Danh mục tai nạn thương tích */
    // getDanhMucTaiNanThuongTich(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_NHOM_TAI_NAN_THUONG_TICH, requestData);
    // }

    // createDanhMucTaiNanThuongTich(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_NHOM_TAI_NAN_THUONG_TICH, requestData);
    // }

    // updateDanhMucTaiNanThuongTich(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_NHOM_TAI_NAN_THUONG_TICH, requestData);
    // }

    // deleteDanhMucTaiNanThuongTich(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_NHOM_TAI_NAN_THUONG_TICH, requestData);
    // }

    /* Danh mục gói thầu */
    // getDanhMucGoiThau(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_DANH_MUC_GOI_THAU, requestData);
    // }

    // createDanhMucGoiThau(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_DANH_MUC_GOI_THAU, requestData);
    // }

    // updateDanhMucGoiThau(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_DANH_MUC_GOI_THAU, requestData);
    // }

    // deleteDanhMucGoiThau(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_DANH_MUC_GOI_THAU, requestData);
    // }

    /* Danh mục nhóm thầu */
    // getDanhMucNhomThau(requestData): Observable<any> {
    //     return super.get(apiUrl.QD4210.DANH_MUC.LAY_DANH_MUC_NHOM_THAU, requestData);
    // }

    // createDanhMucNhomThau(requestData): Observable<any> {
    //     return super.post(apiUrl.QD4210.DANH_MUC.THEM_DANH_MUC_NHOM_THAU, requestData);
    // }

    // updateDanhMucNhomThau(requestData): Observable<any> {
    //     return super.put(apiUrl.QD4210.DANH_MUC.SUA_DANH_MUC_NHOM_THAU, requestData);
    // }

    // deleteDanhMucNhomThau(requestData): Observable<any> {
    //     return super.delete(apiUrl.QD4210.DANH_MUC.XOA_DANH_MUC_NHOM_THAU, requestData);
    // }
    // End: 4210 API

    //phân quyền -- danh mục api
    // readAPI(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_CT_API, requestData);
    // }
    // readListAPI(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_DS_API, requestData);
    // }
    // createAPI(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.THEM_API, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    // updateAPI(requestData): Observable<any> {
    //     return super.put(apiUrl.PHAN_QUYEN.CAP_NHAT_API, requestData);
    // }
    // deleteAPI(requestData): Observable<any> {
    //     return super.delete(apiUrl.PHAN_QUYEN.XOA_API, requestData);
    // }
    //phân quyền - nhóm quyền api
    // readListNhomQuyenAPI(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_DS_NHOM_QUYEN_API, requestData);
    // }
    // readNhomQuyenAPI(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_CT_NHOM_QUYEN_API, requestData);
    // }
    // createNhomQuyenAPI(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.THEM_NHOM_QUYEN_API, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    // updateNhomQuyenAPI(requestData): Observable<any> {
    //     return super.put(apiUrl.PHAN_QUYEN.CAP_NHAT_NHOM_QUYEN_API, requestData);
    // }
    // deleteNhomQuyenAPI(requestData): Observable<any> {
    //     return super.delete(apiUrl.PHAN_QUYEN.XOA_NHOM_QUYEN_API, requestData);
    // }
    //phân quyền -- danh mục menu
    // readMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_CT_MENU, requestData);
    // }
    // readMenuTheoCap(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_DS_MENU_THEO_CAP, requestData);
    // }
    // readCapMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_MAX_CAP_MENU, requestData);
    // }
    // readListMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_DS_MENU, requestData);
    // }
    // createMenu(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.THEM_MENU, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    // updateMenu(requestData): Observable<any> {
    //     return super.put(apiUrl.PHAN_QUYEN.CAP_NHAT_MENU, requestData);
    // }
    // deleteMenu(requestData): Observable<any> {
    //     return super.delete(apiUrl.PHAN_QUYEN.XOA_MENU, requestData);
    // }
    //phân quyền - nhóm quyền menu
    // readListNhomQuyenMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_DS_NHOM_QUYEN_MENU, requestData);
    // }
    // readNhomQuyenMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_CT_NHOM_QUYEN_MENU, requestData);
    // }
    // createNhomQuyenMenu(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.THEM_NHOM_QUYEN_MENU, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    // updateNhomQuyenMenu(requestData): Observable<any> {
    //     return super.put(apiUrl.PHAN_QUYEN.CAP_NHAT_NHOM_QUYEN_MENU, requestData);
    // }
    // deleteNhomQuyenMenu(requestData): Observable<any> {
    //     return super.delete(apiUrl.PHAN_QUYEN.XOA_NHOM_QUYEN_MENU, requestData);
    // }
    // phân quyền api
    // readNApi(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_N_API, requestData);
    // }
    // readNNhomQuyenApi(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_N_NHOM_QUYEN_API, requestData);
    // }
    // xemQuyenApi(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.XEM_QUYEN_API, requestData);
    // }
    // phanQuyenApi(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.PHAN_QUYEN_API, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    //phân quyền menu
    // readNMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_N_MENU, requestData);
    // }
    layDsMenuIoc(requestData): Observable<any> {
        return super.get(apiUrl.PHAN_QUYEN.LAY_DS_MENU_IOC, requestData);
    }
    // readNNhomQuyenMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_N_NHOM_QUYEN_MENU, requestData);
    // }
    // xemQuyenMenu(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.XEM_QUYEN_MENU, requestData);
    // }
    // phanQuyenMenu(requestData): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.PHAN_QUYEN_MENU, requestData, POST_BODY_TYPE.FORM_DATA);
    // }
    //cấu hình hệ thống -- danh mục phân cấp
    readPhanCap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LAY_CT_PHAN_CAP, requestData);
    }
    readMaxPhanCap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LAY_MAX_LEVEL_USER, requestData);
    }
    readAllPhanCap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LAY_ALL_PHAN_CAP, requestData);
    }
    readListPhanCap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LAY_DS_PHAN_CAP, requestData);
    }
    createPhanCap(requestData): Observable<any> {
        return super.post(apiUrl.CAU_HINH_HE_THONG.THEM_PHAN_CAP, requestData,POST_BODY_TYPE.FORM_DATA);
    }
    updatePhanCap(requestData): Observable<any> {
        return super.put(apiUrl.CAU_HINH_HE_THONG.CAP_NHAT_PHAN_CAP, requestData);
    }
    deletePhanCap(requestData): Observable<any> {
        return super.delete(apiUrl.CAU_HINH_HE_THONG.XOA_PHAN_CAP, requestData);
    }
    //cấu hình hệ thống -- phân cấp api
    xemAPITheoPhanCap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LAY_API_THEO_PHAN_CAP, requestData);
    }
    phanCapAPI(requestData): Observable<any> {
        return super.post(apiUrl.CAU_HINH_HE_THONG.PHAN_CAP_API, requestData,POST_BODY_TYPE.FORM_DATA);
    }
    //cấu hình hệ thống -- log hệ thống
    readLogDangNhap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LOG_DANG_NHAP, requestData);
    }
    readLogChucNang(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.LOG_CHUC_NANG, requestData);
    }
    readCTLogDangNhap(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.CT_LOG_DANG_NHAP, requestData);
    }
    readCTLogChucNang(requestData): Observable<any> {
        return super.get(apiUrl.CAU_HINH_HE_THONG.CT_LOG_CHUC_NANG, requestData);
    }
    // phân quyền người dùng - api
    // readUsers(requestData): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.LAY_N_USER, requestData);
    // }
    // xemQuyenUser(requestParam): Observable<any> {
    //     return super.get(apiUrl.PHAN_QUYEN.XEM_QUYEN_USER, requestParam);
    // }
    // phanQuyenUser(requestParam): Observable<any> {
    //     return super.post(apiUrl.PHAN_QUYEN.PHAN_QUYEN_USER, requestParam,POST_BODY_TYPE.FORM_DATA);
    // }

    layDanhSachDonVi(requestData): Observable<any> {
        return super.get(apiUrl.DON_VI.LAY_DANH_SACH_DON_VI, requestData);
    }

    layDanhSachCapNguoiDung(requestData): Observable<any> {
        return super.get(apiUrl.DON_VI.LAY_DANH_SACH_USER_LEVEL, requestData);
    }

    // importFileXML4210(requestData): Observable<any>{
    //     return super.post(apiUrl.QD4210.IMPORT.IMPORT_FILE_XML,requestData,POST_BODY_TYPE.FORM_DATA)
    // }

    // importFileXML831(requestData): Observable<any>{
    //     return super.post(apiUrl.QD831.IMPORT.IMPORT_FILE_XML,requestData,POST_BODY_TYPE.FORM_DATA)
    // }

    // NguyenDTT-AGG: APIs using for IOC
    /** Thống kê chung */
    thongkeTongQuan1(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_TONG_QUAN_1, requestData);
    }

    thongkeSuDungDVKT(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SU_DUNG_DVKT, requestData);
    }

    thongkeSoHoSoBHYT(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SO_HO_SO_BHYT, requestData);
    }

    thongkeGioiTinhKCB(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_GIOI_TINH_KCB, requestData);
    }

    thongkeSoNgayDieuTri(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SO_NGAY_DIEU_TRI, requestData);
    }

    thongkeCacBenhPhoBien(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_CAC_BENH_PHO_BIEN, requestData);
    }

    thongkeCacBenhPhoBienTheoKhuVuc(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_CAC_BENH_PHO_BIEN_THEO_KHU_VUC, requestData);
    }

    thongkeTaiNanThuongTich(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_TAI_NAN_THUONG_TICH, requestData);
    }

    thongkeTaiNanThuongTichTheoKhuVuc(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_TAI_NAN_THUONG_TICH_THEO_KHU_VUC, requestData);
    }

    thongkeChiPhiKhamChuaBenh(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_CHI_PHI_KHAM_CHUA_BENH, requestData);
    }

    thongkeChiPhiKhamChuaBenhTheoKhuVuc(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_CHI_PHI_KHAM_CHUA_BENH_THEO_KHU_VUC, requestData);
    }

    thongkeSoCaCapCuu(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SO_CA_CAP_CUU, requestData);
    }

    thongkeSoCaTuVong(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_SO_CA_TU_VONG, requestData);
    }

    thongkeNhomTuoiKCB(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_NHOM_TUOI_KCB, requestData);
    }

    thongkeKhamChuaBenhBaoHiem(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.THONG_KE_KCB_BAO_HIEM, requestData);
    }
    /** End: Thống kê chung */

    /** CDC Tiêm chủng */
    thongKeTiemChungTreDuoi1Tuoi(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.DAY_DU_CHO_TRE_DUOI_1_TUOI, requestData);
    }
    thongKeTiemViemGanBTreDuoi1Tuoi(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.VIEM_GAN_B_TRE_DUOI_1_TUOI, requestData);
    }
    thongKeTiemMoRongTre18Thang(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.MO_RONG_TRE_DUOI_18_THANG, requestData);
    }
    thongKeTiemDTPTre18Thang(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.DPT_TRE_18_THANG, requestData);
    }
    thongKeTiemViemNaoNhatBan(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.MUI_2_VA_3_VIEM_NAO_NHAT_BAN, requestData);
    }
    thongKeTiemVATPhuNuCoThai(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG.VAT_PHU_NU_CO_THAI, requestData);
    }
    /** End: CDC Tiêm chủng */

    /** Nghiệp vụ y */
    thongKe4ChiTieu(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.THONG_KE_4_CHI_TIEU, requestData);
    }

    thongKeTinhHinhThucHienDeAn06(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.THONG_KE_NHIEM_VU, requestData);
    }

    khamChuaBenhBangCCCDTichHopBHYT(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.KCB_BANG_CCCD_TICH_HOP_BHYT, requestData);
    }

    luuTruTrucTuyenDeAn06(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.THUC_HIEN_LUU_TRU_TRUC_TUYEN, requestData);
    }

    giayKhamSucKhoeLaiXe(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.LIEN_THONG_GIAY_KHAM_SUC_KHOE_LAI_XE, requestData);
    }

    nvCaiDatVNeIDMucDo2(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_Y.DE_AN_06.NV_CAI_DAT_VNEID_MUC_DO_2, requestData);
    }
    /** End: Nghiệp vụ y */

    /** Nghiệp vụ dược */
    soLieuTongQuanChungChiHanhNghe(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.THONG_TIN_TONG_QUAN, requestData);
    }
    soLieuTongQuanChungChiHanhNgheHgi(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.THONG_TIN_TONG_QUAN_HGI, requestData);
    }
    loaiVanBangChungChiHanhNghe(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.LOAI_VAN_BANG, requestData);
    }
    loaiVanBangChungChiHanhNgheHgi(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.LOAI_VAN_BANG_HGI, requestData);
    }
    
    danhSachChungChiHanhNghe(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.DANH_SACH_CCHN, requestData);
    }
    danhSachChungChiHanhNgheHgi(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.DANH_SACH_CCHN_HGI, requestData);
    }
    danhSachChungChiHanhNgheTheoLoai(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CHUNG_NHAN_CHUNG_CHI_HANH_NGHE.DANH_SACH_CCHN_THEO_LOAI, requestData);
    }
    soLieuTongQuanGCN(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN.THONG_TIN_TONG_QUAN, requestData);
    }
    tkSoLuongCoSoTheoHuyen(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN.SO_CS_THEO_HUYEN, requestData);
    }
    tkTyLehinhThucToChuc(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN.TY_LE_HTTC, requestData);
    }
    danhSachGCN(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN.DS_GCN, requestData);
    }
    danhSachGCNTheoLoai(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN.DS_GCN_THEO_LOAI, requestData);
    }
    /** End: Nghiệp vụ duọc */

    getBaoCaoPhongChongHivAids(requestData): Observable<any> {
        return super.get(apiUrl.BCTK.TRUNG_TAM_KIEM_SOAT_BENH_TAT, requestData);
    }
    getChiTieuHoatDongTrungTamKiemNghiem(requestData): Observable<any> {
        return super.get(apiUrl.BCTK.CHI_TIEU_HOAT_DONG_TT_KIEM_NGHIEM, requestData);
    }
    // END NguyenDTT-AGG: APIs using for IOC

    // Create by HoangHenIT
    // Công Bố Mỹ Phẩm
    getListNam(): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_NAM);
    }

    getListThang(): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.LAY_DS_THANG);
    }
    getListQuy(): Observable<any> {
        return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_QUY);
      }
    getTongThe(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CONG_BO_MY_PHAM.TONG_THE, requestData);
    }
    getCTySanXuatTheoHuyen(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CONG_BO_MY_PHAM.TK_CTY_SAN_XUAT_THEO_HUYEN, requestData);
    }
    getSanPhamTheoCTySanXuat(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CONG_BO_MY_PHAM.TK_SAN_PHAM_THEO_CTY_SAN_XUAT, requestData);
    }
    getNhanHangTheoCTySanXuat(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CONG_BO_MY_PHAM.TK_NHAN_HANG_THEO_CTY_SAN_XUAT, requestData);
    }
    getTop10BenhNguyCoCao(requestData): Observable<any> {
        return super.get(apiUrl.KHAM_CHUA_BENH.TK_BENH_NGUY_CO_CAO, requestData);
    }
    getTongTheChiTiet(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.CONG_BO_MY_PHAM.TONG_THE_CHI_TIET, requestData);
    }
    //PHONG DA LIỄU HGI
    getChiTieuPhongDaLieu(requestData): Observable<any> {
      return super.get(apiUrl.PHONG_DA_LIEU.GET_CHI_TIEU_PHONG_DA_LIEU, requestData);
    }
    getPhongDaLieuDonVi(requestData): Observable<any> {
      return super.get(apiUrl.PHONG_DA_LIEU.GET_PHONG_DA_LIEU_THEO_DONVI, requestData);
    }
    getTSTamThan(requestData): Observable<any> {
      return super.get(apiUrl.PHONG_DA_LIEU.GET_TS_BENHNHAN_TAMTHAN, requestData);
    }
    getCTTamThanDonVi(requestData): Observable<any> {
      return super.get(apiUrl.PHONG_DA_LIEU.GET_CHI_TIEU_TAM_THAN_THEO_DONVI, requestData);
    }
    getCTTamThan(requestData): Observable<any> {
      return super.get(apiUrl.PHONG_DA_LIEU.GET_CHI_TIEU_TAM_THAN, requestData);
    }

    //trung tâm kiểm nghiệm
    getChiTieuTTKiemNghiem(requestData): Observable<any> {
      return super.get(apiUrl.TRUNG_TAM_KIEM_NGHIEM.GET_CHI_TIEU_KY_THUAT_KIEM_NGHIEM, requestData);
    }
    getChiTieuDinhTinh(requestData): Observable<any> {
      return super.get(apiUrl.TRUNG_TAM_KIEM_NGHIEM.GET_CHI_TIEU_DINH_TINH, requestData);
    }
    getChiTieuDinhLuong(requestData): Observable<any> {
      return super.get(apiUrl.TRUNG_TAM_KIEM_NGHIEM.GET_CHI_TIEU_DINH_LUONG, requestData);
    }

    /** CDC Tiêm chủng HGI*/
    thongKeTiemChungTreDuoi1TuoiHGI(requestData): Observable<any> {
      return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.DAY_DU_CHO_TRE_DUOI_1_TUOI, requestData);
    }
    thongKeTiemViemGanBTreDuoi1TuoiHGI(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.VIEM_GAN_B_TRE_DUOI_1_TUOI, requestData);
    }
    thongKeTiemMoRongTre18ThangHGI(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.MO_RONG_TRE_DUOI_18_THANG, requestData);
    }
    thongKeTiemDTPTre18ThangHGI(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.DPT_TRE_18_THANG, requestData);
    }
    thongKeTiemViemNaoNhatBanHGI(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.MUI_2_VA_3_VIEM_NAO_NHAT_BAN, requestData);
    }
    thongKeTiemVATPhuNuCoThaiHGI(requestData): Observable<any> {
        return super.get(apiUrl.TRUNG_TAM_CDC.TIEM_CHUNG_HGI.VAT_PHU_NU_CO_THAI, requestData);
    }
    /** End CDC Tiêm chủng HGI*/

    // end HoangHenIT
    getSoLieuTongQuanCCHNNghiepVuY(requestData): Observable<any>{
        return super.get(apiUrl.NGHIEP_VU_Y.TINH_HINH_CAP_CCHN, requestData);
    }
    getDSCCHNGanNhat(requestData): Observable<any>{
        return super.get(apiUrl.NGHIEP_VU_Y.DS_CCHN_GAN_NHAT, requestData);
    }

    /** Nghiệp vụ dược HGI*/
    soLieuTongQuanGCNHGI(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN_HGI.THONG_TIN_TONG_QUAN_HGI, requestData);
    }
    tkSoLuongCoSoTheoHuyenHGI(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN_HGI.SO_CS_THEO_HUYEN_HGI, requestData);
    }
    tkTyLehinhThucToChucHGI(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN_HGI.TY_LE_HTTC_HGI, requestData);
    }
    danhSachGCNHGI(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN_HGI.DS_GCN_HGI, requestData);
    }
    danhSachGCNTheoLoaiHGI(requestData): Observable<any> {
        return super.get(apiUrl.NGHIEP_VU_DUOC.GCN_HGI.DS_GCN_THEO_LOAI_HGI, requestData);
    }
    /** End: Nghiệp vụ dược HGI */
    // END KIETNQ-CTO: APIs using for IOC




}
