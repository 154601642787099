// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-benh-khong-lay-hgi',
//   templateUrl: './benh-khong-lay-hgi.component.html',
//   styleUrls: ['./benh-khong-lay-hgi.component.scss']
// })
// export class BenhKhongLayHgiComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { CDCService } from "src/app/services/trung-tam-cdc.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";

@Component({
  selector: "app-benh-khong-lay-hgi",
  templateUrl: "./benh-khong-lay-hgi.component.html",
  styleUrls: ["./benh-khong-lay-hgi.component.scss"],
})
export class BenhKhongLayHgiComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    nam: new FormControl(new Date().getFullYear()),
    thang: new FormControl("", [Validators.required]),
    loai: new FormControl(1),
    tungay:  new FormControl(new Date().toISOString()),
    denngay:  new FormControl(new Date().toISOString()),
  });
  lableMonth = true;
  showNam = true;
  showThang = false;
  showTuNgay = false;
  showDenNgay = false;
  YEARS: any[] = [];
  MONTHS: any[] = [];
  rowStyles: any = {};
  isWithChart: any
  isWithChart1: any
  @ViewChild("tangHuyetApCapHuyen", { static: true })
  tangHuyetApCapHuyen: BarChartComponent;
  @ViewChild("daiThaoDuongCapHuyen", { static: true })
  daiThaoDuongCapHuyen: BarChartComponent;
  @ViewChild("tyLeTuVong", { static: true })
  tyLeTuVongChart: PieChartComponent;
  @ViewChild("tangHuyetApCapTinh", { static: true })
  tangHuyetApCapTinh: PieChartComponent;
  @ViewChild("daiThaoDuongCapTinh", { static: true })
  daiThaoDuongCapTinh: PieChartComponent;

  slDTDPhatHien: number;
  slDTDQuanLyDieuTri: number;
  slHuyetApPhatHien: number;
  slHuyetApQuanLyDieuTri: number;

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdcService: CDCService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    // let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["thang"]; //.setValue(currentMonth)
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }

    this.detectMode();
    this.getData();

    this.tyLeTuVongChart.pieChartOptions.legend.position = "right";
    this.tangHuyetApCapTinh.pieChartOptions.legend.position = "right";
    this.daiThaoDuongCapTinh.pieChartOptions.legend.position = "right";
  }

  // public getFilter() {
  //   let params = {};
  //   if (this.formSearch.controls.year.value) {
  //     params["nam"] = this.formSearch.controls.nam.value;
  //   }
  //   if(this.formSearch.controls.month.value){
  //     params["thang"] = this.formSearch.controls.thang.value==0?null:this.formSearch.controls.month.value;
  //   }
  //   // if (
  //   //   this.formSearch.controls.month.value &&
  //   //   this.formSearch.controls["timeType"].value == 1
  //   // ) {
  //   //   params["thang"] = this.formSearch.controls.month.value;
  //   // }
  //   // if (
  //   //   this.formSearch.controls.quarter.value &&
  //   //   this.formSearch.controls["timeType"].value == 2
  //   // ) {
  //   //   params["qui"] = this.formSearch.controls.quarter.value;
  //   // }

  //   return params;
  // }
  public getFilter() {
    let loai = this.formSearch.get("loai").value
    let nam  = this.formSearch.get("nam").value;
    let thang  = this.formSearch.get("thang").value.toString() == "Tất cả"?"": this.formSearch.get("thang").value.toString();
    let tungay = this.formatDate(this.formSearch.get("tungay").value).toString();
    let denngay = this.formatDate(this.formSearch.get("denngay").value).toString();
    let result = {};
    if(loai == 1){
      result = {
        nam: nam,
        thang: "",
        tungay: "",
        denngay: ""
      }
    }else if (loai == 2){
      result = {
        nam: nam,
        thang: thang,
        tungay: "",
        denngay: ""
      }
    } else {
      result = {
        nam: "",
        thang: "",
        tungay: tungay,
        denngay: denngay
      }
    }
    console.log(result)
    return result;
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
  }


  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tangHuyetApCapHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.daiThaoDuongCapHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeTuVongChart.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeTuVongChart.pieChartType = "doughnut";
    this.tangHuyetApCapTinh.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.daiThaoDuongCapTinh.pieChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  public getData(): void {
    this.tyLePhatHienTangHuyetApCapHuyen();
    this.tyLePhatHienDaiThaoDuongCapHuyen();
    this.tyLeTuVong();
    this.tyLePhatHienTangHuyetApCapTinh();
    this.tyLePhatHienDaiThaoDuongCapTinh();
    this.soLuongBNHuyetApDaiThaoDuong();
  }
  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    if(loai == 1 ){
      this.showNam = true
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 2){
      this.showNam = true
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else{
      this.showNam = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }
  onFilterChange (){
    this.detectMode();
    this.getData();
  }
  tyLePhatHienTangHuyetApCapHuyen(): void {
    let obj = this.getFilter();
    this.isWithChart=1
    this.subscription.push(
      this.cdcService.getTLPhatHienTangHuyetApCapHuyenHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tangHuyetApCapHuyen.barChartData = [];
            return false;
          }
          this.tangHuyetApCapHuyen.barChartLabels = rs.data.map(
            (x) => x.TEN_HUYEN
          );
          if(data.length <= 6){
            this.isWithChart = 1
          }else{
            this.isWithChart = 2
          }
          this.tangHuyetApCapHuyen.barChartData = [
            {
              data: rs.data.map((x) => x.BN_PHAT_HIEN),
              label: "Bệnh nhân phát hiện",
              backgroundColor: COLOR_PALETTES.PALETTE_2[3],
            },
            {
              data: rs.data.map((x) => x.BN_DIEU_TRI),
              label: "Bệnh nhân điều trị",
              backgroundColor: COLOR_PALETTES.PALETTE_6[1],
              datalabels:{
              }
            },
          ];
          this.tangHuyetApCapHuyen.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,

                  },

                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0';
                  // or >= 1 or !== 0 or ...
                },

              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        }));
  }

  tyLePhatHienDaiThaoDuongCapHuyen(): void {
    let obj = this.getFilter();

    this.isWithChart1 =1
    this.subscription.push(
      this.cdcService.getTLPhatHienDaiThaoDuongCapHuyenHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.daiThaoDuongCapHuyen.barChartData = [];
            return false;
          }
          this.daiThaoDuongCapHuyen.barChartLabels = data.map(x =>  x.TEN_HUYEN);
          if(data.length <= 6){
            this.isWithChart1 = 1
          }else{
            this.isWithChart1 = 2
          }
          this.daiThaoDuongCapHuyen.barChartData = [
            {
              data: data.map((x) => x.BN_PHAT_HIEN),
              label: "Bệnh nhân phát hiện",
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[6],
            },
            {
              data: data.map((x) => x.BN_DIEU_TRI),
              label: "Bệnh nhân điều trị",
              backgroundColor: COLOR_PALETTES.ORANGE_TO_PURPLE[1],
            },
          ];
          this.daiThaoDuongCapHuyen.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: false,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: false,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,

                  },

                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0';
                  // or >= 1 or !== 0 or ...
                },

              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        }));
      }

  tyLeTuVong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLTuVongHGI(obj).subscribe(
        (rs: any) => {
          this.tyLeTuVongChart.pieChartData = [];
          this.tyLeTuVongChart.pieChartLabels = [];
          if (!rs.success || rs.data.length == 0) {
            return false;
          }
          rs.data.forEach((element) => {
            this.tyLeTuVongChart.pieChartLabels.push(element.TEN_NGUYEN_NHAN);
            this.tyLeTuVongChart.pieChartData.push(element.TY_LE);
          });
          this.tyLeTuVongChart.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tyLePhatHienTangHuyetApCapTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLPhatHienTangHuyetApCapTinhHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tangHuyetApCapTinh.pieChartData = [];
            return false;
          }
          let benhNhanPhatHien = data[0].BN_PHAT_HIEN;
          let benhNhanQuanLyDieuTri = data[0].BN_QUAN_LY_DIEU_TRI;
          let benhNhanChuaPhatHien = data[0].CHUA_BN_PHAT_HIEN;

          this.tangHuyetApCapTinh.pieChartData = [
            benhNhanPhatHien,
            benhNhanQuanLyDieuTri,
            benhNhanChuaPhatHien,
          ];
          this.tangHuyetApCapTinh.pieChartLabels = [
            "Bệnh nhân đã phát hiện",
            "Bệnh nhân quản lý điều trị",
            "Bệnh nhân chưa phát hiện",
          ];
          this.tangHuyetApCapTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_1 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tyLePhatHienDaiThaoDuongCapTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLPhatHienDaiThaoDuongCapTinhHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.daiThaoDuongCapTinh.pieChartData = [];
            return false;
          }
          let benhNhanPhatHien = data[0].BN_PHAT_HIEN;
          let benhNhanQuanLyDieuTri = data[0].BN_QUAN_LY_DIEU_TRI;
          let benhNhanChuaPhatHien = data[0].CHUA_BN_PHAT_HIEN;

          this.daiThaoDuongCapTinh.pieChartData = [
            benhNhanPhatHien,
            benhNhanQuanLyDieuTri,
            benhNhanChuaPhatHien,
          ];
          this.daiThaoDuongCapTinh.pieChartLabels = [
            "Bệnh nhân đã phát hiện",
            "Bệnh nhân quản lý điều trị",
            "Bệnh nhân chưa phát hiện",
          ];
          this.daiThaoDuongCapTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.RIVER_NIGHTS },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  soLuongBNHuyetApDaiThaoDuong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getSLBNHuyetApDaiThaoDuongHGI(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.slDTDPhatHien = rs.data[0].DTD_SO_BN_PHAT_HIEN;
          this.slDTDQuanLyDieuTri = rs.data[0].DTD_SO_BN_QUAN_LY_DIEU_TRI;
          this.slHuyetApPhatHien = rs.data[0].HUYETAP_SO_BN_PHAT_HIEN;
          this.slHuyetApQuanLyDieuTri =
            rs.data[0].HUYETAP_SO_BN_QUAN_LY_DIEU_TRI;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }
  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

}

