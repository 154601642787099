import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { ChiCucDanSoService } from 'src/app/services/chi-cuc-dan-so.service';
import { DmChungService } from 'src/app/services/dm-chung.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { VNDateAdapter } from 'src/environments/datepicker-apdapter';
import { PICK_FORMATS } from 'src/app/services/config.service';
import { DatePipe } from '@angular/common';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { CommonFunctionService } from 'src/app/services/common-function.service';
import * as moment from 'moment';

@Component({
  selector: 'app-phan-tich-so-lieu-dan-so',
  templateUrl: './phan-tich-so-lieu-dan-so.component.html',
  styleUrls: ['./phan-tich-so-lieu-dan-so.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: VNDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: PICK_FORMATS },
    DatePipe]
})
export class PhanTichSoLieuDanSoComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  isFitPage = false;
  rowStyles: any = {};

  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public QUARTERS: any[] = [];
  public LIST_TINH: any[] = [];
  public LIST_HUYEN: any[] = [];
  public LIST_XA: any[] = [];

  @ViewChild("tkTyLeDanSo", { static: true }) tkTyLeDanSo: BarChartComponent;
  @ViewChild("tkTyLeDanSoGioiTinh", { static: true }) tkTyLeDanSoGioiTinh: BarChartComponent;
  @ViewChild("tkTyLeDanSoTranhThai", { static: true }) tkTyLeDanSoTranhThai: BarChartComponent;
  @ViewChild("tkTyLeDanSoTuoiLD", { static: true }) tkTyLeDanSoTuoiLD: BarChartComponent;
  @ViewChild("tkTyLeDanSoTDVH", { static: true }) tkTyLeDanSoTDVH: BarChartComponent;

  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    maTinh: new FormControl('93'),
    maHuyen: new FormControl(""),
    maXa: new FormControl(""),
    nam: new FormControl(new Date().getFullYear()),
    quy: new FormControl(""),
    thang: new FormControl("", [Validators.required]),
    tuNgay: new FormControl(new Date().toISOString()),
    denNgay: new FormControl(new Date().toISOString()),
  });

  constructor(
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private chiCucDanSoService: ChiCucDanSoService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonFunctionService
  ) {
    this.dateAdapter.setLocale("vi-VN");
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = currentYear; i >= 2020; i--) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["thang"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }
    this.getListTinh(() => {
      this.getListHuyen(this.formSearch.controls["maTinh"].value);
    });
    this.detectMode();
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);

    this.tkTyLeDanSo.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkTyLeDanSo.barChartOptions.legend.position = "top";
    this.tkTyLeDanSoGioiTinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkTyLeDanSoGioiTinh.barChartOptions.legend.position = "top";
    this.tkTyLeDanSoTranhThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkTyLeDanSoTranhThai.barChartOptions.legend.position = "top";
    this.tkTyLeDanSoTuoiLD.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkTyLeDanSoTuoiLD.barChartOptions.legend.position = "top";
    this.tkTyLeDanSoTDVH.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkTyLeDanSoTDVH.barChartOptions.legend.display = false;

    this.buildStyles();
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  getListTinh(callback?: any) {
    var obj = { page: 1, size: 100 };
    this.subscription.push(this.dmchungService.getListTinh(obj).subscribe({
      next: (rs) => {
        this.LIST_TINH = rs.data.length == 0 ? [] : rs.data;
        this.LIST_TINH.unshift({ MA_TINH: "", TEN_TINH: "Tất cả" });
        if (callback) { callback(); }
      }, error: (error) => {
        this.snackbar.showError(error.message, MESSAGE_TYPE.ERROR);
      }
    }));
  }

  getListHuyen(maTinh: any) {
    let idTinh = this.LIST_TINH.find(x => x.MA_TINH == maTinh).ID;
    if (!idTinh) {
      this.LIST_HUYEN = [{ MA_HUYEN: "", TEN_HUYEN: "Tất cả" }];
      this.LIST_XA = [{ MA_XA: "", TEN_XA: "Tất cả" }];
      this.formSearch.controls["maHuyen"].setValue("");
      this.formSearch.controls["maXa"].setValue("");
      return;
    }
    var obj = { page: 1, size: 100, idTinh: idTinh };
    this.subscription.push(this.dmchungService.getListHuyen(obj).subscribe({
      next: (rs) => {
        this.LIST_HUYEN = rs.data.length == 0 ? [] : rs.data;
        this.LIST_HUYEN.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
      }, error: (error) => {
        this.snackbar.showError(error.message, MESSAGE_TYPE.ERROR);
      }
    }));
  }

  getListXa(maHuyen: any) {
    let idHuyen = this.LIST_HUYEN.find(x => x.MA_HUYEN == maHuyen).ID;
    if (!idHuyen) {
      this.LIST_XA = [{ MA_XA: "", TEN_XA: "Tất cả" }];
      this.formSearch.controls["maXa"].setValue("");
      return;
    }
    var obj = { page: 1, size: 100, idTinh: 60, idHuyen: idHuyen };
    this.subscription.push(this.dmchungService.getListXa(obj).subscribe({
      next: (rs) => {
        this.LIST_XA = rs.data.length == 0 ? [] : rs.data;
        this.LIST_XA.unshift({ MA_XA: "", TEN_XA: "Tất cả" });
      }, error: (error) => {
        this.snackbar.showError(error.message, MESSAGE_TYPE.ERROR);
      }
    }));
  }

  getFilter() {
    let data = this.formSearch.getRawValue();
    let params: any = {
      maTinh: data.maTinh ?? 0,
      maHuyen: data.maHuyen ?? 0,
      maXa: data.maXa ?? 0
    }
    if (data.timeType == 0) {
      params.nam = data.nam;
    } else if (data.timeType == 1) {
      params.nam = data.nam;
      params.thang = data.thang;
    } else if (data.timeType == 2) {
      params.nam = data.nam;
      params.quy = data.quy;
    } else if (data.timeType == 3) {
      params.nam = data.nam;
      params.tuNgay = moment(data.tuNgay).format('DD/MM/YYYY');
      params.denNgay = moment(data.denNgay).format('DD/MM/YYYY');
    }
    return params;
  }

  getData() {
    this.tKeTyLeDanSoTuNhien();
    this.tKeTyLeDanSoGioiTinh();
    this.tKeTyLeDanSoTranhThai();
    this.tKeDanSoTuoiLaoDong();
    this.tKeDanSoTrinhDoVanHoa();
  }

  tKeTyLeDanSoTuNhien(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.chiCucDanSoService.getTyLeDanSoTuNhien(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let data: any[] = [];
      rs.data.forEach((item: any) => {
        let element = data.find(x => x.TEN_RUT_GON == item.TEN_RUT_GON);
        if (!element) {
          data.push({
            TEN_RUT_GON: item.TEN_RUT_GON,
            HIEN_TAI: item.NAM == obj.nam ? item.CHI_TIEU : 0,
            CUNG_KY: item.NAM == (obj.nam - 1) ? item.CHI_TIEU : 0,
          });
        } else {
          if (item.NAM == obj.nam) {
            element.HIEN_TAI = item.CHI_TIEU;
          } else if (item.NAM == (obj.nam - 1)) {
            element.CUNG_KY = item.CHI_TIEU;
          }
        }
      });

      this.tkTyLeDanSo.barChartLabels = data.map((x) => x.TEN_RUT_GON);
      this.tkTyLeDanSo.barChartData = [
        {
          data: data.map((x) => x.HIEN_TAI),
          label: "Hiện tại",
          backgroundColor: "#008000",
        },
        {
          data: data.map((x) => x.CUNG_KY),
          label: "Cùng kỳ",
          backgroundColor: "#ffa500",
        },
      ];
    }, (err) => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  tKeTyLeDanSoGioiTinh(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.chiCucDanSoService.getTyLeDanSoGioiTinh(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let data: any[] = [];
      rs.data.forEach((item: any) => {
        let element = data.find(x => x.TEN_RUT_GON == item.TEN_RUT_GON);
        if (!element) {
          data.push({
            TEN_RUT_GON: item.TEN_RUT_GON,
            HIEN_TAI: item.NAM == obj.nam ? (item.CHI_TIEU_NAM + item.CHI_TIEU_NU) : 0,
            CUNG_KY: item.NAM == (obj.nam - 1) ? (item.CHI_TIEU_NAM + item.CHI_TIEU_NU) : 0,
          });
        } else {
          if (item.NAM == obj.nam) {
            element.HIEN_TAI = (item.CHI_TIEU_NAM + item.CHI_TIEU_NU);
          } else if (item.NAM == (obj.nam - 1)) {
            element.CUNG_KY = (item.CHI_TIEU_NAM + item.CHI_TIEU_NU);
          }
        }
      });

      this.tkTyLeDanSoGioiTinh.barChartLabels = data.map((x) => x.TEN_RUT_GON);
      this.tkTyLeDanSoGioiTinh.barChartData = [
        {
          data: data.map((x) => x.HIEN_TAI),
          label: "Hiện tại",
          backgroundColor: "#fa728a",
        },
        {
          data: data.map((x) => x.CUNG_KY),
          label: "Cùng kỳ",
          backgroundColor: "#08dbff",
        },
      ];
    }, (err) => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  tKeTyLeDanSoTranhThai(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.chiCucDanSoService.getTyLeDanSoBpTranhThai(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      let data: any[] = [];
      rs.data.forEach((item: any) => {
        let element = data.find(x => x.TEN_RUT_GON == item.TEN_RUT_GON);
        if (!element) {
          data.push({
            TEN_RUT_GON: item.TEN_RUT_GON,
            HIEN_TAI: item.NAM == obj.nam ? item.CHI_TIEU : 0,
            CUNG_KY: item.NAM == (obj.nam - 1) ? item.CHI_TIEU : 0,
          });
        } else {
          if (item.NAM == obj.nam) {
            element.HIEN_TAI = item.CHI_TIEU;
          } else if (item.NAM == (obj.nam - 1)) {
            element.CUNG_KY = item.CHI_TIEU;
          }
        }
      });

      this.tkTyLeDanSoTranhThai.barChartLabels = data.map((x) => x.TEN_RUT_GON);
      this.tkTyLeDanSoTranhThai.barChartData = [
        {
          data: data.map((x) => x.HIEN_TAI),
          label: "Hiện tại",
          backgroundColor: "#0000ff",
        },
        {
          data: data.map((x) => x.CUNG_KY),
          label: "Cùng kỳ",
          backgroundColor: "#800080",
        },
      ];
    }, (err) => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  tKeDanSoTuoiLaoDong(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.chiCucDanSoService.getTyLeDanSoTuoiLaoDong(obj).subscribe({
      next: (rs) => {
        if (!rs.success) {
          return false;
        }
        this.tkTyLeDanSoTuoiLD.barChartLabels = rs.data.map((x) =>
          this.commonService.convertStringToArray(
            " ",
            x.TEN_RUT_GON.replace(/huyện/gi, "H.").replace(/thành phố/gi, "TP.").replace(/thị xã/gi, "TX."),
            2
          )
        );
        let data = rs.data.map((x) => x.CHI_TIEU);
        this.tkTyLeDanSoTuoiLD.barChartData = [
          {
            label: "Số lượng",
            data: data,
            backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[4],
            datalabels: { align: "end", anchor: "end", clamp: true },
          },
        ];
      }, error: (error) => {
        this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
      }
    }));
  }

  tKeDanSoTrinhDoVanHoa(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.chiCucDanSoService.getTyLeDanSoTrinhDoVanHoa(obj).subscribe({
      next: (rs) => {
        if (!rs.success) {
          return false;
        }
        this.tkTyLeDanSoTDVH.barChartLabels = rs.data.map((x) =>
          this.commonService.convertStringToArray(
            " ",
            x.TEN_RUT_GON.replace(/huyện/gi, "H.").replace(/thành phố/gi, "TP.").replace(/thị xã/gi, "TX."),
            2
          )
        );
        let data = rs.data.map((x) => x.CHI_TIEU);
        this.tkTyLeDanSoTDVH.barChartData = [
          {
            label: "Số lượng",
            data: data,
            backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[6],
            datalabels: { align: "end", anchor: "end", clamp: true },
          },
        ];
      }, error: (error) => {
        this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
      }
    }));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
