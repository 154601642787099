<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year"
                label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn tháng" [data]="MONTHS"
                          [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()"
                          keyId="id" keyName="text">
          </ngx-dropdown>
          <!-- <div  class="ml-auto mr-3">
            <mat-form-field  class="w-100 mb-0" appearance="outline">
                <mat-label>Chọn tháng</mat-label>
                <mat-select panelClass="dark-mode" (selectionChange)="getData()" formControlName="thang">
                    <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        </div>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>
    <div [ngStyle]="this.rowStyles">
      <div class="row small-gutters h-100">
        <div [ngClass]="isFitPage ? 'col-6 h-100 mt-3' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Chiến dịch bổ sung vitamin A cho Trẻ em</h4>
            <div class="h-100 pt-2" style="overflow-y: auto;">
              <div class="h-100" style="width: 120%;">
                <app-bar-chart #treEm></app-bar-chart>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="isFitPage ? 'col-6 h-100 mt-3' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Chiến dịch bổ sung vitamin A cho Bà mẹ sau sinh</h4>
            <div class="h-100 pt-2" style="overflow-y: auto;">
              <div class="h-100" style="width: 120%;">
                <app-bar-chart #baMe></app-bar-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



