import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ChiCucDanSoService extends BaseService {

  getTKDanSoTheoHuyen(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_DAN_SO_THEO_HUYEN, requestData);
  }

  getTKDanSoTheoHuyenHgi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_DAN_SO_THEO_HUYEN_HGI, requestData);
  }

  getTLDanSoGioiTinh(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TL_GIOI_TINH, requestData);
  }

  getTLDanSoGioiTinhHgi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TL_GIOI_TINH_HGI, requestData);
  }

  getTLDanSoTTNT(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TL_THANHTHI_NONGTHON, requestData);
  }

  getTLDanSoSangLoc(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TL_SANG_LOC_SO_SINH_TRUOC_SINH, requestData);
  }

  getTKGTNhomTuoi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_GT_NHOM_TUOI, requestData);
  }

  getTKGTNhomTuoiHgi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_GT_NHOM_TUOI_HGI, requestData);
  }

  getTKSDBPTranhThai(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_SD_TRANH_THAI, requestData);
  }

  getTKSDBPTranhThaiHgi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_SD_TRANH_THAI_HGI, requestData);
  }

  getTKSinhConThu3(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_SINH_CON_THU_3, requestData);
  }

  getTKSinhConThu3Hgi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_SINH_CON_THU_3_HGI, requestData);
  }
  //hgi
  getTKQuyMoChatLuongDoTuoi(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_QUYMO_CHATLUONG_DOTUOI, requestData);
  }

  getTyLeDanSoTuNhien(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_TY_LE_DAN_SO_TU_NHIEN, requestData);
  }

  getTyLeDanSoGioiTinh(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_TY_LE_DAN_SO_GIOI_TINH, requestData);
  }

  getTyLeDanSoBpTranhThai(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_TY_LE_DAN_SO_BP_TRANH_THAI, requestData);
  }

  getTyLeDanSoTuoiLaoDong(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_TY_LE_DAN_SO_TUOI_LAO_DONG, requestData);
  }

  getTyLeDanSoTrinhDoVanHoa(requestData): Observable<any> {
    return super.get(apiUrl.CHI_CUC_DAN_SO.TK_TY_LE_DAN_SO_TRINH_DO_VAN_HOA, requestData);
  }
}
