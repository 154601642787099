import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TTPhapYServive } from 'src/app/services/trung-tam-phap-y.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PieChartComponent } from './../../../../shared/components/chart-js/pie-chart/pie-chart.component';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';


import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';

@Component({
  selector: 'app-tt-phap-y',
  templateUrl: './tt-phap-y.component.html',
  styleUrls: ['./tt-phap-y.component.scss']
})
export class TTPhapYComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  documentElement: any;
  isFullScreen = false;
  listQuy = [];
  listThang = [];
  listNam = [];
  listDataGiamDinhY =[];
  listDataGiamKhuyetTat =[];
  showNam = false;
  showThang = false;
  showQuy = false;
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[1],
    COLOR_PALETTES.PALETTE_6[2],
    COLOR_PALETTES.RIVER_NIGHTS[3],
    COLOR_PALETTES.ORANGE_TO_PURPLE[4],
    COLOR_PALETTES.PALETTE_3[5],
    COLOR_PALETTES.PALETTE_3[6],
    COLOR_PALETTES.PALETTE_3[7],
    COLOR_PALETTES.RIVER_NIGHTS[8],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
  ];
  public formSearch = new FormGroup({
    nam: new FormControl(new Date().getFullYear()),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    loai: new FormControl(0),
  });

  @ViewChild("soCaGiamDinhPhapY", { static: true }) soCaGiamDinhPhapY: PieChartComponent;
  @ViewChild("bieuDoGiamDinh", { static: true }) bieuDoGiamDinh: BarChartComponent;

  constructor(
    private tTPhapY: TTPhapYServive,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.documentElement = document.documentElement;
    this.detectMode()
    this.getListNam()
    this.getListThang()
    this.getData()
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.soCaGiamDinhPhapY.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.soCaGiamDinhPhapY.pieChartOptions.legend.position = "right";
    this.soCaGiamDinhPhapY.pieChartType = "doughnut";
    this.bieuDoGiamDinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  toggleFullScreen() {
    this.documentElement = document.documentElement;
    if (!this.isFullScreen) {
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
  
  getListNam(){
    this.subscription.push(
      this.tTPhapY.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getListThang(){
    this.subscription.push(
      this.tTPhapY.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  onFilterChange (){
    this.detectMode
    this.getData();
  }
  public getFilter() {
    let nam  = this.formSearch.get("nam").value;
    // let thang  = this.formSearch.get("thang").value == "Tất cả"?"": this.formSearch.get("thang").value;
    let result = {
      nam: nam,
      thang: ''
    }
    return result;
  }
  public getTKNhanLuc(): void {
    var obj = { 
      nam : this.formSearch.get("nam").value
    };
    this.subscription.push(
      this.tTPhapY.getTKNhanLuc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.soCaGiamDinhPhapY.pieChartData = [];
            return false;
          }
          let tyLeNhanSu = data[0].NHAN_SU;
          let tyLeBacSi = data[0].BAC_SI;
          let tyLeGDVBacSi = data[0].GDV_BAC_SI;
          let tyLeGDVKiemNhiem = data[0].GDV_KIEM_NHIEM;
          let tyLeGDVKhac = data[0].GDV_KHAC;
          this.soCaGiamDinhPhapY.pieChartData = [
            tyLeNhanSu,
            tyLeBacSi,
            tyLeGDVBacSi,
            tyLeGDVKiemNhiem,
            tyLeGDVKhac
          ];
          this.soCaGiamDinhPhapY.pieChartLabels = [
            "Nhân sự",
            "Bác sĩ",
            "GDV Bác sĩ",
            "CDV kiêm nhiệm",
            "CDV khác",
          ];
          this.soCaGiamDinhPhapY.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_1 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKSoCaGiamDinhPhapY(){
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.tTPhapY.getTKSoCaGiamDinhPhapY(obj).subscribe(
        (rs) => {
          this.listDataGiamKhuyetTat = []
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.NHIEMVU == "Tình dục người lớn"),{COLOR:'#e72e3d'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.NHIEMVU == "Tình dục trẻ em"),{COLOR:'#e89632'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.NHIEMVU == "Thương tích"),{COLOR:'#e5bc30'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.NHIEMVU == "Tử thi"),{COLOR:'#e4e331'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.NHIEMVU == "Khác"),{COLOR:'#8cc63e'}))
          this.bieuDoGiamDinh.barChartLabels = this.listDataGiamKhuyetTat.map((x) =>x.NHIEMVU );
          
          this.bieuDoGiamDinh.barChartData = [
            {
              data: this.listDataGiamKhuyetTat.map((x) => Math.round(x.SO_LUONG)),
              // label: "Số tiền",
              backgroundColor: this.listDataGiamKhuyetTat.map((x) =>x.COLOR)
            }
          ];
          //Set option for chart
          this.bieuDoGiamDinh.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#ffffff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '1'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getData() {
    this.getTKNhanLuc()
    this.getTKSoCaGiamDinhPhapY()
  }

}
