<div class="bg-plum-plate vh-100">
    <div class="d-flex h-100 justify-content-center align-items-center">
        <div class="login-wrapper mat-app-background">
            <h1 class="title text-center">KHO DỮ LIỆU Y TẾ</h1>
            <h1 class="province font-weight-bold text-center">TỈNH VĨNH LONG</h1>
            <form [formGroup]="loginForm" id="loginForm" (submit)="login()">
                <mat-form-field class="w-100 my-3">
                    <mat-label>Tên tài khoản</mat-label>
                    <input matInput formControlName="username" placeholder="Nhập tên tài khoản">
                    <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                        Vui lòng nhập tên tài khoản
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Mật khẩu</mat-label>
                    <input matInput formControlName="password" type="password" placeholder="Nhập mật khẩu tài khoản">
                    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                        Vui lòng nhập mật khẩu
                    </mat-error>
                </mat-form-field>
                <div class="mt-3">
                    <button type="submit" mat-raised-button color="primary" class="btn-block">Đăng nhập</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #otpModalContent let-modal>
    <div class="modal-header">
      <h3 class="modal-title mb-0">Xác thực OTP</h3>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="formOTP" id="formOTP" (submit)="checkOTP()">
      <div class="modal-body">
        <div class="row mb-2">
          <div class="col col-lg-12 col-md-12 col-sm-12 text-center">
              <mat-label>Xin chào <span id="lbUsername" style="font-weight: 600;"> {{user_name}} </span></mat-label>
          </div>
      </div>
        <div class="row mb-2">
            <div class="col col-lg-12 col-md-12 col-sm-12 text-center">
                <mat-label>Mã OTP đã được gửi đến số điện thoại di động của bạn</mat-label>
                <input type="hidden" [formControl]="formOTP.controls.username"/>
            </div>
        </div>
        <div class="row">
          <div class="col col-lg-12 col-md-12 col-sm-12">
              <mat-form-field class="w-100 mb-2" appearance="outline">
                <mat-label>Mã OTP</mat-label>
                <input type="number" matInput [formControl]="formOTP.controls.otp"/>
              </mat-form-field>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-raised-button mat-button color="primary" form="formOTP" type="submit">Đăng nhập</button>
      </div>
    </form>
  </ng-template>