import { TaiChinhKeHoachService } from './../../../../services/tai-chinh-ke-hoach.service';
import { ToChucCanBoService } from "../../../../services/to-chuc-can-bo.service";
import { DmChungService } from 'src/app/services/dm-chung.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
  MA_DV_SO_Y_TE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { LineChartComponent } from 'src/app/shared/components/chart-js/line-chart/line-chart.component';
import { ColorService } from 'src/app/services/color.service';

@Component({
  selector: 'app-tai-san-nha-dat',
  templateUrl: './tai-san-nha-dat.component.html',
  styleUrls: ['./tai-san-nha-dat.component.scss']
})
export class TaiSanNhaDatComponent implements OnInit {
  subscription: Subscription[] = [];
  isFitPage = false;
  formSearch = new FormGroup({
    // nam: new FormControl(new Date().getFullYear()),
    // thang: new FormControl((new Date().getMonth() + 1).toString()),
    year: new FormControl(new Date().getFullYear()),
    donvi:new FormControl('')

  });

  isWithChartCT : any;
  lableDonVi = true;

  documentElement: any;
  isFullScreen = false;
  nam: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  YEARS: any[] = [];
  showNam = true;
  showThang = false;
  listThang = [];
  listNam = [];
  listDonVi=[];
public isNoneChart = false;
public isNoneChartNN = false;
public isNoneChartHD = false;
public isNoneChartCT = false;
public isNoneChartBS = false;

userStorage = JSON.parse(sessionStorage.user);
@ViewChild("tKeTSTHEONAM", { static: true })
tKeTSTHEONAM: BarChartComponent;
@ViewChild("tKeTSLAYDIENTICHDAT", { static: true })
tKeTSLAYDIENTICHDAT: BarChartComponent;
@ViewChild("tKeTSLAYNGUYENGIA", { static: true })
tKeTSLAYNGUYENGIA: BarChartComponent;
@ViewChild("tKeTSLAYHIENTRANGNHA", { static: true })
tKeTSLAYHIENTRANGNHA: BarChartComponent;
@ViewChild("tKeTSLAYHIENTRANGCONGTRINH", { static: true })
tKeTSLAYHIENTRANGCONGTRINH: BarChartComponent;
@ViewChild("tKeTSLAYDIENTICHDATVANHA", { static: true })
tKeTSLAYDIENTICHDATVANHA: LineChartComponent;
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

constructor(
  private activatedRoute: ActivatedRoute,
  private snackbar: SnackbarService,
  private dateAdapter: DateAdapter<Date>,
  private dmchungService: DmChungService,
  private taiChinhKeHoachService: TaiChinhKeHoachService,
  private color: ColorService
) {
  this.dateAdapter.setLocale("vi-VN")
}

ngOnInit(): void {
  this.documentElement = document.documentElement;
    this.detectMode();
    this.tKeTSLAYHIENTRANGNHA.barChartType = "horizontalBar";
    this.tKeTSLAYHIENTRANGCONGTRINH.barChartType= "horizontalBar";
    this.tKeTSLAYDIENTICHDATVANHA.lineChartOptions.legend.display = false;
    this.getListDonVi();
    this.getData();
    let currentDate = new Date();
    this.YEARS.push({ id: '', text: "Không chọn" });
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 1980; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

}
public detectMode() {
  let mode = this.activatedRoute.snapshot.params.mode;
  this.isFitPage = mode === null || mode !== "responsive";
  this.buildStyles();

  this.tKeTSTHEONAM.barChartOptions.maintainAspectRatio = !this.isFitPage;
  this.tKeTSLAYDIENTICHDAT.barChartOptions.maintainAspectRatio = !this.isFitPage;

  this.tKeTSLAYNGUYENGIA.barChartOptions.maintainAspectRatio = !this.isFitPage;
  // this.tKeTSLAYNGUYENGIA.barChartOptions.legend.position = "top";
  // this.tKeTSLAYNGUYENGIA.barChartOptions.legend.align = "start";
  // this.tKeTSLAYNGUYENGIA.barChartOptions.scales.xAxes[0].stacked = true;
  // this.tKeTSLAYNGUYENGIA.barChartOptions.scales.yAxes[0].stacked = true;

  this.tKeTSLAYHIENTRANGNHA.barChartOptions.maintainAspectRatio = !this.isFitPage;
  this.tKeTSLAYHIENTRANGNHA.barChartOptions.legend.position = "top";
  this.tKeTSLAYHIENTRANGNHA.barChartOptions.legend.align = "start";
  this.tKeTSLAYHIENTRANGNHA.barChartOptions.scales.xAxes[0].stacked = true;
  this.tKeTSLAYHIENTRANGNHA.barChartOptions.scales.yAxes[0].stacked = true;

  this.tKeTSLAYHIENTRANGCONGTRINH.barChartOptions.maintainAspectRatio = !this.isFitPage;
  this.tKeTSLAYHIENTRANGCONGTRINH.barChartOptions.legend.position = "top";
  this.tKeTSLAYHIENTRANGCONGTRINH.barChartOptions.legend.align = "start";
  this.tKeTSLAYHIENTRANGCONGTRINH.barChartOptions.scales.xAxes[0].stacked = true;
  this.tKeTSLAYHIENTRANGCONGTRINH.barChartOptions.scales.yAxes[0].stacked = true;


  this.tKeTSLAYDIENTICHDATVANHA.lineChartOptions.maintainAspectRatio = !this.isFitPage;
  // this.formSearch.controls.isFitPage.setValue(this.isFitPage);
}
buildStyles() {
  this.rowStyles = {};
  if (this.isFitPage) {
    let others =
      16 + // padding top
      43.75 +
      16 + // form height and its margin bottom
      16; // 2 rows spacing
    let rowHeight = "calc((100% - " + others + "px) / 2)";
    this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
  }
}
toggleFullScreen() {
  if (!this.isFullScreen) {

    this.documentElement.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
  this.isFullScreen = !this.isFullScreen;
}
onFullscreenChange(event) {
  let mainView = document.getElementsByClassName("main-wrapper")[0];
  if (!document.fullscreenElement) {
    // Xử lý khi thoát khỏi chế độ toàn màn hình
    mainView.classList.add("vh-100");
  }
}

onFilterChange (){
  this.getFilter_Dv()
  this.getData();
}
public getData(): void {
  this.thongKeTaiSanTheoNam();
  this.thongKeTaiSanDatLayDienTichDat();
  this.thongKeTaiSanDatLayNguyenGia();
  this.thongKeTaiSanDatLayHienTrangNha();
  this.thongKeTaiSanDatLayHienTrangCongTrinh();
  this.thongKeTaiSanTheoDienTichDatVaNha();

}
getListDonVi() {
  this.subscription.push(
    this.taiChinhKeHoachService.getDSDonVi().subscribe(
      (rs) => {
        this.listDonVi = rs.data;
        // Add a default value for 'Tổng quan'
        this.listDonVi.unshift({ ID:'', TEN_DON_VI: 'Tất cả đơn vị' });
      },
      (err) => {
        this.snackbar.showError(
          MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
          MESSAGE_TYPE.ERROR
        );
      }
    )
  );
}
getFilter_Dv() {
  let selectedDonVi = this.formSearch.get("donvi").value;
  let selectedYear = this.formSearch.get("year").value;

  if (selectedDonVi === '') {
    return { nam_duavao_sudung: selectedYear };
  }
  else if (selectedYear === '') {
    return { id_donvi_quanly: selectedDonVi };
  }
  else {
    let result = {
      id_donvi_quanly: selectedDonVi,
      nam_duavao_sudung: selectedYear
    };
    return result;
  }
}

donViCount: number = 0; // Khởi tạo biến đếm đơn vị

thongKeTaiSanTheoNam(): void {
  let obj: any = this.getFilter_Dv();
  this.subscription.push(
    this.taiChinhKeHoachService.getDSTAISANTheoNam(obj).subscribe(
      (rs: any) => {
        if (!rs.success) {
          return false;
        }
        this.donViCount = rs.data.length; // Lưu tổng số đơn vị hiện có
        this.tKeTSTHEONAM.barChartLabels = rs.data.map(x => x.TEN_DON_VI);
        let SLHienTai = rs.data.map(x => x.DIENTICH_DAT_S1);

        this.tKeTSTHEONAM.barChartData = [
          {
            data: SLHienTai,
            label: 'Diện tích đất',
            backgroundColor: COLOR_PALETTES.PALETTE_1[2],
            datalabels: {
              align: 'top',
              anchor: 'start',
              padding: 15,
            },
          },
        ];
      }, err => {
        this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
      })
  );
}
thongKeTaiSanDatLayDienTichDat():void
  {
    let obj: any = this.getFilter_Dv();
  this.subscription.push(
    this.taiChinhKeHoachService.getDSTAISANLayDienTichDat(obj).subscribe(
      (rs: any) => {
        if (!rs.success) {
          return false;
        }
        this.tKeTSLAYDIENTICHDAT.barChartLabels = rs.data.map(x => x.TEN_DON_VI);
        let DIENTICHXAYDUNGNHA = rs.data.map(x => x.DIENTICH_XAYDUNG_NHA_S2);
        let DIENTICHSANSUDUNGNHA = rs.data.map(x => x.DIENTICH_SAN_SUDUNG_NHA_S2);

        this.tKeTSLAYDIENTICHDAT.barChartData = [
          {
            data: DIENTICHXAYDUNGNHA,
            label: 'Diện tích xây dựng nhà',
            backgroundColor: COLOR_PALETTES.PALETTE_4[2],
            datalabels: {
              align: 'top',
              anchor: 'end',
              padding:0,
            },
          },
          {
            data: DIENTICHSANSUDUNGNHA,
            label: 'Diện tích sàn sử dụng nhà',
            backgroundColor: COLOR_PALETTES.PALETTE_1[3],
            datalabels: {
              align: 'top',
              anchor: 'end',
              padding:0,
            },

          }
        ];
      }, err => {
        this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
      })
  );
  }
thongKeTaiSanDatLayNguyenGia(): void {
    let obj: any = this.getFilter_Dv();
    this.subscription.push(
      this.taiChinhKeHoachService.getDSTAISANLayNguyenGiaNhaVaDat(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tKeTSLAYNGUYENGIA.barChartLabels = rs.data.map(x => x.TEN_DON_VI);
          let DIENTICHXAYDUNGNHA = rs.data.map(x => x.GIATRI_DAT_S1);
          let NGUYENGIA_NHA_S2 = rs.data.map(x => x.NGUYENGIA_NHA_S2);


          this.tKeTSLAYNGUYENGIA.barChartData = [
            {
              data: DIENTICHXAYDUNGNHA,
              label: 'Nguyên giá đất',
              backgroundColor: COLOR_PALETTES.PALETTE_2[2],
              datalabels: {
                align: 'top',
                anchor: 'center',
                padding: 0,
              },
            },
            {
              data: NGUYENGIA_NHA_S2,
              label: 'Nguyên giá nhà', // Đặt tên cho cột phụ mới
              backgroundColor: COLOR_PALETTES.PALETTE_3[3], // Chọn màu sắc mới
              datalabels: {
                align: 'top',
                anchor: 'center',
                padding: 0,
              },
            },
          ];
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        })
    );
  }

thongKeTaiSanDatLayHienTrangNha():void
  {
    let obj: any = this.getFilter_Dv();
    this.subscription.push(this.taiChinhKeHoachService.getDSTAISANLayHienTrangNha(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      this.tKeTSLAYHIENTRANGNHA.barChartLabels = rs.data.map(x =>x.TEN_DON_VI);

      let dtxd = rs.data.map(x => x.DIENTICH_XAYDUNG_NHA_1_S4);
      let dtssd = rs.data.map(x => x.DIENTICH_SANSUDUNG_NHA_1_S4);
      let sn = rs.data.map(x => x.SONGOI_NHA_S4);
      this.tKeTSLAYHIENTRANGNHA.barChartData = [

        { data: dtxd, label: 'Diện tích xây dựng', backgroundColor: COLOR_PALETTES.PALETTE_5[2] },
        { data: dtssd, label: 'Diện tích sàn sử dụng', backgroundColor: COLOR_PALETTES.PALETTE_6[2] },
        { data: sn, label: 'Số ngôi', backgroundColor: COLOR_PALETTES.PALETTE_3[1] },
      ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
thongKeTaiSanDatLayHienTrangCongTrinh():void
  {
    let obj: any = this.getFilter_Dv();
    this.subscription.push(this.taiChinhKeHoachService.getDSTAISANLayHienTrangCongTrinh(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      this.tKeTSLAYHIENTRANGCONGTRINH.barChartLabels = rs.data.map(x =>x.TEN_DON_VI);
      let sn = rs.data.map(x => x.DIENTICH_SAN_NHAXE_S4);
      let dtxd = rs.data.map(x => x.DIENTICH_XLNT_S4);

      this.tKeTSLAYHIENTRANGCONGTRINH.barChartData = [
        { data: sn, label: 'Diện tích sàn và nhà xe', backgroundColor: COLOR_PALETTES.PALETTE_3[4] },
        { data: dtxd, label: 'Diện tích xử lý nước thải', backgroundColor: COLOR_PALETTES.PALETTE_2[1] },

      ];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
  thongKeTaiSanTheoDienTichDatVaNha():void
  {
    let obj: any = this.getFilter_Dv();
    let listData = [];
    let arrDienTichDat = [];
    let arrDienTichXayDungNha = [];
    let arrDienTichSanSuDungNha = [];
    let arrDonVi = [];

    this.subscription.push(
      this.taiChinhKeHoachService.getDSTAISANLayDienTichDatVaNha(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrDienTichDat = rs.data.map((x) => Math.round(x.DIENTICH_DAT_S1));
              arrDienTichXayDungNha = rs.data.map((x) => Math.round(x.DIENTICH_XAYDUNG_NHA_S2));
              arrDienTichSanSuDungNha = rs.data.map((x) => Math.round(x.DIENTICH_SAN_SUDUNG_NHA_S2));

              arrDonVi.push(this.convertToArray(' ', this.reduceLabel(e.TEN_DON_VI), 2));
            });
          }
          // nếu lơn hơn 10 thì show scroll
          if(arrDonVi.length <= 10){
            this.isWithChartCT = "10"
          }else if(arrDonVi.length <= 50){
            this.isWithChartCT = "20"
          } else{
            this.isWithChartCT = "50"
          }
          //Set label for chart
          this.tKeTSLAYDIENTICHDATVANHA.lineChartLabels = arrDonVi
          //Set data for chart
          this.tKeTSLAYDIENTICHDATVANHA.lineChartData = [
            {
              data: arrDienTichDat,
              label: "Diện tích đất",
              fill: false,
              pointRadius: 4,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#118AB2",
            },
            {
              data: arrDienTichXayDungNha,
              label: "Diện tích xây dựng nhà",
              fill: false,
              pointRadius: 4,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#ff7d48",
            },
            {
              data: arrDienTichSanSuDungNha,
              label: "Diện tích sàn sử dụng nhà",
              fill: false,
              pointRadius: 4,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: "#ff0048",
            },

          ];

          this.tKeTSLAYDIENTICHDATVANHA.lineChartColors = [
            { borderColor: "#118AB2" },
            { borderColor: "#ff7d48" },
            { borderColor: "#ff0048" },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }


getNumber(value: any) {
  return new Intl.NumberFormat("vi-VN").format(Math.round(value));
}

getDynamicWidth(obj: any, percent: number) {
  let array: any[] = obj ? obj.data : [];
  let length = array ? array.length : 1;
  return "width: " + length * percent + "%";
}
private reduceLabel(label: string) {
  return label.replace(/huyện/gi, "")
              .replace(/thị xã/gi, "TX. ")
              .replace(/thành phố/gi, "TP. ");
}
private convertToArray(character: string, value: string, step?: number) {
  let array = value.split(character);
  let count = 0;
  let temp = '';
  let result = [];
  array.forEach(element => {
    temp += element + " ";
    count++;
    if (count == step ?? 1) {
      result.push(temp.trim());
      count = 0;
      temp = '';
    }
  });
  if (temp !== '') {
    result.push(temp);
  }
  return result;
}



}
