import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import {CDCService} from 'src/app/services/trung-tam-cdc.service';
import { DmChungService } from 'src/app/services/dm-chung.service';

@Component({
  selector: 'app-vitamin-a-hgi',
  templateUrl: './vitamin-a-hgi.component.html',
  styleUrls: ['./vitamin-a-hgi.component.scss']
})
export class VitaminAHgiComponent implements OnInit {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  // listThang = [];
  documentElement: any;
  isFullScreen = false;
  isWithChart1: any;
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
  });
  @ViewChild("treEm", { static: true }) treEm: BarChartComponent;
  @ViewChild("baMe", { static: true }) baMe: BarChartComponent;

  constructor(
    private cdcService: CDCService,
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear()-1;
    this.formSearch.controls["year"].setValue(currentYear+1);
    for (let i = 2018; i <= currentYear + 1; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    this.MONTHS.push({id:-1,text:"Tất cả"});
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    // for (let i = 1; i <= 12; i++) {
    //   this.MONTHS.push({ id: i, text: "Tháng " + i });
    // }
    this.detectMode();
    this.treEm.barChartOptions.legend.display = false;
    this.baMe.barChartOptions.legend.display = false;
    // this.getListThang();
  }
  onFilterChange (){
    this.getFilter();
    this.getData();

  }
  getData() {
    this.getVitaminABaMe()
    this.getVitaminATreEm()
   }
  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.treEm.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.baMe.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
  public getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if(this.formSearch.controls.month.value){
      params["thang"] = this.formSearch.controls.month.value==0?null:this.formSearch.controls.month.value;
    }
    return params;
  }
  // getListThang(){
  //   this.subscription.push(
  //     this.dmchungService.getListThang().subscribe(
  //       (rs) => {
  //         this.listThang = rs.data;
  //       },
  //       (err) => {
  //         this.snackbar.showError(
  //           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
  //           MESSAGE_TYPE.ERROR
  //         );
  //       }
  //     )
  //   );
  // }
  getVitaminATreEm() {
    let obj = this.getFilter();
    this.subscription.push(this.cdcService.getVitaminATreEm(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.treEm.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
      let data = rs.data.map(x => x.QUAN_LY);
      let dataThucHien = rs.data.map(x => x.THUC_HIEN);
      this.treEm.barChartData = [
        {
          data: data,
          label: 'Quản lý',
          backgroundColor: "#ff1aff",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: dataThucHien,
          label: 'Thực hiện',
          backgroundColor: "#4d4dff",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:0
          },

        }
      ];
      this.treEm.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }

        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,

              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }


  getVitaminABaMe() {
    let obj = this.getFilter();
    this.subscription.push(this.cdcService.getVitaminABaMe(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return false;
      }
      this.baMe.barChartLabels = rs.data.map(x => x.TEN_HUYEN);
      let data = rs.data.map(x => x.QUAN_LY);
      let dataThucHien = rs.data.map(x => x.THUC_HIEN);
      this.baMe.barChartData = [
        {
          data: data,
          label: 'Quản lý',
          backgroundColor: "#00b300",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:15,
          },
        },
        {
          data: dataThucHien,
          label: 'Thực hiện',
          backgroundColor: "#6666ff",
          datalabels: {
            align: 'top',
            anchor: 'end',
            padding:0
          },

        }
      ];
      this.baMe.barChartOptions = {
        layout: {
          padding: {
            top: 30
          }

        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#fff',
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                 fontColor: "#fff",
                 maxRotation: 0,
                 minRotation: 0,

              },
              afterTickToLabelConversion: function(data) {
                // custom function to split labels into multiple lines
                let xLabels = data.ticks;
                xLabels.forEach(function (value, index, array) {
                  let lines = value.split(' ');
                  array[index] = [];
                  let i = 0;
                  while (lines.length > 0) {
                    array[index].push(lines.shift());
                    if (i > 0) {
                      array[index][i] = ' ' + array[index][i];
                    }
                    i++;
                  }
                });
                data.ticks = [].concat.apply([], data.ticks);
              },
              gridLines: { display: false },
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: '',
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              stacked: false,
              ticks: {
                display:true,
                fontColor: "#fff",
                suggestedMin: 0,
              },
            },
          ],
        },
        plugins: {
          labels: false,
          datalabels: {
            // render: "value",
            color: "#fff",
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
              // or >= 1 or !== 0 or ...
            },
          },
        },
      };
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }
}

