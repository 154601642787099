import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { DmChungService } from 'src/app/services/dm-chung.service';
import { ThongTinChungService } from 'src/app/services/thong-tin-chung.service'
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LineChartComponent } from "src/app/shared/components/chart-js/line-chart/line-chart.component";
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { ColorService } from 'src/app/services/color.service';
@Component({
  selector: 'app-thong-tin-chung',
  templateUrl: './thong-tin-chung.component.html',
  styleUrls: ['./thong-tin-chung.component.scss'],
})
export class ThongTinChungComponent implements OnInit {
  subscription: Subscription[] = [];

  @ViewChild("raVaoNgayChart", { static: true })
  raVaoNgayChart: BarChartComponent;
  @ViewChild("noiTruNgoaiTruChart", { static: true })
  noiTruNgoaiTruChart: BarChartComponent;
  @ViewChild("capCuuTuVongChart", { static: true })
  capCuuTuVongChart: LineChartComponent;
  @ViewChild("luotKhamBenh", { static: true })
  luotKhamBenh: LineChartComponent;
  formSearch = new FormGroup({
    nam: new FormControl(new Date().getFullYear()),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    loai: new FormControl(3),
    huyen: new FormControl(""),
    tenhuyen: new FormControl(null),
    coSoKCB: new FormControl([""]),
    loaihinh: new FormControl(0),
    tuNgay:  new FormControl(new Date().toISOString()),
    denNgay:  new FormControl(new Date().toISOString()),
  });
  public DISTRICT_LIST: any[] = [];
  public DON_VI_LIST: any = [];
  documentElement: any;
  isFullScreen = false;
  params: any;
  keyword = "";
  currentPage = 1;
  totalPage: number;
  rowStyles: any = {};
  rowStylesFull: any = {};
  timeOut: any;
  listQuy = [];
  listThang = [];
  listNam = [];
  showNam = false;
  showThang = false;
  showQuy = false;
  showTuNgay = false;
  showDenNgay = false;
  isFitPage = false;
  isHuyen= false
  isLoaiHinh= false
  isWithChartKCB: any;
  isWithChartNT : any;
  isWithChartRV : any;
  isWithChartCT : any;
  isFullListCSYT = false;
  lableCoSoKCB = false;
  TK_TONG_SL_KB : any;
  SL_KHAMBENH_CAPTINH: any;
  SL_KHAMBENH_CAPHUYEN: any;
  SL_KHAMBENH_CAPXA: any;
  SL_KHAMBENH_TUNHAN: any;
  SL_CAPCUU: any;
  SL_TUVONG: any;
  SL_VAOVIEN: any;
  SL_RAVIEN: any;
  listRaVaoVien =[];
  listCSKCB = [];
  listHuyen = [];
  listHuyenUser = [];
  defaultHuyen =[]
  labelNgay: any;
  SL_NOI_TRU: any;
  SL_NGOAI_TRU: any;
  SL_KCB: any;
  SL_CHUYENVIEN: any;
  SL_CANANG_XINVE: any;
  SL_NOITRU_CUOIKY: any;
  screenWidth: number;
  screenHeight: number;
  classInvisible = 1;
  
  userStorage = JSON.parse(sessionStorage.user); //lấy user trong sessionStorage
  constructor(
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
    private thongTinChung : ThongTinChungService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    )
  { 
    this.dateAdapter.setLocale("vi-VN")
    this.SL_NOI_TRU = {
      SL_NOI_TRU: 0,
      SL_NOI_TRU_TRUNGUONG: 0,		
      SL_NOI_TRU_CAPTINH: 0,		
      SL_NOI_TRU_CAPHUYEN: 0,	
      SL_NOI_TRU_CAPXA: 0,
    }
    this.SL_NGOAI_TRU = {
      SL_NGOAI_TRU: 0,
      SL_NGOAI_TRU_TRUNGUONG: 0,	
      SL_NGOAI_TRU_CAPTINH: 0,	
      SL_NGOAI_TRU_CAPHUYEN: 0,	
      SL_NGOAI_TRU_CAPXA: 0,	
    }
    this.SL_KCB ={
      TONG_SL_KHAMBENH: 0,
      SL_KHAMBENH_TRUNGUONG: 0,
      SL_KHAMBENH_CAPTINH: 0,	
      SL_KHAMBENH_CAPHUYEN: 0,	
      SL_KHAMBENH_CAPXA: 0,	
    }
    this.SL_NOITRU_CUOIKY = {
      SL_NOI_TRU_CUOIKY:0,
    }
      
  }
  ngOnInit(): void {
    this.getDsHuyen()
    this.luotKhamBenh.lineChartOptions.legend.display = false;
    this.noiTruNgoaiTruChart.barChartOptions.legend.display = false;
    this.raVaoNgayChart.barChartOptions.legend.display = false;
    this.capCuuTuVongChart.lineChartOptions.legend.display = false;
    this.documentElement = document.documentElement;
    this.getListNam()
    this.getListThang()
    this.getListQuy()
    this.detectMode();
    this.onFilterChangeCombobox();
    
   
  }
  getFilter(){
      let loai_cbx = this.formSearch.get("loai").value;
      let nam  = this.formSearch.get("nam").value;
      let thang  = this.formSearch.get("thang").value == "Tất cả"?"0": this.formSearch.get("thang").value;
      let quy = this.formSearch.get("quy").value == "Tất cả"?"0": this.formSearch.get("quy").value;
      let tungay = this.formatDate(this.formSearch.get("tuNgay").value);
      let denngay = this.formatDate(this.formSearch.get("denNgay").value);
      let huyen = "";
      let cosokb = "";
      if (this.userStorage?.LEVEL_USER_GIA_TRI == "Admin" || this.userStorage?.MA_DON_VI == 89000) {
        huyen = this.formSearch.controls.huyen.value == ""?"0":this.formSearch.controls.huyen.value;
        cosokb = this.formSearch.controls.coSoKCB.value == ""?"0":this.formSearch.controls.coSoKCB.value;
      }else{
        huyen = this.userStorage?.MA_HUYEN
        cosokb =  this.formSearch.controls.coSoKCB.value == ""?this.userStorage?.MA_DON_VI:  this.formSearch.controls.coSoKCB.value
      }
      let loaihinh = this.formSearch.get("loaihinh").value;
      
      let result  =  { 
        loai_cbx: loai_cbx,
        nam: nam,
        thang:thang,
        quy: quy,
        tungay: tungay,
        denngay: denngay,
        huyen: huyen,
        loaihinh: loaihinh,
        cosokb: cosokb.toString()
      };
      return result;
  }
  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  toggleFullScreen() {
    if (!this.isFullScreen) {
      
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  
  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== 'responsive';
    this.luotKhamBenh.lineChartOptions.maintainAspectRatio = !this.isFitPage;
    this.raVaoNgayChart.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.noiTruNgoaiTruChart.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  ngAfterViewInit() {
  }
  getDsHuyen() {
    var obj = {
      keyword: "",
      page: 1,
      size: 20,
      idTinh: ID_TINH,
    };
    this.subscription.push(
      this.dmchungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.listHuyen = rs.data;
          if (this.userStorage?.LEVEL_USER_GIA_TRI == "Admin" || this.userStorage?.MA_DON_VI == 89000) {
            this.isHuyen= false
            this.listHuyen.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
            this.getListCSKCB();
          } else {
            this.formSearch.controls.huyen.setValue(
              this.userStorage?.MA_HUYEN
            );
            this.formSearch.controls.tenhuyen.setValue(
              this.listHuyen.find(element => element.MA_HUYEN == this.userStorage?.MA_HUYEN)?.TEN_HUYEN
            );
            this.getListCSKCB();
            this.formSearch.controls.coSoKCB.setValue(
              [this.userStorage?.MA_DON_VI]
            );
            this.isHuyen= true
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListCSKCB() {
    this.lableCoSoKCB = true
    let obj = {
      maHuyen: this.formSearch.controls.huyen.value ?? "",
      loaiHinh: this.formSearch.controls.loaihinh.value === 2?"": this.formSearch.controls.loaihinh.value
    };
    this.subscription.push(
      this.dmchungService.getListCoSoKCB(obj).subscribe(
        (rs: any) => {
          if (rs.data.length == 0) {
            this.listCSKCB = [];
            return false;
          }
          this.listCSKCB = rs.data;
          this.listCSKCB.forEach((element, index) => {
            if (element.MA_DON_VI == "89000") this.listCSKCB.splice(index, 1);
          });
        },
        (err) => {
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }
  getListNam(){
    this.subscription.push(
      this.dmchungService.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getListThang(){
    this.subscription.push(
      this.dmchungService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListQuy(){
    this.subscription.push(
      this.dmchungService.getListQuy().subscribe(
        (rs) => {
          this.listQuy = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  onFilterChangeLoaiHinh(){
    this.getListCSKCB();
    this.detectMode
    this.getData();
  }
  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    
    if(loai == 0){
      this.showNam = true
      this.showQuy = false
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 1 ){
      this.showNam = true
      this.showQuy = true
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 2){
      this.showNam = true
      this.showQuy = false
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else{
      this.showNam = false
      this.showQuy = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }
  onFilterChangeCSKB(){
    this.lableCoSoKCB= false
    this.detectMode
    this.getData();
  }
  onFilterChange (){
    this.detectMode
    this.getData();
  }
  getTKHSKhamBenh(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.getTKHSKhamBenh(obj).subscribe(
        (rs) => {
          this.SL_KCB = {
            TONG_SL_KHAMBENH: new Intl.NumberFormat("vi-VN").format(rs.data[0].TONG_SL_KHAMBENH),
            SL_KHAMBENH_TRUNGUONG: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_KHAMBENH_TRUNGUONG),
            SL_KHAMBENH_CAPTINH: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_KHAMBENH_CAPTINH),
            SL_KHAMBENH_CAPHUYEN: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_KHAMBENH_CAPHUYEN),
            SL_KHAMBENH_CAPXA: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_KHAMBENH_CAPXA),
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKSL_CAPCUU(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.getTKSLCapCuu(obj).subscribe(
        (rs) => {
          this.SL_CAPCUU = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SL_TU_CAPCU
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKSL_TuVong(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.getTKSLTuVong(obj).subscribe(
        (rs) => {
          this.SL_TUVONG = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SL_TU_VONG
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKChartRaVaoVien(){
    let obj: any = this.getFilter();
    let arrLuotRa = [];
    let arrLuotVao = [];
    let arrDonVi = [];
    this.isWithChartRV = "10"
    this.subscription.push(
      this.thongTinChung.GetTKSLRaVaoVien(obj).subscribe(
        (rs) => {
          this.listRaVaoVien = rs.data;
          if (this.listRaVaoVien && this.listRaVaoVien.length > 0) {
            this.listRaVaoVien.forEach((e) => {
              arrLuotRa.push(e.SO_LUONG_RA == null ? 0 : e.SO_LUONG_RA );
              arrLuotVao.push(e.SO_LUONG_VAO == null ? 0 : e.SO_LUONG_VAO);
              arrDonVi.push(e.TEN_DON_VI.replaceAll("Bệnh viện Đa Khoa", "BVĐK").replaceAll("Bệnh viện đa khoa", "BVĐK").replaceAll("Trung Tâm Y Tế", "TTYT").replaceAll("Trung tâm Y tế", "TTYT")
              .replaceAll("Trạm Y tế", "TYT").replaceAll("Trạm Y Tế", "TYT").replaceAll("Trạm y tế", "TYT").replaceAll("Phòng khám ĐK","PKĐK")
              .replaceAll("Phòng khám Đa khoa","PKĐK").replaceAll("Phòng khám đa khoa","PKĐK").replaceAll("thành phố","tp"));
            });
          }
          if(arrDonVi.length <= 10){
            this.isWithChartRV = "10"
          }else if(arrDonVi.length <= 50){
            this.isWithChartRV = "50"
          } else{
            this.isWithChartRV = "100"
          }
          //Set label for chart
          this.raVaoNgayChart.barChartLabels = arrDonVi;
          //Set data for chart
          this.raVaoNgayChart.barChartData = [
            {
              data: arrLuotRa,
              label: "Lượt ra viện",
              backgroundColor: COLOR_PALETTES.ORANGE_TO_PURPLE[1],
            },
            {
              data: arrLuotVao,
              label: "Lượt vào viện",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
            },
          ];
          //Set option for chart
          this.raVaoNgayChart.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              // labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    display:false,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
   
  }
  tkCapCuuTuVong() {
    let obj: any = this.getFilter();
    let listData = [];
    let arrCapCuu = [];
    let arrTuVong = [];
    let arrDonVi = [];
    this.isWithChartCT = "10"
    this.subscription.push(
      this.thongTinChung.GetTKSLCapCuuTuVong(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
              arrCapCuu.push(e.SO_LUONG_CAPCUU == null ? 0 : e.SO_LUONG_CAPCUU);
              arrTuVong.push(e.SO_LUONG_TUVONG == null ? 0 : e.SO_LUONG_TUVONG);
              arrDonVi.push(e.TEN_DON_VI.replaceAll("Bệnh viện Đa Khoa", "BVĐK").replaceAll("Bệnh viện", "BV").replaceAll("Bệnh viện đa khoa", "BVĐK").replaceAll("Trung Tâm Y Tế", "TTYT").replaceAll("Trung tâm Y tế", "TTYT")
              .replaceAll("Trạm Y tế", "TYT").replaceAll("Trạm Y Tế", "TYT").replaceAll("Trạm y tế", "TYT").replaceAll("Phòng khám ĐK","PKĐK")
              .replaceAll("Phòng khám Đa khoa","PKĐK").replaceAll("Phòng khám đa khoa","PKĐK").replaceAll("thành phố","tp"));
            });
          }
          // nếu lơn hơn 10 thì show scroll
          if(arrDonVi.length <= 10){
            this.isWithChartCT = "10"
          }else if(arrDonVi.length <= 50){
            this.isWithChartCT = "50"
          } else{
            this.isWithChartCT = "100"
          }
          //Set label for chart
          this.capCuuTuVongChart.lineChartLabels = arrDonVi
          //Set data for chart
          this.capCuuTuVongChart.lineChartData = [
            {
              data: arrCapCuu,
              label: "Lượt cấp cứu",
              backgroundColor: "#EB1D36",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
            },
            {
              data: arrTuVong,
              label: "Lượt tử vong",
              backgroundColor: "#de25da",
              fill: false,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
            },
          ];

          this.capCuuTuVongChart.lineChartColors = [
            { borderColor: "#EB1D36" },
            { borderColor: "#de25da" },
          ];

          //Set option for chart
          this.capCuuTuVongChart.lineChartOptions = {
            layout: {
              padding: {
                top: 30,
                right:20
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display:false
            },
            scales: {
              xAxes: [
                {
                  
                  gridLines: { display: false },
                  ticks: { 
                    display:true,
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: true,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] <= '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  tkLuotKhamBenh() {
    let obj: any = this.getFilter();
    let listData = [];
    let arrSoLuong = [];
    let arrDonVi = [];
    this.isWithChartKCB = "10"
    this.subscription.push(
      this.thongTinChung.GetTKKCB(obj).subscribe(
        (rs) => {
          listData = rs.data;
          if (listData && listData.length > 0) {
            listData.forEach((e) => {
             
              arrSoLuong.push(e.TONG_SL_KHAMBENH);
              arrDonVi.push(e.TEN_DON_VI.replaceAll("Bệnh viện Đa Khoa", "BVĐK").replaceAll("Bệnh viện", "BV").replaceAll("Bệnh viện", "BV").replaceAll("Bệnh viện đa khoa", "BVĐK").replaceAll("Trung Tâm Y Tế", "TTYT").replaceAll("Trung tâm Y tế", "TTYT")
              .replaceAll("Trạm Y tế", "TYT").replaceAll("Trạm Y Tế", "TYT").replaceAll("Trạm y tế", "TYT").replaceAll("Phòng khám ĐK","PKĐK")
              .replaceAll("Phòng khám Đa khoa","PKĐK").replaceAll("Phòng khám đa khoa","PKĐK").replaceAll("thành phố","tp"));
            });
          }
          if(arrDonVi.length <= 10){
            this.isWithChartKCB = "10"
          }else if(arrDonVi.length <= 50){
            this.isWithChartKCB = "50"
          } else{
            this.isWithChartKCB = "100"
          }
          // .map(x => this.convertToArray(' ', x))
          this.luotKhamBenh.lineChartLabels = arrDonVi;
          this.luotKhamBenh.lineChartData = [
            {
              data: arrSoLuong,
              label: "Số lượt khám",
              fill: true,
              pointRadius: 2,
              lineTension: 0,
              datalabels: { align: "end", anchor: "center" },
              backgroundColor: this.colorService.hexToRGB(
                COLOR_PALETTES.DUTCH_FIELD[8],
                0.3
              ),
            },
          ];
          this.luotKhamBenh.lineChartColors = [
            { borderColor: COLOR_PALETTES.DUTCH_FIELD[8] },
          ];
          this.luotKhamBenh.lineChartOptions = {
            layout: {
              padding: {
                top: 30, 
                right:20
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    display:false,
                    fontColor: "#fff",
                    suggestedMin: 0,
                    padding: 20,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                display: function (context) {
                  //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
                  return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getTKNoiTruNgoaiTru(){
    let obj: any = this.getFilter();
    let arrNoiTru = [];
    let arrNgoaiTru = [];
    let arrDonVi = [];
    this.isWithChartNT = "10"
    this.subscription.push(
      this.thongTinChung.GetTKNoiTruNgoaiTru(obj).subscribe(
        (rs) => {
          this.listRaVaoVien = rs.data;
          if (this.listRaVaoVien && this.listRaVaoVien.length > 0) {
            this.listRaVaoVien.forEach((e) => {
              arrNoiTru.push(e.SL_NOI_TRU == null ? 0 : e.SL_NOI_TRU );
              arrNgoaiTru.push(e.SL_NGOAI_TRU == null ? 0 : e.SL_NGOAI_TRU);
              arrDonVi.push(e.TEN_DON_VI.replaceAll("Bệnh viện Đa Khoa", "BVĐK").replaceAll("Bệnh viện", "BV").replaceAll("Bệnh viện đa khoa", "BVĐK").replaceAll("Trung Tâm Y Tế", "TTYT").replaceAll("Trung tâm Y tế", "TTYT")
              .replaceAll("Trạm Y tế", "TYT").replaceAll("Trạm Y Tế", "TYT").replaceAll("Trạm y tế", "TYT").replaceAll("Phòng khám ĐK","PKĐK")
              .replaceAll("Phòng khám Đa khoa","PKĐK").replaceAll("Phòng khám đa khoa","PKĐK").replaceAll("thành phố","tp"));
            });
          }
          // nếu lơn hơn 10 thì show scroll
          if(arrDonVi.length <= 10){
            this.isWithChartNT = "10"
          }else if(arrDonVi.length <= 50){
            this.isWithChartNT = "50"
          } else{
            this.isWithChartNT = "100"
          }

          //Set label for chart
          this.noiTruNgoaiTruChart.barChartLabels = arrDonVi;
          //Set data for chart
          this.noiTruNgoaiTruChart.barChartData = [
            {
              data: arrNoiTru,
              label: "Nội trú",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[4],
            },
            {
              data: arrNgoaiTru,
              label: "Ngoại trú",
              backgroundColor: COLOR_PALETTES.BLUES[4],
            },
          ];
          //Set option for chart
          this.noiTruNgoaiTruChart.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    display:false,
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getSLVaoVien(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLVaoVien(obj).subscribe(
        (rs) => {
          this.SL_VAOVIEN = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SO_LUONG_VAO
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getSLRaVien(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLRaVien(obj).subscribe(
        (rs) => {
          this.SL_RAVIEN = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SO_LUONG_RA
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  GetSLCaNangXinVe(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLCaNangXinVe(obj).subscribe(
        (rs) => {
          this.SL_CANANG_XINVE = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SL_BENH_NANG_XIN_VE
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  GetSLChuyenVien(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLChuyenVien(obj).subscribe(
        (rs) => {
          this.SL_CHUYENVIEN = new Intl.NumberFormat("vi-VN").format(
            rs.data[0].SL_CHUYEN_VIEN
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  // getSLNoiTruNgoaiTru(){
  //   let obj: any = this.getFilter();
  //   this.subscription.push(
  //     this.thongTinChung.GetSLNoiTruNgoaiTru(obj).subscribe(
  //       (rs) => {
  //         this.SL_NOITRU_NGOAI_TRU = {
  //           SL_NOI_TRU: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU),
  //           SL_NGOAI_TRU: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU),
  //           SL_NGOAI_TRU_TRUNGUONG :  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_TRUNGUONG),
  //           SL_NOI_TRU_TRUNGUONG:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_TRUNGUONG),
  //           SL_NGOAI_TRU_CAPTINH :  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPTINH),
  //           SL_NOI_TRU_CAPTINH:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPTINH),
  //           SL_NGOAI_TRU_CAPHUYEN:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPHUYEN),
  //           SL_NOI_TRU_CAPHUYEN:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPHUYEN),
  //           SL_NGOAI_TRU_CAPXA:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPXA),
  //           SL_NOI_TRU_CAPXA:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPXA),
  //         }
  //       },
  //       (err) => {
  //         this.snackbar.showError(
  //           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
  //           MESSAGE_TYPE.ERROR
  //         );
  //       }
  //     )
  //   );
  // }
  getSLCuoiKyNoiTru(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLCuoiKyNoiTruNew(obj).subscribe(
        (rs) => {
          this.SL_NOITRU_CUOIKY = {
            SL_NOI_TRU_CUOIKY: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CUOIKY),
            
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
 
  GetSLNoiTruNew(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLNgoaiTruNew(obj).subscribe(
        (rs) => {
          this.SL_NGOAI_TRU = {
            SL_NGOAI_TRU: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU),
            SL_NGOAI_TRU_TRUNGUONG:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_TRUNGUONG),
            SL_NGOAI_TRU_CAPTINH:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPTINH),
            SL_NGOAI_TRU_CAPHUYEN:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPHUYEN),
            SL_NGOAI_TRU_CAPXA:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NGOAI_TRU_CAPXA),
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  GetSLNgoaiTruNew(){
    let obj: any = this.getFilter();
    this.subscription.push(
      this.thongTinChung.GetSLNoiTruNew(obj).subscribe(
        (rs) => {
          this.SL_NOI_TRU = {
            SL_NOI_TRU: new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU),
            SL_NOI_TRU_TRUNGUONG:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_TRUNGUONG),
            SL_NOI_TRU_CAPTINH:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPTINH),
            SL_NOI_TRU_CAPHUYEN:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPHUYEN),
            SL_NOI_TRU_CAPXA:  new Intl.NumberFormat("vi-VN").format(rs.data[0].SL_NOI_TRU_CAPXA),
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getData() {
   
    this.getTKHSKhamBenh()
    this.GetSLNoiTruNew()
    this.getSLCuoiKyNoiTru()
    this.GetSLNgoaiTruNew()
    this.getTKNoiTruNgoaiTru()
    this.getSLVaoVien()
    this.getSLRaVien()
    this.GetSLCaNangXinVe()
    this.GetSLChuyenVien()
    this.tkLuotKhamBenh()
    this.getTKChartRaVaoVien()
    this.getTKSL_CAPCUU()
    this.getTKSL_TuVong()
    this.tkCapCuuTuVong()
    
  }
  
}
