import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { COLOR_PALETTES } from 'src/app/constant/system-constant';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { LineChartComponent } from 'src/app/shared/components/chart-js/line-chart/line-chart.component';
import { MeterHighchartComponent } from 'src/app/shared/components/highcharts/meter-highchart/meter-highchart.component';

@Component({
  selector: 'app-qd4210',
  templateUrl: './qd4210.component.html',
  styleUrls: ['./qd4210.component.scss']
})
export class QD4210Component implements OnInit {

  @ViewChild("chart1", { static: true }) chart1: BarChartComponent;
  public barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartData1: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', backgroundColor: COLOR_PALETTES.DUTCH_FIELD[0] },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1] }
  ];

  @ViewChild("chart2", { static: true }) chart2: BarChartComponent;
  public barChartLabels2: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[2] }
  ];

  @ViewChild("chart3", { static: true }) chart3: LineChartComponent;
  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', fill: false, pointRadius: 5, lineTension: 0 },
  ];
  public lineChartColors: Color[] = [{ borderColor: COLOR_PALETTES.DUTCH_FIELD[6], backgroundColor: 'rgba(255,0,0,0.3)'} ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  @ViewChild("chart4", { static: true }) chart4: MeterHighchartComponent;
  public data1 = 65;
  public color1 = COLOR_PALETTES.RIVER_NIGHTS[4];

  @ViewChild("chart5", { static: true }) chart5: MeterHighchartComponent;
  public data2 = 34;
  public color2 = COLOR_PALETTES.SPRING_PASTELS[0];

  constructor() {
  }

  ngOnInit(): void {
  }

}
