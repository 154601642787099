import { PieChartComponent } from "./../../../../shared/components/chart-js/pie-chart/pie-chart.component";
import { ChiCucDanSoService } from "./../../../../services/chi-cuc-dan-so.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { SnackbarService } from "src/app/services/snackbar.service";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { CommonFunctionService } from "src/app/services/common-function.service";

@Component({
  selector: "app-thong-ke-dan-so",
  templateUrl: "./thong-ke-dan-so.component.html",
  styleUrls: ["./thong-ke-dan-so.component.scss"],
})
export class ThongKeDanSoComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};

  @ViewChild("tkDanSoTheoHuyen", { static: true })
  tkDanSoTheoHuyen: BarChartComponent;
  @ViewChild("tyLeGioiTinh", { static: true })
  tyLeGioiTinh: PieChartComponent;
  @ViewChild("tyLeTTNT", { static: true })
  tyLeTTNT: PieChartComponent;
  @ViewChild("tkGTNhomTuoi", { static: true })
  tkGTNhomTuoi: BarChartComponent;
  @ViewChild("tkSDBPTranhThai", { static: true })
  tkSDBPTranhThai: BarChartComponent;
  @ViewChild("tkSinhConThu3", { static: true })
  tkSinhConThu3: BarChartComponent;


  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private chiCucDanSoService: ChiCucDanSoService,
    private commonService: CommonFunctionService
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear-1);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    this.detectMode();
    this.getData();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.tkDanSoTheoHuyen.barChartOptions.scales.yAxes[0].ticks.beginAtZero = true;
    this.tkDanSoTheoHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkDanSoTheoHuyen.barChartOptions.legend.display = false;
    this.tyLeGioiTinh.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeGioiTinh.pieChartOptions.legend.position = "right";
    this.tyLeTTNT.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeTTNT.pieChartOptions.legend.position = "right";
    this.tkGTNhomTuoi.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkGTNhomTuoi.barChartType = "horizontalBar";
    this.tkGTNhomTuoi.barChartOptions.scales.yAxes[0].stacked = true;
    // this.tkGTNhomTuoi.barChartOptions.scales.yAxes[0].ticks.beginAtZero = true;
    this.tkGTNhomTuoi.barChartOptions.scales.xAxes[0].stacked = false;
    // this.tkGTNhomTuoi.barChartOptions.scales.xAxes[0].ticks.beginAtZero = true;

    this.tkSinhConThu3.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkSinhConThu3.barChartOptions.legend.display = false;
    this.tkSDBPTranhThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkSDBPTranhThai.barChartOptions.legend.display = false;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }

    return params;
  }

  getData(): void {
    this.tKeDanSoTheoHuyen();
    this.tlDanSoTheoGioiTinh();
    this.tlDanSoTheoTTNT();
    this.tKeGTNhomTuoi();
    this.tKeSDBPTranhThai();
    this.tKeSinhConThu3();
  }

  tKeDanSoTheoHuyen(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.chiCucDanSoService.getTKDanSoTheoHuyen(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkDanSoTheoHuyen.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.LABEL.replace(/Thành phố/gi, "TP.").replace(/Thị xã/gi, "TX."),
              2
            )
          );
          let data = rs.data.map((x) => Math.round(x.VALUE));
          this.tkDanSoTheoHuyen.barChartData = [
            {
              data: data,
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[4],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tlDanSoTheoGioiTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.chiCucDanSoService.getTLDanSoGioiTinh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tyLeGioiTinh.pieChartData = [];
            return false;
          }
          let tyLeNam = data[0].NAM;
          let tyLeNu = data[0].NU;

          this.tyLeGioiTinh.pieChartData = [tyLeNam, tyLeNu];
          this.tyLeGioiTinh.pieChartLabels = ["Nam", "Nữ"];
          this.tyLeGioiTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.SPRING_PASTELS },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tlDanSoTheoTTNT(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.chiCucDanSoService.getTLDanSoTTNT(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tyLeTTNT.pieChartData = [];
            return false;
          }
          let tyLeTT = data[0].THANH_THI;
          let tyLeNT = data[0].NONG_THON;

          this.tyLeTTNT.pieChartData = [tyLeTT, tyLeNT];
          this.tyLeTTNT.pieChartLabels = ["Thành thị", "Nông thôn"];
          this.tyLeTTNT.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeGTNhomTuoi(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.chiCucDanSoService.getTKGTNhomTuoi(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          // this.tkGTNhomTuoi.barChartLabels = rs.data.map((x) => x.LABEL);
          this.tkGTNhomTuoi.barChartLabels = [
            "80+",
            "75-79",
            "70-74",
            "65-69",
            "60-64",
            "55-59",
            "50-54",
            "45-49",
            "40-44",
            "35-39",
            "30-34",
            "25-29",
            "20-24",
            "15-19",
            "10-14",
            "5-9",
            "0-4",
          ];
          let label = rs.data.map((x) => x.LABEL);
          let arrN1 = rs.data.map((x) => Math.round(x.TREN_80));
          let arrN2 = rs.data.map((x) => Math.round(x.TU_75_DEN_79));
          let arrN3 = rs.data.map((x) => Math.round(x.TU_70_DEN_74));
          let arrN4 = rs.data.map((x) => Math.round(x.TU_65_DEN_69));
          let arrN5 = rs.data.map((x) => Math.round(x.TU_60_DEN_64));
          let arrN6 = rs.data.map((x) => Math.round(x.TU_55_DEN_59));
          let arrN7 = rs.data.map((x) => Math.round(x.TU_50_DEN_54));
          let arrN8 = rs.data.map((x) => Math.round(x.TU_45_DEN_49));
          let arrN9 = rs.data.map((x) => Math.round(x.TU_40_DEN_44));
          let arrN10 = rs.data.map((x) => Math.round(x.TU_35_DEN_39));
          let arrN11 = rs.data.map((x) => Math.round(x.TU_30_DEN_34));
          let arrN12 = rs.data.map((x) => Math.round(x.TU_25_DEN_29));
          let arrN13 = rs.data.map((x) => Math.round(x.TU_20_DEN_24));
          let arrN14 = rs.data.map((x) => Math.round(x.TU_15_DEN_19));
          let arrN15 = rs.data.map((x) => Math.round(x.TU_10_DEN_14));
          let arrN16 = rs.data.map((x) => Math.round(x.TU_5_DEN_9));
          let arrN17 = rs.data.map((x) => Math.round(x.TU_0_DEN_4));

          let arrData = [arrN1[0], arrN2[0], arrN3[0], arrN4[0], arrN5[0], arrN6[0], arrN7[0], arrN8[0], arrN9[0], arrN10[0], arrN11[0], arrN12[0], arrN13[0], arrN14[0], arrN15[0], arrN16[0], arrN17[0]];
          let arrConvert = [];
          arrData.forEach(e => arrConvert.push(e * -1));

          this.tkGTNhomTuoi.barChartData = [
            {
              data: arrConvert,
              label: label[0] ? label[0] : "",
              backgroundColor: COLOR_PALETTES.PALETTE_1[0]
            },
            {
              data: arrData,
              label: label[1] ? label[1] : "",
              backgroundColor: COLOR_PALETTES.PALETTE_1[1]
            },
          ];
        },


        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeSDBPTranhThai(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.chiCucDanSoService.getTKSDBPTranhThai(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data.sort((a, b) => a.VALUE - b.VALUE);
          this.tkSDBPTranhThai.barChartLabels = data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.LABEL.replace(/Thành phố/gi, "TP.").replace(/Thị xã/gi, "TX."),
              2
            )
          );
          
          let dataf = data.map((x) => Math.round(x.VALUE)).sort((a, b) => a - b);
          this.tkSDBPTranhThai.barChartData = [
            {
              data: dataf,
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[3],
              datalabels: { align: "end", anchor: "end", clamp: true },
              order:1
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeSinhConThu3(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.chiCucDanSoService.getTKSinhConThu3(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tkSinhConThu3.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.LABEL.replace(/Thành phố/gi, "TP.").replace(/Thị xã/gi, "TX."),
              2
            )
          );
          let data = rs.data.map((x) => Math.round(x.VALUE));
          this.tkSinhConThu3.barChartData = [
            {
              data: data,
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[8],
              datalabels: { align: "end", anchor: "end", clamp: true,  },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

}
