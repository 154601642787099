import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MA_DV_SO_Y_TE,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { AggService } from "src/app/services/agg.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { LineChartComponent } from "src/app/shared/components/chart-js/line-chart/line-chart.component";
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";
import { PieHighchartComponent } from "src/app/shared/components/highcharts/pie-highchart/pie-highchart.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DmChungService } from "src/app/services/dm-chung.service";
import { DateAdapter } from "@angular/material/core";
import { CommonFunctionService } from "src/app/services/common-function.service";

@Component({
  selector: "app-thong-tin-chung-p2",
  templateUrl: "./thong-tin-chung-p2.component.html",
  styleUrls: ["./thong-tin-chung-p2.component.scss"],
})
export class ThongTinChungP2Component implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public isFitPage = false;
  public rowStyles: any = {};
  public isNoteHidden = true;
  public noteContent;

  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public QUARTERS: any[] = [];
  public DISTRICT_LIST: any[] = [];
  public DON_VI_LIST: any = [];
  public SUMMARY_INFO: any;
  public listBenhPhoBien: any[] = [];
  selectAll = false;
  LOAI_HINH_LIST: any[] = [];
  userStorage = JSON.parse(sessionStorage.user);
  isHuyen = true;

  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(1, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    day: new FormControl(new Date(), [Validators.required]),
    tuNgay: new FormControl(new Date(), [Validators.required]),
    denNgay: new FormControl(new Date(), [Validators.required]),
    quarter: new FormControl(""),
    district: new FormControl(""),
    coSoKCB: new FormControl(),
    loaiHinh: new FormControl(0),
  });

  public CARD_COLORS: any[] = COLOR_PALETTES.PALETTE_7;

  COST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_4[0],
    COLOR_PALETTES.PALETTE_4[1]
  ];

  @ViewChild("khamChuaBenhBaoHiem", { static: true })
  khamChuaBenhBaoHiem: PieChartComponent;
  @ViewChild("nhomTuoiKCB", { static: true }) nhomTuoiKCB: PieChartComponent;
  @ViewChild("cacBenhPhoBien", { static: true })
  cacBenhPhoBien: BarChartComponent;
  @ViewChild("chiPhiKhamChuaBenh", { static: true })
  chiPhiKhamChuaBenh: BarChartComponent;
  @ViewChild("taiNanThuongTich", { static: true })
  taiNanThuongTich: LineChartComponent;
  // @ViewChild("chiPhiKhamChuaBenh", { static: true })
  // chiPhiKhamChuaBenh: PieHighchartComponent;
  @ViewChild("chiPhiKCBTheoDV", { static: true })
  chiPhiKCBTheoDV: BarChartComponent;

  constructor(
    private aggService: AggService,
    private dmService: DmChungService,
    private snackbar: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private commonService: CommonFunctionService
  ) {
    this.dateAdapter.setLocale("vi-VN");
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }

    this.detectMode();

    this.SUMMARY_INFO = {
      HO_SO_RA_VIEN: 0,
      CO_BAO_HIEM: 0,
      KHONG_BAO_HIEM: 0,
      TONG_CHI_PHI: 0,
      TOTAL_BHTT: 0,
      TOTAL_BNTT: 0,
    };

    this.LOAI_HINH_LIST = [
      { MA_LH: 2, TEN_LH: "Tất cả" },
      { MA_LH: 0, TEN_LH: "Công lập" },
      { MA_LH: 1, TEN_LH: "Tư nhân" },
    ];
    this.chiPhiKhamChuaBenh.barChartOptions.legend.display = false;

    this.chiPhiKhamChuaBenh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.nhomTuoiKCB.pieChartType = "doughnut";
    this.nhomTuoiKCB.pieChartOptions.legend.position = "right";

    this.cacBenhPhoBien.barChartOptions.legend = { display: false };
    this.cacBenhPhoBien.barChartType = "horizontalBar";

    // this.chiPhiKhamChuaBenh.seriesName = "Tỷ lệ";

    this.getDistricts(() => {
      this.getData();
      this.getListCSKCB();
    });
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.khamChuaBenhBaoHiem.pieChartOptions.maintainAspectRatio =
      !this.isFitPage;
    this.nhomTuoiKCB.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.cacBenhPhoBien.barChartOptions.maintainAspectRatio = !this.isFitPage;
    // this.cacBenhPhoBien.barChartOptions.layout.padding["right"] = 35;
    this.taiNanThuongTich.lineChartOptions.maintainAspectRatio =
      !this.isFitPage;
    this.taiNanThuongTich.lineChartOptions.scales.yAxes[0].display = false;
    this.chiPhiKhamChuaBenh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.chiPhiKhamChuaBenh.barChartOptions.scales.yAxes[0].display = false;
    this.chiPhiKCBTheoDV.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.chiPhiKCBTheoDV.barChartOptions.scales.yAxes[0].display = false;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16 * 2; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 3)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  // modeChange(event: MatSlideToggleChange) {
  //   if (event.checked) {
  //     window.location.href = 'ioc/thong-tin-chung-2';
  //   } else {
  //     window.location.href = 'ioc/thong-tin-chung-2/responsive';
  //   }
  //   return event;
  // }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  getDistricts(callback?) {
    var obj = { size: 20, idTinh: 60 };
    this.subscription.push(
      this.dmService.getListHuyen(obj).subscribe(
        (rs: any) => {
          if (this.userStorage?.MA_DON_VI == MA_DV_SO_Y_TE) {
            this.DISTRICT_LIST = rs.data.length == 0 ? [] : rs.data;
            this.DISTRICT_LIST.unshift({ MA_HUYEN: "", TEN_HUYEN: "Tất cả" });
            this.isHuyen = false;
          } else {
            this.DISTRICT_LIST = [
              {
                MA_HUYEN: this.userStorage?.MA_HUYEN,
                TEN_HUYEN: rs.data.find(
                  (e) => e.MA_HUYEN == this.userStorage?.MA_HUYEN
                )?.TEN_HUYEN,
              },
            ];
            this.formSearch.controls.district.setValue(
              this.userStorage?.MA_HUYEN
            );
            this.isHuyen = true;
          }

          if (callback) {
            callback();
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getListCSKCB() {
    this.formSearch.controls.coSoKCB.reset();
    let maHuyen = this.formSearch.controls.district.value;
    let loaiHinh = this.formSearch.controls.loaiHinh.value;
    // if (!maHuyen) {
    //   this.DON_VI_LIST = [];
    //   return false;
    // }
    let params = { maHuyen: maHuyen };
    if (loaiHinh !== 2) {
      params["loaiHinh"] = loaiHinh;
    }

    this.subscription.push(
      this.dmService.getListCoSoKCB(params).subscribe(
        (rs: any) => {
          if (rs.data.length == 0) {
            this.DON_VI_LIST = [];
            return false;
          }
          this.DON_VI_LIST = rs.data;
          this.DON_VI_LIST.forEach((element, index) => {
            if (element.MA_DON_VI == MA_DV_SO_Y_TE)
              this.DON_VI_LIST.splice(index, 1);
          });
        },
        (err) => {
          this.snackbar.showError(err.message, MESSAGE_TYPE.ERROR);
        }
      )
    );
  }

  public getFilter() {
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let quarter = this.formSearch.controls.quarter.value;
    let tuNgay = "01/01/" + year;
    let denNgay = "31/12/" + year;
    let timeType = this.formSearch.controls.timeType.value;
    switch (timeType) {
      case 1: // theo tháng
        if (month) {
          let temp = new Date(year, month, 0);
          tuNgay = "01/" + month + "/" + year;
          denNgay = temp.getDate() + "/" + month + "/" + year;
        }
        break;
      case 2: // theo quý
        if (quarter) {
          month = 3 * quarter - 2;
          tuNgay = "01/" + month + "/" + year;
          month = 3 * quarter;
          let temp = new Date(year, month, 0);
          denNgay = temp.getDate() + "/" + month + "/" + year;
        } else {
          let temp = new Date(year, month, 0);
          tuNgay = "01/" + month + "/" + year;
          denNgay = temp.getDate() + "/" + month + "/" + year;
        }
        break;
      case 3:
        let tungay = new Date(this.formSearch.controls["tuNgay"].value);
        let denngay = new Date(this.formSearch.controls["denNgay"].value);
        tuNgay =
          tungay.getDate() +
          "/" +
          (tungay.getMonth() + 1) +
          "/" +
          tungay.getFullYear();
        denNgay =
          denngay.getDate() +
          "/" +
          (denngay.getMonth() + 1) +
          "/" +
          denngay.getFullYear();
        break;
    }
    let maHuyen = this.formSearch.controls.district.value;
    let maDonVi = "";
    if (this.formSearch.controls.coSoKCB.value) {
      maDonVi = this.formSearch.controls.coSoKCB.value.toString();
    }

    let result = {
      tuNgay: tuNgay,
      denNgay: denNgay,
      maHuyen: maHuyen ?? "",
      maDonVi,
    };

    if (this.formSearch.controls.loaiHinh.value != 2) {
      result["loaiHinh"] = this.formSearch.controls.loaiHinh.value;
    }
    return result;
  }

  public getData(): void {
    this.thongTinTongQuan();
    this.tKeKhamChuaBenhBaoHiem();
    this.tKeNhomTuoiKhamChuaBenh();
    this.tKeCacBenhPhoBien();
    this.tKeTaiNanThuongTich();
    this.getTop10BenhNguyCoCao();
    // this.tKeChiPhiKhamChuaBenh();
    this.tKeChiPhiKCBTheoDonVi();
  }
  public getTop10BenhNguyCoCao(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.aggService.getTop10BenhNguyCoCao(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.chiPhiKhamChuaBenh.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",  x.TEN_LOAI,  1
            )
          );
          // this.chiPhiKhamChuaBenh.barChartLabels = rs.data.map(
          //   (x) => x.TEN_LOAI
          // );
          let data = rs.data.map((x) => Math.round(x.TONG_SO_TUVONG));
          
          this.chiPhiKhamChuaBenh.barChartData = [
            {
              data: data,
              backgroundColor:  "#c7980d",
              datalabels: {align: "top", anchor: "end", clamp: true}
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  public thongTinTongQuan(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.thongkeTongQuan1(obj).subscribe(
        (rs: any) => {
          if (!rs.success || rs.data.length === 0) {
            return false;
          }
          this.SUMMARY_INFO = rs.data[0];
          this.SUMMARY_INFO.TONG_CHI_PHI = this.nFormatter(
            Number(this.SUMMARY_INFO.TONG_CHI_PHI),
            1
          );
          this.SUMMARY_INFO.TOTAL_BHTT = this.nFormatter(
            Number(this.SUMMARY_INFO.TOTAL_BHTT),
            1
          );
          this.SUMMARY_INFO.TOTAL_BNTT = this.nFormatter(
            Number(this.SUMMARY_INFO.TOTAL_BNTT),
            1
          );
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // using PALETTE_4
  public tKeKhamChuaBenhBaoHiem(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.thongkeKhamChuaBenhBaoHiem(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.khamChuaBenhBaoHiem.pieChartData = [];
            return false;
          }
          let khongBaoHiem = data
            .map((x) => x.KHONG_BAO_HIEM)
            .reduce(function (a, b) {
              return a + b;
            });
          let coBaoHiem = data
            .map((x) => x.CO_BAO_HIEM)
            .reduce(function (a, b) {
              return a + b;
            });

          this.khamChuaBenhBaoHiem.pieChartData = [coBaoHiem, khongBaoHiem];
          this.khamChuaBenhBaoHiem.pieChartLabels = ["Có BHYT", "Không BHYT"];
          this.khamChuaBenhBaoHiem.pieChartColor = [
            { backgroundColor: this.COST_COLOR },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // using SPRING_PASTELS
  public tKeNhomTuoiKhamChuaBenh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.thongkeNhomTuoiKCB(obj).subscribe(
        (rs: any) => {
          this.nhomTuoiKCB.pieChartData = [];
          this.nhomTuoiKCB.pieChartLabels = [];
          if (!rs.success || rs.data.length == 0) {
            return false;
          }
          rs.data.forEach((element) => {
            this.nhomTuoiKCB.pieChartLabels.push(element.NHOM_TUOI.replace(/^[0-9]+_/, ""));
            this.nhomTuoiKCB.pieChartData.push(element.SO_LUONG);
          });
          this.nhomTuoiKCB.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.SPRING_PASTELS },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // using PALETTE_3[2]
  public tKeCacBenhPhoBien(): void {
    let obj: any = this.getFilter();
    obj.rowNum = 10;
    this.subscription.push(
      this.aggService.thongkeCacBenhPhoBienTheoKhuVuc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.cacBenhPhoBien.barChartLabels = rs.data.map((x) => x.TEN_LOAI);
          let data = rs.data.map((x) => x.SO_LUONG);
          this.cacBenhPhoBien.barChartData = [
            {
              data: data,
              label: "Số ca mắc",
              backgroundColor: COLOR_PALETTES.PALETTE_3[2],
              datalabels: {align: "end", anchor: "end", clamp: true, }
            },
          ];
          this.cacBenhPhoBien.barChartOptions={
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              // labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                    
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    // lineHeight: 10,
                    display:true,
                    fontColor: "#fff",
                    beginAtZero: true,
                    
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or !== 0 or ...
                },
              },
            },
          }
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // using PALETTE_3[1]
  public tKeTaiNanThuongTich(): void {
    let obj: any = this.getFilter();
    obj.rowNum = 7;
    this.subscription.push(
      this.aggService.thongkeTaiNanThuongTichTheoKhuVuc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          //Bỏ loại tai nạn là không xác định
          rs.data.forEach((element, index) => {
            if (element.MA_TAI_NAN == 0)
            rs.data.splice(index, 1);
          });

          this.taiNanThuongTich.lineChartLabels = rs.data.map(
            (x) => x.TEN_TNTT
          );
          let data = rs.data.map((x) => x.SO_LUONG);
          this.taiNanThuongTich.lineChartData = [
            {
              data: data,
              label: "Số bệnh nhân",
              fill: false,
              pointRadius: 5,
              lineTension: 0,
              datalabels: { align: "top", anchor: "start" },
            },
          ];
          this.taiNanThuongTich.lineChartColors = [
            { borderColor: COLOR_PALETTES.PALETTE_3[1] },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  //using PALETTE_5[2]
  public tKeChiPhiKCBTheoDonVi(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.aggService.thongkeChiPhiKhamChuaBenhTheoKhuVuc(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.chiPhiKCBTheoDV.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(
              " ",
              x.TEN_DON_VI.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện đa khoa/gi, "BVĐK")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám đa khoa/gi, "PKĐK")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT"),
              2
            )
          );
          let data = rs.data.map((x) => Math.round(x.TOTAL_TONGCHI));
          this.chiPhiKCBTheoDV.barChartData = [
            {
              data: data,
              label: "Số tiền",
              backgroundColor: COLOR_PALETTES.PALETTE_5[2],
              datalabels: {align: "top", anchor: "end", clamp: true}
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  // public tKeChiPhiKhamChuaBenh(): void {
  //   let obj = this.getFilter();
  //   this.subscription.push(
  //     this.aggService.thongkeChiPhiKhamChuaBenh(obj).subscribe(
  //       (rs: any) => {
  //         if (!rs.success) {
  //           return false;
  //         }
  //         let data = rs.data;
  //         if (data.length == 0) {
  //           this.chiPhiKhamChuaBenh.data = [];
  //           this.chiPhiKhamChuaBenh.refreshChart();
  //           return false;
  //         }
  //         let benhNhanTra = data
  //           .map((x) => x.TOTAL_BNTT)
  //           .reduce(function (a, b) {
  //             return a + b;
  //           });
  //         let benhNhanCungTra = data
  //           .map((x) => x.TOTAL_BNCCT)
  //           .reduce(function (a, b) {
  //             return a + b;
  //           });
  //         let baoHiemChiTra = data
  //           .map((x) => x.TOTAL_BHTT)
  //           .reduce(function (a, b) {
  //             return a + b;
  //           });
  //         let nguonKhac = data
  //           .map((x) => x.TOTAL_NGUONKHAC)
  //           .reduce(function (a, b) {
  //             return a + b;
  //           });

  //         this.chiPhiKhamChuaBenh.data = [
  //           { name: "Bệnh nhân trả", y: benhNhanTra, x: " VNĐ" },
  //           { name: "Bệnh nhân cùng chi trả", y: benhNhanCungTra, x: " VNĐ" },
  //           { name: "Bảo hiểm chi trả", y: baoHiemChiTra, x: " VNĐ" },
  //           { name: "Nguồn khác", y: nguonKhac, x: " VNĐ" },
  //         ];
  //         this.chiPhiKhamChuaBenh.refreshChart();
  //       },
  //       (err) => {
  //         this.snackbar.showError(
  //           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
  //           MESSAGE_TYPE.ERROR
  //         );
  //       }
  //     )
  //   );
  // }

  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

  setMarginLeft() {
    if (this.isHuyen) {
      return "margin-left: auto;";
    } 
  }

  nFormatter(num: any, digits: number) {
    if (isNaN(num)) {
      return num;
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: " triệu" },
      // { value: 1e9, symbol: " tỷ" },
      // { value: 1e12, symbol: "T" },
      // { value: 1e15, symbol: "P" },
      // { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i: number;
    for (i = lookup.length - 1; i > 0; i--) {
      if (num >= lookup[i].value) {
        break;
      }
    }
    if (i >= 3) {
      return (
        Number(
          (num / lookup[i].value).toFixed(digits).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    } else {
      return (
        Number(
          (num / lookup[i].value).toFixed(0).replace(rx, "$1")
        ).toLocaleString("vi") + lookup[i].symbol
      );
    }
  }
}
