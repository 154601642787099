<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>

                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">

                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê dân số theo địa bàn huyện/thị/thành</h4>
                        <div class="h-100" style="overflow-x: auto;overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkDanSoTheoHuyen.barChartData[0], 20)">
                                <app-bar-chart #tkDanSoTheoHuyen [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ dân số theo giới tính</h4>
                        <app-pie-chart #tyLeGioiTinh></app-pie-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ dân số ở thành thị, nông thôn</h4>
                        <app-pie-chart #tyLeTTNT></app-pie-chart>
                    </div>
                </div>
            </div>
        </div>

        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê dân số theo giới tính, độ tuổi</h4>
                        <app-bar-chart #tkGTNhomTuoi [isSummary]="true" [isPyramid]="true"></app-bar-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title mb-5" >Thống kê dân số sử dụng biện pháp tránh thai hiện đại</h4>
                        <div class="h-100" style="overflow-x: auto;overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkSDBPTranhThai.barChartData[0], 16)">
                                <app-bar-chart #tkSDBPTranhThai></app-bar-chart>
                            </div>
                        </div>
                       
                       
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tỷ lệ sinh con thứ 3 trở lên (%)</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkSinhConThu3.barChartData[0], 20)">
                                <app-bar-chart #tkSinhConThu3 [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tỷ lệ sinh con thứ 3 trở lên (%)</h4>
                        <div class="h-100" style="overflow-x: auto;padding-bottom: 3px;">
                            <div class="h-100" [style]="getDynamicWidth(this.tkSinhConThu3.barChartData[0], 18)">
                                <app-bar-chart #tkSinhConThu3 [isSummary]="true"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</div>