import { CommonFunctionService } from './../../../../services/common-function.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CDCService } from "src/app/services/trung-tam-cdc.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";

@Component({
  selector: "app-kiem-dich-y-te",
  templateUrl: "./kiem-dich-y-te.component.html",
  styleUrls: ["./kiem-dich-y-te.component.scss"],
})
export class KiemDichYTeComponent implements OnInit {
  subscription: Subscription[] = [];
  showNam = true;
  showThang = false;
  showTuan = true;
  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    month: new FormControl(""),
    week: new FormControl(""),
    loai: new FormControl(1),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  WEEK: any[] = [];
  rowStyles: any = {};

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  @ViewChild("tkXuatNhapQuaCanh", { static: true })
  tkXuatNhapQuaCanh: BarChartComponent;
  @ViewChild("tkPhuongTienNhapCanh", { static: true })
  tkPhuongTienNhapCanh: BarChartComponent;
  @ViewChild("tkPhuongTienXuatCanh", { static: true })
  tkPhuongTienXuatCanh: BarChartComponent;
  @ViewChild("tkPhuongTienQuaCanh", { static: true })
  tkPhuongTienQuaCanh: BarChartComponent;

  slNhapCanh: number;
  slNhapCanhNhiemCovid: number;
  slXuatCanh: number;
  slQuaCanh: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private cdcService: CDCService,
    private commonService: CommonFunctionService
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }

    for (let i = 1; i <= 53; i++) {
      this.WEEK.push({ id: i, text: "Tuần " + i });
    }
    this.WEEK.unshift({ id: -1, text: "Tất cả" });
    this.formSearch.controls["week"].setValue(-1);

    let currentMonth = currentDate.getMonth() ; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    this.MONTHS.push({id: -1,text:"Tất cả"});
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.detectMode();
    this.getData();
  }
  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    if(loai == 1 ){
      this.showNam = true
      this.showTuan = true
      this.showThang = false
    }else if(loai == 2){
      this.showNam = true
      this.showTuan = false
      this.showThang = true
    }else{
      this.showNam = false
      this.showTuan = false
      this.showThang = false
    }
    this.getData()
  }
  onFilterChange (){
    this.detectMode
    this.getData();
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tkXuatNhapQuaCanh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkPhuongTienNhapCanh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkPhuongTienXuatCanh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tkPhuongTienQuaCanh.barChartOptions.maintainAspectRatio = !this.isFitPage;

  }


  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  // getFilter() {
  //   let params = {};
  //   if (this.formSearch.controls.year.value) {
  //     params["nam"] = this.formSearch.controls.year.value;
  //   }
  //   if (
  //     this.formSearch.controls.week.value
  //   ) {
  //     params["tuan"] = this.formSearch.controls.week.value;
  //   }
  //   if (
  //     this.formSearch.controls.month.value
  //   ) {
  //     params["thang"] = this.formSearch.controls.month.value;
  //   }

  //   return params;
  // }
  public getFilter() {
    let loai = this.formSearch.get("loai").value
    let nam  = this.formSearch.get("year").value;
    let thang  = this.formSearch.get("month").value == "Tất cả"?"": this.formSearch.get("month").value;
    let tuan = this.formSearch.get("week").value == "Tất cả"?"": this.formSearch.get("week").value;
    let result = {};
    if(loai == 1){
      result = {
        nam: nam,
        tuan: tuan,
        thang: "",
      }
    }else{
      result = {
        nam: nam,
        tuan: "",
        thang: thang,
      }
    }
    console.log(result)
    return result;
  }

  getData(): void {
    this.tKeTQKiemDichYTe();
    this.tKeXuatNhapQuaCanh();
    this.tKePhuongTienNhapCanh();
    this.tKePhuongTienXuatCanh();
    this.tKePhuongTienQuaCanh();
  }

  tKeTQKiemDichYTe(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeTQKiemDichYTe(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.slNhapCanh = rs.data[0].NHAP_CANH;
          this.slNhapCanhNhiemCovid = rs.data[0].NGUOI_MAC_COVID;
          this.slXuatCanh = rs.data[0].XUAT_CANH;
          this.slQuaCanh = rs.data[0].QUA_CANH;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKeXuatNhapQuaCanh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKeXuatNhapQuaCanh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tkXuatNhapQuaCanh.barChartData = [];
            return false;
          }
          this.tkXuatNhapQuaCanh.barChartLabels = rs.data.map(
            (x) => this.commonService.convertStringToArray(" ", x.TEN_CUA_KHAU, 2)
          );

          this.tkXuatNhapQuaCanh.barChartData = [
            {
              data: rs.data.map((x) => x.NHAP_CANH),
              label: "Nhập cảnh",
              backgroundColor: this.CONST_COLOR[2],
            },
            {
              data: rs.data.map((x) => x.XUAT_CANH),
              label: "Xuất cảnh",
              backgroundColor: this.CONST_COLOR[1],
            },
            {
              data: rs.data.map((x) => x.QUA_CANH),
              label: "Quá cảnh",
              backgroundColor: this.CONST_COLOR[3],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKePhuongTienNhapCanh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKePhuongTien(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tkPhuongTienNhapCanh.barChartData = [];
            return false;
          }
          this.tkPhuongTienNhapCanh.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(" ", x.TEN_CUA_KHAU, 2)
          );

          this.tkPhuongTienNhapCanh.barChartData = [
            {
              data: rs.data.map((x) => x.NHAP_CANH_DUONG_BO),
              label: "Nhập cảnh đường bộ",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
            },
            {
              data: rs.data.map((x) => x.NHAP_CANH_DUONG_BO_XU_LY_Y_TE),
              label: "Nhập cảnh đường bộ đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[2],
            },
            {
              data: rs.data.map((x) => x.NHAP_CANH_DUONG_THUY),
              label: "Nhập cảnh đường thủy",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[4],
            },
            {
              data: rs.data.map((x) => x.NHAP_CANH_DUONG_THUY_XU_LY_Y_TE),
              label: "Nhập cảnh đường thủy đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[5],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKePhuongTienXuatCanh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKePhuongTien(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tkPhuongTienXuatCanh.barChartData = [];
            return false;
          }
          this.tkPhuongTienXuatCanh.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(" ", x.TEN_CUA_KHAU, 2)
          );

          this.tkPhuongTienXuatCanh.barChartData = [
            {
              data: rs.data.map((x) => x.XUAT_CANH_DUONG_BO),
              label: "Xuất cảnh đường bộ",
              backgroundColor: COLOR_PALETTES.PALETTE_1[0],
            },
            {
              data: rs.data.map((x) => x.XUAT_CANH_DUONG_BO_XU_LY_Y_TE),
              label: "Xuất cảnh đường bộ đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.PALETTE_1[1],
            },
            {
              data: rs.data.map((x) => x.XUAT_CANH_DUONG_THUY),
              label: "Xuất cảnh đường thủy",
              backgroundColor: COLOR_PALETTES.PALETTE_1[2],
            },
            {
              data: rs.data.map((x) => x.XUAT_CANH_DUONG_THUY_XU_LY_Y_TE),
              label: "Xuất cảnh đường thủy đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.PALETTE_1[3],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tKePhuongTienQuaCanh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.gettKePhuongTien(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tkPhuongTienQuaCanh.barChartData = [];
            return false;
          }
          this.tkPhuongTienQuaCanh.barChartLabels = rs.data.map((x) =>
            this.commonService.convertStringToArray(" ", x.TEN_CUA_KHAU, 2)
          );

          this.tkPhuongTienQuaCanh.barChartData = [
            {
              data: rs.data.map((x) => x.QUA_CANH_DUONG_BO),
              label: "Quá cảnh đường bộ",
              backgroundColor: COLOR_PALETTES.PALETTE_3[1],
            },
            {
              data: rs.data.map((x) => x.QUA_CANH_DUONG_BO_XU_LY_Y_TE),
              label: "Quá cảnh đường bộ đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.PALETTE_3[2],
            },
            {
              data: rs.data.map((x) => x.QUA_CANH_DUONG_THUY),
              label: "Quá cảnh đường thủy",
              backgroundColor: COLOR_PALETTES.PALETTE_3[3],
            },
            {
              data: rs.data.map((x) => x.QUA_CANH_DUONG_THUY_XU_LY_Y_TE),
              label: "Quá cảnh đường thủy đã xử lý y tế",
              backgroundColor: COLOR_PALETTES.PALETTE_3[4],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
}
