<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <ngx-dropdown class="mb-0 w-100" panelClass="dark-mode"  [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn Đơn vị" [data]="listDonVi" 
                        [label]="!lableDonVi ?'Chọn Đơn vị':'Tất cả'"
                        [formControl]="formSearch.controls.maDonVi" keyId="MA_DON_VI" keyName="TEN_DON_VI"
                        (selected)="onFilterChangeDonVi()">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>

                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng trang thiết bị</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[0]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'tongTTB', 'xl')"> {{ getNumber(this.summaryInfo.TONG) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị trạng thái tốt</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[2]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'trangThaiTot', 'xl')"> {{ getNumber(this.summaryInfo.TOT) }} </div>
                            </div>
                        </div>
                        <div style="height: 15px;" class="w-100">&nbsp;</div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị trạng thái hư</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[1]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'trangThaiHu', 'xl')"> {{ getNumber(this.summaryInfo.HU) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị trạng thái khác</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[3]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'trangThaiKhac', 'xl')"> {{ getNumber(this.summaryInfo.KHAC) }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100 overflow-x">
                        <h4 class="chart-title">Thống kê tổng số trang thiết bị theo đơn vị</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeTongTTB.barChartData[0], 20)">
                                <app-bar-chart #tKeTongTTB [isSummary]="true" (barChartClicked)="onClicked($event, viewDetailTTBTheoDonViContent, 'TTBTheoDonVi', 'xl')"></app-bar-chart>
                            </div>
                        </div>
                    </div>

                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tổng số trang thiết bị theo trạng thái</h4>
                        <!-- <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                            <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tKeTTBTrangThai.barChartData">
                                <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                <span class="ml-1">{{ item.label }}</span>
                            </div>
                        </div> -->
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeTTBTrangThai.barChartData[0], 20)">
                                <app-bar-chart #tKeTTBTrangThai [isSummary]="true" (barChartClicked)="onClicked($event, viewDetailTTBTheoDonViContent, 'TTBTheoDonVi', 'xl')"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- ROW 2 -->

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị có nguồn gốc ngân sách</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[4]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'nguonGocNS', 'xl')"> {{ getNumber(this.summaryInfo.NGAN_SACH) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị có nguồn gốc dự án</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[5]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'nguonGocDA', 'xl')"> {{ getNumber(this.summaryInfo.DU_AN) }} </div>
                            </div>
                        </div>
                        <div style="height: 15px;" class="w-100">&nbsp;</div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị có nguồn gốc tài trợ</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[6]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'nguonGocTT', 'xl')"> {{ getNumber(this.summaryInfo.TAI_TRO) }} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Số lượng trang thiết bị có nguồn gốc của đơn vị</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[9]" (click)="onClicked($event, viewDetailTTBTheoDonViContent, 'nguonGocDV', 'xl')"> {{ getNumber(this.summaryInfo.DON_VI) }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tổng số trang thiết bị theo nguồn gốc</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeTTBNguonGoc.barChartData[0], 20)">
                                <app-bar-chart #tKeTTBNguonGoc [isSummary]="true" (barChartClicked)="onClicked($event, viewDetailTTBTheoDonViContent, 'TTBTheoDonVi', 'xl')"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>

                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê tổng số trang thiết bị theo số năm sử dụng</h4>
                        <div class="h-100" style="overflow-x: auto;">
                            <div class="h-100" [style]="getDynamicWidth(this.tKeTTBNamSuDung.barChartData[0], 20)">
                                <app-bar-chart #tKeTTBNamSuDung [isSummary]="true" (barChartClicked)="onClicked($event, viewDetailTTBTheoDonViContent, 'TTBTheoDonVi', 'xl')"></app-bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewDetailTTBTheoDonViContent let-modal>
    <div class="modal-header">
        <h2 class="modal-title mb-0">Danh sách chi tiết</h2>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-ng-modal [modal]="modal" [ELEMENT_DATA]="ELEMENT_DATA" [COLUMNS_SCHEMA]="listHeaderColumnTTBTheoDonVi" [pageIndex]="index" [length]="length" (paginatorOut)="getListTTBByDonVi($event)"></app-ng-modal>
</ng-template>