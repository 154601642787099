import { SimpleChanges } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, OnInit, AfterViewInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export const INITIAL_DATA: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgxDropdownComponent),
  multi: true
};

@Component({
  selector: 'ngx-dropdown',
  templateUrl: './ngx-dropdown.component.html',
  styleUrls: ['./ngx-dropdown.component.scss'],
  providers: [INITIAL_DATA]
})

export class NgxDropdownComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input()
  public label!: string;

  @Input()
  public placeholder!: string;

  @Input()
  public selectedItem!: string;

  @Input()
  public data!: any[];

  @Input()
  public allItemLabel!: string;

  @Input()
  public disableAllItem: boolean = false;

  @Input()
  public formControl!: FormControl;

  @Input()
  public keyId!: string;

  @Input()
  public keyName!: string;

  @Input()
  public id!: string;

  @Input()
  public class!: string;

  @Input()
  public error: any;

  @Input()
  public multiple!: boolean;

  @Input()
  public noEntriesFoundLabel!: string;

  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() objectSelected: EventEmitter<any> = new EventEmitter();

  public onModelChange: Function = () => { };
  public onModelTouched: Function = () => { };

  /** control for the MatSelect filter keyword */
  public selectFilterCtrl: FormControl = new FormControl();

  /** control for the selected select for multi-selection */
  public selectMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public selectMultiFilterCtrl: FormControl = new FormControl();

  /** list of selects filtered by search keyword */
  public filteredselects: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect') singleSelect!: MatSelect;
  @ViewChild("multiAllOption") multiAllOption: MatCheckbox;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  ngOnInit() {
    // const validator = this.formControl.valid ? null : this.formControl.validator({} as AbstractControl);
    const validator = this.formControl.validator ? this.formControl.validator({} as AbstractControl) : null;
    if (validator && validator['required']) {
      this.formControl.setValidators([Validators.required]);
    }

    if (this.data) {
      // load the initial select list
      this.filteredselects.next(this.data.slice());

      // listen for search field value changes
      this.selectFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterselects();
        });
    }

    if(this.allItemLabel){
      const changeEvent: MatCheckboxChange = { checked: true } as MatCheckboxChange;
      this.toggleSelection(changeEvent);
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['data']) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredselects are loaded initially
   */
  private setInitialValue() {
    this.filteredselects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) => a.id === b.id;
      });
  }

  private filterselects() {
    if (!this.data) {
      return;
    }
    // get the search keyword
    let search = this.selectFilterCtrl.value;
    if (!search) {
      this.filteredselects.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the selects
    this.filteredselects.next(
      this.data.filter(select => select[this.keyName].toLowerCase().indexOf(search) > -1)
    );
  }

  writeValue(obj: any): void {
    this.onModelChange(obj);
  }
  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  // it seems no need this function
  // setDisabledState?(isDisabled: boolean): void {
  //   // throw new Error('Method not implemented.');
  // }

  selectionChange(event: MatSelectChange) {
    if(this.formControl.value.length === this.data.length){
      this.selected.emit(0);
    }else{
      this.selected.emit(event);
    }
  }

  isChecked(): boolean {
    
    let controlValues = this.formControl.value;
    let values = this.data.map(select => select[this.keyId]);
    return controlValues && values.length && controlValues.length === values.length;
  }

  isIndeterminate(): boolean {
    let controlValues = this.formControl.value;
    let values = this.data.map(select => select[this.keyId]);
    return controlValues && values.length && (controlValues.length > 0) && controlValues.length < values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.selected.emit(0);
      let values = this.data.map(select => select[this.keyId]);
      this.formControl.setValue(values);
    } else {
      this.formControl.setValue([]);
    }
    // this.changeValue();
  }
}
