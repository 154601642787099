import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MessageSnackbarComponent } from './components/snackbar/message-snackbar/message-snackbar.component';
import { ErrorSnackbarComponent } from './components/snackbar/error-snackbar/error-snackbar.component';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxDropdownComponent } from './components/ngx-dropdown/ngx-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuComponent } from './components/sidebar/menu/menu.component';
import { NgxTreeCheckboxesComponent } from './components/ngx-treeview/ngx-treecheckboxes.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChartsModule } from 'ng2-charts';
import { PieChartComponent } from './components/chart-js/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/chart-js/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/chart-js/line-chart/line-chart.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MeterHighchartComponent } from './components/highcharts/meter-highchart/meter-highchart.component';
import { PieHighchartComponent } from './components/highcharts/pie-highchart/pie-highchart.component';
import { PolarAreaChartComponent } from './components/chart-js/polar-area-chart/polar-area-chart.component';
import { NgModalComponent } from './components/ng-modal/ng-modal.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ListReportsComponent } from '../modules/thongkebaocao/viewreports/listreports/listreports.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    MenuComponent,
    FooterComponent,
    MessageSnackbarComponent,
    ErrorSnackbarComponent,
    NgxDropdownComponent,
    NgxTreeCheckboxesComponent,
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    MeterHighchartComponent,
    PieHighchartComponent,
    PolarAreaChartComponent,
    NgModalComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTreeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    ChartsModule,
    ChartModule,
    MatSelectInfiniteScrollModule,
    MatTableModule,
    MatPaginatorModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NgxDropdownComponent,
    NgxTreeCheckboxesComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    BarChartComponent,
    LineChartComponent,
    MeterHighchartComponent,
    PieHighchartComponent,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectInfiniteScrollModule,
    MatIconModule,
    NgModalComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }
  ]
})
export class SharedModule { }
