import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KythuatkiemnghiemComponent } from './kythuatkiemnghiem/kythuatkiemnghiem.component';

const routes: Routes = [
  { path: 'trung-tam-kiem-nghiem', component: KythuatkiemnghiemComponent },
  { path: 'trung-tam-kiem-nghiem/ky-thuat-kiem-nghiem', component: KythuatkiemnghiemComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrungTamKiemNghiemRoutingModule { }
