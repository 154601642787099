import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, retry, takeUntil } from 'rxjs/operators';
import { MESSAGE_COMMON, MESSAGE_TYPE, POST_BODY_TYPE } from '../constant/system-constant';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { SnackbarService } from './snackbar.service';
import { Spinner } from './spinner.service';

@Injectable()
export class APIService {
    constructor(
        private authService: AuthenticationService,
        private http: HttpService,
        private message: SnackbarService,
        private spinner: Spinner
    ) { }

    private _destroyed$ = new Subject();
    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    /**
     * Check client is online
     * @param {boolean} showMessage default true
     * @return {boolean} true if is online
     */
    public isOnline(showMessage: boolean = true): boolean {
        if (!navigator.onLine) {
            this.spinner.hide();
            if (showMessage) {
                this.message.showMessage(MESSAGE_COMMON.NETWORK_OFFLINE, MESSAGE_TYPE.ERROR);
            }
            return false;
        }
        return true;
    }

    /**
     * Function get: Get data by parameter
     * @param apiUrl
     * @param parameter
     */
    public get(apiUrl: string = '', params: object = {}, autoShowMessage: boolean = true): Observable<any> {
        if (this.isOnline()) {
            var option = this.getOptions();
            option['params'] = params;

            return this.http.get(apiUrl, option).pipe(
                map((res: Response) => {
                    return this.forwardData(res, res.status);
                }),
                catchError((error) => {
                    return this.forwardError(error, error.status, autoShowMessage);
                }),
                takeUntil(this._destroyed$)
            );
        } else {
            throw Observable.throw(
                this.message.showMessage(
                    MESSAGE_COMMON.NETWORK_OFFLINE, MESSAGE_TYPE.ERROR
                )
            );
        }
    }

    /** Function post: post data
     * @apiUrl URL reference to API
     */
    public post(apiUrl: string = '', condition?: any, type?: any, autoShowMessage: boolean = true) {
        if (!this.isOnline()) {
            throw throwError(
                this.message.showMessage(
                    MESSAGE_COMMON.NETWORK_OFFLINE, MESSAGE_TYPE.ERROR
                )
            );
        }
        let normalizeData = this.normalizeDataValue(condition);
        let requestData = {
            headers: this.getJsonHeaders(),
            body: normalizeData == undefined ? '' : normalizeData,
            observe: 'response',
        };
        if (type === POST_BODY_TYPE.FORM_DATA) {
            let myData = condition instanceof Object ? condition : normalizeData;
            let formData = new FormData();
            for (var key in myData) {
                formData.append(key, myData[key]);
            }
            requestData.headers = this.getHeaders();
            requestData.body = formData;
        }
        return this.http.request(apiUrl, 'POST', requestData).pipe(
            map((res: Response) => {
                return this.forwardData(res, res.status);
            }),
            catchError((error) => {
                return this.forwardError(error, error.status, autoShowMessage);
            }),
            takeUntil(this._destroyed$)
        );
    }

    /** Function put: put data
     * @apiUrl URL reference to API
     */
    public put(apiUrl: string = '', condition?: any, autoShowMessage: boolean = true) {
        if (this.isOnline()) {
            let normalizeData = this.normalizeDataValue(condition);
            let requestOptions = {
                headers: this.getJsonHeaders(),
                body: normalizeData == undefined ? '' : normalizeData,
                observe: 'response',
            };

            return this.http.request(apiUrl, 'PUT', requestOptions).pipe(
                map((res: Response) => {
                    return this.forwardData(res, res.status);
                }),
                catchError((error) => {
                    return this.forwardError(error, error.status, autoShowMessage);
                })
            );
        } else {
            throw throwError(
                this.message.showMessage(
                    MESSAGE_COMMON.NETWORK_OFFLINE, MESSAGE_TYPE.ERROR
                )
            );
        }
    }

    /** Function delete: Delete data by key
     * @apiUrl URL reference to API, this is contain key
     */
    public delete(apiUrl: string = '', data?: any, autoShowMessage: boolean = true) {
        if (this.isOnline()) {
            let requestOptions = {
                headers: this.getJsonHeaders(),
                observe: 'response',
                params: data,
            };

            return this.http.request(apiUrl, 'DELETE', requestOptions).pipe(
                map((res: Response) => {
                    return this.forwardData(res, res.status);
                }),
                catchError((error) => {
                    return this.forwardError(error, error.status, autoShowMessage);
                })
            );
        } else {
            throw throwError(
                this.message.showMessage(
                    MESSAGE_COMMON.NETWORK_OFFLINE, MESSAGE_TYPE.ERROR
                )
            );
        }
    }

    getOptions() {
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.authService.getToken(),
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers, observe: 'response' };
    }

    getHeaders() {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.authService.getToken()
            // 'Access-Control-Allow-Origin': '*'
        });
    }

    getJsonHeaders() {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.authService.getToken(),
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
        });
    }

    /**
     * Request Successful handler
     * @param {Response} res
     * @param {number} status
     * @returns {any}
     */
    private forwardData(res: Response, status: number): any {
        if (status == 200) {
            return res.body;
        } else if (status == 203) {
            return res.body;
        } else {
            return res.body;
        }
    }

    /**
     * Request Error handler
     * @param {Response} error
     * @param status
     * @param autoShowMessage
     * @returns {ErrorObservable}
     */
    forwardError(error: HttpErrorResponse, status, autoShowMessage) {
        this.spinner.hide();

        if (status == 400) {
            //bad request (validate fail)
            return throwError(error.error);
        } else if (status == 401) {
            // unauthorized
            window.location.href = "/dang-nhap";
            return throwError(error.error);
        } else if (status == 403) {
            //forbidden
            window.location.href = '/error/insufficient-permission';
        } else if (status == 405) {
            // method not allow
            return throwError(error.error);
        } else if (status == 410) {
            window.location.href = "/unavailable";
        } else if (status == 500) {
            // internal error
            return throwError(error.error);
        } else if (status == 404) {
            // window.location.href = '/error/page-not-found';
        }

        return throwError(error.error);
    }

    /**
     * Normalize data before sending request to server
     * @param data
     */
    public normalizeDataValue(data: any): any {
        if (Array.isArray(data)) {
            var normalizeData = Object.assign([], data);
            for (let index = 0; index < normalizeData.length; index++) {
                const element = normalizeData[index];
                normalizeData[index] = this.normalizeDataValue(element);
            }
            return normalizeData;
        } else {
            if (data instanceof Object) {
                var normalize = Object.assign({}, data);
                var keys = Object.keys(normalize);
                keys.forEach((key) => {
                    normalize[key] = this.normalizeDataValue(normalize[key]);
                });
                return normalize;
            }
            return data;
        }
    }
}