<mat-form-field class="ngx-dropdown" appearance="outline" #singleSelect [ngClass]="class">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select placeholder="{{ placeholder }}" [formControl]="formControl" [multiple]="multiple" 
                (selectionChange)="selectionChange($event)" [id]="id" >
        <mat-option>
            <ngx-mat-select-search [formControl]="selectFilterCtrl" [placeholderLabel]="placeholder"
                [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
        </mat-option>
        <!-- <mat-option value="null" *ngIf="allItemLabel" (click)="changeValue()" [disabled]="disableAllItem">{{
            allItemLabel }}</mat-option> -->
        <mat-checkbox *ngIf="allItemLabel" class="mat-option" [checked]="isChecked()" [indeterminate]="isIndeterminate()"
            (click)="$event.stopPropagation()" (change)="toggleSelection($event)" #multiAllOption>
            {{ allItemLabel }}
        </mat-checkbox>
        <mat-select-trigger *ngIf="formControl.value.length === this.data.length">
            <span>Tất cả</span>
        </mat-select-trigger>
        <mat-select-trigger *ngIf="formControl.value.length > 1 && formControl.value.length < this.data.length && allItemLabel">
            <span>Nhiều đơn vị</span>
        </mat-select-trigger>
        <mat-option *ngFor="let select of filteredselects | async" [value]="select[keyId]">
            {{ select[keyName] }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="error" [innerHtml]="error"></mat-error>
</mat-form-field>
