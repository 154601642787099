<div class="position-relative h-100">
  
  <canvas baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    (chartClick)="onChartClick($event)">
  </canvas>
</div>
