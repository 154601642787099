import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";
import { POST_BODY_TYPE } from "./../constant/system-constant";

@Injectable({ providedIn: "root" })
export class VlgService extends BaseService {
  ChiSoTaiChinhYTe_getCardsChiNSNN(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CARDS_CHI_NSNN, requestData);
  }
  ChiSoTaiChinhYTe_getChartTheoNguonTaiChinh(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CHART_THEO_NGUON_TAI_CHINH, requestData);
  }
  ChiSoTaiChinhYTe_getChartTheoLoaiHoatDong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CHART_THEO_LOAI_HOAT_DONG, requestData);
  }
  ChiSoTaiChinhYTe_getCardsTongChiYTe(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CARDS_TONG_CHI_Y_TE, requestData);
  }
  ChiSoTaiChinhYTe_getChartDanSoTrungBinh(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CHART_DAN_SO_TRUNG_BINH, requestData);
  }
  ChiSoTaiChinhYTe_getChartTyGiaHoiDoaiBinhQuan(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CHART_TY_GIA_HOI_DOAI_BINH_QUAN, requestData);
  }
  ChiSoTaiChinhYTe_getChartChiTieuCongChoYTeTheoNguonVon(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TAI_CHINH_Y_TE.GET_CHART_CHI_TIEU_CONG_CHO_Y_TE_THEO_NGUON_VON, requestData);
  }
  ChiSoChatLuongAnToan_getCardsValue(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_CHAT_LUONG_AN_TOAN.GET_CARDS, requestData);
  }
  ChiSoChatLuongAnToan_getChartNguoiBenhHaiLong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_CHAT_LUONG_AN_TOAN.GET_CHART_NGUOI_BENH_HAI_LONG, requestData);
  }
  ChiSoChatLuongAnToan_getChartKiemTraMauThuoc(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_CHAT_LUONG_AN_TOAN.GET_CHART_KIEM_TRA_MAU_THUOC, requestData);
  }
  ChiSoTinhTrangSucKhoe_getCardsValue(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_TINH_TRANG_SUC_KHOE.GET_CARDS, requestData);
  }

  // Chỉ số Nhân lực y tế
  getDanhMucDonVi(): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHAN_LUC_YTE.DANH_MUC_DONVI);
  }
  get5TheTong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHAN_LUC_YTE.THE_TONG, requestData);
  }
  getTheoChuyenMon(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHAN_LUC_YTE.CHUYEN_MON, requestData);
  }
  getTheoTrinhDo(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHAN_LUC_YTE.TRINH_DO, requestData);
  }

  // Chỉ số độ bao phủ và ảnh hưởng biện pháp can thiệp
  get14TheTong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_DO_BAO_PHU_VA_AH_BIEN_PHAP_CAN_THIEP.THE_TONG_14, requestData);
  }
  getSoLuongBenhTruyenNhiem(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_DO_BAO_PHU_VA_AH_BIEN_PHAP_CAN_THIEP.SL_BENH_TRUYEN_NHIEM, requestData);
  }
  getTyLeTuVong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_DO_BAO_PHU_VA_AH_BIEN_PHAP_CAN_THIEP.TL_TU_VONG, requestData);
  }

  // Chỉ số nhiệm vụ
  get8TheTong(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHIEM_VU.THE_TONG_8, requestData);
  }
  getTyLeGiaoBoYTe(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHIEM_VU.TL_GIAO_BO_Y_TE, requestData);
  }
  getTyLeGiaoDonVi(requestData): Observable<any> {
    return super.get(apiUrl.DASHBOARD_VLG.CHI_SO_NHIEM_VU.TL_GIAO_DON_VI, requestData);
  }
  checkOTP(requestData): Observable<any> {
    return super.post(apiUrl.USERS.CHECK_OTP, requestData, POST_BODY_TYPE.FORM_DATA);
}
}
