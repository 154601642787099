<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3 ">
          <mat-form-field class="w-100 mb-0" appearance="outline">
            <mat-label>Loại thời gian</mat-label>
            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
              <mat-option [value]="1">Theo năm </mat-option>
              <mat-option [value]="2">Theo tháng </mat-option>
              <mat-option [value]="3">Theo ngày </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showNam" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.nam" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showThang" class="mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0" [multiple]="true" allItemLabel="Tất cả" placeholder="Chọn tháng" [data]="MONTHS" [formControl]="formSearch.controls.thang" [label]="!lableMonth ?'Chọn tháng': 'Tất cả' " (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <div *ngIf="showTuNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Từ ngày</mat-label>
            <input matInput formControlName="tungay" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="tuNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #tuNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="showDenNgay" class="mr-3">
          <mat-form-field class="w-100 mb-0" panelClass="dark-mode" appearance="outline">
            <mat-label>Đến ngày</mat-label>
            <input matInput formControlName="denngay" placeholder="Thời gian đến ngày" [matDatepicker]="denNgayPicker" autocomplete="off" (dateChange)="onFilterChange()">
            <mat-datepicker-toggle matSuffix [for]="denNgayPicker"></mat-datepicker-toggle>
            <mat-datepicker #denNgayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>

      <!-- ROW 1 -->
      <div [ngStyle]="this.rowStyles" >
          <div class="row small-gutters h-100">
              <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12'">
                  <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu tính chất/mô tả/cảm quan</h4>
                              <div class="card-value" style="font-size:2.5vw" [style.color]="CONST_COLOR[7]"> {{ getNumber(this.CTTINHCHATMOTA) }} </div>
                          </div>
                      </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                        <div class="bg-card p-2 h-100">
                            <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu đo pH</h4>
                            <div class="card-value"  style="font-size:2.5vw" [style.color]="CONST_COLOR[3]"> {{ getNumber(this.CTDOPH) }} </div>
                        </div>
                    </div>
                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu độ đồng đều khối lượng, thể tích</h4>
                              <div class="card-value" style="font-size:2.5vw" [style.color]="CONST_COLOR[1]"> {{ getNumber(this.CTDODONGDEU) }} </div>
                          </div>
                      </div>

                      <div style="height: 15px;" class="w-100">&nbsp;</div>

                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                          <div class="bg-card p-2 h-100">
                              <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu độ rã hoặc hoà tan</h4>
                              <div class="card-value" style="font-size:2.5vw" [style.color]="CONST_COLOR[2]"> {{ getNumber(this.CTDORAHOATAN) }} </div>
                          </div>
                      </div>


                      <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                        <div class="bg-card p-2 h-100">
                            <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu tạp chất</h4>
                            <div class="card-value" style="font-size:2.5vw" [style.color]="CONST_COLOR[4]"> {{ getNumber(this.CTTAPCHAT) }} </div>
                        </div>
                    </div>
                    <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-md-4 col-sm-12'">
                        <div class="bg-card p-2 h-100">
                            <h4 class="card-title" style="font-size:0.9vw">Tổng số lượng chỉ tiêu phương pháp khác</h4>
                            <div class="card-value"  style="font-size:2.5vw" [style.color]="CONST_COLOR[6]"> {{ getNumber(this.CTKHAC) }} </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-8 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                  <h4 class="chart-title">Thống kê tỷ lệ đạt chỉ tiêu định tính</h4>
                  <app-pie-chart #datDinhTinh></app-pie-chart>
              </div>
              </div>
          </div>
      </div>


      <!-- ROW 2 -->

      <div [ngStyle]="this.rowStyles">
          <div class="row small-gutters h-100">
            <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12'">
              <div class="bg-chart p-2 h-100">
                <h4 class="chart-title">Thống kê tỷ lệ chỉ tiêu định tính</h4>
                <app-pie-chart #dinhTinh></app-pie-chart>
            </div>

          </div>
              <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                  <h4 class="chart-title">Thống kê tỷ lệ chỉ tiêu định lượng</h4>
                  <app-pie-chart #dinhLuong></app-pie-chart>
              </div>
              </div>
          </div>
      </div>
  </div>
</div>

