
<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <div class="d-flex justify-content-end mb-3 pt-2 w-100">
            <form class="dark-mode mb-3"  [formGroup]="formSearch" id="formSearch">
                <div class="d-flex align-items-center">
                    <div class="ml-auto mr-3">
                        <mat-form-field class="w-100 mb-0" appearance="outline" >
                            <mat-label>Loại thời gian</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeLoai()" formControlName="loai">
                                <mat-option [value]="0">Theo năm</mat-option>
                                <mat-option [value]="1">Theo quý </mat-option>
                                <mat-option [value]="2">Theo tháng </mat-option>
                                <mat-option [value]="3">Từ ngày đến ngày </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div  *ngIf="!isHuyen" class="ml-auto mr-3">
                        <ngx-dropdown panelClass="dark-mode" class="mb-0 w-100"  placeholder="Chọn huyện/thị/thành" [data]="listHuyen"  (selected)="getData()"
                            [formControl]="formSearch.controls.huyen" label="Huyện/Thị/Thành"
                            keyId="MA_HUYEN" keyName="TEN_HUYEN">
                        </ngx-dropdown>
                    </div>
                    <div *ngIf="isHuyen"  class="ml-auto mr-3">
                        <mat-form-field  class="mb-0 w-100 " appearance="outline" floatLabel="always">
                            <mat-label>Quận/huyện</mat-label>
                            <input matInput readonly="true" formControlName="tenhuyen" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNam" class="mr-3" >
                        <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                        </ngx-dropdown>
                    </div>

                    <div *ngIf="showThang" class="ml-auto mr-3">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="getData()" formControlName="thang">
                                <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showQuy" class="ml-auto mr-3">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn quý</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="getData()" formControlName="quy">
                                <mat-option [value]="quy.GIA_TRI_QUY" *ngFor="let quy of listQuy">{{ quy.GIA_TRI_QUY =='Tất cả'?'Tất cả': 'Qúy ' + quy.GIA_TRI_QUY}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNgay" class="mr-3">
                        <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                          <mat-label>Từ ngày</mat-label>
                          <input
                            matInput
                            formControlName="fromDate"
                            placeholder="Thời gian từ ngày"
                            [matDatepicker]="tuNgayPicker"
                            class="c-text"
                            autocomplete="off"
                            class="c-text"
                            (dateChange)="getData()"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="tuNgayPicker"
                            class="c-text"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #tuNgayPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showNgay" class="mr-3">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                          <mat-label>Đến ngày</mat-label>
                          <input
                            matInput
                            formControlName="toDate"
                            placeholder="Thời gian đến ngày"
                            [matDatepicker]="denNgayPicker"
                            autocomplete="off"
                            class="c-text"
                            (dateChange)="getData()"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="denNgayPicker"
                            class="c-text"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #denNgayPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                        <mat-icon color="primary">fullscreen</mat-icon>
                    </button>
                </div>

            </form>
        </div>
        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm thuốc </h4>
                                <div class="card-value" [style.color]="CONST_COLOR[0]"> {{SL_9_THE_TONG.MAU_THUOC}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm thuốc dược liệu - thuốc cổ truyền</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[2]"> {{SL_9_THE_TONG.MAU_DUOCLIEU_THUOCCOTRUYEN}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100 mt-3' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm mỹ phẩm</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[1]"> {{SL_9_THE_TONG.MAU_MYPHAM}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm lấy thuốc </h4>
                                <div class="card-value" [style.color]="CONST_COLOR[5]"> {{SL_9_THE_TONG.MAU_THUOC_LAY}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm lấy thuốc dược liệu - thuốc cổ truyền</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[7]"> {{SL_9_THE_TONG.MAU_DUOCLIEU_THUOCCOTRUYEN_LAY}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100 mt-3' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm lấy mỹ phẩm</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[0]"> {{SL_9_THE_TONG.MAU_MYPHAM_LAY}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7.5px)">
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm gửi thuốc </h4>
                                <div class="card-value" [style.color]="CONST_COLOR[4]"> {{SL_9_THE_TONG.MAU_THUOC_GUI}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm gửi thuốc dược liệu - thuốc cổ truyền</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[6]"> {{SL_9_THE_TONG.MAU_DUOCLIEU_THUOCCOTRUYEN_GUI}} </div>
                            </div>
                        </div>
                        <div [ngClass]="isFitPage ? 'col-6 h-100 mt-3' : 'col-md-6 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">SL mẫu kiểm nghiệm gửi mỹ phẩm</h4>
                                <div class="card-value" [style.color]="CONST_COLOR[3]"> {{SL_9_THE_TONG.MAU_MYPHAM_GUI}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ mẫu thuốc,thuốc dược liệu - thuốc cổ truyền và mỹ phẩm đạt </h4>
                        <app-pie-chart #tlMauKN></app-pie-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ mẫu thuốc,thuốc dược liệu - thuốc cổ truyền và mỹ phẩm đạt </h4>
                        <app-pie-chart #tlMauKNLay></app-pie-chart>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ mẫu thuốc,thuốc dược liệu - thuốc cổ truyền và mỹ phẩm đạt </h4>
                        <app-pie-chart #tlMauKNGui></app-pie-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
