export const rootUrl = "https://api-dashboard.kdlyt.vinhlong.vn/api/";
// export const rootUrl = "https://dev-api-dashboard.kdlyt.vinhlong.vn/api/";
// export const rootUrl = "http://localhost:7096/api/";

export const apiUrl = {
  USERS: {
    LOGIN: rootUrl + "get-token",
    DANH_SACH_USER: rootUrl + "user/lay-danh-sach-nguoi-dung",
    LAY_CHI_TIET_USER: rootUrl + "user/lay-thong-tin-chi-tiet-nguoi-dung",
    // THEM_USER: rootUrl + "user/them-moi-nguoi-dung",
    // CAP_NHAT_USER: rootUrl + "user/cap-nhat-nguoi-dung",
    CAP_NHAT_THONG_TIN_USER:
      rootUrl + "user/cap-nhat-thong-tin-chi-tiet-nguoi-dung",
    CAP_NHAT_MAT_KHAU: rootUrl + "user/doi-mat-khau",
    // XOA_USER: rootUrl + "user/xoa-nguoi-dung"
  },
  PHAN_QUYEN: {
    LAY_DS_MENU_IOC: rootUrl + "user/lay-list-menu-ioc",
  },
  DANH_MUC_DUNG_CHUNG: {
    LAY_DS_QUOC_TICH: rootUrl + "danh-muc-dung-chung/quoc-tich",
    LAY_DS_DAN_TOC: rootUrl + "danh-muc-dung-chung/dan-toc",
    LAY_DS_TON_GIAO: rootUrl + "danh-muc-dung-chung/ton-giao",
    LAY_DS_DON_VI: rootUrl + "danh-muc-dung-chung/don-vi",
    LAY_DS_CO_SO_KCB: rootUrl + "danh-muc-dung-chung/co-so-kham-chua-benh",
    LAY_DS_TINH: rootUrl + "danh-muc-dung-chung/tinh",
    LAY_DS_HUYEN: rootUrl + "danh-muc-dung-chung/huyen",
    LAY_DS_XA: rootUrl + "danh-muc-dung-chung/xa",
    LAY_DM_KY: rootUrl + "danh-muc-dung-chung/dm-ky",
    LAY_DS_NAM: rootUrl + "danh-muc-dung-chung/nam",
    LAY_DS_THANG: rootUrl + "danh-muc-dung-chung/thang",
    LAY_DS_QUY: rootUrl + "danh-muc-dung-chung/quy",
  },
  THONG_TIN_CHUNG: {
    TK_HS_KB: rootUrl + "thong-tin-Chung/sl-tong-hoso-kcb",
    TK_SL_CAPCUU: rootUrl + "thong-tin-chung/tk-tong-sl-cap-cuu",
    TK_SL_TUVONG: rootUrl + "thong-tin-chung/tk-tong-sl-tu-vong",
    TK_LUOT_RA_VAO_VIEN: rootUrl + "thong-tin-chung/tk-sl-ra-vao-vien",
    TK_SL_CAP_CUU_TU_VONG: rootUrl + "thong-tin-chung/tk-sl-cap-cuu-tu-vong",
    TK_LUOT_KHAM_BENH: rootUrl + "thong-tin-chung/tk-luot-kham-benh",
    SL_LUOT_KHAM_BENH_NOI_TRU:
      rootUrl + "thong-tin-chung/tk-luot-kham-benh-noi-tru",
    TK_NOI_TRU_NGOAI_TRU:
      rootUrl + "thong-tin-chung/tk-luot-kham-benh-ngoai-tru-noi-tru",
    SL_NOI_TRU_NGOAI_TRU: rootUrl + "thong-tin-chung/sl-noi-tru-ngoai-tru",
    SL_LUOT_VAO_VIEN: rootUrl + "thong-tin-chung/tk-luot-vao-vien",
    SL_LUOT_RA_VIEN: rootUrl + "thong-tin-chung/tk-luot-ra-vien",
    SL_CA_NANG_XIN_VE: rootUrl + "thong-tin-chung/sl-ca-nang-xin-ve",
    SL_CHUYEN_VIEN: rootUrl + "thong-tin-chung/sl-chuyen-vien",
    SL_NOI_TRU_CUOI_KY: rootUrl + "thong-tin-chung/sl-noi-tru-cuoi-ky",
    SL_NOI_TRU: rootUrl + "thong-tin-chung/sl-noi-tru",
    SL_NGOAI_TRU: rootUrl + "thong-tin-chung/sl-ngoai-tru",
    SL_CUOI_KY_NOI_TRU: rootUrl + "thong-tin-chung/sl-cuoi-ky-noi-tru",
  },
  CAU_HINH_HE_THONG: {
    LAY_DS_PHAN_CAP: rootUrl + "he-thong/danh-muc/lay-danh-sach-phan-cap",
    LAY_ALL_PHAN_CAP: rootUrl + "he-thong/danh-muc/lay-tat-ca-phan-cap",
    LAY_CT_PHAN_CAP: rootUrl + "he-thong/danh-muc/lay-chi-tiet-phan-cap",
    THEM_PHAN_CAP: rootUrl + "he-thong/danh-muc/them-phan-cap",
    CAP_NHAT_PHAN_CAP: rootUrl + "he-thong/danh-muc/cap-nhat-phan-cap",
    XOA_PHAN_CAP: rootUrl + "he-thong/danh-muc/xoa-phan-cap",
    LAY_MAX_LEVEL_USER: rootUrl + "he-thong/danh-muc/lay-max-level-user",
    LAY_API_THEO_PHAN_CAP: rootUrl + "he-thong/cau-hinh/get-phan-cap-api",
    PHAN_CAP_API: rootUrl + "he-thong/cau-hinh/phan-cap-api",
    LOG_CHUC_NANG: rootUrl + "he-thong/log-he-thong/lay-log-chuc-nang",
    CT_LOG_CHUC_NANG: rootUrl + "he-thong/log-he-thong/lay-ct-log-chuc-nang",
    LOG_DANG_NHAP: rootUrl + "he-thong/log-he-thong/lay-log-dang-nhap",
    CT_LOG_DANG_NHAP: rootUrl + "he-thong/log-he-thong/lay-ct-log-dang-nhap",
  },
  DON_VI: {
    LAY_DANH_SACH_DON_VI: rootUrl + "user/lay-combobox-don-vi",
    LAY_DANH_SACH_USER_LEVEL: rootUrl + "user/lay-combobox-level-user",
  },

  QD831: {
    THONG_KE_GIOI_TINH: rootUrl + "dashboard-831/lay-thong-ke-gioi-tinh",
    THONG_KE_HO_KHAU: rootUrl + "dashboard-831/lay-thong-ke-tong-ho-khau",
    THONG_KE_HSSK: rootUrl + "dashboard-831/lay-thong-ke-tong-hssk",
    TK_TIEN_SU_BENH_TAT:
      rootUrl + "dashboard-831/lay-thong-ke-tien-su-benh-tat",
    TK_TY_LE_SAY_THAI:
      rootUrl + "dashboard-831/lay-thong-ke-skss-ty-le-say-thai",
    TK_TY_LE_PHA_THAI:
      rootUrl + "dashboard-831/lay-thong-ke-skss-ty-le-pha-thai",
    THONG_KE_HSSK_THEO_DO_TUOI:
      rootUrl + "dashboard-831/lay-thong-ke-hssk-theo-do-tuoi",

    /*
      Tạo lập hssk
    */
    TAO_LAP_HO_SO_SUC_KHOE: {
      THONG_KE_DS_SL_HSSK_TAO_LAP_VA_CAP_NHAT: rootUrl + "qd-831/hssk-sl",
      TI_LE_HSSK_CAP_NHAT_VA_TAO_LAP: rootUrl + "qd-831/hssk-tile"
    }

  },

  //API khám chữa bệnh QĐ 4210
  KHAM_CHUA_BENH: {
    THONG_KE_TONG_QUAN_1:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-tong-quan-1",
    THONG_KE_SU_DUNG_DVKT:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-su-dung-dich-vu-ky-thuat",
    THONG_KE_SO_HO_SO_BHYT:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-so-ho-so-kham-chua-benh-bhyt",
    THONG_KE_GIOI_TINH_KCB:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-gioi-tinh-kham-chua-benh",
    THONG_KE_SO_NGAY_DIEU_TRI:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-so-ngay-dieu-tri",
    THONG_KE_CAC_BENH_PHO_BIEN:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-cac-benh-pho-bien",
    THONG_KE_CAC_BENH_PHO_BIEN_THEO_KHU_VUC:
      rootUrl +
      "kham-chua-benh/tong-hop/thong-ke-cac-benh-pho-bien-theo-khu-vuc",
    THONG_KE_TAI_NAN_THUONG_TICH:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-tai-nan-thuong-tich",
    THONG_KE_TAI_NAN_THUONG_TICH_THEO_KHU_VUC:
      rootUrl +
      "kham-chua-benh/tong-hop/thong-ke-tai-nan-thuong-tich-theo-khu-vuc",
    THONG_KE_CHI_PHI_KHAM_CHUA_BENH:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-chi-phi-kham-chua-benh",
    THONG_KE_CHI_PHI_KHAM_CHUA_BENH_THEO_KHU_VUC:
      rootUrl +
      "kham-chua-benh/tong-hop/thong-ke-chi-phi-kham-chua-benh-theo-khu-vuc",
    THONG_KE_SO_CA_CAP_CUU:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-so-ca-cap-cuu",
    THONG_KE_SO_CA_TU_VONG:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-so-ca-tu-vong",
    THONG_KE_NHOM_TUOI_KCB:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-nhom-tuoi-kham-chua-benh",
    THONG_KE_KCB_BAO_HIEM:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-kham-chua-benh-bao-hiem",

    TK_TONG_SO_HO_SO: rootUrl + "kham-chua-benh/tong-hop/tk-tong-so-ho-so",
    TK_VAO_VIEN: rootUrl + "kham-chua-benh/tong-hop/tk-ho-so-vao-vien",
    TK_CAP_CUU: rootUrl + "kham-chua-benh/tong-hop/tk-cap-cuu",
    TK_TU_VONG: rootUrl + "kham-chua-benh/tong-hop/tk-tu-vong",
    TK_KHAM_THEO_NGAY: rootUrl + "kham-chua-benh/tong-hop/tk-kham-theo-ngay",
    TK_RA_VAO_THEO_NGAY: rootUrl + "kham-chua-benh/tong-hop/tk-ra-vao-ngay",
    TK_NOI_TRU_NGOAI_TRU_NGAY:
      rootUrl + "kham-chua-benh/tong-hop/tk-noi-tru-ngoai-tru-ngay",
    TK_KHAM_BHYT_NGAY: rootUrl + "kham-chua-benh/tong-hop/tk-kham-bhyt-ngay",
    TK_CAP_CUU_TU_VONG_NGAY:
      rootUrl + "kham-chua-benh/tong-hop/tk-cap-cuu-tu-vong-ngay",
    TK_TAI_NAN_THUONG_TICH_NGAY:
      rootUrl + "kham-chua-benh/tong-hop/tk-tai-nan-thuong-tich-ngay",
    TK_KHAM_BHYT: rootUrl + "kham-chua-benh/tong-hop/tk-kham-bhyt",
    TK_BENH_NHAN_THEO_TUOI: rootUrl + "kham-chua-benh/tong-hop/tk-theo-do-tuoi",
    TK_BENH_NHAN_THEO_LOAI_BENH:
      rootUrl + "kham-chua-benh/tong-hop/tk-theo-loai-benh",
    TK_CHI_PHI_BENH_NHAN_THANH_TOAN:
      rootUrl + "kham-chua-benh/tong-hop/tk-chi-phi-thanh-toan",
    TL_KCB_CO_BHYT: rootUrl + "phan-tich-du-lieu-kcb/ty-le-kcb-co-bhyt",
    TK_KCB_LOAI_KHAM: rootUrl + "phan-tich-du-lieu-kcb/tk-kcb-loai-kham",
    TK_CHI_PHI_KCB: rootUrl + "phan-tich-du-lieu-kcb/tk-chi-phi-kcb",
    TK_CAP_CUU_TU_VONG: rootUrl + "phan-tich-du-lieu-kcb/tk-cap-cuu-tu-vong",
    TK_BENH_NGUY_CO_CAO:
      rootUrl + "kham-chua-benh/tong-hop/thong-ke-top-10-benh-nguy-co-cao",
  },
  TRUNG_TAM_CDC: {
    LAY_DS_NAM: rootUrl + "danh-muc-dung-chung/nam",
    LAY_DS_THANG: rootUrl + "danh-muc-dung-chung/thang",
    LAY_DS_6_THE_TONG: rootUrl + "cdc/benh-lao/6-the-tong",
    TK_KHAMBENH_PHATHIEN_LAO: rootUrl + "cdc/benh-lao/phat-hien-lao",
    TK_DIEUTRI_LAO_KHANGTHUOC:
      rootUrl + "cdc/benh-lao/thu-nhan-dieu-tri-lao-khang-thuoc",
    TK_DIEUTRI_LAO_CACTHE:
      rootUrl + "cdc/benh-lao/thu-nhan-dieu-tri-lao-cac-the",
    TL_PHAT_HIEN_TANG_HUYET_AP_HUYEN:
      rootUrl + "cdc/benh-khong-lay/tang-huyet-ap-huyen",
    TL_PHAT_HIEN_DAI_THAO_DUONG_HUYEN:
      rootUrl + "cdc/benh-khong-lay/dai-thao-duong-huyen",
    TL_TU_VONG: rootUrl + "cdc/benh-khong-lay/ty-le-tu-vong",
    TL_PHAT_HIEN_TANG_HUYET_AP_TINH:
      rootUrl + "cdc/benh-khong-lay/tang-huyet-ap-tinh",
    TL_PHAT_HIEN_DAI_THAO_DUONG_TINH:
      rootUrl + "cdc/benh-khong-lay/dai-thao-duong-tinh",
    SL_BN_HUYET_AP_DAI_THAO_DUONG: rootUrl + "cdc/benh-khong-lay/4-the-tong",
    TK_DU_BAO_SXH: rootUrl + "cdc/benh-truyen-nhiem/du-bao-sxh",
    TK_DU_BAO_SXH_HGI: rootUrl + "cdc/benh-truyen-nhiem/du-bao-sxh-hgi",
    TK_MAC_SOC_CHET_SXH: rootUrl + "cdc/benh-truyen-nhiem/mac-soc-chet-sxh",
    TK_MAC_SOC_CHET_SXH_HGI: rootUrl + "cdc/benh-truyen-nhiem/mac-soc-chet-sxh-hgi",
    TK_DU_BAO_TCM: rootUrl + "cdc/benh-truyen-nhiem/du-bao-tcm",
    TK_DU_BAO_TCM_HGI: rootUrl + "cdc/benh-truyen-nhiem/du-bao-tcm-hgi",
    TK_MAC_CHET_TCM: rootUrl + "cdc/benh-truyen-nhiem/mac-chet-tcm",
    TK_MAC_CHET_TCM_HGI: rootUrl + "cdc/benh-truyen-nhiem/mac-chet-tcm-hgi",
    TK_TQ_KIEM_DICH_Y_TE: rootUrl + "cdc/kiem-dich-y-te/4-the-tong",
    TK_XUAT_NHAP_QUA_CANH: rootUrl + "cdc/kiem-dich-y-te/nguoi-nhap-xuat-canh",
    TK_PHUONG_TIEN: rootUrl + "cdc/kiem-dich-y-te/phuong-tien",
    TK_HOAT_DONG_XET_NGHIEM: rootUrl + "cdc/xet-nghiem-pkdk/xet-nghiem",
    TK_HOAT_DONG_SKSS: rootUrl + "cdc/xet-nghiem-pkdk/suc-khoe-sinh-san",
    TK_HOAT_DONG_CDHA: rootUrl + "cdc/xet-nghiem-pkdk/chan-doan-hinh-anh",
    TK_HOAT_DONG_KSK: rootUrl + "cdc/xet-nghiem-pkdk/kham-suc-khoe",
    TK_HOAT_DONG_TIEM_CHUNG: rootUrl + "cdc/xet-nghiem-pkdk/tiem-chung",
    SUC_KHOE_SINH_SAN: rootUrl + "cdc/suc-khoe-sinh-san",
    VITAMIN_A_TRE_EM: rootUrl + "cdc/vitamin-a/tre-em",
    VITAMIN_A_BA_ME: rootUrl + "cdc/vitamin-a/ba-me",
    LAY_DS_6_THE_TONG_HGI: rootUrl + "cdc/benh-lao-hgi/6-the-tong",
    TK_KHAMBENH_PHATHIEN_LAO_HGI: rootUrl + "cdc/benh-lao-hgi/phat-hien-lao",
    TK_DIEUTRI_LAO_KHANGTHUOC_HGI:
      rootUrl + "cdc/benh-lao-hgi/thu-nhan-dieu-tri-lao-khang-thuoc",
    TK_DIEUTRI_LAO_CACTHE_HGI:
      rootUrl + "cdc/benh-lao-hgi/thu-nhan-dieu-tri-lao-cac-the",

    TIEM_CHUNG: {
      DAY_DU_CHO_TRE_DUOI_1_TUOI: rootUrl + "cdc/tiem-chung/day-du-te-1-tuoi",
      VIEM_GAN_B_TRE_DUOI_1_TUOI:
        rootUrl + "cdc/tiem-chung/viem-gan-b-tre-so-sinh",
      MO_RONG_TRE_DUOI_18_THANG: rootUrl + "cdc/tiem-chung/mo-rong-te-18-thang",
      DPT_TRE_18_THANG: rootUrl + "cdc/tiem-chung/dpt-te-18-thang",
      MUI_2_VA_3_VIEM_NAO_NHAT_BAN: rootUrl + "cdc/tiem-chung/mui-2-3-vnnb",
      VAT_PHU_NU_CO_THAI: rootUrl + "cdc/tiem-chung/vat-phu-nu-co-thai",
    },
    TIEM_CHUNG_HGI: {
      DAY_DU_CHO_TRE_DUOI_1_TUOI: rootUrl + "cdc/tiem-chung-hgi/day-du-te-1-tuoi",
      VIEM_GAN_B_TRE_DUOI_1_TUOI: rootUrl + "cdc/tiem-chung-hgi/viem-gan-b-tre-so-sinh",
      MO_RONG_TRE_DUOI_18_THANG: rootUrl + "cdc/tiem-chung-hgi/mo-rong-te-18-thang",
      DPT_TRE_18_THANG: rootUrl + "cdc/tiem-chung-hgi/dpt-te-18-thang",
      MUI_2_VA_3_VIEM_NAO_NHAT_BAN: rootUrl + "cdc/tiem-chung-hgi/mui-2-3-vnnb",
      VAT_PHU_NU_CO_THAI: rootUrl + "cdc/tiem-chung-hgi/vat-phu-nu-co-thai",
    },
    HIV: {
      TONG_QUAN_HIV_TAI_TINH: rootUrl + "cdc/hiv/tong-quan-dich",
      MAU_DUONG_TINH_QUA_CAC_NAM: rootUrl + "cdc/hiv/so-mau-hiv",
      NGUOI_NGHIEM_THEO_DOI_TUONG:
        rootUrl + "cdc/hiv/nguoi-nhiem-theo-doi-tuong",
      NGUOI_NGHIEM_THEO_GIOI_TINH: rootUrl + "cdc/hiv/theo-gioi-tinh",
      NGUOI_NHIEM_THEO_NGUY_CO: rootUrl + "cdc/hiv/theo-nguy-co",
      NGUOI_NHIEM_THEO_DO_TUOI: rootUrl + "cdc/hiv/theo-do-tuoi",
    },
    SUY_DINH_DUONG: {
      TY_LE_SUY_DINH_DUONG:
        rootUrl + "cdc/hoat-dong-dinh-duong/ty-le-suy-dinh-duong",
      HOAT_DONG_SUY_DINH_DUONG:
        rootUrl + "cdc/hoat-dong-dinh-duong/hoat-dong-dinh-duong",
    },
    BENH_KHONG_LAY_HGI: {
      TL_PHAT_HIEN_TANG_HUYET_AP_HUYEN: rootUrl + "cdc/benh-khong-lay-hgi/tang-huyet-ap-huyen",
      TL_PHAT_HIEN_DAI_THAO_DUONG_HUYEN: rootUrl + "cdc/benh-khong-lay-hgi/dai-thao-duong-huyen",
      TL_TU_VONG: rootUrl + "cdc/benh-khong-lay-hgi/ty-le-tu-vong",
      TL_PHAT_HIEN_TANG_HUYET_AP_TINH: rootUrl + "cdc/benh-khong-lay-hgi/tang-huyet-ap-tinh",
      TL_PHAT_HIEN_DAI_THAO_DUONG_TINH: rootUrl + "cdc/benh-khong-lay-hgi/dai-thao-duong-tinh",
      SL_BN_HUYET_AP_DAI_THAO_DUONG: rootUrl + "cdc/benh-khong-lay-hgi/4-the-tong",
    },

    /*
    HIV HGI
    */
    HIV_HGI: {
      TONG_QUAN_HIV_TAI_TINH_HGI: rootUrl + "cdc/hiv/tong-quan-dich-hgi",
      MAU_DUONG_TINH_QUA_CAC_NAM_HGI: rootUrl + "cdc/hiv/so-mau-hiv-hgi",
      NGUOI_NGHIEM_THEO_DOI_TUONG_HGI:
        rootUrl + "cdc/hiv/nguoi-nhiem-theo-doi-tuong-hgi",
      NGUOI_NGHIEM_THEO_GIOI_TINH_HGI: rootUrl + "cdc/hiv/theo-gioi-tinh-hgi",
      NGUOI_NHIEM_THEO_NGUY_CO_HGI: rootUrl + "cdc/hiv/theo-nguy-co-hgi",
      NGUOI_NHIEM_THEO_DO_TUOI_HGI: rootUrl + "cdc/hiv/theo-do-tuoi-hgi",
    },
  },
  BCTK: {
    LAY_DS_XA: rootUrl + "get-ds-xa",
    LAY_DS_HUYEN: rootUrl + "get-ds-huyen",
    LAY_DS_CSKCB: rootUrl + "get-ds-cskcb",
    LAY_DS_MAU_BC: rootUrl + "get-ds-mau-bao-cao",
    BC_HD_KCB_MAU_02: rootUrl + "reports/nghiep-vu-y/syt/hoat-dong-kcb-b02",
    BC_HD_DIEU_TRI_MAU_03:
      rootUrl + "reports/nghiep-vu-y/syt/hoat-dong-dieu-tri-b03",
    BC_HD_CLS_MAU_06: rootUrl + "reports/nghiep-vu-y/syt/hoat-dong-cls-b06",
    BC_BENH_TAT_TU_VONG_MAU_14:
      rootUrl + "reports/nghiep-vu-y/syt/benh-tat-tu-vong-b14",
    BC_TINH_HINH_QLSK_HOGIADINH: "reports/nghiep-vu-y/bao-cao-qlsk-ho-gia-dinh",
    TRUNG_TAM_KIEM_SOAT_BENH_TAT: rootUrl + "syt/view-bao-cao-phong-chong-hiv-aids",
    CHI_TIEU_HOAT_DONG_TT_KIEM_NGHIEM: rootUrl + "syt/view-chi-tieu-hoat-dong",
  },
  NGHIEP_VU_Y: {
    DE_AN_06: {
      THONG_KE_4_CHI_TIEU: rootUrl + "nghiep-vu-y/de-an-06/4-chi-tieu",
      THONG_KE_NHIEM_VU: rootUrl + "nghiep-vu-y/de-an-06/thuc-hien-nhiem-vu",
      KCB_BANG_CCCD_TICH_HOP_BHYT:
        rootUrl + "nghiep-vu-y/de-an-06/kcb-bang-cccd",
      THUC_HIEN_LUU_TRU_TRUC_TUYEN:
        rootUrl + "nghiep-vu-y/de-an-06/luu-tru-truc-tuyen",
      LIEN_THONG_GIAY_KHAM_SUC_KHOE_LAI_XE:
        rootUrl + "nghiep-vu-y/de-an-06/ltdl-giay-ksk-lai-xe",
      NV_CAI_DAT_VNEID_MUC_DO_2:
        rootUrl + "nghiep-vu-y/de-an-06/cai-dat-vneid-muc-2",
    },
    TINH_HINH_CAP_CCHN: rootUrl + "nghiep-vu-y/tinh-hinh-cap-cchn-tong",
    DS_CCHN_GAN_NHAT: rootUrl + "nghiep-vu-y/ds-cchn-gan-nhat",
  },
  NGHIEP_VU_DUOC: {
    CHUNG_NHAN_CHUNG_CHI_HANH_NGHE: {
      THONG_TIN_TONG_QUAN: rootUrl + "nghiep-vu-duoc/3-tong-the",
      THONG_TIN_TONG_QUAN_HGI: rootUrl + "nghiep-vu-duoc/3-tong-the-hgi",
      LOAI_VAN_BANG: rootUrl + "nghiep-vu-duoc/van-bang-cchn",
      LOAI_VAN_BANG_HGI: rootUrl + "nghiep-vu-duoc/van-bang-cchn-hgi",
      DANH_SACH_CCHN: rootUrl + "nghiep-vu-duoc/ds-cchn-gan-nhat",
      DANH_SACH_CCHN_HGI: rootUrl + "nghiep-vu-duoc/ds-cchn-gan-nhat-hgi",
      DANH_SACH_CCHN_THEO_LOAI: rootUrl + "nghiep-vu-duoc/ds-cchn-theo-loai",
    },
    GCN: {
      THONG_TIN_TONG_QUAN: rootUrl + "nghiep-vu-duoc/nvd-cap-gcn-tong",
      SO_CS_THEO_HUYEN: rootUrl + "nghiep-vu-duoc/nvd-so-cs-theo-huyen",
      DS_GCN: rootUrl + "nghiep-vu-duoc/nvd-gcn-gan-nhat",
      TY_LE_HTTC: rootUrl + "nghiep-vu-duoc/nvd-ty-le-hinh-thuc-to-chuc",
      DS_GCN_THEO_LOAI: rootUrl + "nghiep-vu-duoc/nvd-gcn-theo-loai",
    },
    CONG_BO_MY_PHAM: {
      TONG_THE: rootUrl + "nghiep-vu-duoc/cong-bo-my-pham/6-the-tong",
      TK_CTY_SAN_XUAT_THEO_HUYEN:
        rootUrl + "nghiep-vu-duoc/cong-bo-my-pham/tk-cty-san-xuat-theo-huyen",
      TK_SAN_PHAM_THEO_CTY_SAN_XUAT:
        rootUrl +
        "nghiep-vu-duoc/cong-bo-my-pham/tk-san-phan-theo-cty-san-xuat",
      TK_NHAN_HANG_THEO_CTY_SAN_XUAT:
        rootUrl + "nghiep-vu-duoc/cong-bo-my-pham/tk-nhan-hieu-theo-cty",
      TONG_THE_CHI_TIET:
        rootUrl + "nghiep-vu-duoc/cong-bo-my-pham/6-the-tong-chi-tiet",
    },
    GCN_HGI: {
      THONG_TIN_TONG_QUAN_HGI: rootUrl + "nghiep-vu-duoc/nvd-cap-gcn-tong-hgi",
      SO_CS_THEO_HUYEN_HGI: rootUrl + "nghiep-vu-duoc/nvd-so-cs-theo-huyen-hgi",
      DS_GCN_HGI: rootUrl + "nghiep-vu-duoc/nvd-gcn-gan-nhat-hgi",
      TY_LE_HTTC_HGI: rootUrl + "nghiep-vu-duoc/nvd-ty-le-hinh-thuc-to-chuc-hgi",
      DS_GCN_THEO_LOAI_HGI: rootUrl + "nghiep-vu-duoc/nvd-gcn-theo-loai",
    },
  },
  VAN_BAN_DIEU_HANH: {
    THONG_TIN_CHUNG: {
      LAY_DS_DON_VI: rootUrl + "van-ban-dieu-hanh/don-vi",
      THONG_KE_2_CHI_TIEU: rootUrl + "van-ban-dieu-hanh/thong-ke-2-chi-tieu",
      TK_VB_DI_DEN_THEO_DON_VI:
        rootUrl + "van-ban-dieu-hanh/tk-vb-di-den-theo-don-vi",
      TK_VB_DI_DEN_KY_SO: rootUrl + "van-ban-dieu-hanh/tk-vb-di-den-ky-so",
      TK_VB_DEN_TRE_HAN: rootUrl + "van-ban-dieu-hanh/tk-vb-den-tre-han",
      TK_TINH_HINH_PHE_DUYET_VB_DEN:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-phe-duyet-vb-den",
      LAY_DS_PHONG_BAN: rootUrl + "van-ban-dieu-hanh/phong-ban",
    },
    XU_LY_VAN_BAN_THEO_THANG: {
      TK_TINH_HINH_KY_SO_VB_DI_DEN:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-ky-so-vb-di-den",
      TK_TINH_HINH_DUYET_VB_DEN:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-duyet-vb-den",
      TK_TINH_HINH_TIEP_NHAN_VB_DEN:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-tiep-nhan-vb-den",
      TK_HINH_HINH_XL_VB_DEN_BI_TRE_HAN:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-xl-vb-den-bi-tre-han",
      TK_HINH_HINH_SD_HE_THONG:
        rootUrl + "van-ban-dieu-hanh/tk-tinh-hinh-sd-he-thong",
    },
    XU_LY_VAN_BAN_THEO_DON_VI: {
      TK_TOP_5_DON_VI_CO_VB_DI_DEN_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/tk-top-5-don-vi-co-vb-di-den-nhieu-nhat",
      TK_TOP_5_DON_VI_CO_VB_DEN_TRE_HAN_NHIEU_NHAT:
        rootUrl +
        "van-ban-dieu-hanh/tk-top-5-don-vi-co-vb-den-tre-han-nhieu-nhat",
      TK_TOP_5_DON_VI_CO_SO_NGUOI_TRUY_CAP_NHIEU_NHAT:
        rootUrl +
        "van-ban-dieu-hanh/tk-top-5-don-vi-co-so-nguoi-truy-cap-nhieu-nhat",
      TK_TOP_5_DON_VI_CO_NHIEU_VB_DEN_CHUA_TIEP_NHAN:
        rootUrl +
        "van-ban-dieu-hanh/tk-top-5-don-vi-co-nhieu-vb-den-chua-tiep-nhan",
    },
    XU_LY_VAN_BAN_THEO_PHONG_BAN: {
      TK_VB_DI_DEN_THEO_PHONG_BAN:
        rootUrl + "van-ban-dieu-hanh/tk-vb-di-den-theo-phong-ban",
      TK_TOP_10_CA_NHAN_NHAN_VB_DEN_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/tk-top-10-ca-nhan-nhan-vb-den-nhieu-nhat",
      TK_TOP_10_CA_NHAN_THAM_MUU_VB_DI_NHIEU_NHAT:
        rootUrl +
        "van-ban-dieu-hanh/tk-top-10-ca-nhan-tham-muu-vb-di-nhieu-nhat",
      TK_VB_DEN_TRE_HAN_THEO_PHONG_BAN:
        rootUrl + "van-ban-dieu-hanh/tk-vb-den-tre-han-theo-phong-ban",
    },
    THONG_TIN_CHUNG_TINH_HINH_XU_LY: {
      CAC_CHI_SO_VAN_BAN_DEN: rootUrl + "van-ban-dieu-hanh/vbden",
      CAC_CHI_SO_VAN_BAN_DI: rootUrl + "van-ban-dieu-hanh/vbdi",
      TK_VB_DEN_VA_DEN_QUA_HAN_THEO_THANG:
        rootUrl + "van-ban-dieu-hanh/vbden-theothang",
      CAC_CHI_SO_VAN_BAN_DI_LIEN_THONG_THEO_THANG:
        rootUrl +
        "van-ban-dieu-hanh/thong-tin-chung-tinh-hinh-xu-ly-vbdi-theothang",
      TK_SO_LUONG_TRUY_CAP_THEO_THANG:
        rootUrl + "van-ban-dieu-hanh/thongketruycap",
    },
    HIEU_SUAT_XU_LY_VA_DIEU_HANH: {
      TOP5_DON_VI_CO_VB_DEN_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/vbden-top5",
      TRUNG_BINH_SO_LUONG_VB_DEN: rootUrl + "van-ban-dieu-hanh/vbden-trungbinh",
      TOP5_DON_VI_CO_VB_DEN_DUNG_HAN_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/vbden-top5-dunghan",
      TOP5_DON_VI_CO_VB_DEN_QUA_HAN_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/vbden-top5-quahan",
      TOP5_TRUY_CAP_HE_THONG: rootUrl + "van-ban-dieu-hanh/thongketruycap-top5",
      TRUNG_BINH_SO_LUONG_VB_DI: rootUrl + "van-ban-dieu-hanh/vbdi-trungbinh",
      TOP5_DON_VI_CO_VB_DI_NHIEU_NHAT: rootUrl + "van-ban-dieu-hanh/vbdi-top5",
      TOP5_DON_VI_CO_VB_LIEN_THONG_NHIEU_NHAT:
        rootUrl + "van-ban-dieu-hanh/vbdi-lienthong-top5",
    },
    TK_DON_VI_TRUC_THUOC_SO_Y_TE: {
      TK_VB_DEN_THEO_DON_VI: rootUrl + "van-ban-dieu-hanh/vbden-donvi",
      TK_VB_DI_THEO_DON_VI: rootUrl + "van-ban-dieu-hanh/vbdi-donvi",
      TK_SL_TRUY_CAP_THEO_DON_VI:
        rootUrl + "van-ban-dieu-hanh/thongketruycap-donvi",
    },
    QUAN_LY_CONG_VIEC: {
      CONG_VIEC: rootUrl + "van-ban-dieu-hanh/congviec",
      CONG_VIEC_DON_VI: rootUrl + "van-ban-dieu-hanh/congviec-donvi",
    },
  },
  TAI_CHINH_KE_HOACH: {
    SO_LIEU_TK_TONG: rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/7-the-tong",
    TONG_TTB_DON_VI:
      rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/don-vi-so-luong",
    TTB_TRANG_THAI: rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/trang-thai",
    TTB_NGUON_GOC: rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/nguon-goc",
    TTB_NAM_SU_DUNG: rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/nam-su-dung",
    CTYT_LUOT_KHAM_BENH:
      rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/luot-kham-benh",
    CTYT_DIEUTRI_NOITRU:
      rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/dieu-tri-noi-tru",
    CTYT_TNGT: rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/tai-nan-giao-thong",
    CTYT_THDB: rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/tinh-hinh-dich-benh",
    CTYT_NHIEM_BENH_HIV_AIDS_TV:
      rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/tinh-hinh-nhiem-hiv-aids-tv",
    CTYT_TINH_HINH_ATVSTP : rootUrl + "tai-chinh-ke-hoach/cong-tac-y-te/tinh-hinh-atvstp-hgi",
    TTB_LIST_TTB_BY_DONVI:
      rootUrl + "tai-chinh-ke-hoach/trang-thiet-bi/list-trang-thiet-bi-don-vi",
    BAO_CAO_TH_TAI_SAN: rootUrl + "tai-chinh-ke-hoach/bao-cao-tong-hop/tai-san",
    BAO_CAO_TH_NGUON_VON:
      rootUrl + "tai-chinh-ke-hoach/bao-cao-tong-hop/nguon-von",
    BAO_CAO_TH_4_THE_TONG:
      rootUrl + "tai-chinh-ke-hoach/bao-cao-tong-hop/4-the-tong",
    DANHSACH_TAISAN_LAY_THEONAM:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-dien-tich-dat",
    DANHSACH_TAISAN_LAY_DIENTICH_DAT:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-dien-tich-dat",
    DANHSACH_TAISAN_LAY_HIENTRANG_NHA:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-hien-trang-nha",
    DANHSACH_TAISAN_LAY_NGUYENGIA_NHA_DAT:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-nguyen-gia-nha-va-dat",
    DANHSACH_TAISAN_LAY_HIENTRANG_CONGTRINH:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-hien-trang-cong-trinh",
    NAM_DUA_VAO_SUDUNG:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-nam-dua-vao-su-dung",
    DANHSACH_TAISAN_LAY_DIENTICH_DAT_VA_NHA:
      rootUrl +
      "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-tai-san-theo-dien-tich-dat-va-dien-tich-nha",
    DANHSACH_ID_TEN_DONVI:
      rootUrl + "tai-chinh-ke-hoach/tai-san-nha-nhat/liet-ke-id-ten-don-vi",
    SO_LIEU_THONG_KE_DU_AN: rootUrl + "tai-chinh-ke-hoach/so-lieu-thong-ke-du-an",
    SO_LIEU_THONG_KE_DU_AN_THEO_LOAI: rootUrl + "tai-chinh-ke-hoach/so-lieu-thong-ke-du-an-theo-loai",
    TAICHINH_KETOAN_TONG:
      rootUrl + "tai-chinh-ke-hoach/tai-chinh-ke-toan-tong",
    TINHHINH_TAICHINH:
      rootUrl + "tai-chinh-ke-hoach/tinh-hinh-tai-chinh",
    KINH_PHI_HOAT_DONG:
      rootUrl + "tai-chinh-ke-hoach/kinh-phi-hoat-dong",
    HOAT_DONG_DAU_TU:
      rootUrl + "tai-chinh-ke-hoach/hoat-dong-dau-tu-cong",
    HOATDONG_TAICHIH_TONGHOP:
      rootUrl + "tai-chinh-ke-hoach/hoat-dong-tong-hop",
  },
  TO_CHUC_CAN_BO: {
    TRINH_DO_CHUYEN_MON: rootUrl + "to-chuc-can-bo/trinh-do-chuyen-mon",
    QUAN_LY_NHA_NUOC: rootUrl + "to-chuc-can-bo/quan-ly-nha-nuoc",
    TY_LE_VIEN_CHUC_HOP_DONG:
      rootUrl + "to-chuc-can-bo/ty-le-vien-chuc-hop-dong",
    TY_LE_VIEN_CHUC_GIOI_TINH:
      rootUrl + "to-chuc-can-bo/ty-le-vien-chuc-gioi-tinh",
    TY_LE_TRINH_DO_CHINH_TRI: rootUrl + "to-chuc-can-bo/ty-le-chinh-tri",
    THE_TONG: rootUrl + "to-chuc-can-bo/4-the-tong",
    DANH_MUC_DONVI: rootUrl + "danh-muc-dung-chung/danh-muc-donvi",
    DANH_SACH_BS: rootUrl + "to-chuc-can-bo/tk_tong_theo_bs",
  },
  GIAM_DINH_Y_KHOA: {
    GIAM_DINH_PHAP_Y: {
      TK_SOCA_GIAMDINH: rootUrl + "giam-dinh-y-khoa/so-ca-kham-giam-dinh",
      TK_SOCA_GIAMDINH_MUCDO_KHUYETTAT:
        rootUrl + "giam-dinh-y-khoa/so-ca-kham-giam-dinh-khuyet-tat",
    },
    TT_PHAP_Y: {
      TK_NHAN_LUC: rootUrl + "phap-y/nhan-luc",
      TK_SO_CA_GIAM_DINH_PHAP_Y: rootUrl + "phap-y/so-ca-giam-dinh",
    },
  },
  CHI_CUC_DAN_SO: {
    TK_DAN_SO_THEO_HUYEN: rootUrl + "danso/thong-ke-dan-so-cac-huyen",
    TK_DAN_SO_THEO_HUYEN_HGI: rootUrl + "nhan-khau/thong-ke-dan-so-cac-huyen",
    TL_GIOI_TINH: rootUrl + "danso/thong-ke-dan-so-theo-gioi-tinh",
    TL_GIOI_TINH_HGI: rootUrl + "nhan-khau/thong-ke-dan-so-theo-gioi-tinh",
    TL_THANHTHI_NONGTHON:
      rootUrl + "danso/thong-ke-dan-so-theo-thanh-thi-nong-thon",
    TL_SANG_LOC_SO_SINH_TRUOC_SINH: rootUrl + "nhan-khau/thong-ke-dan-so-sang-loc-so-sinh-truoc-sinh",
    TK_GT_NHOM_TUOI: rootUrl + "danso/thong-ke-dan-so-theo-do-tuoi",
    TK_GT_NHOM_TUOI_HGI: rootUrl + "nhan-khau/thong-ke-dan-so-theo-do-tuoi",
    TK_SD_TRANH_THAI: rootUrl + "danso/thong-ke-dan-so-bien-phap-tranh-thai",
    TK_SD_TRANH_THAI_HGI: rootUrl + "nhan-khau/thong-ke-dan-so-bien-phap-tranh-thai",
    TK_SINH_CON_THU_3: rootUrl + "danso/thong-ke-ty-le-sinh-con-thu-3-tro-len",
    TK_SINH_CON_THU_3_HGI: rootUrl + "nhan-khau/thong-ke-dan-so-sinh-con-thu-3-tro-len",
    TK_QUYMO_CHATLUONG_DOTUOI: rootUrl + "danso/thongke-quymo-chatluong-dotuoi-khac",
    TK_TY_LE_DAN_SO_TU_NHIEN: rootUrl + "chi-cuc-dan-so/get-ty-le-dan-so-tu-nhien",
    TK_TY_LE_DAN_SO_GIOI_TINH: rootUrl + "chi-cuc-dan-so/get-ty-le-dan-so-gioi-tinh",
    TK_TY_LE_DAN_SO_BP_TRANH_THAI: rootUrl + "chi-cuc-dan-so/get-ty-le-dan-so-bien-phap-tranh-thai",
    TK_TY_LE_DAN_SO_TUOI_LAO_DONG: rootUrl + "chi-cuc-dan-so/get-ty-le-dan-so-tuoi-lao-dong",
    TK_TY_LE_DAN_SO_TRINH_DO_VAN_HOA: rootUrl + "chi-cuc-dan-so/get-ty-le-dan-so-trinh-do-van-hoa",
  },
  CHI_CUC_ATVSTP: {
    TK_DV_AN_UONG: rootUrl + "an-toan-ve-sinh-thuc-pham/tk-dich-vu-an-uong",
    TL_DV_AN_UONG: rootUrl + "an-toan-ve-sinh-thuc-pham/tl-dich-vu-an-uong",
    TK_CO_SO_KINH_DOANH:
      rootUrl + "an-toan-ve-sinh-thuc-pham/tk-co-so-kinh-doanh",
    TL_CO_SO_KINH_DOANH:
      rootUrl + "an-toan-ve-sinh-thuc-pham/tl-co-so-kinh-doanh",
    TK_TU_CONG_BO_SP: rootUrl + "an-toan-ve-sinh-thuc-pham/tk-tu-cong-bo-sp",
    TT_THEO_LOAI_HINH: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-theo-loai-hinh",
    TT_THEO_LOAI_HINH_HGI: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-theo-loai-hinh-hgi",
    TT_THEO_DOT: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-theo-dot",
    TT_THEO_DOT_HGI: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-theo-dot-hgi",
    TT_TONG: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-tong",
    THANH_TRA_TONG_HGI: rootUrl + "an-toan-ve-sinh-thuc-pham/thanh-tra-tong-hgi",
    TONG_ATVSLD: rootUrl + "an-toan-ve-sinh-thuc-pham/atvstp-the-tong",
    NHOM_LOAI_SP_CAP_GCN:
      rootUrl + "an-toan-ve-sinh-thuc-pham/nhom-loai-sp-cap-gcn",
    DVAU_CAP_GCN: rootUrl + "an-toan-ve-sinh-thuc-pham/dvau-cap-gcn",
    NHOM_LOAI_SP_HET_HAN:
      rootUrl + "an-toan-ve-sinh-thuc-pham/nhom-loai-sp-het-han",
    DVAU_HET_HAN: rootUrl + "an-toan-ve-sinh-thuc-pham/dvau-het-han",
    LOAI_CO_SO_CAP_GCN: rootUrl + "an-toan-ve-sinh-thuc-pham/loai-co-so",
    NHOM_LOAI_SP_TU_CONG_BO: rootUrl + "an-toan-ve-sinh-thuc-pham/tu-cong-bo",
    CO_SO_CAP_GCN_ATTP: rootUrl + "an-toan-ve-sinh-thuc-pham/co-so-tong",
    So_LUONG_CO_SO: rootUrl + "an-toan-ve-sinh-thuc-pham/co-so-don-vi",
    So_LUONG_CO_SO_CAP_GCN: rootUrl + "an-toan-ve-sinh-thuc-pham/co-so-cap-gcn-don-vi",
    So_LUONG_SAN_PHAM_TU_CONG_BO: rootUrl + "an-toan-ve-sinh-thuc-pham/co-so-tu-cong-bo-don-vi",
    So_LUONG_SAN_PHAM_TU_CONG_BO_HUY: rootUrl + "an-toan-ve-sinh-thuc-pham/co-so-tu-cong-bo-bi-huy-don-vi",
  },
  TT_KN_DUOCPHAM_MYPHAM: {
    GET_TONG_4_THE: rootUrl + "kn-duoc-my-pham/4-the-tong",
    GET_MAU_LAY: rootUrl + "kn-duoc-my-pham/mau-lay",
    GET_MAU_GUI: rootUrl + "kn-duoc-my-pham/mau-gui",
    GET_MAU_LAY_KHONG_DAT: rootUrl + "kn-duoc-my-pham/mau-lay-khong-dat",
    GET_MAU_GUI_KHONG_DAT: rootUrl + "kn-duoc-my-pham/mau-gui-khong-dat",
    GET_MAU_GUI_THEO_CSKCB:
      rootUrl + "kn-duoc-my-pham/mau-lay-gui-theo-loai-co-so",
  },
  THANH_TRA_YTE: {
    THANH_TRA_SYT: rootUrl + "thanh-tra-syt/5-the-tong-ttkt",
    TONG_VI_PHAM: rootUrl + "thanh-tra-syt/5-the-tong-vi-pham",
    THANH_TRA_KIEM_TRA_CK:
      rootUrl + "thanh-tra-syt/so-sanh-ttkt-luy-ke_cung_ky",
    THANH_TRA_KIEM_TRA_TC_CK:
      rootUrl + "thanh-tra-syt/so-sanh-ttkt-to-chuc-luy-ke_cung_ky",
    THANH_TRA_KIEM_TRA_CN_CK:
      rootUrl + "thanh-tra-syt/so-sanh-ttkt-ca-nhan-luy-ke_cung_ky",
    TIEN_PHAT_SINH_LUYKE_CK:
      rootUrl + "thanh-tra-syt/so-sanh-tien-phat-luy-ke_cung_ky",
    DAI_DIEN_TREN_MOT_DON: rootUrl + "thanh-tra-syt/thong-ke-dai-dien-tren-don",
    PHAN_LOAI_THEO_NOI_DUNG: rootUrl + "thanh-tra-syt/phan-loai-theo-noi-dung",
    PHAN_LOAI_THEO_TINH_TRANG:
      rootUrl + "thanh-tra-syt/phan-loai-theo-tinh-trang",
    KET_QUA_XU_LY_THUOC_THAM_QUYEN:
      rootUrl + "thanh-tra-syt/ket-qua-xu-ly-thuoc-tham-quyen",
    KET_QUA_XU_LY_KHONG_THUOC_THAM_QUYEN:
      rootUrl + "thanh-tra-syt/ket-qua-xu-ly-khong-thuoc-tham-quyen",
    XU_LY_DON_6_THE_TONG: rootUrl + "thanh-tra-syt/6-the-tong",
    NOIDUNG_CANHAN_VIPHAM_CUNGKY: rootUrl + "thanh-tra-syt/noidung-canhan-vipham-cungky",
    NOIDUNG_TIEN_VIPHAM_CUNGKY: rootUrl + "thanh-tra-syt/noidung-tien-vipham-cungky",
    NOIDUNG_DOITUONG_KHOITO_CUNGKY: rootUrl + "thanh-tra-syt/noidung-doituong-khoito-cungky",
    NOIDUNG_TIEN_VIPHAM_DONVI: rootUrl + "thanh-tra-syt/noidung-tien-vipham-donvi",
    THANH_TRA_LINH_VUC_4_THE_TONG: rootUrl + "thanh-tra-syt/4-thetong-linhvuc",
    LINH_VUC_VI_PHAM_CUNG_KY: rootUrl + "thanh-tra-syt/linhvuc-vipham-cungky",
  },
  TAMTHANKINH_PHONG_DALIEU: {
    VIEW_TAMTHANKINH_PHONG_DALIEU: 'reports/tam-than-kinh-phong-da-lieu/view-tam-than-kinh-phong-dalieu',
    VIEW_PHATHIEN_BENHMOI: 'reports/tam-than-kinh-phong-da-lieu/view-phat-hien-benh-moi',
    VIEW_BENHNHAN_QUANLY: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-quan-ly',
    VIEW_BENHNHAN_DHTL: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-DHTL',
    VIEW_BENHNHAN_HOANTHANH_DHTL: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-hoan-thanh-DHTL',
    VIEW_BENHNHAN_GIAMSAT: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-giam-sat',
    VIEW_NGUOITIEPXUC_BENHNHAN_PHONG: 'reports/tam-than-kinh-phong-da-lieu/view-nguoi-tiep-xuc-BN-phong',
    VIEW_BENHNHAN_STI: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-STI',
    VIEW_BENHNHAN_LAU: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-lau',
    VIEW_BENHNHAN_GIANGMAI: 'reports/tam-than-kinh-phong-da-lieu/view-benh-nhan-giang-mai',
    VIEW_BENHNHAN_CHUAKHOIONDINH_TTDK: 'reports/tam-than-kinh-phong-da-lieu/view-chua-khoi-on-dinh-tt-dk',
  },
  PHONG_DA_LIEU:{
    GET_CHI_TIEU_PHONG_DA_LIEU : rootUrl + "tam-than-kinh-phong-da-lieu/benhnhan-phong-dalieu",
    GET_PHONG_DA_LIEU_THEO_DONVI : rootUrl + "tam-than-kinh-phong-da-lieu/benhnhan-phong-dalieu-donvi",
    GET_TS_BENHNHAN_TAMTHAN : rootUrl + "tam-than-kinh-phong-da-lieu/ts-benhnhan-tamthan",
    GET_CHI_TIEU_TAM_THAN_THEO_DONVI : rootUrl + "tam-than-kinh-phong-da-lieu/ct-benhnhan-donvi",
    GET_CHI_TIEU_TAM_THAN : rootUrl + "tam-than-kinh-phong-da-lieu/ct-benhnhan",
  },
  TRUNG_TAM_KIEM_NGHIEM:{
    GET_CHI_TIEU_KY_THUAT_KIEM_NGHIEM : rootUrl + "ky-thuat-kiem-nghiem/so-lieu-chi-tieu-phuong-phap-kiem-nghiem",
    GET_CHI_TIEU_DINH_TINH : rootUrl + "ky-thuat-kiem-nghiem/so-lieu-chi-tieu-dinh-tinh",
    GET_CHI_TIEU_DINH_LUONG : rootUrl + "ky-thuat-kiem-nghiem/so-lieu-chi-tieu-dinh-luong",
  },
  BAO_CAO_DAN_SO_KHHGD: {
    BAO_CAO_SANG_LOC_SO_SINH: 'syt/view-sang-loc-so-sinh',
    KH_DANSO_KHH_GIADINH: 'syt/view-khds-khhgd',
    TYLE_SINH_CON_LAN3_TRO_LEN: 'syt/view-sinh-con-lan3-tro-len',
    BAO_CAO_DANH_SACH_SANG_LOC_TRUOC_SINH: 'syt/view-sang-loc-truoc-sinh'
  },
  TRUNG_TAM_KIEM_SOAT_BENH_TAT: {
    PHONG_CHONG_UNG_THU: 'syt/view-bao-cao-phong-chong-ung-thu',
    PHONG_CHONG_HEN_PHE_QUAN: 'syt/view-bao-cao-phong-chong-hen-phe-quan',
    PHONG_CHONG_BENH_PHOI_TAT_NGHEN_MAN_TINH: 'syt/view-bao-cao-phong-chong-benh-phoi-tat-nghen-man-tinh',
    PHONG_CHONG_DAI_THAO_DUONG: 'syt/view-bao-cao-phong-chong-dai-thao-duong',
    PHONG_CHONG_TANG_HUYET_AP: 'syt/view-bao-cao-phong-chong-tang-huyet-ap',
    PHONG_CHONG_THIEU_VITAMIN_A: 'syt/view-bao-cao-phong-chong-thieu-vitamin-a',
    PHONG_CHONG_SOT_RET: 'syt/view-bao-cao-phong-chong-sot-ret',
    TIEM_CHUNG_MO_RONG: 'syt/view-bao-cao-tiem-chung-mr',
    PHONG_CHONG_SOT_XUAT_HUYET: 'syt/view-bao-cao-phong-chong-sot-xuat-huyet'
  },
  BAO_CAO_PHONG_THANH_TRA: {
    LV_Y_BCTT: 'syt/view-bao-cao-thanh-tra-linh-vuc-y',
    LV_Y_BCKT: 'syt/view-bao-cao-kiem-tra-linh-vuc-y',
    LV_Y_VIPHAM: 'syt/view-bao-cao-co-so-y-vi-pham',
    LV_DUOC_BCTT: 'syt/view-bao-cao-thanh-tra-linh-vuc-duoc',
    LV_DUOC_BCKT: 'syt/view-bao-cao-kiem-tra-linh-vuc-duoc',
    LV_DUOC_VIPHAM: 'syt/view-bao-cao-co-so-duoc-vi-pham',
    TIEN_VIPHAM: 'syt/view-bao-cao-tien-vi-pham',
    NGUOI_BI_KHOI_TO: 'syt/view-bao-cao-nguoi-bi-khoi-to',
  },
  BAO_CAO_CHI_CUC_ATVSTP: {
    SO_LUONG_CO_SO_THUC_PHAM_THEO_LOAI: "syt/view-bao-cao-so-luong-co-so-thuc-pham-theo-loai",
    KET_QUA_KIEM_TRA_THANH_TRA: "syt/view-bao-cao-ket-qua-kiem-tra-thanh-tra",
  },
  BAO_CAO_TRUNG_TAM_PHAP_Y: {
    CONG_TAC_PHAP_Y_THEO_THANG: 'syt/view-bao-cao-cong-tac-phap-y'
  },
  BAO_CAO_TRUNG_TAM_GIAM_DINH_Y_KHOA: {
    CONG_TAC_GIAM_DINH_Y_KHOA_THEO_QUI: 'syt/view-bao-cao-giam-dinh-chung'
  },
  GIAM_DINH_Y_KHOA_HGI: {
    GIAM_DINH_PHAP_Y_HGI: {
      TK_SOCA_GIAMDINH: rootUrl + "giam-dinh-y-khoa-hgi/so-ca-kham-giam-dinh",
      TK_SOCA_GIAMDINH_MUCDO_KHUYETTAT:
        rootUrl + "giam-dinh-y-khoa-hgi/so-ca-kham-giam-dinh-khuyet-tat",
      TL_CAKHAM_GIAMDINH: rootUrl + "giam-dinh-y-khoa-hgi/ty-le-ca-kham-giam-dinh",
    },
    TT_PHAP_Y_HGI: {
      TK_NHAN_LUC: rootUrl + "phap-y-hgi/nhan-luc",
      TK_SO_CA_GIAM_DINH_PHAP_Y: rootUrl + "phap-y-hgi/so-ca-giam-dinh",
    },
  },
  TT_KN_DUOCPHAM_MYPHAM_HGI: {
    GET_TONG_9_THE: rootUrl + "kn-duoc-my-pham-hgi/9-the-tong",
    GET_TYLE_KN_DUOC_MYPHAM: rootUrl + "kn-duoc-my-pham-hgi/ty-le-kiem-nghiem-duoc-my-pham-dat",
    GET_TYLE_GUI_KN_DUOC_MYPHAM: rootUrl + "kn-duoc-my-pham-hgi/ty-le-gui-kiem-nghiem-duoc-my-pham-dat",
    GET_TYLE_LAY_KN_DUOC_MYPHAM: rootUrl + "kn-duoc-my-pham-hgi/ty-le-lay-kiem-nghiem-duoc-my-pham-dat",
  },
  DASHBOARD_VLG: {
    CHI_SO_TAI_CHINH_Y_TE: {
      GET_CARDS_CHI_NSNN: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/cards-chi-nsnn',
      GET_CHART_THEO_NGUON_TAI_CHINH: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/chart-chi-tieu-y-te-theo-nguon-tai-chinh',
      GET_CHART_THEO_LOAI_HOAT_DONG: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/chart-chi-tieu-y-te-theo-loai-hoat-dong',
      GET_CARDS_TONG_CHI_Y_TE: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/cards-tong-chi-y-te',
      GET_CHART_DAN_SO_TRUNG_BINH: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/chart-dan-so-trung-binh',
      GET_CHART_TY_GIA_HOI_DOAI_BINH_QUAN: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/chart-ty-gia-hoi-doai-binh-quan',
      GET_CHART_CHI_TIEU_CONG_CHO_Y_TE_THEO_NGUON_VON: rootUrl + 'dashboard-vlg/chi-so-tai-chinh-y-te/chart-chi-tieu-cong-cho-y-te-theo-nguon-von',
    },
    CHI_SO_CHAT_LUONG_AN_TOAN: {
      GET_CARDS: rootUrl + 'dashboard-vlg/chi-so-chat-luong-an-toan/cards',
      GET_CHART_NGUOI_BENH_HAI_LONG: rootUrl + 'dashboard-vlg/chi-so-chat-luong-an-toan/chart-nguoi-benh-hai-long',
      GET_CHART_KIEM_TRA_MAU_THUOC: rootUrl + 'dashboard-vlg/chi-so-chat-luong-an-toan/chart-kiem-tra-mau-thuoc',
    },
    CHI_SO_TINH_TRANG_SUC_KHOE: {
      GET_CARDS: rootUrl + 'dashboard-vlg/chi-so-tinh-trang-suc-khoe/cards',
      THONG_TIN_CHUNG: rootUrl + "dashboard-vlg/chi-so-tt-suc-khoe/thong-tin-chung",
      TY_LE_HIV: rootUrl + "dashboard-vlg/chi-so-tt-suc-khoe/ty-le-hiv",
    },
    CHI_SO_NHAN_LUC_YTE: {
      DANH_MUC_DONVI: rootUrl + "danh-muc-dung-chung/danh-muc-donvi",
      THE_TONG: rootUrl + "dashboard-vlg/nhan-luc-y-te/5-the-tong",
      CHUYEN_MON: rootUrl + "dashboard-vlg/nhan-luc-y-te/chuyen-mon",
      TRINH_DO: rootUrl + "dashboard-vlg/nhan-luc-y-te/trinh-do",
    },
    CHI_SO_DO_BAO_PHU_VA_AH_BIEN_PHAP_CAN_THIEP: {
      THE_TONG_14: rootUrl + "dashboard-vlg/do-bao-phu-ah-bien-phap-can-thiep/14-the-tong",
      SL_BENH_TRUYEN_NHIEM: rootUrl + "dashboard-vlg/do-bao-phu-ah-bien-phap-can-thiep/sl-benh-truyen-nhiem",
      TL_TU_VONG: rootUrl + "dashboard-vlg/do-bao-phu-ah-bien-phap-can-thiep/ty-le-tu-vong"
    },
    CHI_SO_NHIEM_VU: {
      THE_TONG_8: rootUrl + "dashboard-vlg/theo-doi-nhiem-vu/8-the-tong",
      TL_GIAO_BO_Y_TE: rootUrl + "dashboard-vlg/theo-doi-nhiem-vu/ty-le-trang-thai-giao-bo-y-te",
      TL_GIAO_DON_VI: rootUrl + "dashboard-vlg/theo-doi-nhiem-vu/ty-le-trang-thai-giao-don-vi"
    },
    CHI_SO_CO_SO_HA_TANG: {
      THONG_TIN_CHUNG: rootUrl + "dashboard-vlg/chi-so-co-so-ha-tang/thong-tin-chung",
      LOAI_GIUONG: rootUrl + "dashboard-vlg/chi-so-co-so-ha-tang/loai-giuong",
      LOAI_MAY: rootUrl + "dashboard-vlg/chi-so-co-so-ha-tang/loai-may",
    },
    CHI_SO_HANH_VI: {
      THONG_TIN_CHUNG: rootUrl + "dashboard-vlg/chi-so-hanh-vi/thong-tin-chung",
      CUNG_KY: rootUrl + "dashboard-vlg/chi-so-hanh-vi/cung-ky",
    },
    THONG_TIN_CHUNG: {
      TK_LUOT_KHAM_BENH: rootUrl + "dashboard-vlg/cssd-dvyt/tk-luot-kham-benh", // bđ1
      TK_NOI_TRU_NGOAI_TRU: rootUrl + "dashboard-vlg/cssd-dvyt/tk-luot-kham-benh-ngoai-tru-noi-tru",
      TK_LUOT_RA_VAO_VIEN: rootUrl + "dashboard-vlg/cssd-dvyt/tk-sl-ra-vao-vien",
      TK_SL_CAP_CUU_TU_VONG: rootUrl + "dashboard-vlg/cssd-dvyt/tk-sl-cap-cuu-tu-vong",
      TK_HS_KB: rootUrl + "dashboard-vlg/cssd-dvyt/sl-tong-hoso-kcb",
      SL_NOI_TRU: rootUrl + "dashboard-vlg/cssd-dvyt/sl-noi-tru",
      SL_NGOAI_TRU: rootUrl + "dashboard-vlg/cssd-dvyt/sl-ngoai-tru",
      SL_CUOI_KY_NOI_TRU: rootUrl + "dashboard-vlg/cssd-dvyt/sl-cuoi-ky-noi-tru",
      SL_LUOT_VAO_VIEN: rootUrl + "dashboard-vlg/cssd-dvyt/tk-luot-vao-vien",
      SL_LUOT_RA_VIEN: rootUrl + "dashboard-vlg/cssd-dvyt/tk-luot-ra-vien",
      SL_CHUYEN_VIEN: rootUrl + "dashboard-vlg/cssd-dvyt/sl-chuyen-vien",
      SL_THONGTIN_CHUNG: rootUrl + "dashboard-vlg/cssd-dvyt/sl-thong-tin-chung"
    },
    CHI_SO_COVID: {
      THONG_TIN_CHUNG: rootUrl + "dashboard-vlg/chi-so-covid/thong-tin-chung",
      TK_THEO_HUYEN: rootUrl + "dashboard-vlg/chi-so-covid/theo-huyen",
      TK_THEO_LOAI: rootUrl + "dashboard-vlg/chi-so-covid/theo-loai",
    }
  }
};
