import { TrangThietBiComponent } from "./trang-thiet-bi/trang-thiet-bi.component";
import { BaoCaoTongHopComponent } from './bao-cao-tong-hop/bao-cao-tong-hop.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TaiSanNhaDatComponent } from "./tai-san-nha-dat/tai-san-nha-dat.component";
import { DauTuCongComponent } from "./dau-tu-cong/dau-tu-cong.component";
import { TaiChinhKeToanComponent } from "./tai-chinh-ke-toan/tai-chinh-ke-toan.component";
const routes: Routes = [
  { path: "", component: TrangThietBiComponent },
  { path: "trang-thiet-bi", component: TrangThietBiComponent },
  { path: "bao-cao-tong-hop", component: BaoCaoTongHopComponent},
  { path: "", component: TaiSanNhaDatComponent },
  { path: "tai-san-nha-dat", component: TaiSanNhaDatComponent },
  { path: "dau-tu-cong", component: DauTuCongComponent },
  { path: "tai-chinh-ke-toan", component: TaiChinhKeToanComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaiChinhKeHoachRoutingModule {}
