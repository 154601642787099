<div class="modal-body">
  <table mat-table [dataSource]="dataSource" class="w-100" style="text-align: center">
    <!-- <ng-container matColumnDef="STT" class="mat-card">
      <th mat-header-cell *matHeaderCellDef>STT</th>
      <td mat-cell *matCellDef="let element; let i = index;" class="text-center">
        {{ (i + 1) + paginator.pageIndex * paginator.pageSize }}
      </td>
    </ng-container> -->

    <!-- table dynamic columns -->
    <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        {{col.label}}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element[col.key] }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="d-flex justify-content-end">
    <mat-paginator class="w-100" #paginator [pageSize]="pageSizeDefault" [pageSizeOptions]="pageOption" (page)="pageChange($event)" showFirstLastButtons></mat-paginator>

  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="warn" type="button" (click)="modal.dismiss('Cross click')"> Đóng </button>
</div>