<div class= "main-wrapper bg-ioc vh-100">
    <div class="b-header" >
        <div class="d-flex justify-content-end mb-3 pt-3 w-100">
            <form class="dark-mode mr-4 w-100" [formGroup]="formSearch" id="formSearch">
                <div class="row align-items-center justify-content-end ">

                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn năm</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="nam">
                                <mat-option [value]="nam.GIA_TRI_NAM" *ngFor="let nam of listNam"> Năm {{ nam.GIA_TRI_NAM}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="thang">
                                <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </div>
            </form>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="!isFullScreen" matTooltip="Toàn màn hình">
                <mat-icon color="primary">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="isFullScreen" matTooltip="Thu nhỏ">
                <mat-icon color="primary">fullscreen_exit</mat-icon>
            </button>
        </div>
    </div>
    <div class="b-body pl-2 pr-2 pt-3">
        <div class="b-body-top">
            <div class="row small-gutters h-100">
                <div class="col-md-6 h-100 ">
                    <div class="bg-chart p-2 h-100">
                        <h3 class="chart-title">Biểu đồ thống kê nhân lực (năm)</h3>
                        <app-pie-chart #soCaGiamDinhPhapY></app-pie-chart>
                    </div>
                </div>
                <div class="col-md-6 h-100 ">
                    <div class="bg-chart p-2 h-100">
                        <h3 class="chart-title">Biểu đồ thống kê số ca giám định</h3>
                        <app-bar-chart #bieuDoGiamDinh [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>