import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GiamDinhYServive } from 'src/app/services/giam-dinh-phap-y.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';

@Component({
  selector: 'app-giam-dinh-phap-y',
  templateUrl: './giam-dinh-phap-y.component.html',
  styleUrls: ['./giam-dinh-phap-y.component.scss']
})
export class GiamDinhPhapYComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  documentElement: any;
  isFullScreen = false;
  listQuy = [];
  listThang = [];
  listNam = [];
  listDataGiamDinhY =[];
  listDataGiamKhuyetTat =[];
  showNam = false;
  showThang = false;
  showQuy = false;
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[1],
    COLOR_PALETTES.PALETTE_6[2],
    COLOR_PALETTES.RIVER_NIGHTS[3],
    COLOR_PALETTES.ORANGE_TO_PURPLE[4],
    COLOR_PALETTES.PALETTE_3[5],
    COLOR_PALETTES.PALETTE_3[6],
    COLOR_PALETTES.PALETTE_3[7],
    COLOR_PALETTES.RIVER_NIGHTS[8],
    COLOR_PALETTES.RIVER_NIGHTS[1],
    COLOR_PALETTES.RIVER_NIGHTS[0],
  ];
  public formSearch = new FormGroup({
    nam: new FormControl(new Date().getFullYear()),
    quy: new FormControl("Tất cả"),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    loai: new FormControl(0),
  });

  @ViewChild("soCaGiamDinh", { static: true }) soCaGiamDinh: BarChartComponent;
  @ViewChild("soCaGiamDinhKhuyetTat", { static: true }) soCaGiamDinhKhuyetTat: BarChartComponent;

  constructor(
    private giamDinhY: GiamDinhYServive,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.documentElement = document.documentElement;
    this.onFilterChangeCombobox()
    this.detectMode()
    this.getListNam()
    this.getListQuy()
    this.getListThang()
    this.getData()
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.buildStyles();
    this.soCaGiamDinh.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.soCaGiamDinhKhuyetTat.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 0); // no row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 1)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }
  toggleFullScreen() {
    this.documentElement = document.documentElement;
    if (!this.isFullScreen) {
      this.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }
  
  getListNam(){
    this.subscription.push(
      this.giamDinhY.getListNam().subscribe(
        (rs) => {
          this.listNam = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  getListThang(){
    this.subscription.push(
      this.giamDinhY.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  getListQuy(){
    this.subscription.push(
      this.giamDinhY.getListQuy().subscribe(
        (rs) => {
          this.listQuy = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  onFilterChange (){
    this.detectMode
    this.getData();
  }
  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    if(loai == 0){
      this.showNam = true
      this.showQuy = false
      this.showThang = false
    }else if(loai == 1 ){
      this.showNam = true
      this.showQuy = true
      this.showThang = false
    }else if(loai == 2){
      this.showNam = true
      this.showQuy = false
      this.showThang = true
    }else{
      this.showNam = false
      this.showQuy = false
      this.showThang = false
    }
    this.getData()
  }
  public getFilter() {
    let loai = this.formSearch.get("loai").value
    let nam  = this.formSearch.get("nam").value;
    let thang  = this.formSearch.get("thang").value == "Tất cả"?"": this.formSearch.get("thang").value;
    let quy = this.formSearch.get("quy").value == "Tất cả"?"": this.formSearch.get("quy").value;
    let result = {};
    if(loai == 0){
      result = {
        nam: nam,
        qui: "",
        thang: "",
      }
    }else if(loai == 1){
      result = {
        nam: nam,
        qui: quy,
        thang: "",
      }
    }else{
      result = {
        nam: nam,
        qui: '',
        thang: thang,
      }
    }
    console.log(result)
    return result;
  }
  public getTKSoCaGiamDinh(): void {
    let arrDonVi = []
    let arrKetQuaDuoi5 = []
    let arrKetQuaDuoi20 = []
    let arrKetQuaDuoi30 = []
    let arrKetQuaDuoi60 = []
    let arrKetQuaDuoi80 = []
    let arrKetQuaTren80 = []
    let arrKetKhongMatBenh = []
    let arrKetQuaCoMatBenh = []
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.giamDinhY.getTKSoCaGiamDinh(obj).subscribe(
        (rs) => {
          this.listDataGiamDinhY = rs.data;
          if (this.listDataGiamDinhY && this.listDataGiamDinhY.length > 0) {
            this.listDataGiamDinhY.forEach((e) => {
              arrKetQuaDuoi20.push(e.KET_QUA_5_DEN_20);
              arrKetQuaDuoi30.push(e.KET_QUA_21_DEN_30);
              arrKetQuaDuoi60.push(e.KET_QUA_31_DEN_60);
              arrKetQuaDuoi80.push(e.KET_QUA_61_DEN_80);
              arrKetQuaTren80.push(e.KET_QUA_TREN_80);
              arrKetKhongMatBenh.push(e.KHONG_PHAN_TRAM_KHONG_MAC_BENH);
              arrKetQuaCoMatBenh.push(e.KHONG_PHAN_TRAM_CO_MAC_BENH);
              arrDonVi.push(e.TEN_DOI_TUONG);
            });
          }
          //Set label for chart
          this.soCaGiamDinh.barChartLabels = arrDonVi;
          //Set data for chart
          this.soCaGiamDinh.barChartData = [
            {
              data: arrKetQuaDuoi5,
              label: "Đối tượng dưới 5",
              backgroundColor: "#70c158",
            },
            {
              data: arrKetQuaDuoi20,
              label: "Đối tượng từ 5 đến 20",
              backgroundColor: "#e7e441",
            },
            {
              data: arrKetQuaDuoi30,
              label: "Đối tượng từ 21 đến 30",
              backgroundColor: "#e5bc30",
            },
            {
              data: arrKetQuaDuoi60,
              label: "Đối tượng từ 31 đến 60",
              backgroundColor: "#e89632",
            },
            {
              data: arrKetQuaDuoi80,
              label: "Đối tượng từ 61 đến 80",
              backgroundColor: "#e86131",
            },
            {
              data: arrKetQuaTren80,
              label: "Đối tượng trên 80",
              backgroundColor: "#e72e3e",
            },
            {
              data: arrKetKhongMatBenh,
              label: "Không mắc bệnh",
              backgroundColor: "#c94b9c",
            },
            {
              data: arrKetQuaCoMatBenh,
              label: "Có mắc bệnh",
              backgroundColor: "#8c56a4",
            }
          ];
          //Set option for chart
          this.soCaGiamDinh.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:true
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '1'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  public getTKSoCaGiamDinhKhuyetTat(): void {
    let obj: any = this.getFilter();
    // obj.rowNum = 10;
    this.subscription.push(
      this.giamDinhY.getTKSoCaGiamDinhKhuyetTat(obj).subscribe(
        (rs) => {
          this.listDataGiamKhuyetTat = []
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.TEN_MUC_DO == "Nhẹ"),{COLOR:'#8cc63e'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.TEN_MUC_DO == "Không khuyết tật"),{COLOR:'#e89632'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.TEN_MUC_DO == "Nặng"),{COLOR:'#e86131'}))
          this.listDataGiamKhuyetTat.push( Object.assign(rs.data.find(x=>x.TEN_MUC_DO == "Đặc biệt nặng"),{COLOR:'#e72e3e'}))
          this.soCaGiamDinhKhuyetTat.barChartLabels = this.listDataGiamKhuyetTat.map((x) =>x.TEN_MUC_DO );
          
          this.soCaGiamDinhKhuyetTat.barChartData = [
            {
              data: this.listDataGiamKhuyetTat.map((x) => Math.round(x.TONG_SO_DOI_TUONG)),
              // label: "Số tiền",
              backgroundColor: this.listDataGiamKhuyetTat.map((x) =>x.COLOR)
            }
          ];
          //Set option for chart
          this.soCaGiamDinhKhuyetTat.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
             
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: { fontColor: "#fff" },
              display:false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { 
                    fontColor: "#fff",
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '1'; // or >= 1 or !== 0 or ...
                },
              },
            },
          };
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
    
  }
  public getData(): void {
    this.getTKSoCaGiamDinh()
    this.getTKSoCaGiamDinhKhuyetTat()
  }

}
