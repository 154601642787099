import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { MESSAGE_COMMON, MESSAGE_TYPE } from "src/app/constant/system-constant";
import { pageSizeOptions } from "src/app/services/config.service";
import { DmChungService } from "src/app/services/dm-chung.service";
import { PaginatorService } from "src/app/services/paginator.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Spinner } from "src/app/services/spinner.service";

@Component({
  selector: "app-danh-muc-dv-hanh-chinh",
  templateUrl: "./danh-muc-dv-hanh-chinh.component.html",
  styleUrls: ["./danh-muc-dv-hanh-chinh.component.scss"],
})
export class DanhMucDvHanhChinhComponent implements OnInit {
  private subscription: Subscription[] = [];
  public formSearch = new FormGroup({
    tinh: new FormControl(""),
    huyen: new FormControl(""),
  });
  public pageEvent: PageEvent;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number;
  public pageOption = pageSizeOptions;
  public totalPage: number;
  public ELEMENT_DATA: any[] = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = [
    "stt",
    "maDVHanhChinh",
    "tenDVHanhChinh",
  ];
  timeOut: any;
  listDataTempl = [];
  dsTinh = [];
  isFullListTinh = false;
  totalPageTinh: number;
  selectedValueTinh: number;
  selectedValueHuyen: number;
  currentPageTinh = 1;
  keyword = "";
  dsHuyen = [];
  isFullListHuyen = false;
  totalPageHuyen: number;
  currentPageHuyen = 1;

  constructor(
    private dmChungService: DmChungService,
    private spinner: Spinner,
    private snackbar: SnackbarService,
    private translator: PaginatorService,
    private cdRef: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    this.search();
    //get dữ liệu khi select giá trị combobox tỉnh
    this.formSearch.get("tinh").valueChanges.subscribe((data) => {
      this.selectedValueHuyen = null;
      if (data !== undefined) {
        this.currentPageHuyen = 1;
        this.dsHuyen = [];
        this.pageIndex = 0;
        if (!!data) {
          this.searchHuyen(data);
          this.onChangeTinh(data, null, this.currentPageHuyen);
        } else if (data === 0) {
          this.searchHuyen(null);
          this.onChangeTinh(null, null, this.currentPageHuyen);
        }
      }
    });
    //get dữ liệu khi select giá trị combobox huyện
    this.formSearch.get("huyen").valueChanges.subscribe((data) => {
      if (this.selectedValueTinh !== undefined) {
        this.pageIndex = 0;
        if (!!data) {
          this.searchXa(null, data, null);
        } else if (data === 0 && this.selectedValueTinh !== 0) {
          this.searchXa(this.selectedValueTinh, null, null);
        } else if (data === 0 && this.selectedValueTinh === 0) {
          this.searchXa(null, null, null);
          this.onChangeTinh(null, null, this.currentPageHuyen);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.translator.translatePaginator(this.paginator);
    this.paginator.nextPage = () => this.paginatorChange(1, null);
    this.paginator.previousPage = () => this.paginatorChange(2, null);
    this.paginator.lastPage = () => this.paginatorChange(3, null);
    this.paginator.firstPage = () => this.paginatorChange(4, null);
    this.cdRef.detectChanges();
    this.getListTinh();
  }

  paginatorChange(type: number, event?: PageEvent) {
    switch (type) {
      case 1:
        this.pageIndex++;
        if (this.selectedValueTinh === undefined) {
          this.search();
        } else if (
          this.selectedValueTinh !== undefined &&
          this.selectedValueHuyen === null
        ) {
          this.searchHuyen(this.selectedValueTinh, null);
        } else {
          this.searchXa(this.selectedValueTinh, this.selectedValueHuyen, event);
        }
        this.resetPageSize();
        break;
      case 2:
        this.pageIndex--;
        if (this.selectedValueTinh === undefined) {
          this.search();
        } else if (
          this.selectedValueTinh !== undefined &&
          this.selectedValueHuyen === null
        ) {
          this.searchHuyen(this.selectedValueTinh, null);
        } else {
          this.searchXa(this.selectedValueTinh, this.selectedValueHuyen, null);
        }
        this.resetPageSize();
        break;
      case 3:
        this.pageIndex = this.totalPage - 1;
        if (this.selectedValueTinh === undefined) {
          this.search();
        } else if (
          this.selectedValueTinh !== undefined &&
          this.selectedValueHuyen === null
        ) {
          this.searchHuyen(this.selectedValueTinh, null);
        } else {
          this.searchXa(this.selectedValueTinh, this.selectedValueHuyen, null);
        }
        this.resetPageSize();
        break;
      case 4:
        this.pageIndex = 0;
        if (this.selectedValueTinh === undefined) {
          this.search();
        } else if (
          this.selectedValueTinh !== undefined &&
          this.selectedValueHuyen === null
        ) {
          this.searchHuyen(this.selectedValueTinh, null);
        } else {
          this.searchXa(this.selectedValueTinh, this.selectedValueHuyen, null);
        }
        this.resetPageSize();
        break;
      case 5:
        this.pageIndex = 0;
        if (this.selectedValueTinh === undefined) {
          this.search(event);
        } else if (
          this.selectedValueTinh !== undefined &&
          this.selectedValueHuyen === null
        ) {
          this.searchHuyen(this.selectedValueTinh, event);
        } else {
          this.searchXa(this.selectedValueTinh, this.selectedValueHuyen, event);
        }
        this.resetPageSize();
        break;
    }
  }

  resetPageSize() {
    setTimeout(() => {
      this.paginator.pageIndex = this.pageIndex;
      this.dataSource.paginator.length = this.length;
    }, 500);
  }

  search(event?: PageEvent) {
    let page = event ? event.pageIndex : this.pageIndex;
    let size = event ? event.pageSize : this.pageSize;
    var obj = {
      page: page + 1,
      size: size,
    };
    this.spinner.show();
    this.subscription.push(
      this.dmChungService.getListTinh(obj).subscribe(
        (rs) => {
          this.convertListData(rs.data, 1);
          this.pageIndex = page;
          this.pageSize = size;
          this.length = rs.total_row;
          this.totalPage = rs.total_page;
          this.resetPageSize();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  //hàm get dữ liệu khi scroll combobox
  getNextBatch(idTinh?: number) {
    if (idTinh === undefined) {
      this.currentPageTinh += 1;
      this.getListTinh(this.keyword, this.currentPageTinh);
    } else if (idTinh === 0) {
      this.currentPageHuyen += 1;
      this.onChangeTinh(null, null, this.currentPageHuyen);
    } else {
      this.currentPageHuyen += 1;
      this.onChangeTinh(idTinh, null, this.currentPageHuyen);
    }
  }
  //hàm load dữ liệu search trong combobox
  onEnter(event, idTinh?: number) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.keyword = event.target.value;
      if (idTinh === undefined) {
        this.dsTinh = [];
        this.currentPageTinh = 1;
        this.getListTinh(this.keyword, this.currentPageTinh);
      } else {
        this.dsHuyen = [];
        this.currentPageHuyen = 1;
        this.onChangeTinh(idTinh, this.keyword, this.currentPageHuyen);
      }
    }, 300);
  }
  //clear text search
  resetDsTinhSearchForm() {
    this.currentPageTinh = 1;
    this.dsTinh = [];
    this.getListTinh("", this.currentPageTinh);
  }
  //clear text search
  resetDsHuyenSearchForm(idTinh: number) {
    this.currentPageHuyen = 1;
    this.dsHuyen = [];
    this.onChangeTinh(idTinh, "", this.currentPageHuyen);
  }
  //Lấy dữ liệu tỉnh trong combobox
  getListTinh(keyword?: String, page?: Number) {
    var obj = {
      keyword: keyword ?? "",
      page: page ?? this.currentPageTinh,
      size: 15,
    };
    this.subscription.push(
      this.dmChungService.getListTinh(obj).subscribe(
        (rs) => {
          this.dsTinh = this.dsTinh.concat(rs.data);
          this.totalPageTinh = rs.total_page;
          this.isFullListTinh =
            this.totalPageTinh <= this.currentPageTinh ?? true;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  //hàm convert dữ liệu cho data table
  convertListData(listData: any[], type: Number) {
    let arr = [];
    let donViHanhChinh = {
      maDVHC: "",
      tenDVHC: "",
    };
    switch (type) {
      //dữ liệu ds tỉnh
      case 1:
        listData.forEach((e) => {
          donViHanhChinh = {
            maDVHC: e.MA_TINH,
            tenDVHC: e.TEN_TINH,
          };
          arr.push(donViHanhChinh);
        });
        this.dataSource.data = [];
        this.dataSource.data = arr;
        break;
      //dữ liệu ds huyện
      case 2:
        listData.forEach((e) => {
          donViHanhChinh = {
            maDVHC: e.MA_HUYEN,
            tenDVHC: e.TEN_HUYEN,
          };
          arr.push(donViHanhChinh);
        });
        this.dataSource.data = [];
        this.dataSource.data = arr;
        break;
      //dữ liệu ds xã
      case 3:
        listData.forEach((e) => {
          donViHanhChinh = {
            maDVHC: e.MA_XA,
            tenDVHC: e.TEN_XA,
          };
          arr.push(donViHanhChinh);
        });
        this.dataSource.data = [];
        this.dataSource.data = arr;
        break;
    }
  }
  //hàm lấy dữ liệu combobox huyện
  onChangeTinh(id?: number, keyword?: string, page?: number) {
    var obj = {
      keyword: keyword ?? "",
      page: page ?? this.currentPageHuyen,
      size: 15,
    };
    if (id !== null && id !== 0) {
      obj["idTinh"] = id;
    }
    this.subscription.push(
      this.dmChungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.dsHuyen = this.dsHuyen.concat(rs.data);
          this.totalPageHuyen = rs.total_page;
          this.isFullListHuyen =
            this.totalPageHuyen <= this.currentPageHuyen ?? true;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  //hàm lấy dữ liệu huyện cho data table
  searchHuyen(id?: number, event?: PageEvent) {
    let page = event ? event.pageIndex : this.pageIndex;
    let size = event ? event.pageSize : this.pageSize;
    var obj = {
      page: page + 1,
      size: size,
    };
    if (id !== null && id !== 0) {
      obj["idTinh"] = id;
    }
    this.subscription.push(
      this.dmChungService.getListHuyen(obj).subscribe(
        (rs) => {
          this.convertListData(rs.data, 2);
          this.pageIndex = page;
          this.pageSize = size;
          this.length = rs.total_row;
          this.totalPage = rs.total_page;
          this.resetPageSize();
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  //hàm lấy dữ liệu xã cho data table
  searchXa(idTinh?: number, idHuyen?: number, event?: PageEvent) {
    let page = event ? event.pageIndex : this.pageIndex;
    let size = event ? event.pageSize : this.pageSize;
    var obj = {
      page: page + 1,
      size: size,
    };
    if (idTinh !== null && idTinh !== 0) {
      obj["idTinh"] = idTinh;
    }
    if (idHuyen !== null && idHuyen !== 0) {
      obj["idHuyen"] = idHuyen;
    }

    this.subscription.push(
      this.dmChungService.getListXa(obj).subscribe(
        (rs) => {
          this.convertListData(rs.data, 3);
          this.pageIndex = page;
          this.pageSize = size;
          this.length = rs.total_row;
          this.totalPage = rs.total_page;
          this.resetPageSize();
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
}
