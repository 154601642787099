<div class="main-wrapper bg">
    <div class=" d-flex justify-content-end">
        <form class="filter mb-3">
            <mat-form-field appearance="none" class="mb-0 mt-2 mx-2">
                <mat-label>Huyện/Thị/Thành</mat-label>
                <mat-select panelClass="panel-filter" (selectionChange)="onFilterHuyenChange($event)">
                    <mat-option [value]="0">Tất cả</mat-option>
                    <mat-option [value]="huyen.ID" *ngFor="let huyen of listHuyen">{{ huyen.TEN_HUYEN }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="none" class="mb-0 mt-2 mx-2">
                <mat-label>Xã/Phường/Thị trấn</mat-label>
                <mat-select panelClass="panel-filter" (selectionChange)="onFilterXaChange($event)">
                    <mat-option [value]="0">Tất cả</mat-option>
                    <mat-option [value]="xa.ID" *ngFor="let xa of listXa">{{ xa.TEN_XA }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <div class="row small-gutters">
        <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12">
            <div class="d-flex flex-column h-100">
                <div class="bg-chart h-100 p-2">
                    <h3 class="text-white text-center text-uppercase">Tổng số hộ khẩu</h3>
                    <div class="value">
                        <span style="color: #00E0BB;">{{tongHoKhau}}</span>
                    </div>
                </div>
                <div class="bg-chart mt-3 h-100 p-2">
                    <h3 class="text-white text-center text-uppercase">Tổng số HSSK</h3>
                    <div class="value">
                        <span style="color: #00E0BB;">{{tongHSSK}}</span>
                    </div>
                </div>
                <div class="bg-chart mt-3 h-100 p-2">
                    <h3 class="text-white text-center text-uppercase">Tỷ lệ sảy thai</h3>
                    <div class="value">
                        <span style="color: #00E0BB;">{{tyLeSayThai}}%</span>
                    </div>
                </div>
                <div class="bg-chart mt-3 h-100 p-2">
                    <h3 class="text-white text-center text-uppercase">Tỷ lệ phá thai</h3>
                    <div class="value">
                        <span style="color: #00E0BB;">{{tyLePhaThai}}%</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-xl-10 col-lg-4 col-md-6 col-sm-12">
            <div class="row small-gutters w-100">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mx-2 mb-3 bg-chart p-3">
                    <h3 class="text-white text-center text-uppercase">Thống kê theo giới tính</h3>
                    <app-pie-chart [pieChartData]="ELEMENT_DATA" [pieChartLabels]='chartLabels'></app-pie-chart>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                    <div class="bg-chart p-3">
                        <h3 class="text-white text-center text-uppercase">Biểu đồ thống kê HSSK theo đội tuổi</h3>
                        <app-bar-chart #chart [barChartData]="barChartData" [barChartLegend]="false" [barChartLabels]="barChartLabels"></app-bar-chart>
                    </div>
                </div>

                <div class="col-xl-7 col-lg-4 col-md-6 col-sm-12 mx-2 bg-chart p-3">
                    <h3 class="text-white text-center text-uppercase">Thống kê tiền sử bệnh tật</h3>
                    <app-bar-chart #barChart [barChartLegend]="false"></app-bar-chart>
                </div>
            </div>
        </div>

    </div>
</div>