import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TrungTamGiamDinhPhapYHGIRoutingModule } from "./tt-phap-y-hgi-routing.module";
import { TTPhapYHgiComponent } from "./tt-phap-y-hgi/tt-phap-y-hgi.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [TTPhapYHgiComponent],
    imports: [
        CommonModule,
        TrungTamGiamDinhPhapYHGIRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSlideToggleModule,
        SharedModule           
    ]
})
export class TrungTamGiamDinhPhapYHGIModule {}