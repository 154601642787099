<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <div class="ml-auto mr-3">
                    <mat-form-field class="w-100 mb-0" appearance="outline" >
                        <mat-label>Loại thời gian</mat-label>
                        <mat-select panelClass="dark-mode" (selectionChange)="onFilterChangeCombobox()" formControlName="loai">
                            <mat-option [value]="0">Theo tháng </mat-option>
                            <mat-option [value]="1">Theo ngày </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="showNam" class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS"
                        [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()"
                        keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div *ngIf="showThang" class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn tháng" [data]="MONTHS"
                        [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()"
                        keyId="id" keyName="text">
                    </ngx-dropdown>
                </div>
                <div *ngIf="showTuNgay" class="mr-3">
                    <mat-form-field class="w-100 mb-0 c-text" appearance="outline">
                        <mat-label>Từ ngày</mat-label>
                        <input matInput formControlName="fromDate" placeholder="Thời gian từ ngày" [matDatepicker]="tuNgayPicker" class="c-text"  autocomplete="off" class="c-text"  (dateChange)="onFilterChange()">
                        <mat-datepicker-toggle matSuffix [for]="tuNgayPicker" class="c-text" ></mat-datepicker-toggle>
                        <mat-datepicker #tuNgayPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="showDenNgay" class="mr-3">
                    <mat-form-field class="w-100 mb-0" appearance="outline" >
                        <mat-label>Đến ngày</mat-label>
                        <input matInput formControlName="toDate" placeholder="Thời gian đến ngày" [matDatepicker]="denNgayPicker"  autocomplete="off" class="c-text" (dateChange)="onFilterChange()">
                        <mat-datepicker-toggle matSuffix [for]="denNgayPicker" class="c-text" ></mat-datepicker-toggle>
                        <mat-datepicker #denNgayPicker ></mat-datepicker>
                    </mat-form-field>
                </div>

                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" 
                        type="button" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>

        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-2 h-100' : 'col-xl-2 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(25% - 10.5px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Tổng Cơ sở đã cấp GCN(Tính đến tháng hiện tại)</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[2]" (click)="onClicked($event, viewDetailGCNContent, 'TONG', 'xl')"> {{ getNumber(this.sumraryInfo.TONG) }} </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 14px;">&nbsp;</div>
                    <div class="row small-gutters" style="height: calc(25% - 10.5px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Cơ sở cấp mới trong tháng</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[3]" (click)="onClicked($event, viewDetailGCNContent, 'CAP_MOI', 'xl')"> {{ getNumber(this.sumraryInfo.CAPMOI) }} </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 14px;">&nbsp;</div>
                    <div class="row small-gutters" style="height: calc(25% - 10.5px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Cơ sở cấp lại trong tháng</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[4]" (click)="onClicked($event, viewDetailGCNContent, 'CAP_LAI', 'xl')"> {{ getNumber(this.sumraryInfo.CAPLAI) }} </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 14px;">&nbsp;</div>
                    <div class="row small-gutters" style="height: calc(25% - 10.5px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-card p-2 h-100">
                                <h4 class="card-title">Cơ sở thu hồi trong tháng</h4>
                                <div class="card-value" [style.color]="CARD_COLORS[5]" (click)="onClicked($event, viewDetailGCNContent, 'THU_HOI', 'xl')"> {{ getNumber(this.sumraryInfo.THUHOI) }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-md-5 col-sm-12 mb-3'">
                    <div class="row small-gutters" style="height: calc(50% - 7px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-chart p-2 h-100">
                                <h4 class="chart-title">Thống kê số cơ sở theo Huyện </h4>
                                <div class="legend d-flex align-items-center justify-content-center flex-wrap">
                                    <div class="mx-2 d-flex align-items-center" *ngFor="let item of this.tkSoLuongCoSoTheoHuyen.barChartData">
                                        <span class="shape" [style]="'background-color: ' + item.backgroundColor"></span>
                                        <span class="ml-1">{{ item.label }}</span>
                                    </div>
                                </div>
                                <div class="h-100 pt-5" style="overflow-x: auto;">
                                    <div class="h-100" [style]="getDynamicWidth(this.tkSoLuongCoSoTheoHuyen.barChartData[0], 20)">
                                        <app-bar-chart #tkSoLuongCoSoTheoHuyen [isSummary]="true"></app-bar-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 14px;">&nbsp;</div>
                    <div class="row small-gutters" style="height: calc(50% - 7px)">
                        <div [ngClass]="isFitPage ? 'col-12 h-100' : 'col-md-12 col-sm-12 mb-3'">
                            <div class="bg-chart p-2 ps-4 h-100">
                                <div class="h-100 " style="overflow-x: auto;">
                                    <div class="h-100" style="width: 95%; margin: 0 auto">
                                        <h4 class="chart-title">Tỷ lệ giữa các hình thức tổ chức</h4>
                                        <app-pie-chart #tyLeHTTC [isSummary]="true"></app-pie-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-5 h-100' : 'col-md-7 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Danh sách cấp giấy chứng nhận trong tháng</h4>
                        <div class="mt-5" style="height: calc(100% - 50px);">
                            <div class="row small-gutters h-100" style="overflow-y: auto;">
                                <div [ngClass]="isFitPage ? 'col-4 mb-3' : 'col-lg-4 col-md-6 col-sm-12 mb-3'"
                                        *ngFor="let element of this.LIST_GCN" style="display: flex; align-items: stretch;">
                                    <div class="p-2 text-white w-100" [style]="getBorderColor(element)">
                                        <h5 class="font-weight-bold">{{ element.TEN_CO_SO }}</h5>
                                        <small class="d-block">Hình thức tổ chức: {{ element.HINH_THUC_TO_CHUC }}</small>
                                        <small class="d-block">Số GCN ĐKKD: {{ element.SO_GCN_DKKD }}</small>
                                        <small class="d-block">Số GP GPP: {{ element.SO_GIAYPHEP_GPP }}</small>
                                        <small class="d-block">Họ tên: {{ element.HO_TEN }}</small>        
                                        <small class="d-block" [style]="getColor(element)">Loại: {{ element.LOAI_CAP_GCN }}</small>           
                                        <small class="d-block">Ngày cấp: {{ element.NGAY_CAP_QD }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewDetailGCNContent let-modal>
    <div class="modal-header">
        <h2 class="modal-title mb-0">Danh sách chi tiết</h2>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-ng-modal [modal]="modal" [ELEMENT_DATA]="ELEMENT_DATA" [COLUMNS_SCHEMA]="listHeaderColumnGCN" [pageIndex]="index" [length]="length" (paginatorOut)="getListGCNByTrangThai($event)"></app-ng-modal>
</ng-template>