<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
            <div class="d-flex align-items-center">
                <!-- <div class="mr-auto">
                    <mat-slide-toggle color="primary" formControlName="isFitPage"
                        (change)="modeChange($event)">Toàn trang</mat-slide-toggle>
                </div> -->
                <div class="ml-auto mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn huyện/thị/thành" [data]="DISTRICT_LIST"
                        [formControl]="formSearch.controls.district" label="Huyện/Thị/Thành"
                        (selected)="getDanhSachCoSoKhamChuaBenh()" keyId="MA_HUYEN" keyName="TEN_HUYEN">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <ngx-dropdown panelClass="dark-mode" class="mb-0" placeholder="Chọn cơ sở khám chữa bệnh" [data]="DON_VI_LIST"
                        [formControl]="formSearch.controls.coSoKCB" label="Cơ sở khám chữa bệnh"
                        (selected)="getData()" keyId="MA_DON_VI" keyName="TEN_DON_VI">
                    </ngx-dropdown>
                </div>
                <div class="mr-3">
                    <mat-form-field class="mb-0" appearance="outline">
                        <mat-label>Chọn năm</mat-label>
                        <mat-select panelClass="dark-mode" formControlName="year" (selectionChange)="getData()">
                            <mat-option *ngFor="let year of YEARS" [value]="year.id">{{ year.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                    <mat-icon color="primary">fullscreen</mat-icon>
                </button>
            </div>
        </form>
        <div class="row small-gutters" [ngStyle]="this.rowStyles">
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                    <h4 class="chart-title">Thống kê sử dụng dịch vụ kỹ thuật</h4>
                    <app-bar-chart #suDungDVKT [isSummary]="true"></app-bar-chart>
                    <!-- <small class="text-center text-white d-block">Tháng</small> -->
                    <div class="note right">
                        <button type="button" class="btn btn-link" (click)="showNoteDVKT()">Ghi chú</button>
                    </div>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                    <h4 class="chart-title">Thống kê số hồ sơ khám chữa bệnh có bảo hiểm y tế</h4>
                    <app-line-chart #soHoSoBHYT [lineChartLegend]="false" [isSummary]="true"></app-line-chart>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100 w-100">
                    <h4 class="chart-title">Tỷ lệ bệnh nhân Nam/Nữ</h4>
                    <app-pie-chart #gioiTinhKCB></app-pie-chart>
                </div>
            </div>
        </div>
        <div class="row small-gutters" [ngStyle]="this.rowStyles">
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                    <h4 class="chart-title">Thống kê số ngày điều trị</h4>
                    <app-bar-chart #soNgayDieuTri [barChartLegend]="false" [isSummary]="true"></app-bar-chart>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                    <h4 class="chart-title">Thống kê các bệnh phổ biến</h4>
                    <app-bar-chart #cacBenhPhoBien [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                    <div class="note right">
                        <button type="button" class="btn btn-link" (click)="showNoteBenh()">Ghi chú</button>
                    </div>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100">
                    <h4 class="chart-title">Thống kê tai nạn thương tích</h4>
                    <app-line-chart #taiNanThuongTich [isSummary]="true"></app-line-chart>
                </div>
            </div>
        </div>
        <div class="row small-gutters" [ngStyle]="this.rowStyles">
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-3 w-100 h-100">
                    <h4 class="chart-title">Chi phí khám chữa bệnh</h4>
                    <app-pie-highchart [paddingTop]="28" #chiPhiKhamChuaBenh></app-pie-highchart>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100 w-100">
                    <h4 class="chart-title">Thống kê số ca cấp cứu</h4>
                    <app-bar-chart class="w-100" #soCaCapCuu [barChartLegend]="false" [isSummary]="true"></app-bar-chart>
                </div>
            </div>
            <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
                <div class="bg-chart p-2 h-100 w-100">
                    <h4 class="chart-title">Thống kê số ca tử vong</h4>
                    <app-line-chart class="h-100" #soCaTuVong [lineChartLegend]="false"></app-line-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="note-container bg-light" *ngIf="!isNoteHidden" [@fadeInOut]>
        <div class="d-flex align-items-center justify-content-between px-3 py-2">
            <div class="content" [innerHTML]="noteContent"></div>
            <button type="button" class="btn btn-link p-0" (click)="isNoteHidden = true">
                <i class="fa-regular fa-circle-xmark"></i>
            </button>
        </div>
    </div>
</div>