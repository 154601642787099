import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NghiepVuDuocRoutingModule } from './nghiep-vu-duoc-routing.module';
import { CapCchnComponent } from './cap-cchn/cap-cchn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { CapGcnComponent } from './cap-gcn/cap-gcn.component';
import { CongBoMyPhamComponent } from './cong-bo-my-pham/cong-bo-my-pham.component';
import { CapGcnHGIComponent } from './cap-gcn-hgi/cap-gcn-hgi.component';
import { CapCchnHgiComponent } from './cap-cchn-hgi/cap-cchn-hgi.component';
@NgModule({
  declarations: [CapCchnComponent, CapGcnComponent, CongBoMyPhamComponent, CapGcnHGIComponent, CapCchnHgiComponent],
  imports: [
    CommonModule,
    NghiepVuDuocRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class NghiepVuDuocModule { }
