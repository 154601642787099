import { ThongKeDanSoComponent } from './thong-ke-dan-so/thong-ke-dan-so.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhanTichSoLieuDanSoComponent } from './phan-tich-so-lieu-dan-so/phan-tich-so-lieu-dan-so.component';


const routes: Routes = [
  { path: "", component: ThongKeDanSoComponent },
  { path: "thong-ke-dan-so", component: ThongKeDanSoComponent },
  { path: "phan-tich-so-lieu-dan-so", component: PhanTichSoLieuDanSoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChiCucDanSoRoutingModule { }
