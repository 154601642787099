<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
  <div class="container-fluid h-100 pt-3">
    <form class="dark-mode mb-3" [formGroup]="formSearch" id="formSearch">
      <div class="d-flex align-items-center">
        <div class="ml-auto mr-3">
          <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text"></ngx-dropdown>
        </div>
        <!-- <div class="mr-3">
          <mat-select panelClass="dark-mode" [formControl]="formSearch.controls.month" (selected)="getData()" keyId="id" keyName="text">
            <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
        </mat-select>
        </div> -->
        <ngx-dropdown panelClass="dark-mode" class="mb-0 mr-2" placeholder="Chọn tháng" [data]="MONTHS"
                        [formControl]="formSearch.controls.month" label="Chọn tháng" (selected)="getData()"
                        keyId="id" keyName="text">
        </ngx-dropdown>
        <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" type="button" matTooltip="Toàn màn hình">
          <mat-icon color="primary">fullscreen</mat-icon>
        </button>
      </div>
    </form>
    <div [ngStyle]="this.rowStyles">
      <div class="row small-gutters h-100">
        <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Hoạt động xét nghiệm</h4>
            <app-bar-chart #xetNghiem></app-bar-chart>
          </div>
        </div>
        <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Hoạt động Sức khỏe sinh sản - KHHGĐ</h4>
            <app-bar-chart #sucKhoesinhSan></app-bar-chart>
          </div>
        </div>
        <div [ngClass]="isFitPage ? 'col-4 h-100' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Hoạt động khám sức khỏe</h4>
            <!-- <app-pie-chart #khamSuckhoe></app-pie-chart> -->
            <div class="h-100  overflow-x">
              <div [ngClass] = "isWithChart1 == 1 ? 'h-100' : 'h-100 w-chart' ">
                  <app-pie-chart #khamSuckhoe></app-pie-chart>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="isFitPage ? 'col-4 h-100 mt-3' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3 mt-3'">
          <div class="bg-chart p-2 h-100">
              <h4 class="chart-title">Chẩn đoán hình ảnh - Thăm dò chức năng</h4>
              <app-pie-chart #CDHA></app-pie-chart>
          </div>

      </div>
        <div [ngClass]="isFitPage ? 'col-8 h-100 mt-3' : 'col-xl-8 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Hoạt động tiêm ngừa</h4>
            <div class="h-100 pt-5" style="overflow-y: auto;">
              <div class="h-100" style="width: 130%;">
                <app-bar-chart #tiemChung></app-bar-chart>
              </div>
            </div>
          </div>
        </div>

        <!-- <div [ngClass]="isFitPage ? 'col-4 h-100 mt-3' : 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3'">
          <div class="bg-chart p-2 h-100">
            <h4 class="chart-title">Chẩn đoán hình ảnh - Thăm dò chức năng</h4>
            <div class="h-100">
              <div [ngClass]= "isWithChart1 == 1 ? 'h-100' : 'h-100 w-chart' " >
                  <app-pie-chart #CDHA></app-pie-chart>
              </div>
            </div>
          </div>
        </div> -->


        <!-- [ngClass]= "isWithChart1 == 1 ? 'h-100' : 'h-100 w-chart' " -->

      </div>
    </div>
  </div>
</div>
