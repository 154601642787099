import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ColorService } from 'src/app/services/color.service';
import { AggService } from "src/app/services/agg.service";
import { CommonFunctionService } from './../../../../services/common-function.service';
import { PieChartComponent } from 'src/app/shared/components/chart-js/pie-chart/pie-chart.component';

@Component({
  selector: 'app-kythuatkiemnghiem',
  templateUrl: './kythuatkiemnghiem.component.html',
  styleUrls: ['./kythuatkiemnghiem.component.scss']
})
export class KythuatkiemnghiemComponent implements OnInit {
  subscription: Subscription[] = [];
  @ViewChild("datDinhTinh", { static: true })
  datDinhTinh: PieChartComponent;
  @ViewChild("dinhTinh", { static: true })
  dinhTinh: PieChartComponent;
  @ViewChild("dinhLuong", { static: true })
  dinhLuong: PieChartComponent;
  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl(new Date().getFullYear()),
    thang: new FormControl(""),
    loai: new FormControl(1),
    tungay:  new FormControl(new Date().toISOString()),
    denngay:  new FormControl(new Date().toISOString()),
  });
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  documentElement: any;
  isFullScreen = false;
  params: any;
  keyword = "";
  currentPage = 1;
  totalPage: number;
  rowStyles: any = {};
  rowStylesFull: any = {};
  timeOut: any;
  listThang = [];
  listNam = [];
  showNam = true;
  showThang = false;
  showTuNgay = false;
  showDenNgay = false;

  CTTINHCHATMOTA: number;
  CTDODONGDEU: number;
  CTDORAHOATAN: number;
  CTDOPH: number;
  CTTAPCHAT: number;
  CTKHAC: number;

  screenWidth: number;
  screenHeight: number;
  classInvisible = 1;
  lableMonth = true;
  constructor(
    private snackbar: SnackbarService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    private aggService : AggService,
    private commonService: CommonFunctionService
  ) { }

  ngOnInit(): void {
    this.datDinhTinh.pieChartOptions.legend.display = true;
    this.datDinhTinh.pieChartOptions.legend.position = "right";
    this.dinhTinh.pieChartOptions.legend.display = true;
    this.dinhTinh.pieChartOptions.legend.position = "right";
    this.dinhLuong.pieChartOptions.legend.display = true;
    this.dinhLuong.pieChartOptions.legend.position = "top";
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    //this.formSearch.controls["month"].setValue([1,2,3,4,5,6,7,8,9,10,11,12]);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.lableMonth = true;
    this.onFilterChangeCombobox();
    this.detectMode();
  }
  getData(): void {
     this.getChiTieuKiemNghiem();
     this.getDatChiTieuDinhTinh();
     this.getDinhTinh();
     this.getDinhLuong();
  }

  getChiTieuKiemNghiem(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getChiTieuTTKiemNghiem(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.CTTINHCHATMOTA = rs.data[0].CTTINHCHATMOTA;
          this.CTDODONGDEU = rs.data[0].CTDODONGDEU;
          this.CTDORAHOATAN = rs.data[0].CTDORAHOATAN;
          this.CTDOPH = rs.data[0].CTDOPH;
          this.CTTAPCHAT = rs.data[0].CTTAPCHAT;
          this.CTKHAC = rs.data[0].CTKHAC;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }



  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

  public getFilter() {
    let loai = this.formSearch.get("loai").value
    let nam  = this.formSearch.get("nam").value;
    let thang  = this.formSearch.get("thang").value.toString() == "Tất cả"?"": this.formSearch.get("thang").value.toString();
    let tungay = this.formatDate(this.formSearch.get("tungay").value).toString();
    let denngay = this.formatDate(this.formSearch.get("denngay").value).toString();
    let result = {};
    if(loai == 1){
      result = {
        nam: nam,
        thang: "",
        tungay: "",
        denngay: ""
      }
    }else if (loai == 2){
      result = {
        nam: nam,
        thang: thang,
        tungay: "",
        denngay: ""
      }
    } else {
      result = {
        nam: "",
        thang: "",
        tungay: tungay,
        denngay: denngay
      }
    }
    console.log(result)
    return result;
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
  }

  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    if(loai == 1 ){
      this.showNam = true
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 2){
      this.showNam = true
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else{
      this.showNam = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }
  onFilterChange (){
    this.detectMode();
    this.getData();
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.dinhLuong.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.dinhTinh.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.datDinhTinh.pieChartOptions.maintainAspectRatio = !this.isFitPage;

  }
  getDinhLuong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getChiTieuDinhLuong(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.dinhLuong.pieChartData = [];
            return false;
          }
          let ctDLUVVIS = data[0].CTDLUVVIS;
          let ctDLHPLC = data[0].CTDLHPLC;
          let ctChuando = data[0].CTCHUANDO;

          this.dinhLuong.pieChartData = [
            ctDLUVVIS,
            ctDLHPLC,
            ctChuando
          ];
          this.dinhLuong.pieChartLabels = [
            "Phương pháp quang phổ tử ngoại (UV-Vis)",
            "Phương pháp HPLC",
            "Phương pháp chuẩn độ thể tích"
          ];
          this.dinhLuong.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.RIVER_NIGHTS },
          ];
        },

        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getDinhTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getChiTieuDinhTinh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.dinhTinh.pieChartData = [];
            return false;
          }
          let ctSoibot = data[0].CTSOIBOT;
          let ctDTUVVIS = data[0].CTDTUVVIS;
          let ctDTHPLC = data[0].CTDTHPLC;
          let ctHoahoc = data[0].CTHOAHOC;
          let ctIR = data[0].CTIR;
          let ctSacky = data[0].CTSACKY;

          this.dinhTinh.pieChartData = [
            ctSoibot,
            ctDTUVVIS,
            ctDTHPLC,
            ctHoahoc,
            ctIR,
            ctSacky

          ];
          this.dinhTinh.pieChartLabels = [
            "Soi bột",
            "Phương pháp quang phổ tử ngoại (UV-Vis)",
            "Phương pháp HPLC",
            "Phương pháp phản ứng hóa học",
            "Phương pháp IR",
            "Phương pháp sắc ký lớp mỏng"
          ];
          this.dinhTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.IOC },
          ];
        },

        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getDatChiTieuDinhTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getChiTieuTTKiemNghiem(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.datDinhTinh.pieChartData = [];
            return false;
          }
          let thTinhchatMota = data[0].THTINHCHATMOTA;
          let thDodongdeu = data[0].THDODONGDEU;
          let thDoraHoatan = data[0].THDORAHOATAN;
          let thDopH = data[0].THDOPH;
          let thTapchat = data[0].THTAPCHAT;
          let thKhac = data[0].THKHAC;

          this.datDinhTinh.pieChartData = [
            thTinhchatMota,
            thDodongdeu,
            thDoraHoatan,
            thDopH,
            thTapchat,
            thKhac

          ];
          this.datDinhTinh.pieChartLabels = [
            "Tính chất/Mô tả/Cảm quan",
            "Khối lượng, thể tích",
            "Độ rã hoặc hoà tan",
            "Đo pH",
            "Chỉ tiêu tạp chất",
            "Phương pháp khác"
          ];
          this.datDinhTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },

        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_4[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.RIVER_NIGHTS[4],
    COLOR_PALETTES.GREY_TO_RED[3],
    COLOR_PALETTES.PALETTE_1[1],
    COLOR_PALETTES.PALETTE_1[2],
    COLOR_PALETTES.PALETTE_1[4],
    COLOR_PALETTES.PALETTE_1[3],
    COLOR_PALETTES.PALETTE_2[1],
    COLOR_PALETTES.PALETTE_2[2],
    COLOR_PALETTES.PALETTE_2[4],
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_3[1],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[4],
    COLOR_PALETTES.PALETTE_3[3],
  ];

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }


}


