<div class="position-relative h-100">
  <canvas baseChart
    [data]="polarAreaChartData"
    [labels]="polarAreaChartLabels"
    [colors]="polarAreaChartColor"
    [options]="polarAreaChartOptions"
    [legend]="polarAreaLegend"
    [plugins]="polarAreaChartPlugins"
    [chartType]="polarAreaChartType">
  </canvas>
</div>
