<div *ngFor="let menu of menuList">
    <mat-nav-list *ngIf="menu.children === undefined"> <!-- If single menu -->
        <a class="my-1" mat-list-item routerLinkActive="list-item-active" [routerLink]="['/' + menu.route]"><i class="material-icons mr-2">{{menu.icon}}</i> {{ menu.name }}</a>
    </mat-nav-list>

    <mat-nav-list *ngIf="menu.children !== undefined && menu.children.length > 0"> <!-- If nested menu -->
        <mat-list-item (click)="isShow(menu)" class="parent">
            <i class="material-icons">{{menu.icon}}</i> <div class="w-100 ml-2">{{ menu.name }}</div>
            <mat-icon class="menu-button m-0" [ngClass]="{'rotated' : menu.isShow}">expand_more</mat-icon>
        </mat-list-item>
        <!-- Get sub menus -->
        <!-- <div class="submenu" [ngClass]="{'expanded' : menu.isShow}" *ngFor="let subMenu of menu.children"> -->
        <div class="submenu" [ngClass]="{'expanded' : menu.isShow}">
            <!-- <a class="my-1" mat-list-item routerLinkActive="list-item-active" 
                [routerLink]="['/' + subMenu.route]">{{ subMenu.name }}</a> -->
            <app-menu [menuList]="menu.children"></app-menu>
        </div>
    </mat-nav-list>
</div>