import { PieChartComponent } from 'src/app/shared/components/chart-js/pie-chart/pie-chart.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import { AggService } from 'src/app/services/agg.service';
import { MatPaginator } from "@angular/material/paginator";
import { pageSizeOptions } from "src/app/services/config.service";
import { Spinner } from "src/app/services/spinner.service";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-cap-gcn',
  templateUrl: './cap-gcn.component.html',
  styleUrls: ['./cap-gcn.component.scss']
})
export class CapGcnComponent implements OnInit {

  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};

  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    loai: new FormControl(0),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    fromDate:  new FormControl(new Date().toISOString()),
    toDate:  new FormControl(new Date().toISOString())
  });

  public CARD_COLORS: any[] = COLOR_PALETTES.DUTCH_FIELD;
  public sumraryInfo = { TONG: 0, CAPMOI: 0, CAPLAI: 0, THUHOI: 0 };
  public LIST_GCN: any[] = [];

  @ViewChild("tyLeHTTC", { static: true }) tyLeHTTC: PieChartComponent;
  @ViewChild("tkSoLuongCoSoTheoHuyen", { static: true })
  tkSoLuongCoSoTheoHuyen: BarChartComponent;

  dataSource!: MatTableDataSource<any>;
  pageIndex: number = 0;
  index: number = 0;
  length: number;
  pageOption = pageSizeOptions;
  pageSizeDefault: number = this.pageOption[0];
  ELEMENT_DATA: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRefs: any[] = [];
  listHeaderColumnGCN: any[] = [
    {
      key: "TEN_CO_SO",
      label: "TÊN CƠ SỞ",
    },
    {
      key: "HO_TEN",
      label: "HỌ TÊN",
    },
    {
      key: "SO_GCN_DKKD",
      label: "Số GCN",
    },
    {
      key: "LOAI_CAP_GCN",
      label: "LOẠI",
    },
    {
      key: "HINH_THUC_TO_CHUC",
      label: "HÌNH THỨC",
    },
  ];

  loai: string;
  showNam = true;
  showThang = true;
  showTuNgay = false;
  showDenNgay = false;

  constructor(
    private aggService: AggService,
    private snackbar: SnackbarService,
    private spinner: Spinner,
    private modalService: NgbModal
  ) { 
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.MONTHS.unshift({id: "0", text: "- Tất cả -"})
    this.detectMode();
    this.tkSoLuongCoSoTheoHuyen.barChartOptions.legend.display = false;
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tyLeHTTC.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeHTTC.pieChartOptions.legend.position = "right";
    this.tyLeHTTC.pieChartType = "doughnut";
    this.tkSoLuongCoSoTheoHuyen.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 0); // no row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 1)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  public getFilter() {
    let loai_cbx = this.formSearch.get("loai").value;
    let year = this.formSearch.controls.year.value ?? "";
    let month = this.formSearch.controls.month.value ?? "";
    let tungay = this.formatDate(this.formSearch.get("fromDate").value);
    let denngay = this.formatDate(this.formSearch.get("toDate").value);
    return { loai_cbx: loai_cbx, nam: year, thang: month, tungay: tungay, denngay: denngay };
  }

  tyLehinhThucToChuc(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.tkTyLehinhThucToChuc(obj).subscribe(
        (rs: any) => {
          this.tyLeHTTC.pieChartData = [];
          this.tyLeHTTC.pieChartLabels = [];
          if (!rs.success || rs.data.length == 0) {
            return false;
          }
          rs.data.forEach((element) => {
            this.tyLeHTTC.pieChartLabels.push(element.HINH_THUC_TO_CHUC);
            this.tyLeHTTC.pieChartData.push(element.TILE);
          });
          this.tyLeHTTC.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tkSLCoSoTheoHuyen() {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.tkSoLuongCoSoTheoHuyen(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;;
      }
      let result = rs.data;
      this.tkSoLuongCoSoTheoHuyen.barChartLabels = [];
      this.tkSoLuongCoSoTheoHuyen.barChartData = [];
      if (!(result && result.length > 0)) {  
        return;
      }
      result = result.filter(x => x.TEN_HUYEN != "");
      this.tkSoLuongCoSoTheoHuyen.barChartLabels = result.map(x => this.convertToArray(' ', this.reduceLabel(x.TEN_HUYEN), 2));
      this.tkSoLuongCoSoTheoHuyen.barChartData.push({
        data: result.map(x => x.TONG),
        label: "Tổng số cở sở đã cấp tính đến tháng hiện tại",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[6],
        datalabels: {align: "end", anchor:"end", clamp: true}
      });
      this.tkSoLuongCoSoTheoHuyen.barChartData.push({
        data: result.map(x => x.THANG),
        label: "Số lượng cấp mới trong tháng",
        backgroundColor: COLOR_PALETTES.DUTCH_FIELD[1],
        datalabels: {align: "end", anchor:"end", clamp: true}
      });
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getData(): void {
    this.thongKeTongQuan();
    this.tkSLCoSoTheoHuyen();
    this.tyLehinhThucToChuc();
    this.danhSachCCHNMoiCap();
  }
  
  private reduceLabel(label: string) {
    return label.replace(/trung tâm y tế/gi, "TTYT")
                .replace(/bệnh viện/gi, "BV")
                .replace(/phòng khám/gi, "PK")
                .replace(/trạm y tế/gi, "TYT");
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
 
  public thongKeTongQuan(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.soLieuTongQuanGCN(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      this.sumraryInfo = rs.data[0];
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  

  public danhSachCCHNMoiCap(): void {
    let obj: any = this.getFilter();
    this.subscription.push(this.aggService.danhSachGCN(obj).subscribe((rs: any) => {
      if (!rs.success) {
        return;
      }
      this.LIST_GCN = rs.data;
    }, err => {
      this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
    }));
  }

  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  public getColor(element: any) {
    let style = "color: ";
    return style + (element.MA_LOAI_CAP == "M" ? this.CARD_COLORS[6] : this.CARD_COLORS[3]);
  }

  public getBorderColor(element: any) {
    let style = "border: 1px solid ";
    return style + (element.LOAI_CAP_GCN == "Mới" ? this.CARD_COLORS[3] : this.CARD_COLORS[3]);
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  onClicked(data: any, content: any, id: string, size: string) {
    switch (id) {
      case "TONG": {
        this.loai = 'TG';
        break;
      }
      case "CAP_MOI": {
        this.loai = 'M';
        break;
      }
      case "CAP_LAI": {
        this.loai = 'L';
        break;
      }
      case "THU_HOI": {
        this.loai = 'T';
        break;
      }
    }
    this.getListGCNByTrangThai();
    this.openModal(content, id, size);
  }

  openModal(content, id, size?) {
    let modalRef = {};
    modalRef[id] = this.modalService.open(content, {
      ariaLabelledBy: id,
      size: size ?? "md",
    });
    modalRef[id].result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`with: ${reason}`);
      }
    );
    this.modalRefs.push(modalRef);
  }

  getListGCNByTrangThai(event?: any) {
    if (!event && this.pageIndex != 0) {
      this.paginator.pageIndex = 0;
    } else if (event) {
      this.paginator = event;
    }
    let loai_cbx = this.formSearch.get("loai").value;
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let tungay = this.formatDate(this.formSearch.get("fromDate").value);
    let denngay = this.formatDate(this.formSearch.get("toDate").value);
    let page = event ? event.pageIndex : this.pageIndex;
    var obj = {
      loai_cbx: loai_cbx,
      nam: year,
      thang: month,
      tungay: tungay,
      denngay: denngay,
      loai: this.loai,
      page: page + 1,
      size: this.paginator ? this.paginator.pageSize : this.pageSizeDefault,
    };
    this.spinner.show();
    this.subscription.push(
      this.aggService.danhSachGCNTheoLoai(obj).subscribe(
        (rs) => {
          this.ELEMENT_DATA = rs.data;
          this.dataSource.data = this.ELEMENT_DATA;
          this.spinner.hide();

          this.index = obj.page - 1;
          this.length = rs.total_row;
        },
        (err) => {
          this.spinner.hide();
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );

    return event;
  }

  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    
    if(loai == 0){
      this.showNam = true
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else {
      this.showNam = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }

  onFilterChange (){
    this.detectMode
    this.getData();
  }

}
