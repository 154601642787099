<div [class]="'main-wrapper bg-ioc' + (this.isFullScreen ? ' vh-100' : '')">
    <div class="px-2 pt-2 h-100">
        <div class="d-flex justify-content-end mb-3 pt-2">
            <form class="dark-mode mr-4" [formGroup]="formSearch" id="formSearch">
                <div class="row align-items-center justify-content-end">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn năm</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="year">
                                <mat-option [value]="2021">2021</mat-option>
                                <mat-option [value]="2022">2022</mat-option>
                                <mat-option [value]="2023">2023</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-9 col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Cơ sở y tế</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="donVi" msInfiniteScroll (infiniteScroll)="getNextBatch()" [complete]="isFullListCSYT">
                                <div>
                                    <div>
                                        <input matInput #searchInputCSYT (keyup)="onEnter($event)" (keydown)="$event.stopPropagation()" placeholder="Nhập từ khóa tìm kiếm" class="search-nested" />
                                        <button mat-icon-button class="clear-search-nested" *ngIf="searchInputCSYT.value !== ''" (click)="searchInputCSYT.value = ''; resetCSYTSearchForm()">
                                            <mat-icon> close </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <mat-option [value]="0">Tất cả</mat-option>
                                <mat-option [value]="donVi.MA_DON_VI" *ngFor="let donVi of listCSYT">{{donVi.TEN_DON_VI}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="!isFullScreen" matTooltip="Toàn màn hình">
                <mat-icon color="primary">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="isFullScreen" matTooltip="Thu nhỏ">
                <mat-icon color="primary">fullscreen_exit</mat-icon>
            </button>
        </div>

        <div class="row small-gutters" [ngStyle]="this.rowStyles">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Thống kê bệnh nhân theo độ tuổi</h3>
                    <app-pie-chart #benhNhanTheoTuoiChart></app-pie-chart>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Thống kê theo loại bệnh phổ biến</h3>
                    <app-bar-chart #loaiBenhChart [barChartLegend]="false"></app-bar-chart>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Thống kê bệnh nhân tham gia BHYT</h3>
                    <app-pie-chart #benhNhanBHYTChart></app-pie-chart>
                </div>
            </div>
        </div>

        <div class="row small-gutters" [ngStyle]="this.rowStyles">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Số tiền bệnh nhân tự thanh toán theo tháng</h3>
                    <app-bar-chart #benhNhanTTChart [barChartLegend]="false"></app-bar-chart>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Số tiền bảo hiểm thanh toán theo tháng</h3>
                    <app-bar-chart #baoHiemTTChart [barChartLegend]="false"></app-bar-chart>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2">
                <div class="bg-chart p-2 h-100">
                    <h3 class="chart-title">Lượt sử dụng dịch vụ kỹ thuật theo tháng</h3>
                    <app-bar-chart #dichVuKyThuatChart [barChartLegend]="true"></app-bar-chart>
                    <div class="note right">
                        <button type="button" class="btn btn-link" (click)="showNoteDVKT()">Ghi chú</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="note-container bg-light" *ngIf="!isNoteHidden" [@fadeInOut]>
        <div class="d-flex align-items-center justify-content-between px-3 py-2">
            <div class="content" [innerHTML]="noteContent"></div>
            <button type="button" class="btn btn-link p-0" (click)="isNoteHidden = true">
                <i class="fa-regular fa-circle-xmark"></i>
            </button>
        </div>
    </div>
</div>