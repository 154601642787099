<div [class]="'main-wrapper bg-ioc' + (this.isFullScreen ? ' vh-100' : '')">
    <div class="container-fluid">
        <div class="d-flex justify-content-end mb-3 pt-2">

            <form class="dark-mode mr-4" [formGroup]="formSearch" id="formSearch">
                <div class="row align-items-center justify-content-end">
                    <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn năm</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="year">
                                <mat-option [value]="2021">2021</mat-option>
                                <mat-option [value]="2022">2022</mat-option>
                                <mat-option [value]="2023">2023</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="month">
                                <mat-option [value]="month" *ngFor="let month of listMonth">{{month}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Cơ sở y tế</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="onFilterChange()" formControlName="donVi" msInfiniteScroll (infiniteScroll)="getNextBatch()" [complete]="isFullListCSYT">
                                <div>
                                    <div>
                                        <input matInput #searchInputCSYT (keyup)="onEnter($event)" (keydown)="$event.stopPropagation()" placeholder="Nhập từ khóa tìm kiếm" class="search-nested" />
                                        <button mat-icon-button class="clear-search-nested" *ngIf="searchInputCSYT.value !== ''" (click)="searchInputCSYT.value = ''; resetCSYTSearchForm()">
                                            <mat-icon> close </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <mat-option [value]="0">Tất cả</mat-option>
                                <mat-option [value]="donVi.MA_DON_VI" *ngFor="let donVi of listCSYT">{{donVi.TEN_DON_VI}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="!isFullScreen" matTooltip="Toàn màn hình">
                <mat-icon color="primary">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="toggleFullScreen()" *ngIf="isFullScreen" matTooltip="Thu nhỏ">
                <mat-icon color="primary">fullscreen_exit</mat-icon>
            </button>
        </div>

        <!-- Row 1 -->

        <div class="row small-gutters my-2 pt-2" [ngStyle]="this.rowStyles">
            <div class="col-xl-2 col-lg-4">
                <div class="d-flex flex-column h-100">
                    <div class="bg-chart h-100">
                        <h3 class="chart-title pt-2">Tổng số lượt khám bệnh</h3>
                        <div class="value">
                            <span style="color: #00E0BB;">{{tongSoLuotKham}}</span>
                        </div>
                    </div>
                    <div class="bg-chart mt-2 h-100">
                        <h3 class="chart-title pt-2">Tổng số lượt ra viện</h3>
                        <div class="value">
                            <span style="color: #0bb4ff;">{{raVien}}</span>
                        </div>
                    </div>
                    <div class="bg-chart mt-2 h-100">
                        <h3 class="chart-title pt-2">Tổng số lượt vào viện</h3>
                        <div class="value">
                            <span style="color: #d2980d;">{{vaoVien}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi tổng lượt khám theo ngày</h3>
                    <app-line-chart #khamTheoNgayChart [lineChartLegend]=false></app-line-chart>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi lượt ra/vào bệnh viện theo ngày</h3>
                    <app-bar-chart #raVaoNgayChart></app-bar-chart>
                </div>
            </div>
        </div>
        <!-- Row 2 -->
        <div class="row small-gutters my-2" [ngStyle]="this.rowStyles">
            <div class="col-xl-2 col-lg-4 d-flex align-items-stretch">
                <div class="row no-gutters h-100 w-100">
                    <div class="col-6 mb-2">
                        <div class="bg-chart h-100 mr-1">
                            <h3 class="chart-title pt-2">Nội trú</h3>
                            <div class="value tag-value">
                                <span style="color: #9b19f5;">{{luotNoiTru}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bg-chart h-100 ml-1">
                            <h3 class="chart-title pt-2">Ngoại trú</h3>
                            <div class="value tag-value">
                                <span style="color: #0bb4ff;">{{luotNgoaiTru}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="bg-chart h-100 mr-1">
                            <h3 class="chart-title pt-2">Có BHYT</h3>
                            <div class="value tag-value">
                                <span style="color: #0060ff;">{{coBHYT}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="bg-chart h-100 ml-1">
                            <h3 class="chart-title pt-2">Không BHYT</h3>
                            <div class="value tag-value">
                                <span style="color: #ffa300;">{{khongBHYT}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi lượt điều trị nội trú - ngoại trú theo ngày</h3>
                    <app-bar-chart #noiTruNgoaiTruNgayChart></app-bar-chart>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi khám bệnh ngoại trú sử dụng BHYT</h3>
                    <app-line-chart #khamBHYTChart [lineChartLegend]=true></app-line-chart>
                </div>
            </div>
        </div>

        <!-- Row 3 -->

        <div class="row small-gutters my-2" [ngStyle]="this.rowStyles">
            <div class="col-xl-2 col-lg-4">
                <div class="d-flex flex-column h-100">
                    <div class="bg-chart h-100">
                        <h3 class="chart-title pt-2">Lượt cấp cứu</h3>
                        <div class="value">
                            <span style="color: #ec233b;">{{luotCapCuu}}</span>
                        </div>
                    </div>
                    <div class="bg-chart mt-2 h-100">
                        <h3 class="chart-title pt-2">Lượt tử vong</h3>
                        <div class="value">
                            <span style="color: #de25da;">{{luotTuVong}}</span>
                        </div>
                    </div>
                    <div class="bg-chart mt-2 h-100">
                        <h3 class="chart-title pt-2">Tai nạn thương tích</h3>
                        <div class="value">
                            <span style="color: #ffa300;">{{taiNanThuongTich}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi tình hình cấp cứu và tử vong theo ngày</h3>
                    <app-line-chart #capCuuTuVongChart [lineChartLegend]=true></app-line-chart>
                </div>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 h-100">
                <div class="bg-chart p-2 h-100 w-100">
                    <h3 class="chart-title">Theo dõi tình hình tai nạn thương tích</h3>
                    <app-line-chart #taiNanThuongTichChart [lineChartLegend]=true></app-line-chart>
                </div>
            </div>
        </div>
    </div>
</div>