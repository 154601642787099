import { DanhMucDvHanhChinhComponent } from './pages/danh-muc-dv-hanh-chinh/danh-muc-dv-hanh-chinh.component';
import { DanhMucDonViComponent } from './pages/danh-muc-don-vi/danh-muc-don-vi.component';
import { DanhMucTonGiaoComponent } from './pages/danh-muc-ton-giao/danh-muc-ton-giao.component';
import { DanhMucDanTocComponent } from './pages/danh-muc-dan-toc/danh-muc-dan-toc.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'quoc-tich', loadChildren: () => import('./pages/danh-muc-quoc-tich/danh-muc-quoc-tich.module').then((m) => m.DanhMucQuocTichModule) },  
  { path: 'dan-toc', component: DanhMucDanTocComponent },
  { path: 'ton-giao', component: DanhMucTonGiaoComponent},
  { path: 'don-vi', component: DanhMucDonViComponent},
  { path: 'dv-hanh-chinh', component: DanhMucDvHanhChinhComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DanhMucDungChungRoutingModule { }
