import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  COLOR_PALETTES,
  MESSAGE_COMMON,
  MESSAGE_TYPE,
} from "src/app/constant/system-constant";
import { CDCService } from "src/app/services/trung-tam-cdc.service";
import { BarChartComponent } from "src/app/shared/components/chart-js/bar-chart/bar-chart.component";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";

@Component({
  selector: "app-benh-khong-lay",
  templateUrl: "./benh-khong-lay.component.html",
  styleUrls: ["./benh-khong-lay.component.scss"],
})
export class BenhKhongLayComponent implements OnInit {
  subscription: Subscription[] = [];

  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    quarter: new FormControl(""),
  });

  YEARS: any[] = [];
  MONTHS: any[] = [];
  QUARTERS: any[] = [];
  rowStyles: any = {};
  isWithChart: any
  isWithChart1: any
  @ViewChild("tangHuyetApCapHuyen", { static: true })
  tangHuyetApCapHuyen: BarChartComponent;
  @ViewChild("daiThaoDuongCapHuyen", { static: true })
  daiThaoDuongCapHuyen: BarChartComponent;
  @ViewChild("tyLeTuVong", { static: true })
  tyLeTuVongChart: PieChartComponent;
  @ViewChild("tangHuyetApCapTinh", { static: true })
  tangHuyetApCapTinh: PieChartComponent;
  @ViewChild("daiThaoDuongCapTinh", { static: true })
  daiThaoDuongCapTinh: PieChartComponent;

  slDTDPhatHien: number;
  slDTDQuanLyDieuTri: number;
  slHuyetApPhatHien: number;
  slHuyetApQuanLyDieuTri: number;

  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_6[1],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdcService: CDCService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.formSearch.controls["quarter"].setValue(1);
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }

    this.detectMode();    
    this.getData();

    this.tyLeTuVongChart.pieChartOptions.legend.position = "right";
    this.tangHuyetApCapTinh.pieChartOptions.legend.position = "right";
    this.daiThaoDuongCapTinh.pieChartOptions.legend.position = "right";
  }

  public getFilter() {
    let params = {};
    if (this.formSearch.controls.year.value) {
      params["nam"] = this.formSearch.controls.year.value;
    }
    if(this.formSearch.controls.month.value){
      params["thang"] = this.formSearch.controls.month.value==0?null:this.formSearch.controls.month.value;
    }
    // if (
    //   this.formSearch.controls.month.value &&
    //   this.formSearch.controls["timeType"].value == 1
    // ) {
    //   params["thang"] = this.formSearch.controls.month.value;
    // }
    // if (
    //   this.formSearch.controls.quarter.value &&
    //   this.formSearch.controls["timeType"].value == 2
    // ) {
    //   params["qui"] = this.formSearch.controls.quarter.value;
    // }

    return params;
  }

  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.tangHuyetApCapHuyen.barChartOptions.maintainAspectRatio =
      !this.isFitPage;
    this.daiThaoDuongCapHuyen.barChartOptions.maintainAspectRatio =
      !this.isFitPage;
    this.tyLeTuVongChart.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tyLeTuVongChart.pieChartType = "doughnut"
    this.tangHuyetApCapTinh.pieChartOptions.maintainAspectRatio =
      !this.isFitPage;
    this.daiThaoDuongCapTinh.pieChartOptions.maintainAspectRatio =
      !this.isFitPage;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }

  public getData(): void {
    this.tyLePhatHienTangHuyetApCapHuyen();
    this.tyLePhatHienDaiThaoDuongCapHuyen();
    this.tyLeTuVong();
    this.tyLePhatHienTangHuyetApCapTinh();
    this.tyLePhatHienDaiThaoDuongCapTinh();
    this.soLuongBNHuyetApDaiThaoDuong();
  }

  tyLePhatHienTangHuyetApCapHuyen(): void {
    let obj = this.getFilter();
    this.isWithChart=1
    this.subscription.push(
      this.cdcService.getTLPhatHienTangHuyetApCapHuyen(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tangHuyetApCapHuyen.barChartData = [];
            return false;
          }
          this.tangHuyetApCapHuyen.barChartLabels = rs.data.map(
            (x) => x.TEN_HUYEN
          );
          if(data.length <= 6){
            this.isWithChart = 1
          }else{
            this.isWithChart = 2
          }
          this.tangHuyetApCapHuyen.barChartData = [
            {
              data: rs.data.map((x) => x.CHUA_PHAT_HIEN),
              label: "TL người chưa phát hiện (%)",
              backgroundColor: COLOR_PALETTES.PALETTE_1[0],
            },
            {
              data: rs.data.map((x) => x.DUOC_QL_DIEU_TRI),
              label: "TL người phát hiện được QL điều trị",
              backgroundColor: COLOR_PALETTES.PALETTE_1[1],
              datalabels:{
              }
            },
            {
              data: rs.data.map((x) => x.CHUA_QL_DIEU_TRI),
              label: "TL người phát hiện chưa QL điều trị",
              backgroundColor: COLOR_PALETTES.PALETTE_1[2],
              datalabels:{
              }
            },
          ];
          this.tangHuyetApCapHuyen.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: true,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                    
                  },
                  
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; 
                  // or >= 1 or !== 0 or ...
                },
                
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        }));
  }

  tyLePhatHienDaiThaoDuongCapHuyen(): void {
    let obj = this.getFilter();
    
    this.isWithChart1 =1
    this.subscription.push(
      this.cdcService.getTLPhatHienDaiThaoDuongCapHuyen(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.daiThaoDuongCapHuyen.barChartData = [];
            return false;
          }
          this.daiThaoDuongCapHuyen.barChartLabels = data.map(x =>  x.TEN_HUYEN);
          if(data.length <= 6){
            this.isWithChart1 = 1
          }else{
            this.isWithChart1 = 2
          }
          this.daiThaoDuongCapHuyen.barChartData = [
            {
              data: data.map((x) => x.CHUA_PHAT_HIEN),
              label: "Tỷ lệ người chưa phát hiện (%)",
              backgroundColor: COLOR_PALETTES.PALETTE_6[0],
            },
            {
              data: data.map((x) => x.DUOC_QL_DIEU_TRI),
              label: "Tỷ lệ người phát hiện được QL điều trị (%)",
              backgroundColor: COLOR_PALETTES.PALETTE_6[1],
            },
            {
              data: data.map((x) => x.CHUA_QL_DIEU_TRI),
              label: "Tỷ lệ người phát hiện chưa QL điều trị (%)",
              backgroundColor: COLOR_PALETTES.PALETTE_6[2],
            },
          ];
          this.daiThaoDuongCapHuyen.barChartOptions = {
            layout: {
              padding: {
                top: 30
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                fontColor: '#fff',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                     fontColor: "#fff",
                     maxRotation: 0,
                     minRotation: 0,
                  },
                  afterTickToLabelConversion: function(data) {
                    // custom function to split labels into multiple lines
                    let xLabels = data.ticks;
                    xLabels.forEach(function (value, index, array) {
                      let lines = value.split(' ');
                      array[index] = [];
                      let i = 0;
                      while (lines.length > 0) {
                        array[index].push(lines.shift());
                        if (i > 0) {
                          array[index][i] = ' ' + array[index][i];
                        }
                        i++;
                      }
                    });
                    data.ticks = [].concat.apply([], data.ticks);
                  },
                  gridLines: { display: false },
                  stacked: true,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: false },
                  stacked: true,
                  ticks: {
                    display:true,
                    fontColor: "#fff",
                    suggestedMin: 0,
                    
                  },
                  
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                // render: "value",
                color: "#fff",
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > '0'; 
                  // or >= 1 or !== 0 or ...
                },
                
              },
            },
          };
        }, err => {
          this.snackbar.showError(MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL, MESSAGE_TYPE.ERROR);
        }));
      }

  tyLeTuVong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLTuVong(obj).subscribe(
        (rs: any) => {
          this.tyLeTuVongChart.pieChartData = [];
          this.tyLeTuVongChart.pieChartLabels = [];
          if (!rs.success || rs.data.length == 0) {
            return false;
          }
          rs.data.forEach((element) => {
            this.tyLeTuVongChart.pieChartLabels.push(element.TEN_NGUYEN_NHAN);
            this.tyLeTuVongChart.pieChartData.push(element.TY_LE);
          });
          this.tyLeTuVongChart.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.DUTCH_FIELD },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tyLePhatHienTangHuyetApCapTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLPhatHienTangHuyetApCapTinh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.tangHuyetApCapTinh.pieChartData = [];
            return false;
          }
          let chuaPhatHien = data[0].CHUA_PHAT_HIEN;
          let duocQLDieuTri = data[0].DUOC_QL_DIEU_TRI;
          let chuaQLDieuTri = data[0].CHUA_QL_DIEU_TRI;

          this.tangHuyetApCapTinh.pieChartData = [
            chuaPhatHien,
            duocQLDieuTri,
            chuaQLDieuTri,
          ];
          this.tangHuyetApCapTinh.pieChartLabels = [
            "TL người chưa phát hiện (%)",
            "TL người phát hiện được QL điều trị (%)",
            "TL người phát hiện chưa QL điều trị (%)",
          ];
          this.tangHuyetApCapTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_1 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  tyLePhatHienDaiThaoDuongCapTinh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getTLPhatHienDaiThaoDuongCapTinh(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.daiThaoDuongCapTinh.pieChartData = [];
            return false;
          }
          let chuaPhatHien = data[0].CHUA_PHAT_HIEN;
          let duocQLDieuTri = data[0].DUOC_QL_DIEU_TRI;
          let chuaQLDieuTri = data[0].CHUA_QL_DIEU_TRI;

          this.daiThaoDuongCapTinh.pieChartData = [
            chuaPhatHien,
            duocQLDieuTri,
            chuaQLDieuTri,
          ];
          this.daiThaoDuongCapTinh.pieChartLabels = [
            "TL người chưa phát hiện (%)",
            "TL người phát hiện được QL điều trị (%)",
            "TL người phát hiện chưa QL điều trị (%)",
          ];
          this.daiThaoDuongCapTinh.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_6 },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  soLuongBNHuyetApDaiThaoDuong(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getSLBNHuyetApDaiThaoDuong(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.slDTDPhatHien = rs.data[0].DTD_SO_BN_PHAT_HIEN;
          this.slDTDQuanLyDieuTri = rs.data[0].DTD_SO_BN_QUAN_LY_DIEU_TRI;
          this.slHuyetApPhatHien = rs.data[0].HUYETAP_SO_BN_PHAT_HIEN;
          this.slHuyetApQuanLyDieuTri =
            rs.data[0].HUYETAP_SO_BN_QUAN_LY_DIEU_TRI;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }
  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

}
