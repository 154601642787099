import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { DanhMucDungChungModule } from 'src/app/modules/danh-muc-dung-chung/danh-muc-dung-chung.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { NotfoundComponent } from '../notfound/notfound.component';
import { ForbiddenComponent } from '../forbidden/forbidden.component';

@NgModule({
  declarations: [
    DefaultComponent,
    NotfoundComponent,
    ForbiddenComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DashboardModule,
    DanhMucDungChungModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule
  ],
  exports: [
    SharedModule
  ]
})
export class DefaultModule { }
