import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from '../constant/api-url';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ToChucCanBoService extends BaseService {
  
  getTrinhDoChuyenMon(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.TRINH_DO_CHUYEN_MON, requestData);
  }

  getQuanLyNhaNuoc(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.QUAN_LY_NHA_NUOC, requestData);
  }

  getTLVienChucHopDong(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.TY_LE_VIEN_CHUC_HOP_DONG, requestData);
  }

  getTLVienChucGioiTinh(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.TY_LE_VIEN_CHUC_GIOI_TINH, requestData);
  }

  getTLTrinhDoChinhTri(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.TY_LE_TRINH_DO_CHINH_TRI, requestData);
  }
  get4TheTong(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.THE_TONG, requestData);
  }
  getDanhMucDonVi(): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.DANH_MUC_DONVI);
  }
  getTongBs(requestData): Observable<any> {
    return super.get(apiUrl.TO_CHUC_CAN_BO.DANH_SACH_BS, requestData);
  }
}
