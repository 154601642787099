<div class="position-relative h-100">
  <canvas baseChart
    [data]="pieChartData"
    [labels]="pieChartLabels"
    [colors]="pieChartColor"
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend">
  </canvas>
</div>