import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { COLOR_PALETTES } from 'src/app/constant/system-constant';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import { $ } from 'protractor';
more(Highcharts);

@Component({
  selector: 'app-meter-highchart',
  templateUrl: './meter-highchart.component.html',
  styleUrls: ['./meter-highchart.component.scss']
})
export class MeterHighchartComponent implements OnInit {

  chart: Chart;

  @Input()
  public data: number = 0;

  @Input()
  public color: any = COLOR_PALETTES.RIVER_NIGHTS[4];

  public subColor: any = this.color + "1a";
  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.subColor = this.color + "1a";
    let chart = new Chart({
      title: {
        text: null
      },
      chart: {
        //height: '50%',
        height: (3 / 4 * 100) + '%',
        // width: '100%',
        backgroundColor: "#272A3D"
      },
      yAxis: {
        labels: {
          enabled: true,
          x: 0, y: 16,
          style: {
            color: "#fff"
          }
        },
        tickPositions: [0, 100],
        minorTickLength: 0,
        min: 0,
        max: 100,
        lineWidth: 0,
        gridLineWidth: 0,
        tickWidth: 0,
        plotBands: [{
          from: 0,
          to: this.data,
          color: this.color,
          thickness: '40%'
        }, {
          from: this.data,
          to: 100,
          color: this.subColor,
          thickness: '40%'
        }]
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '140%'
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            enabled: false
          },
          dial: {
            baseLength: '0%',
            baseWidth: 10,
            radius: '100%',
            rearLength: '0%',
            topWidth: 1,
            backgroundColor: this.color,
            borderColor: this.color
          },
          pivot: {
            backgroundColor: "#fff",
            borderColor: this.color,
            borderWidth: 4
          }
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
        name: 'Tỷ lệ',
        data: [this.data],
        type: 'gauge'
      }]
    });
    this.chart = chart;
    // chart.ref$.subscribe(console.log);
  }
}
