import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TtKiemNghiemDuocphamMyphamHgiComponent } from './tt-kiem-nghiem-duocpham-mypham-hgi/tt-kiem-nghiem-duocpham-mypham-hgi.component';

const routes: Routes = [
  { path: '', component: TtKiemNghiemDuocphamMyphamHgiComponent },
  { path: 'tt-kiem-nghiem-duoc-pham-my-pham-hgi', component: TtKiemNghiemDuocphamMyphamHgiComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TTKiemNghiemDuocPhamMyPhamHGIRoutingModule { }
