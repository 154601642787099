import { RoleGuardService } from './services/guardservice/role-guard.service';
import { AuthGuardService } from './services/guardservice/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { AuthModule } from './auth/auth.module';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { APIService } from './services/api.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthenticationService } from './services/authentication.service';
import { NghiepvuyComponent } from './modules/thongkebaocao/nghiepvuy/nghiepvuy.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    AuthModule,
    MatProgressSpinnerModule,    
  ],
  providers: [HttpService, APIService, AuthenticationService,AuthGuardService, RoleGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
