import { apiUrl } from "./../constant/api-url";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThongTinChungService extends BaseService {

    //TK ho so KB
    getTKHSKhamBenh(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_HS_KB, requestParam);
    }
    
    //TK số lượng cấp cứu
    getTKSLCapCuu(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_SL_CAPCUU, requestParam);
    }
    //TK số lượng tử vong
    getTKSLTuVong(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_SL_TUVONG, requestParam);
    }
    //Thống kê lượt ra vào viện
    GetTKSLRaVaoVien(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_LUOT_RA_VAO_VIEN, requestParam);
    }
    //Thống kê lượt cấp cưu tử vong
    GetTKSLCapCuuTuVong(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_SL_CAP_CUU_TU_VONG, requestParam);
    }
    //Thống kê lượt cấp cưu tử vong
    GetTKKCB(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_LUOT_KHAM_BENH, requestParam);
    }
    //Thống kê lượt cấp cưu tử vong
    GetSLNoiTru(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_LUOT_KHAM_BENH_NOI_TRU, requestParam);
    }
    //Thống kê lượt nội trú ngoại trú
    GetTKNoiTruNgoaiTru(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.TK_NOI_TRU_NGOAI_TRU, requestParam);
    }
    GetSLNoiTruNgoaiTru(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_NOI_TRU_NGOAI_TRU, requestParam);
    }
    //Số lượng lượt vào viện
    GetSLVaoVien(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_LUOT_VAO_VIEN, requestParam);
    }
    //Số lượng lượt vào viện
    GetSLRaVien(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_LUOT_RA_VIEN, requestParam);
    }
    //Số lượng ca nặng xin về
    GetSLCaNangXinVe(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_CA_NANG_XIN_VE, requestParam);
    }
    GetSLChuyenVien(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_CHUYEN_VIEN, requestParam);
    }
    GetSLNoiTruCuoiKy(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_NOI_TRU_CUOI_KY, requestParam);
    }
    GetSLNoiTruNew(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_NOI_TRU, requestParam);
    }
    GetSLNgoaiTruNew(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_NGOAI_TRU, requestParam);
    }
    GetSLCuoiKyNoiTruNew(requestParam): Observable<any> {
        return super.get(apiUrl.THONG_TIN_CHUNG.SL_CUOI_KY_NOI_TRU, requestParam);
    }
}