import { RoleGuardService as  RoleGuard} from './services/guardservice/role-guard.service';
import { AuthGuardService as AuthGuard } from './services/guardservice/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [
    { path: '', loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },
    { path: 'danh-muc-dung-chung', loadChildren: () => import('./modules/danh-muc-dung-chung/danh-muc-dung-chung.module').then((m) => m.DanhMucDungChungModule) },
    { path: 'he-thong', loadChildren: () => import('./modules/cauhinhhethong/cauhinhhethong.module').then((m) => m.CauHinhHeThongModule) },
    { path: 'report', loadChildren: () => import('./modules/thongkebaocao/thongkebaocao.module').then((m) => m.ThongKeBaoCaoModule) },
    { path: 'dashboard-vlg', loadChildren: () => import('./modules/dashboard-vlg/dashboard-vlg.module').then((m) => m.DashboardVLGModule) }
  ],
  canActivate: [AuthGuard, RoleGuard]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
