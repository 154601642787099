<mat-toolbar color="primary">
    <mat-toolbar-row>
        <div class="d-flex flex-wrap w-100 align-items-center">
            <button mat-icon-button (click)="toggleSideBar()">
                <mat-icon>menu</mat-icon>
            </button>
            <h3 class="mr-auto text-uppercase d-none d-lg-block">{{ this.USER_INFO.TEN_DON_VI }}</h3>
            <ul class="d-flex flex-wrap m-0 ml-auto">
                <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <div class="d-flex align-items-center">
                            <h3 class="mr-2">{{ this.USER_INFO.HO_TEN }}</h3>
                            <mat-icon>person_outline</mat-icon>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openModalViewDetail(viewDetailModalContent, 'viewDetailModal', 'lg')">
                            <mat-icon>person_pin</mat-icon> Thông tin tài khoản
                        </button>
                        <button mat-menu-item (click)="setUpdateUserFormData(); 
                                                            openModal(updateModalContent, 'updateModal', 'lg')">
                            <mat-icon>edit</mat-icon> Cập nhật thông tin
                        </button>
                        <button mat-menu-item (click)="setUpdatePasswordFormData(); 
                                                            openModal(passwordModalContent, 'passwordModal', 'lg')">
                            <mat-icon>vpn_key</mat-icon> Đổi mật khẩu
                        </button>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon> Đăng xuất
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<ng-template #viewDetailModalContent let-modal>
    <div class="modal-header">
        <h3 class="modal-title mb-0">Thông tin tài khoản</h3>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <p><b>Họ tên: </b></p>
                <p><b>Giới tính: </b></p>
                <p><b>Cấp tài khoản: </b></p>
                <p><b>Đơn vị: </b></p>
                <p><b>Email: </b></p>
                <p><b>Ngày sinh: </b></p>
                <p><b>Số điện thoại: </b></p>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-12">
                <p>{{ this.userDetail.HO_TEN }}</p>
                <p>{{ this.userDetail.TEN_GIOI_TINH }}</p>
                <p>{{ this.userDetail.LEVEL_USER_GIA_TRI }}</p>
                <p>{{ this.userDetail.TEN_DON_VI }}</p>
                <p>{{ this.userDetail.MAIL }}</p>
                <p>{{ this.userDetail.NGAY_SINH | date:dateFormat }}</p>
                <p>{{ this.userDetail.SDT }}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button color="warn" type="button" (click)="modal.dismiss('Cross click')"> Đóng </button>
    </div>
</ng-template>

<ng-template #updateModalContent let-modal>
    <div class="modal-header">
        <h3 class="modal-title mb-0">Cập nhật tài khoản người dùng</h3>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="updateUserForm" id="updateUserForm" (submit)="updateUser()">
        <div class="modal-body">
            <p class="mb-4 font-weight-bold">Tài khoản: 
                <span class="text-danger">{{updateUserForm.controls.username.value}}</span>
            </p>
            <div class="row">
                <!-- <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Mật khẩu </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input type="password" matInput formControlName="password" placeholder="Nhập mật khẩu" autocomplete=off>
                    </mat-form-field> 
                </div> -->
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Họ tên </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput formControlName="fullname" placeholder="Nhập họ tên người dùng">
                    </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Ngày sinh </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput formControlName="birthday" placeholder="Nhập ngày sinh" [matDatepicker]="birthdayPicker">
                        <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                        <mat-datepicker #birthdayPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Giới tính </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-select formControlName="sex" placeholder="Chọn giới tính">
                            <mat-option *ngFor="let sex of LIST_SEX" [value]="sex.value">
                                {{ sex.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Số điện thoại </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput formControlName="phone" placeholder="Nhập số điện thoại">
                    </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Email </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput formControlName="email" placeholder="Nhập email">
                    </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Đơn vị </label>
                    <ngx-dropdown class="w-100" placeholder="Chọn đơn vị" [data]="LIST_DON_VI"
                        [formControl]="updateUserForm.controls.office" keyId="MA_DON_VI" keyName="TEN_DON_VI">
                    </ngx-dropdown>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <span class="mr-auto text-danger font-italic">Bạn sẽ đăng nhập lại sau khi cập nhật thành công</span>
            <button class="mr-3" mat-raised-button color="warn" type="button" (click)="modal.dismiss('Cross click')"> Đóng </button>
            <button mat-raised-button color="primary" type="submit"> Cập nhật </button>
        </div>
    </form>
</ng-template>

<ng-template #passwordModalContent let-modal>
    <div class="modal-header">
        <h3 class="modal-title mb-0">Cập nhật mật khẩu tài khoản</h3>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="updatePasswordForm" id="updatePasswordForm" (submit)="updatePassword()">
        <div class="modal-body">
            <p class="mb-4 font-weight-bold">Tài khoản: 
                <span class="text-danger">{{updatePasswordForm.controls.username.value}}</span>
            </p>
            <div class="row">
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Mật khẩu cũ </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input type="password" matInput formControlName="password" placeholder="Nhập mật khẩu cũ" autocomplete=off>
                    </mat-form-field> 
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Mật khẩu mới </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input type="password" matInput formControlName="newpassword" placeholder="Nhập mật khẩu mới" autocomplete=off>
                    </mat-form-field> 
                </div>
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                    <label><span class="text-danger">*</span> Nhập lại mật khẩu mới </label>
                    <mat-form-field class="w-100" appearance="outline">
                        <input type="password" matInput formControlName="repassword" placeholder="Nhập lại mật khẩu mới" autocomplete=off>
                    </mat-form-field> 
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <span class="mr-auto text-danger font-italic">Bạn sẽ đăng nhập lại sau khi cập nhật thành công</span>
            <button class="mr-3" mat-raised-button color="warn" type="button" (click)="modal.dismiss('Cross click')"> Đóng </button>
            <button mat-raised-button color="primary" type="submit"> Cập nhật </button>
        </div>
    </form>
</ng-template>