


import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { ColorService } from 'src/app/services/color.service';
import { AggService } from "src/app/services/agg.service";
import { CommonFunctionService } from './../../../../services/common-function.service';

@Component({
  selector: 'app-phongdalieu',
  templateUrl: './phongdalieu.component.html',
  styleUrls: ['./phongdalieu.component.scss']
})
export class PhongdalieuComponent implements OnInit {
  subscription: Subscription[] = [];
  @ViewChild("benhLayTruyenTD", { static: true })
  benhLayTruyenTD: BarChartComponent;
  @ViewChild("benhNhanPhongDaLieu", { static: true })
  benhNhanPhongDaLieu: BarChartComponent;
  isFitPage = false;
  formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    nam: new FormControl(new Date().getFullYear()),
    thang: new FormControl((new Date().getMonth() + 1).toString()),
    loai: new FormControl(1),
    tungay:  new FormControl(new Date().toISOString()),
    denngay:  new FormControl(new Date().toISOString()),
  });
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  documentElement: any;
  isFullScreen = false;
  params: any;
  keyword = "";
  currentPage = 1;
  totalPage: number;
  rowStyles: any = {};
  rowStylesFull: any = {};
  timeOut: any;
  listThang = [];
  listNam = [];
  showNam = true;
  showThang = false;
  showTuNgay = false;
  showDenNgay = false;

  DHTL: number;
  HOANTHANH_DHTL: number;
  GIAMSAT: number;
  TIEPXUC: number;
  STI: number;
  GIANGMAI: number;
  LAU: number;
  screenWidth: number;
  screenHeight: number;
  classInvisible = 1;
  lableMonth = true;
  constructor(
    private snackbar: SnackbarService,
    private colorService: ColorService,
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
    private aggService : AggService,
    private commonService: CommonFunctionService
  ) { }

  ngOnInit(): void {
    this.benhLayTruyenTD.barChartOptions.legend.display = true;
    this.benhNhanPhongDaLieu.barChartOptions.legend.display = true;
    this.benhLayTruyenTD.barChartOptions.legend.position = "top";
    this.benhLayTruyenTD.barChartOptions.legend.align = "center";
    this.benhNhanPhongDaLieu.barChartOptions.legend.position = "top";
    this.benhNhanPhongDaLieu.barChartOptions.legend.align = "center";
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["nam"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    //this.formSearch.controls["month"].setValue([1,2,3,4,5,6,7,8,9,10,11,12]);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.lableMonth = true;
    this.onFilterChangeCombobox();
    this.detectMode();
  }
  getData(): void {
     this.getChiTieuPhongDaLieu();
     this.getLayTruyenTD();
     this.getPhongDaLieu();
  }

  getChiTieuPhongDaLieu(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getChiTieuPhongDaLieu(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.DHTL = rs.data[0].DHTL;
          this.HOANTHANH_DHTL = rs.data[0].HOANTHANH_DHTL;
          this.GIAMSAT = rs.data[0].GIAM_SAT;
          this.TIEPXUC = rs.data[0].TIEPXUC;
          this.STI = rs.data[0].STI;
          this.GIANGMAI = rs.data[0].GIANGMAI;
          this.LAU = rs.data[0].LAU;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  // getChiTieuPhongDaLieu(): void {
  //   let obj = this.getFilter();
  //   this.subscription.push(
  //     this.aggService.getChiTieuPhongDaLieu(obj).subscribe(
  //       (rs: any) => {
  //         if (!rs.success) {
  //           return false;
  //         }
  //         this.slNhapCanh = rs.data[0].NHAP_CANH;
  //         this.slNhapCanhNhiemCovid = rs.data[0].NGUOI_MAC_COVID;
  //         this.slXuatCanh = rs.data[0].XUAT_CANH;
  //         this.slQuaCanh = rs.data[0].QUA_CANH;
  //       },
  //       (err) => {
  //         this.snackbar.showError(
  //           MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
  //           MESSAGE_TYPE.ERROR
  //         );
  //       }
  //     )
  //   );
  // }
  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  getLayTruyenTD(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getPhongDaLieuDonVi(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.benhLayTruyenTD.barChartData = [];
            return false;
          }
          this.benhLayTruyenTD.barChartLabels = rs.data.map(
            (x) => this.commonService.convertStringToArray(" ", x.TEN_DON_VI, 2)
          );

          this.benhLayTruyenTD.barChartData = [
            {
              data: rs.data.map((x) => x.STI),
              label: "STI",
              backgroundColor: this.CONST_COLOR[4],
            },
            {
              data: rs.data.map((x) => x.GIANGMAI),
              label: "Giang mai",
              backgroundColor: this.CONST_COLOR[15],
            },
            {
              data: rs.data.map((x) => x.LAU),
              label: "Lậu",
              backgroundColor: this.CONST_COLOR[6],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }
  getPhongDaLieu(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.aggService.getPhongDaLieuDonVi(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.benhNhanPhongDaLieu.barChartData = [];
            return false;
          }
          this.benhNhanPhongDaLieu.barChartLabels = rs.data.map(
            (x) => this.commonService.convertStringToArray(" ", x.TEN_DON_VI, 2)
          );

          this.benhNhanPhongDaLieu.barChartData = [
            {
              data: rs.data.map((x) => x.DHTL),
              label: "Bệnh nhân đa hoá trị liệu",
              backgroundColor: this.CONST_COLOR[7],
            },
            {
              data: rs.data.map((x) => x.HOANTHANH_DHTL),
              label: "Bệnh nhân hoàn thành đa hoá trị liệu",
              backgroundColor: this.CONST_COLOR[1],
            },
            {
              data: rs.data.map((x) => x.GIAM_SAT),
              label: "Bệnh nhân giám sát",
              backgroundColor: this.CONST_COLOR[2],
            },
            {
              data: rs.data.map((x) => x.TIEPXUC),
              label: "Người tiếp xúc với BN phong",
              backgroundColor: this.CONST_COLOR[3],
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  CONST_COLOR: any[] = [
    COLOR_PALETTES.PALETTE_4[3],
    COLOR_PALETTES.GREY_TO_RED[6],
    COLOR_PALETTES.RIVER_NIGHTS[6],
    COLOR_PALETTES.ORANGE_TO_PURPLE[1],
    COLOR_PALETTES.RIVER_NIGHTS[4],
    COLOR_PALETTES.GREY_TO_RED[3],
    COLOR_PALETTES.PALETTE_1[1],
    COLOR_PALETTES.PALETTE_1[2],
    COLOR_PALETTES.PALETTE_1[4],
    COLOR_PALETTES.PALETTE_1[3],
    COLOR_PALETTES.PALETTE_2[1],
    COLOR_PALETTES.PALETTE_2[2],
    COLOR_PALETTES.PALETTE_2[4],
    COLOR_PALETTES.PALETTE_2[3],
    COLOR_PALETTES.PALETTE_3[1],
    COLOR_PALETTES.PALETTE_3[2],
    COLOR_PALETTES.PALETTE_3[4],
    COLOR_PALETTES.PALETTE_3[3],
  ];



  public getFilter() {
    let loai = this.formSearch.get("loai").value
    let nam  = this.formSearch.get("nam").value;
    let thang  = this.formSearch.get("thang").value.toString() == "Tất cả"?"": this.formSearch.get("thang").value.toString();
    let tungay = this.formatDate(this.formSearch.get("tungay").value).toString();
    let denngay = this.formatDate(this.formSearch.get("denngay").value).toString();
    let result = {};
    if(loai == 1){
      result = {
        nam: nam,
        thang: "",
        tungay: "",
        denngay: ""
      }
    }else if (loai == 2){
      result = {
        nam: nam,
        thang: thang,
        tungay: "",
        denngay: ""
      }
    } else {
      result = {
        nam: "",
        thang: "",
        tungay: tungay,
        denngay: denngay
      }
    }
    console.log(result)
    return result;
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
  }

  onFilterChangeCombobox(){
    let loai = this.formSearch.get("loai").value
    if(loai == 1 ){
      this.showNam = true
      this.showThang = false
      this.showTuNgay = false
      this.showDenNgay = false
    }else if(loai == 2){
      this.showNam = true
      this.showThang = true
      this.showTuNgay = false
      this.showDenNgay = false
    }else{
      this.showNam = false
      this.showThang = false
      this.showTuNgay = true
      this.showDenNgay = true
    }
    this.getData()
  }
  onFilterChange (){
    this.detectMode();
    this.getData();
  }

  detectMode() {
    let mode = this.activatedRoute.snapshot.params.mode;
    this.isFitPage = mode === null || mode !== "responsive";
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();
    this.benhNhanPhongDaLieu.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.benhLayTruyenTD.barChartOptions.maintainAspectRatio = !this.isFitPage;

  }


  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others =
        16 + // padding top
        43.75 +
        16 + // form height and its margin bottom
        16; // 2 rows spacing
      let rowHeight = "calc((100% - " + others + "px) / 2)";
      this.rowStyles = { height: rowHeight, "margin-bottom": "1rem" };
    }
  }


}
