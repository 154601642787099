import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginLabels from 'chartjs-plugin-datalabels';
import { Context } from 'vm';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: { fontColor: "#fff" }
    },
    scales: {
      xAxes: [{
        gridLines: { display: false },
        ticks: {
          fontColor: "#fff",
          maxRotation: 0,
          minRotation: 0,
          callback: (value, index, values) => { // using lamda expression to call "this"
            if (typeof value == 'number') {
              return (this.isSummary) ? this.nFormatter(value, 1) : value;
            }
            if (typeof value == 'string') {
              return (this.xLabelMaxLength > 0) 
                          ? (value.length > this.xLabelMaxLength ? (value.substring(0, this.xLabelMaxLength) + '...') : value)
                          : value;
            }
            return value;
          }
        },        
        scaleLabel: {
          display: true,
          labelString: "",
          fontColor: "#fff",
        },
      }],
      yAxes: [{ 
        gridLines: { display: false},
        ticks: {
          fontColor: "#fff",
          // suggestedMin: 0,
          padding: 20,
          // display: false
          callback: (value, index, values) => { // using lamda expression to call "this"
            if (typeof value == 'number') {
              if (this.summaryToMillion) {
                return this.nFormatterVNCurrency(value);
              }
              return (this.isSummary) ? this.nFormatter(value, 1) : value;
            }
            if (typeof value == 'string') {
              return (this.yLabelMaxLength > 0) 
                          ? (value.length > this.yLabelMaxLength ? (value.substring(0, this.yLabelMaxLength) + '...') : value)
                          : value;
            }
            return value;
          }
        }
      }],
    },
    layout: {
      padding: {
        top: 35,
        right: 20
      }
    },
    plugins: {
      labels: false,
      datalabels: {
        color: "#fff",
        // backgroundColor: ((context: Context) => this.hexToRGB(context.dataset.borderColor, 0.3)),
        borderRadius: 4,
        // font: { weight: 'bold' },
        formatter: (value, context) => {
          if (this.summaryToMillion) {
            return this.nFormatterVNCurrency(value, 0);
          }
          return new Intl.NumberFormat("vi-VN").format(value);
        },
        padding: 6,
        display: function (context) {
          //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
          return (context.dataset.data[context.dataIndex] < '1') ? false : 'auto';
        },
        // display: false,
        clamp: true,
        font: {
          size: 11
        }
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItems, data) => { return (data.labels[tooltipItems[0].index] + "").replace(/,/g, " "); },
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = parseFloat(dataset.data[tooltipItem.index].toString());
          return new Intl.NumberFormat("vi-VN").format(currentValue);
        }
      }
    }
  };

  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginLabels, {
    beforeInit: function (chart, options) {
      chart.legend.afterFit = function () {
        this.height += 20; // must use `function` and not => because of `this`
      };
    }
  }];

  @Input()
  public lineChartData: ChartDataSets[] = [];

  @Input()
  public lineChartLabels: Label[] = [];

  @Input()
  public lineChartColors: Color[] = [];

  @Input()
  public lineChartLegend = true;

  @Input()
  public isSummary = false;

  @Input()
  public summaryToMillion = false;

  @Input()
  public xLabelMaxLength = 0;

  @Input()
  public yLabelMaxLength = 0;

  constructor() { }

  ngOnInit() {
  }

  public hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  /**
   * Go to bar-chart.component.ts to see the example 
   * @param num 
   * @param digits 
   * @returns the sumarized number
   */
  private nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỷ" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  
  nFormatterVNCurrency(num, digits?) {
    const lookup = [
      // { value: 1, symbol: "" },
      // { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: " triệu" },
      // { value: 1e9, symbol: " triệu" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? new Intl.NumberFormat("vi-VN").format(
          parseFloat((num / item.value).toFixed(digits))
        ) + item.symbol
      : "0";
  }
}
