import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { pageSizeOptions } from 'src/app/services/config.service';
import { PaginatorService } from 'src/app/services/paginator.service';

@Component({
  selector: 'app-ng-modal',
  templateUrl: './ng-modal.component.html',
  styleUrls: ['./ng-modal.component.scss']
})
export class NgModalComponent implements OnInit {
  @Input()
  public modal: any;

  @Input()
  public ELEMENT_DATA: any[] = [];
  @Input()
  public pageIndex: number = 0;
  @Input()
  public length: number = 0;
  @Input()
  public COLUMNS_SCHEMA: any[] = [];

  @Output() 
  public paginatorOut: EventEmitter<any> = new EventEmitter();
  
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  pageOption = pageSizeOptions;
  pageSizeDefault: number = this.pageOption[0];

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  
  constructor(
    private translator: PaginatorService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
   }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.translator.translatePaginator(this.paginator);
    this.cdRef.detectChanges();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['ELEMENT_DATA']) {
      this.ELEMENT_DATA = changes['ELEMENT_DATA'].currentValue;
      this.dataSource.data = this.ELEMENT_DATA;
      this.translator.translatePaginator(this.paginator);
      this.resetPaginator(this.pageIndex, this.length);
      this.displayedColumns = this.COLUMNS_SCHEMA.map((col) => col.key);
    }
  }

  resetPaginator(index: number, length: number) {
    setTimeout(() => { // need timeout
      this.paginator.pageIndex = index;
      this.paginator.length = length;
    });
  }

  pageChange(event?: PageEvent) {
    this.paginatorOut.emit(event);
  }

}
