import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Chart, ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label } from "ng2-charts";
import * as pluginLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    // title: {
    //   display: true,
    //   text: 'Custom Chart Title',
    //   padding: 15,
    //   fontColor: '#fff'
    // },
    layout: {
      padding: {
        top: 35,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: { fontColor: "#fff" },
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            fontColor: "#fff",
            callback: (value, index, values) => {
              // using lamda expression to call "this"
              if (typeof value == "number") {
                if (this.isPyramid) {
                  return this.nFormatter(Math.abs(value), 1);
                }
                return this.isSummary ? this.nFormatter(value, 1) : value;
              }
              if (typeof value == "string") {
                return this.xLabelMaxLength > 0
                  ? value.length > this.xLabelMaxLength
                    ? value.substring(0, this.xLabelMaxLength) + "..."
                    : value
                  : value;
              }
              return value;
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: { display: false },
          ticks: {
            fontColor: "#fff",
            suggestedMin: 0,
            callback: (value, index, values) => {
              // using lamda expression to call "this"
              if (typeof value == "number") {
                if (this.isSummaryVN) {
                  return this.nFormatterVN(value, 0);
                }
                if (this.summaryToMillion) {
                  return this.nFormatterVNCurrency(value);
                }
                return this.isSummary ? this.nFormatter(value, 1) : value;
              }
              if (typeof value == "string") {
                return this.yLabelMaxLength > 0
                  ? value.length > this.yLabelMaxLength
                    ? value.substring(0, this.yLabelMaxLength) + "..."
                    : value
                  : value;
              }
              return value;
            },
          },
        },
      ],
    },
    plugins: {
      labels: false,
      datalabels: {
        // render: "value",
        color: "#fff",
        display: (context) => {
          if (this.isPyramid) {
            return "auto";
          }
          //return context.dataset.data[context.dataIndex] > 10; // or >= 1 or !== 0 or ...
          return context.dataset.data[context.dataIndex] < '1' ? false : "auto";
        },
        formatter: (value, context) => {
          if (this.isPyramid) {
            return new Intl.NumberFormat("vi-VN").format(Math.abs(value));
          }
          if (this.summaryToMillion) {
            return this.nFormatterVNCurrency(value, 0);
          }
          return new Intl.NumberFormat("vi-VN").format(value);
        },
        clamp: true,
        font: {
          size: 11,
        },
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItems, data) => {
          return (data.labels[tooltipItems[0].index] + "").replace(/,/g, " ");
        },
        label: (tooltipItem, data) => {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = parseFloat(
            dataset.data[tooltipItem.index].toString()
          );

          if (this.isPyramid) {
            return new Intl.NumberFormat("vi-VN").format(
              Math.abs(currentValue)
            );
          } else {
            return new Intl.NumberFormat("vi-VN").format(currentValue);
          }
        },
      },
    },
  };

  public barChartType: ChartType = "bar";
  public barChartPlugins = [pluginLabels];

  @Output() barChartClicked: EventEmitter<any> = new EventEmitter();

  @Input()
  public barChartLegend = true;

  @Input()
  public barChartLabels: Label[] = [];

  @Input()
  public barChartData: ChartDataSets[] = [];

  @Input()
  public isSummary = false;

  @Input()
  public isSummaryVN = false;

  @Input()
  public isPyramid = false;

  @Input()
  public summaryToMillion = false;

  @Input()
  public xLabelMaxLength = 0;

  @Input()
  public yLabelMaxLength = 0;

  constructor() {}

  ngOnInit() {}

  private nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỷ" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  private nFormatterVN(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: " triệu" },
      { value: 1e9, symbol: " tỉ" },
      // { value: 1e12, symbol: "T" },
      // { value: 1e15, symbol: "P" },
      // { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  nFormatterVNCurrency(num, digits?) {
    const lookup = [
      // { value: 1, symbol: "" },
      // { value: 1e3, symbol: " nghìn" },
      { value: 1e6, symbol: "" },
      // { value: 1e9, symbol: " triệu" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? new Intl.NumberFormat("vi-VN").format(
          parseFloat((num / item.value).toFixed(digits))
        ) + item.symbol
      : "0";
  }

  /*
    const tests = [
      { num: 0, digits: 1 },
      { num: 12, digits: 1 },
      { num: 1234, digits: 1 },
      { num: 100000000, digits: 1 },
      { num: 299792458, digits: 1 },
      { num: 759878, digits: 1 },
      { num: 759878, digits: 0 },
      { num: 123, digits: 1 },
      { num: 123.456, digits: 1 },
      { num: 123.456, digits: 2 },
      { num: 123.456, digits: 4 }
    ];
    Output
      nFormatter(0, 1) = 0
      nFormatter(12, 1) = 12
      nFormatter(1234, 1) = 1.2k
      nFormatter(100000000, 1) = 100M
      nFormatter(299792458, 1) = 299.8M
      nFormatter(759878, 1) = 759.9k
      nFormatter(759878, 0) = 760k
      nFormatter(123, 1) = 123
      nFormatter(123.456, 1) = 123.5
      nFormatter(123.456, 2) = 123.46
      nFormatter(123.456, 4) = 123.456
  */

  onChartClick(event: any) {
    // Emit the onClick event to the parent component
    // this.barChartClicked.emit(event);
    if (event.active.length > 0) {
      const dataIndex = event.active[0]._index;
      const datasetIndex = event.active[0]._datasetIndex;
      const label = this.barChartLabels[dataIndex];
      const data = this.barChartData[datasetIndex].data[dataIndex];
      this.barChartClicked.emit({label, data});
    }
  }
}
