// create by Tạ Văn Hoàng Hên
// date: 28/12/2022
// servive của Menu Giam Dinh y Khoa
import { apiUrl } from "../constant/api-url";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GiamDinhYServive extends BaseService {
    getListNam(): Observable<any> {
        return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_NAM);
    }
    getListThang(): Observable<any> {
        return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_THANG);
    }
    getListQuy(): Observable<any> {
        return super.get(apiUrl.DANH_MUC_DUNG_CHUNG.LAY_DS_QUY);
    }
    getTKSoCaGiamDinh(requestParam): Observable<any> {
        return super.get(apiUrl.GIAM_DINH_Y_KHOA.GIAM_DINH_PHAP_Y.TK_SOCA_GIAMDINH, requestParam);
    }
    getTKSoCaGiamDinhKhuyetTat(requestParam): Observable<any> {
        return super.get(apiUrl.GIAM_DINH_Y_KHOA.GIAM_DINH_PHAP_Y.TK_SOCA_GIAMDINH_MUCDO_KHUYETTAT, requestParam);
    }
    getTKSoCaGiamDinhHGI(requestParam): Observable<any> {
        return super.get(apiUrl.GIAM_DINH_Y_KHOA_HGI.GIAM_DINH_PHAP_Y_HGI.TK_SOCA_GIAMDINH, requestParam);
    }
    getTKSoCaGiamDinhKhuyetTatHGI(requestParam): Observable<any> {
        return super.get(apiUrl.GIAM_DINH_Y_KHOA_HGI.GIAM_DINH_PHAP_Y_HGI.TK_SOCA_GIAMDINH_MUCDO_KHUYETTAT, requestParam);
    }
    getTyLeCaKhamGiamDinhHGI(requestParam): Observable<any>{
        return super.get(apiUrl.GIAM_DINH_Y_KHOA_HGI.GIAM_DINH_PHAP_Y_HGI.TL_CAKHAM_GIAMDINH, requestParam);
    }
}