import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhongdalieuRoutingModule } from './phongdalieu-routing.module';
import { PhongdalieuComponent } from './phongdalieu/phongdalieu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { TamthanComponent } from './tamthan/tamthan.component';

@NgModule({
  declarations: [PhongdalieuComponent, TamthanComponent],
  imports: [
    CommonModule,
    PhongdalieuRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class PhongdalieuModule { }
