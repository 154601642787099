
<div [class]="'main-wrapper bg-ioc' + (this.isFitPage ? ' vh-100' : '')">
    <div class="container-fluid h-100 pt-3">
        <div class="d-flex justify-content-end mb-3 pt-2 w-100">
            <form class="dark-mode mb-3"  [formGroup]="formSearch" id="formSearch">
                <div class="d-flex align-items-center">
                
                    <!-- <div  *ngIf="!isHuyen" class="ml-auto mr-3">
                        <ngx-dropdown panelClass="dark-mode" class="mb-0 w-100"  placeholder="Chọn huyện/thị/thành" [data]="listHuyen"  (selected)="getData()"
                            [formControl]="formSearch.controls.huyen" label="Huyện/Thị/Thành"
                            keyId="MA_HUYEN" keyName="TEN_HUYEN">
                        </ngx-dropdown>
                    </div>
                    <div *ngIf="isHuyen"  class="ml-auto mr-3">
                        <mat-form-field  class="mb-0 w-100 " appearance="outline" floatLabel="always">
                            <mat-label>Quận/huyện</mat-label>
                            <input matInput readonly="true" formControlName="tenhuyen" />
                        </mat-form-field>
                    </div> -->
                    <div class="mr-3" >
                        <ngx-dropdown panelClass="dark-mode" class="mb-0 dark-mode" placeholder="Chọn năm" [data]="YEARS" [formControl]="formSearch.controls.year" label="Chọn năm" (selected)="getData()" keyId="id" keyName="text">
                        </ngx-dropdown>
                    </div>
                    
                    <div  class="ml-auto mr-3">
                        <mat-form-field  class="w-100 mb-0" appearance="outline">
                            <mat-label>Chọn tháng</mat-label>
                            <mat-select panelClass="dark-mode" (selectionChange)="getData()" formControlName="thang">
                                <mat-option [value]="thang.GIA_TRI_THANG" *ngFor="let thang of listThang"> {{thang.GIA_TRI_THANG =='Tất cả'?'Tất cả': 'Tháng ' + thang.GIA_TRI_THANG }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <button mat-raised-button class="btn-fullscreen" id="btn-fullscreen" (click)="showFullScreen()" matTooltip="Toàn màn hình">
                        <mat-icon color="primary">fullscreen</mat-icon>
                    </button>
                </div>
            
            </form>
        </div>  
        <!-- ROW 1 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ suy dinh dưỡng qua từng năm</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div *ngIf="!istyLeSuyDinhDuong" class="none-form">
                                <b>Dữ liệu chưa có!</b>
                            </div>
                            
                            <div class="h-100">
                                <app-line-chart #tyLeSuyDinhDuong [isSummary]="true"></app-line-chart>
                                <!-- <app-bar-chart #istyLeSuyDinhDuong [isSummary]="true"></app-bar-chart> -->
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Thống kê suy dinh dưỡng trẻ dưới 5 tuổi và dưới 2 tuổi</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div *ngIf="!issuyDinhDuongDuoi52" class="none-form">
                                <b>Dữ liệu chưa có!</b>
                            </div>
                            <div class="h-100" [style]="getDynamicWidth(this.suyDinhDuongDuoi52.barChartData[0], 17)">
                                <app-bar-chart #suyDinhDuongDuoi52 [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                                <!-- <app-bar-chart #suyDinhDuongDuoi52 [isSummary]="true"></app-bar-chart> -->
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW 2 -->
        <div [ngStyle]="this.rowStyles">
            <div class="row small-gutters h-100">
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Hộ gia đình được cán bộ chăm sóc</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div *ngIf="!istyLeSuyDinhDuong" class="none-form">
                                <b>Dữ liệu chưa có!</b>
                            </div>
                            
                            <div class="h-100">
                                <div class="h-100" [style]="getDynamicWidth(this.hoGDCanBoChamSoc.barChartData[0], 15)">
                                    <app-bar-chart #hoGDCanBoChamSoc [barChartLegend]="true" [isSummary]="true"></app-bar-chart>
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div [ngClass]="isFitPage ? 'col-6 h-100' : 'col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3'">
                    <div class="bg-chart p-2 h-100">
                        <h4 class="chart-title">Tỷ lệ suy dinh dưỡng trẻ em</h4>
                        <div class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                            <div *ngIf="!issuyDinhDuongDuoi52" class="none-form">
                                <b>Dữ liệu chưa có!</b>
                            </div>
                            
                            <div class="h-100">
                                <app-line-chart #tyLeSuyDinhDuongTre [isSummary]="true"></app-line-chart>
                                <!-- <app-bar-chart #istyLeSuyDinhDuong [isSummary]="true"></app-bar-chart> -->
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>