import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ID_TINH, MESSAGE_COMMON, MESSAGE_TYPE, COLOR_PALETTES } from 'src/app/constant/system-constant';
import { DmChungService } from 'src/app/services/dm-chung.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { AggService } from 'src/app/services/agg.service';
import { Api831Service } from 'src/app/services/api-831.service';

@Component({
  selector: 'app-dashboard-taolap-hssk',
  templateUrl: './dashboard-taolap-hssk.component.html',
  styleUrls: ['./dashboard-taolap-hssk.component.scss']
})
export class DashboardTaolapHsskComponent implements OnInit {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  public QUARTERS: any[] = [];
  public isFullScreen = false;
  public listDonvi: any = 0;
  public listTinh: any = [];
  public listHuyen: any = [];
  public listXa: any = [];
  public tenTinh: any = [];
  THE: any = {};
  public showDropdown: boolean = false;
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    timeType: new FormControl(0, [Validators.required]),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    quarter: new FormControl(""),
    tinh: new FormControl([""]),
    huyen: new FormControl([""]),
    xa: new FormControl([""]),

  });


  @ViewChild("TKSLHSSK_TaoLap_CapNhat", { static: true }) TKSLHSSK_TaoLap_CapNhat: BarChartComponent;
  @ViewChild("TLHSSK_TaoLap_CapNhat", { static: true }) TLHSSK_TaoLap_CapNhat: BarChartComponent;

  constructor(
    private aggService: AggService,
    private api831Service: Api831Service,
    private snackbar: SnackbarService,
    private dmchungService: DmChungService,
  ) { }

  userStorage = JSON.parse(sessionStorage.user); //lấy user trong sessionStorage


  async ngOnInit(): Promise<void> {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    for (let i = 1; i <= 4; i++) {
      this.QUARTERS.push({ id: i, text: "Quý " + i });
    }

    await this.getListTinhTheoUser();


    this.detectMode();

    this.getData();
    console.log(this.userStorage);

  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      if (subscription != undefined) subscription.unsubscribe();
    });
  }

  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    // this.buildStyles();

    this.TLHSSK_TaoLap_CapNhat.barChartType = "horizontalBar";
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.legend.display = false;
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.scales.xAxes[0].gridLines.display = true;
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.scales.xAxes[0].gridLines.color = "#fff";
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.scales.xAxes[0].gridLines.drawBorder = false;
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.scales.xAxes[0].ticks.suggestedMin = 0;
    this.TLHSSK_TaoLap_CapNhat.barChartOptions.plugins.datalabels.font = { size: 15 };


    // this.TKSLHSSK_TaoLap_CapNhat.barChartOptions.maintainAspectRatio = !this.isFitPage;
    // this.TKSLHSSK_TaoLap_CapNhat.barChartOptions.legend.display = false;
    // this.TKSLHSSK_TaoLap_CapNhat.barChartOptions.scales.yAxes[0].gridLines.display = true;
    // this.TKSLHSSK_TaoLap_CapNhat.barChartOptions.scales.yAxes[0].gridLines.color = "#fff";
    // this.TKSLHSSK_TaoLap_CapNhat.barChartOptions.scales.yAxes[0].gridLines.drawBorder = false;
  }

  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }

  showFullScreen() {
    // Kiểm tra trạng thái fullscreen
    if (!this.isFullScreen) {
      // Nếu không ở chế độ fullscreen, yêu cầu chuyển sang fullscreen
      document.documentElement.requestFullscreen();
      // Cập nhật trạng thái fullscreen
      this.isFullScreen = true;
    } else {
      // Nếu đang ở chế độ fullscreen, yêu cầu thoát fullscreen
      document.exitFullscreen();
      // Cập nhật trạng thái fullscreen
      this.isFullScreen = false;
    }
  }

  public getFilter(): any {
    let year = this.formSearch.controls.year.value;
    let month = this.formSearch.controls.month.value;
    let quarter = this.formSearch.controls.quarter.value;
    let timeType = this.formSearch.controls.timeType.value;
    let tinh = this.formSearch.controls.tinh.value;
    let huyen = this.formSearch.controls.huyen.value;
    let xa = this.formSearch.controls.xa.value;
    let result = { nam: year, thang: "", quy: "", tinh: tinh, huyen: huyen ?? "", xa: xa ?? "" };
    switch (timeType) {
      case 1: // theo tháng
        result = { nam: year, thang: month, quy: "", tinh: tinh, huyen: huyen ?? "", xa: xa ?? "" };
        break;
      case 2: // theo quý
        result = { nam: year, thang: "", quy: quarter, tinh: tinh, huyen: huyen ?? "", xa: xa ?? "" };
        break;
    }
    return result;
  }

  public getData(): void {
    this.getListDonVi();
    this.getListHuyenTheoUser();
    this.getListXaTheoUser();
    this.getTKSLHSSK_TaoLap_CapNhat();
    this.getTLHSSK_TaoLap_CapNhat();

  }

  public async getListDonVi(): Promise<void> {
    try {
      const obj = this.userStorage?.MA_DON_VI;
      const response = await this.aggService.layDanhSachDonVi(obj).toPromise();
      let maTinhItem = response.data.find(item => item.MA_DON_VI === obj);
      if (maTinhItem) {
        let MaTinh = maTinhItem.MA_TINH;
        return MaTinh;
      }
    } catch (err) {
      this.snackbar.showError(
        MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
        MESSAGE_TYPE.ERROR
      );
    }
  }

  public async getListTinhTheoUser(): Promise<void> {
    try {
      const MaTinhDmDonVi = await this.getListDonVi();
      const MaTinh = { matinh: MaTinhDmDonVi };
      const response = await this.dmchungService.getListTinh({ size: 100 }).toPromise();
      const data = response.data;
      const MaTinhDmTinh = data.find(e => e.MA_TINH === MaTinh.matinh);
      if (MaTinhDmTinh) {
        this.listTinh = [MaTinhDmTinh];
        this.formSearch.controls.tinh.setValue(this.listTinh[0]?.ID);
        return this.listTinh[0]?.ID;
      }
    } catch (error) {
      this.snackbar.showError(
        MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
        MESSAGE_TYPE.ERROR
      );
    }
  }

  public async getListHuyenTheoUser(): Promise<number | null> {
    try {
      const idTinh = await this.getListTinhTheoUser();
      const response = await this.dmchungService.getListHuyen({ idTinh, size: 100 }).toPromise();
      const data = response.data;
      this.listHuyen = data;
      return null;
    } catch (error) {
      this.snackbar.showError(
        MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
        MESSAGE_TYPE.ERROR
      );
      return null;

    }
  }

  public async getListXaTheoUser(): Promise<void> {
    try {
      const idTinh = await this.getListTinhTheoUser();
      const idHuyen = this.formSearch.controls.huyen.value;
      const response = await this.dmchungService.getListXa({ idTinh, idHuyen, size: 100 }).toPromise();
      const data = response.data;
      this.listXa = data;
      this.formSearch.controls.huyen.value != null && this.formSearch.controls.huyen.value != '' ? this.showDropdown = true : this.showDropdown = false;
    } catch (err) {
      this.snackbar.showError(
        MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
        MESSAGE_TYPE.ERROR
      );
    }
  }

  public getTKSLHSSK_TaoLap_CapNhat(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.api831Service.getTKSLHSSK_TaoLap_CapNhat(obj).subscribe(
        (rs) => {
          let data = rs.data;

          this.THE = {
            SL_TAOLAP: data[0].SL_TAOLAP,
            SL_CAPNHAT: data[0].SL_CAPNHAT,
            SL_TAOLAP_XACDINH: data[0].SL_TAOLAP_XACDINH,
            SL_TAOLAP_KHONGXACDINH: data[0].SL_TAOLAP_KHONGXACDINH
          }

          let danSo = data[0].DANSO;
          let SL_TAOLAP = data[0].SL_TAOLAP;
          let SL_CAPNHAT = data[0].SL_CAPNHAT;

          this.TKSLHSSK_TaoLap_CapNhat.barChartData =
            [
              {
                data: [danSo, SL_TAOLAP, SL_CAPNHAT],
                backgroundColor: "#1982c4",
                label: "test"
              }
            ];

          this.TKSLHSSK_TaoLap_CapNhat.barChartLabels = ["Dân số", "Hồ sơ tạo lập", "Hồ sơ cập nhật"];

          this.TKSLHSSK_TaoLap_CapNhat.barChartOptions = {
            maintainAspectRatio: !this.isFitPage,
            layout: {
              padding: {
                top: 35,
              },
            },
            responsive: true,
            legend: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: {
                    fontColor: "#fff",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: { display: true, color: "#fff", drawBorder: false },
                  ticks: {
                    fontColor: "#fff",
                    suggestedMin: 0,
                  },
                },
              ],
            },
            plugins: {
              labels: false,
              datalabels: {
                color: "#fff",
                clamp: true,
                font: {
                  size: 15,
                },
              },
            },


          };


        }
      )
    )
  }

  public getTLHSSK_TaoLap_CapNhat(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.api831Service.getTLHSSK_TaoLap_CapNhat(obj).subscribe(
        (rs) => {
          let data = rs.data;

          let TLL_TAOLAP = data[0].TL_TAOLAP;
          let TLL_CAPNHAT = data[0].TL_CAPNHAT;

          this.TLHSSK_TaoLap_CapNhat.barChartData =
            [
              {
                data: [TLL_TAOLAP, TLL_CAPNHAT],
                backgroundColor: "#669900"
              }
            ];

          this.TLHSSK_TaoLap_CapNhat.barChartLabels = ["Tỉ lệ tạo lập", "Tỉ lệ cập nhật"];
        }
      )
    )
  }

  public getNumber(value: any) {
    return new Intl.NumberFormat("vi-VN").format(Math.round(value));
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }

  private reduceLabel(label: string) {
    return label.replace(/trung tâm y tế/gi, "TTYT")
      .replace(/bệnh viện/gi, "BV")
      .replace(/phòng khám/gi, "PK")
      .replace(/trạm y tế/gi, "TYT");
  }
  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

  getDynamicHeight(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "min-height: 100%; height: " + length * percent + "%";
  }

}

