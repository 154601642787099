import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BarChartComponent } from 'src/app/shared/components/chart-js/bar-chart/bar-chart.component';
import { COLOR_PALETTES, MESSAGE_COMMON, MESSAGE_TYPE } from 'src/app/constant/system-constant';
import {CDCService} from 'src/app/services/trung-tam-cdc.service';
import { PieChartComponent } from "src/app/shared/components/chart-js/pie-chart/pie-chart.component";

@Component({
  selector: 'app-xet-nghiem-pkdk',
  templateUrl: './xet-nghiem-pkdk.component.html',
  styleUrls: ['./xet-nghiem-pkdk.component.scss']
})
export class XetNghiemPkdkComponent implements OnInit {
  private subscription: Subscription[] = [];
  public isFitPage = true;
  public rowStyles: any = {};
  public YEARS: any[] = [];
  public MONTHS: any[] = [];
  listThang = [];
  isWithChart1: any;
  public formSearch = new FormGroup({
    isFitPage: new FormControl(this.isFitPage),
    year: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required])
  });
  @ViewChild("xetNghiem", { static: true }) xetNghiem: BarChartComponent;
  @ViewChild("sucKhoesinhSan", { static: true }) sucKhoesinhSan: BarChartComponent;
  @ViewChild("tiemChung", { static: true }) tiemChung: BarChartComponent;
  @ViewChild("khamSuckhoe", { static: true }) khamSuckhoe: PieChartComponent;
  @ViewChild("CDHA", { static: true }) CDHA: PieChartComponent;



  constructor(
    private cdcService: CDCService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.formSearch.controls["year"].setValue(currentYear);
    for (let i = 2020; i <= currentYear; i++) {
      this.YEARS.push({ id: i, text: "Năm " + i });
    }
    let currentMonth = currentDate.getMonth() + 1; // 0 to 11, January = 0, February = 1, ...
    this.formSearch.controls["month"].setValue(currentMonth);
    this.MONTHS.push({id:-1,text:"Tất cả"});
    for (let i = 1; i <= 12; i++) {
      this.MONTHS.push({ id: i, text: "Tháng " + i });
    }
    this.detectMode();
    this.xetNghiem.barChartOptions.legend.display = false;
    this.sucKhoesinhSan.barChartOptions.legend.display = false;
    this.tiemChung.barChartOptions.legend.display = false;
    //this.khamSuckhoe.barChartOptions.legend.display = false;
    // this.tiemViemNaoNhatBan.barChartOptions.legend.display = false;
    // this.tiemVATPhuNuCoThai.barChartOptions.legend.display = false;

    this.getData();

  }
  public detectMode() {
    this.isFitPage = true;
    this.formSearch.controls.isFitPage.setValue(this.isFitPage);
    this.buildStyles();

    this.xetNghiem.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.sucKhoesinhSan.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.tiemChung.barChartOptions.maintainAspectRatio = !this.isFitPage;
    this.CDHA.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    this.khamSuckhoe.pieChartOptions.maintainAspectRatio = !this.isFitPage;
    // this.tiemVATPhuNuCoThai.barChartOptions.maintainAspectRatio = !this.isFitPage;
  }
  buildStyles() {
    this.rowStyles = {};
    if (this.isFitPage) {
      let others = (16 // padding top
        + 43.75 + 16 // form height and its margin bottom
        + 16); // 1 row spacing
      let rowHeight = 'calc((100% - ' + others + 'px) / 2)';
      this.rowStyles = { 'height': rowHeight, 'margin-bottom': '1rem' };
    }
  }
  showFullScreen() {
    document.documentElement.requestFullscreen();
  }

  public getFilter() {
    let year = this.formSearch.controls.year.value ?? "";
    let month = this.formSearch.controls.month.value ?? "";
    return { nam: year, thang: month};
  }
  public getData(): void {
     this.TiemChung();
     this.SucKhoeSinhSan();
    this.ChanDoanHinhAnh();
     this.XetNghiem();
    this.KhamSucKhoe();
    this.getListThang();
  }
  getListThang(){
    this.subscription.push(
      this.cdcService.getListThang().subscribe(
        (rs) => {
          this.listThang = rs.data;
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  XetNghiem(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getHdXetNghiem(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.xetNghiem.barChartLabels = [
            this.convertToArray(' ',"Vi sinh",1),
            "Virus",
            this.convertToArray(' ',"Ký sinh trùng",1),
            this.convertToArray(' ',"Lý hóa",1),
            this.convertToArray(' ',"Sinh hóa",1),
            "CĐHA",
          ];
          let label = rs.data.map((x) => x.LABEL);
          let arrN1 = rs.data.map((x) => Math.round(x.XET_NGHIEM_VI_SINH));
          let arrN2 = rs.data.map((x) => Math.round(x.XET_NGHIEM_VIRUS));
          let arrN3 = rs.data.map((x) => Math.round(x.KY_SINH_TRUNG));
          let arrN4 = rs.data.map((x) => Math.round(x.XET_NGHIEM_LY_HOA));
          let arrN5 = rs.data.map((x) => Math.round(x.XET_NGHIEM_SINH_HOA));
          let arrN6 = rs.data.map((x) => Math.round(x.CHAN_DOAN_HINH_ANH));


          let arrData = [arrN1[0], arrN2[0], arrN3[0], arrN4[0], arrN5[0], arrN6[0]];
          this.xetNghiem.barChartData = [
            {
              data: arrData,
              backgroundColor: COLOR_PALETTES.SPRING_PASTELS[5],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  SucKhoeSinhSan(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getHdSKSS(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.sucKhoesinhSan.barChartLabels = [
            this.convertToArray(' ',"Khám thai",1),
            this.convertToArray(' ',"Phụ khoa",1),
            this.convertToArray(' ',"Khám-tư vấn",1),
            this.convertToArray(' ',"Phá thai",1),
            this.convertToArray(' ',"Hiếm muộn",1),
            "BPTT",
          ];
          let label = rs.data.map((x) => x.LABEL);
          let arrN1 = rs.data.map((x) => Math.round(x.KHAM_THAI));
          let arrN2 = rs.data.map((x) => Math.round(x.PHU_KHOA));
          let arrN3 = rs.data.map((x) => Math.round(x.KHAM_TUVAN));
          let arrN4 = rs.data.map((x) => Math.round(x.PHA_THAI));
          let arrN5 = rs.data.map((x) => Math.round(x.TV_HIEM_MUON));
          let arrN6 = rs.data.map((x) => Math.round(x.BPTT));


          let arrData = [arrN1[0], arrN2[0], arrN3[0], arrN4[0], arrN5[0], arrN6[0]];
          this.sucKhoesinhSan.barChartData = [
            {
              data: arrData,
              backgroundColor: COLOR_PALETTES.DUTCH_FIELD[2],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  TiemChung(): void {
    let obj: any = this.getFilter();
    this.subscription.push(
      this.cdcService.getHdTiemChung(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          this.tiemChung.barChartLabels = [
            this.convertToArray(' ',"VX dại",1),
            this.convertToArray(' ',"HT dại",1),
            this.convertToArray(' ',"VX 6 trong 1",1),
            this.convertToArray(' ',"VX Sởi-Quai Bị-Rubella",1),
            this.convertToArray(' ',"VX tiêu chảy",1),
            this.convertToArray(' ',"VX uốn ván",1),
            this.convertToArray(' ',"HT uốn ván",1),
            this.convertToArray(' ',"VX phế cầu",1),
            this.convertToArray(' ',"VX viêm gan",1),
            this.convertToArray(' ',"VX viêm não mô cầu",1),
            this.convertToArray(' ',"VX thủy đậu",1),
            this.convertToArray(' ',"VX cúm",1),
            this.convertToArray(' ',"VX bạch hầu-ho gà-uốn ván",1),
            this.convertToArray(' ',"VX viêm não NB",1),
            this.convertToArray(' ',"VX covid-19",1)

          ];
          //let label = rs.data.map((x) => this.convertToArray(' ', (x.LABEL), 1));
          let arrN1 = rs.data.map((x) => Math.round(x.VX_DAI));
          let arrN2 = rs.data.map((x) => Math.round(x.HT_DAI));
          let arrN3 = rs.data.map((x) => Math.round(x.VX_6_IN_1));
          let arrN4 = rs.data.map((x) => Math.round(x.VX_SOI_QUAIBI));
          let arrN5 = rs.data.map((x) => Math.round(x.VX_TIEU_CHAY));
          let arrN6 = rs.data.map((x) => Math.round(x.VX_UON_VAN));
          let arrN7 = rs.data.map((x) => Math.round(x.HT_UON_VAN));
          let arrN8 = rs.data.map((x) => Math.round(x.VX_PHE_CAU));
          let arrN9 = rs.data.map((x) => Math.round(x.VX_VIEM_GAN));
          let arrN10 = rs.data.map((x) => Math.round(x.VX_VIEM_NAO_MO_CAU));
          let arrN11 = rs.data.map((x) => Math.round(x.VX_THUY_DAU));
          let arrN12 = rs.data.map((x) => Math.round(x.VX_CUM));
          let arrN13= rs.data.map((x) => Math.round(x.VX_BH_HG_UV));
          let arrN14 = rs.data.map((x) => Math.round(x.VX_VNNB));
          let arrN15 = rs.data.map((x) => Math.round(x.VX_COVID_19));


          let arrData = [arrN1[0], arrN2[0], arrN3[0], arrN4[0], arrN5[0],
           arrN6[0],arrN7[0], arrN8[0], arrN9[0], arrN10[0], arrN11[0], arrN12[0],
           arrN13[0], arrN14[0], arrN15[0]];
          this.tiemChung.barChartData = [
            {
              data: arrData,
              backgroundColor: COLOR_PALETTES.RIVER_NIGHTS[3],
              datalabels: { align: "end", anchor: "end", clamp: true },
            },
          ];
        },
        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  KhamSucKhoe(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getHdKSK(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.khamSuckhoe.pieChartData = [];
            return false;
          }
          let KSKCoQuan = data[0].KSK_CO_QUAN;
          let KSKCongTy = data[0].KSK_CONG_TY;

          this.khamSuckhoe.pieChartData = [
            KSKCoQuan,
            KSKCongTy,
          ];
          this.khamSuckhoe.pieChartLabels = [
            "Khám sức khỏe cơ quan",
            "Khám sức khỏe công ty - xí nghiệp",
          ];
          this.khamSuckhoe.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.RIVER_NIGHTS },
          ];
        },

        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }
  ChanDoanHinhAnh(): void {
    let obj = this.getFilter();
    this.subscription.push(
      this.cdcService.getHdCDHA(obj).subscribe(
        (rs: any) => {
          if (!rs.success) {
            return false;
          }
          let data = rs.data;
          if (data.length == 0) {
            this.CDHA.pieChartData = [];
            return false;
          }
          let SASanPhu = data[0].SAN_PHU;
          let SATongQuat = data[0].TONG_QUAT;
          let DoDienTim = data[0].DO_DIEN_TIM;

          this.CDHA.pieChartData = [
            SASanPhu,
            SATongQuat,
            DoDienTim
          ];
          this.CDHA.pieChartLabels = [
            "SÂ Sản phụ khoa",
            "SÂ tổng quát",
            "Đo điện tim"
          ];
          this.CDHA.pieChartColor = [
            { backgroundColor: COLOR_PALETTES.PALETTE_1 },
          ];
        },

        (err) => {
          this.snackbar.showError(
            MESSAGE_COMMON.GET_DATA_NOT_SUCCESSFUL,
            MESSAGE_TYPE.ERROR
          );
        }
      )
    );
  }

  getDynamicWidth(obj: any, percent: number) {
    let array: any[] = obj ? obj.data : [];
    let length = array ? array.length : 1;
    return "width: " + length * percent + "%";
  }

  private convertToArray(character: string, value: string, step?: number) {
    let array = value.split(character);
    let count = 0;
    let temp = '';
    let result = [];
    array.forEach(element => {
      temp += element + " ";
      count++;
      if (count == step ?? 1) {
        result.push(temp.trim());
        count = 0;
        temp = '';
      }
    });
    if (temp !== '') {
      result.push(temp);
    }
    return result;
  }




}
